import { queryString, request } from '../util'

const url = '/api/admins/'

export default {
  addAdmin (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getAdmin (id) {
    return request(url + id)
  },
  removeAdmin (id) {
    return request(url + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveAdmin (id, values) {
    return request(url + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  findAdminsByName (size, text, filter) {
    return request(`${url}find/name/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  listAdminsByPage (page, size, filter, text) {
    return request(`${url}page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  checkEmail (email) {
    return request(`${url}check/${email}`)
  }
}
