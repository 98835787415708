import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, introService, productService } from '../../../services'
import { notification, validator } from '../../../util'

// UI
import { Button, Input, Page, Panel, Spin, Switch, TextArea, Select } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm
const Option = Select.Option

class Intro extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      loadingImage: false
    }
  }

  componentDidMount () {
    if (this.canRead()) {
      if (this.isEdit()) {
        this.fetchIntro()
      }
    } else {
      authService.unauthorizeAction('/banner')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 15, md: 15 }
    }
    const { form, history } = this.props
    const { item, loading, loadingImage } = this.state

    return (
      <Page.Content>
        <Page.Header title='App Intro Slide' description='Provide guidance with intro picture and description'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Slide Details'>
                <Row>
                  <Col lg={8}>
                    <div className='intro-image-uploader'>
                      <UploadHolder
                        img={{
                          alt: 'Intro',
                          src: item.image ? item.image : ''
                        }}
                        loading={loadingImage}
                        readOnly={!this.canSave()}
                        height={250}
                        width={180}
                        upload={{
                          action: '/api/intro/upload/image',
                          beforeUpload: (file) => this.checkImage(file),
                          data: { _id: item._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: (info) => this.handleUploadImage(info),
                          showUploadList: false
                        }}
                      />
                    </div>
                    <div className='intro-image-hint'>
                      Size: 450px x 800px (JPG or PNG) <br />
                      Maximum file size: 2 MB
                    </div>
                  </Col>
                  <Col lg={16}>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 64 characters' },
                            { max: 64, message: 'Name must be between 2 and 64 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'description',
                        options: {
                          initialValue: item.description,
                          rules: [
                            { min: 2, message: 'Description must be between 2 and 256 characters' },
                            { max: 256, message: 'Description must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Please enter description' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Description'
                      }}
                      input={{
                        autosize: { minRows: 3, maxRows: 5 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    {this.getPositionSelect(formItemLayout, this.isEdit(), item.position)}

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Visible'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 3
                      }}
                      readOnly={!this.canSave()}
                      tooltip="App intro is visible when set to 'Yes'"
                    />
                  </Col>
                </Row>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkDecimal (rule, value, callback) {
    if (value && value.length > 0 && !validator.isDecimal(value)) {
      callback(new Error('Can contain only numbers'))
    } else if (value < 1 || value > 127) {
      callback(new Error('Position must be between 1 and 127'))
    }

    callback()
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  async fetchIntro () {
    try {
      this.setState({ loading: true })
      const item = await introService.get(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load app intro successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async fetchProducts () {
    try {
      const filter = {}
      filter.active = true
      const response = await productService.getProducts({ filter, projection: { name: 1 }, sort: { name: 1 } })
      this.setState({ products: response })
    } catch (e) {
      console.error(e)
    }
  }

  getPositionSelect (formShortItemLayout, isEdit, position) {
    const { form, intro } = this.props
    const { total } = intro
    const defaultPosition = isEdit ? position : total + 1
    const list = []

    for (let i = 1; i <= total + 1; i++) {
      list.push(i)
    }

    return (
      <Select
        fieldDecorator={{
          id: 'position',
          options: {
            initialValue: defaultPosition
          }
        }}
        form={form}
        formItem={{
          ...formShortItemLayout,
          hasFeedback: false,
          label: 'Position'
        }}
        list={list}
        listFunc={(position) => {
          return <Option key={position} value={position}>{position}</Option>
        }}
        select={{
          showSearch: true,
          placeholder: 'Select a position'
        }}
      />
    )
  }

  handleDelete () {
    const { history, match, intro } = this.props
    const { list, total } = intro

    confirm({
      title: 'Confirm to delete this app intro?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          let id = match.params.id
          let updateList = []
          let oldPosition = 0

          for (let i = 0; i < total; i++) {
            const { _id, position } = list[i]
            const newItem = { id: _id }

            if (id === _id.toString()) {
              oldPosition = position
            } else {
              if (oldPosition > 0 && position > oldPosition) {
                newItem.position = i
              } else {
                newItem.position = i + 1
              }

              updateList.push(newItem)
            }
          }

          const response = await introService.remove(id, { list: updateList })

          if (response) {
            notification.show('success', 'Deleted successfully', 'App intro deleted successfully.')
            history.replace('/highlight/app')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete app intro successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history, intro } = this.props
    const { validateFields } = this.props.form
    const { list, total } = intro

    if (total > 99) {
      notification.show('error', 'Unable to save successfully', 'Maximum of 100 app intro.')
      return
    }

    validateFields(async (errors, values) => {
      if (!errors) {
        let response
        const { item } = this.state
        values.description = values.description || ''
        this.setState({ loading: true })

        try {
          values.image = item.image ? (item.image.url || item.image) : ''
          values.image_thumb = item.image ? (item.image.thumbUrl || item.image_thumb) : ''
          values.active = values.active ? 1 : 0

          if (this.isEdit()) {
            let id = item._id
            let oldPosition = item.position || 0
            const { position: newPosition } = values
            const positionChanged = newPosition !== oldPosition
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                const newItem = { id: _id }

                if (_id === id) {
                  newItem.position = newPosition
                } else {
                  if (newPosition < oldPosition) {
                    if (itemPosition < newPosition || itemPosition > oldPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx + 2
                    }
                  } else {
                    if (itemPosition < oldPosition || itemPosition > newPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx
                    }
                  }
                }

                return newItem
              })
            }

            response = await introService.save(item._id, values)
            console.log('ok', { ...values })
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'App intro saved successfully.')
            }
          } else {
            const { position: newPosition } = values
            const positionChanged = newPosition < total + 1
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                return { id: _id, position: itemPosition < newPosition ? idx + 1 : idx + 2 }
              })
            }

            response = await introService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'App intro saved successfully.')
              history.replace(`/highlight/app/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save app intro successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      console.log('done', info.file)
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteAppIntro')
  }

  canRead () {
    return this.hasAccess('readAppIntro')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateAppIntro' : 'createAppIntro')
  }

  canUpload () {
    return this.canSave()
  }

  canDeletePhoto () {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto () {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Intro }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Intro))
