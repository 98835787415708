import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Button extends Component {
  render () {
    const { children, className, ghost } = this.props
    const properties = { ...this.props }
    delete properties.ghost
    properties.className = formatter.toClassName(['wd-button', ghost ? 'button-ghost' : '', className])

    return (
      <button {...properties}>{children}</button>
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ghost: PropTypes.bool,
  onclick: PropTypes.func
}
