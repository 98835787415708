export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED'
export const CATEGORIES_FAILED = 'CATEGORIES_FAILED'

export const FETCH_ALL_CATEGORIES = 'FETCH_ALL_CATEGORIES'
export const ALL_CATEGORIES_LOADED = 'ALL_CATEGORIES_LOADED'
export const ALL_CATEGORIES_FAILED = 'ALL_CATEGORIES_FAILED'

export const FETCH_WEB_CATEGORIES = 'FETCH_WEB_CATEGORIES'
export const WEB_CATEGORIES_LOADED = 'WEB_CATEGORIES_LOADED'
export const WEB_CATEGORIES_FAILED = 'WEB_CATEGORIES_FAILED'

export const FETCH_CATEGORIES_SLIDER = 'FETCH_CATEGORIES_SLIDER'
export const CATEGORIES_SLIDER_LOADED = 'CATEGORIES_SLIDER_LOADED'
export const CATEGORIES_SLIDER_FAILED = 'CATEGORIES_SLIDER_FAILDED'

export const fetchCategories = () => {
  return {
    type: FETCH_CATEGORIES
  }
}

export const fetchAllCategories = () => {
  return {
    type: FETCH_ALL_CATEGORIES
  }
}

export const fetchWebCategories = (page, size, filter, query) => {
  return {
    type: FETCH_WEB_CATEGORIES,
    page,
    size,
    filter,
    query
  }
}

export const fetchCategoriesSlider = (page, size, filter, query) => {
  return {
    type: FETCH_CATEGORIES_SLIDER,
    page,
    size,
    filter,
    query
  }
}
