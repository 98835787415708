import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_AUDIT_LOGS, AUDIT_LOGS_LOADED, AUDIT_LOGS_FAILED } from '../actions/audit'
import auditLogService from '../../services/audit'

function * fetchAuditLogs (params) {
  try {
    const auditLogs = yield auditLogService.listByPage(params.page, params.size, params.filter, params.query)
    yield put({ type: AUDIT_LOGS_LOADED, auditLogs })
  } catch (e) {
    yield put({ type: AUDIT_LOGS_FAILED, message: e.message })
  }
}

function * watchAuditLog () {
  yield takeEvery(FETCH_AUDIT_LOGS, fetchAuditLogs)
}

export default watchAuditLog
