import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import authService from '../../../services/auth'
import { fetchCategories } from '../../../states/actions'
import { formatter, notification, sessionStorage } from '../../../util'

// UI
import { Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const filterKey = 'category.filter'
const pageSize = 20

class CategoryList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchCategories({ loading: true, currentPage, filter, searchText, sort })
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { masterCategories } = this.props
    const { currentPage, loading } = this.state
    const total = masterCategories.length

    return (
      <Page.Content>
        <Page.Header title='Category Highlight' description='Display and highlight banners and brands in categories' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Categories' onChange={this.findCategories} />
              </Col>

              <Col offset={12} lg={6} style={{ textAlign: 'right' }} />
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='category-list'>
                  {masterCategories.map((item) => {
                    // const { id, name, image: thumbUrl = '' } = item
                    const { id, name } = item
                    const Row = (props) => {
                      return (
                        <div className={formatter.toClassName(['list-item', props.className])}>
                          {/* <img className='wd-template-img' alt='' src={thumbUrl || '/img/default-image.png'} /> */}

                          <div className='info-container'>
                            <div className='name'><img src={process.env.PUBLIC_URL + '/icon/intro.svg'} className='Menu-item-icon' />{name}</div>
                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={id} to={`/catalog/categories/${id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>
        </Page.Body>
      </Page.Content>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchCategories({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchCategories = ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      this.setState({ currentPage, loading })
      sessionStorage.setObject(filterKey, filter)
      this.props.fetchCategories(currentPage, pageSize, filter, searchText)
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findCategories = (text) => {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchCategories({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, sort, searchText: text })
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createCategory')
  }

  canList () {
    return this.hasAccess('listCategory')
  }

  canRead () {
    return this.hasAccess('readCategory')
  }
}

const mapDispatchToProps = {
  fetchCategories
}

const mapStateToProps = (state) => {
  return { ...state.Categories }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryList)
