import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class WDTag extends Component {
  render () {
    const { children, className, color, style } = this.props

    return (
      <div
        className={formatter.toClassName(['wd-tag', className])}
        style={{ backgroundColor: this.getDefaultColor(color), ...style }}
      >
        {children}
      </div>
    )
  }

  getDefaultColor (color) {
    switch (color) {
      case 'blue':
        return '#42bef6'
      case 'gray':
        return '#b2b2b2'
      case 'green':
        return '#00bfa5'
      case 'orange':
        return '#f48e36'
      case 'red':
        return '#bf2626'
      case 'yellow':
        return '#ffd73f'
      default:
        return color || '#050505'
    }
  }
}

WDTag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
}
