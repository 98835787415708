import { TEMPLATE_LOADED } from '../actions/template'

const INITIAL_STATE = {
  templates: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TEMPLATE_LOADED:
      return { ...state, templates: { list: action.templates.data, total: action.templates.total } }
    default:
      return state
  }
}
