import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_CUSTOM_PAGE, CUSTOM_PAGE_LOADED, CUSTOM_PAGE_FAILED } from '../actions'
import { customPageService } from '../../services'

// Generators
function * fetchCustomPage (params) {
  try {
    const pages = yield call(() => customPageService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: CUSTOM_PAGE_LOADED, pages })
  } catch (e) {
    yield put({ type: CUSTOM_PAGE_FAILED, message: e.message })
  }
}

function * watchCustomPage () {
  yield takeEvery(FETCH_CUSTOM_PAGE, fetchCustomPage)
}

export default watchCustomPage
