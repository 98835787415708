import { CELEBS_FETCHED, CELEB_SET_PRISTINE } from '../actions/celeb'

const INITIAL_STATE = {
  celebs: { data: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CELEBS_FETCHED:
      return { ...state, celebs: action.celebs, pristine: false }
    case CELEB_SET_PRISTINE:
      return { ...state, pristine: action.pristine }
    default:
      return state
  }
}
