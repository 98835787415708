import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TRENDINGS, FETCH_TRENDING_GROUPS, TRENDINGS_LOADED, TRENDING_GROUPS_LOADED, TRENDINGS_FAILED } from '../actions/trending'
import trendingService from '../../services/trending'

// Generators
function * fetchTrendings (params) {
  try {
    const trendings = yield call(() => trendingService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TRENDINGS_LOADED, trendings })
  } catch (e) {
    yield put({ type: TRENDINGS_FAILED, message: e.message })
  }
}

function * fetchTrendingGroups (params) {
  try {
    const trendings = yield call(() => trendingService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TRENDING_GROUPS_LOADED, trendings })
  } catch (e) {
    yield put({ type: TRENDINGS_FAILED, message: e.message })
  }
}

function * watchTrending () {
  yield takeEvery(FETCH_TRENDINGS, fetchTrendings)
  yield takeEvery(FETCH_TRENDING_GROUPS, fetchTrendingGroups)
}

export default watchTrending
