import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_WEBTILE, WEBTILE_LOADED, WEBTILE_FAILED } from '../actions/tile'
import tileService from '../../services/tile'

// Generators
function * fetchTile (params) {
  try {
    const tiles = yield call(() => tileService.list())
    yield put({ type: WEBTILE_LOADED, tiles })
  } catch (e) {
    yield put({ type: WEBTILE_FAILED, message: e.message })
  }
}

function * watchTile () {
  yield takeEvery(FETCH_WEBTILE, fetchTile)
}

export default watchTile
