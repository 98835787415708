import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_SUMMARY, SUMMARY_LOADED, SUMMARY_FAILED } from '../actions'
import request from '../../util/request'

const apiUrl = '/api/dashboard'

// Services
export const services = {
  getSummary () {
    return request(apiUrl)
  }
}

// Generators
function * fetchSummary () {
  try {
    const summary = yield call(services.getSummary)
    yield put({ type: SUMMARY_LOADED, summary })
  } catch (e) {
    yield put({ type: SUMMARY_FAILED, message: e.message })
  }
}

function * watchSummary () {
  yield takeEvery(FETCH_SUMMARY, fetchSummary)
}

export default watchSummary
