import { call, put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_ALL_STORE,
  ALL_STORE_LOADED,
  ALL_STORE_FAILED,
  FETCH_STORE,
  STORE_LOADED,
  STORE_FAILED
} from '../actions/store'
import storeService from '../../services/store'

// Generators
function * fetchAllStores (params) {
  try {
    const all_stores = yield call(() => storeService.getAllVendors())
    yield put({ type: ALL_STORE_LOADED, all_stores })
  } catch (e) {
    yield put({ type: ALL_STORE_FAILED, message: e.message })
  }
}

function * fetchStores (params) {
  try {
    const stores = yield call(() => storeService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: STORE_LOADED, stores })
  } catch (e) {
    yield put({ type: STORE_FAILED, message: e.message })
  }
}

function * watchStore () {
  yield takeEvery(FETCH_ALL_STORE, fetchAllStores)
  yield takeEvery(FETCH_STORE, fetchStores)
}

export default watchStore
