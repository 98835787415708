import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import authService from '../../../services/auth'
import formatter from '../../../util/formatter'
import notification from '../../../util/notification'
import sessionStorage from '../../../util/sessionStorage'
import { fetchContent } from '../../../states/actions'
import { lamboplace } from '../../../config'

// UI
import { Active, Button, List, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const filterKey = 'collection.layout.filter'
const pageSize = 20

class ContentManagementSystemList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      tiles: [],
      loading: false,
      searchText: '',
      total: 0
    }
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchPages()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { currentPage, loading } = this.state
    const { contentPages } = this.props

    const columns = [
      {
        key: 'title',
        title: 'Title',
        width: 8
      },
      {
        key: 'seo_title',
        title: 'URL Path',
        width: 10,
        render: ({seo_title}) => (`${lamboplace.domain}/contentCustom/${seo_title}`)
      },
      {
        title: 'Created On',
        width: 4,
        render: ({createdAt}) => formatter.toLocalStandardDate(createdAt)
      },
      {
        key: 'active',
        title: 'Active',
        width: 2,
        render: ({active}) => <Active active={active === 1} />
      }
    ]

    return (
      <Page.Content>
        <Page.Header title='Content Management Pages' description='Create content management page with editor' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Pages' onChange={(value) => this.findPages(value)} />
              </Col>

              <Col offset={12} lg={6} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/pages/content-management/add'>
                    <Button>Add Page</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} loading={loading} rows={contentPages.list} link={this.canRead() ? '/pages/content-management/<_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={contentPages.total}
            totalText={`Total ${contentPages.total} pages`}
            current={currentPage}
            onChange={(page) => this.fetchPages(page)}
            style={{marginTop: '15px'}}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  findPages (text) {
    const { currentPage, searchText } = this.state
    this.fetchPages(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  fetchPages (page = 1, text) {
    const { searchText } = this.state
    this.props.fetchContent(page, pageSize, {}, typeof text === 'string' ? text : searchText)
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createCustomPage')
  }

  canList () {
    return this.hasAccess('listCustomPage')
  }

  canRead () {
    return this.hasAccess('readCustomPage')
  }
}

const mapDispatchToProps = {
  fetchContent
}

const mapStateToProps = (state) => {
  return { ...state.ContentManagementSystem }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentManagementSystemList)
