export const ADMINS_FETCHED = 'ADMINS_FETCHED'
export const ADMIN_SET_PRISTINE = 'ADMIN_SET_PRISTINE'

export const adminsFetched = (admins) => {
  return {
    type: ADMINS_FETCHED,
    admins
  }
}

export const adminSetPristine = (pristine) => {
  return {
    type: ADMIN_SET_PRISTINE,
    pristine
  }
}
