import { CARTPRICERULES_GROUPS_LOADED, CARTPRICERULES_LOADED } from '../actions/cartPriceRule'

const INITIAL_STATE = {
  cartPriceRuleGroups: { list: [], total: 0 },
  cartPriceRules: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARTPRICERULES_GROUPS_LOADED:
      return { ...state, cartPriceRuleGroups: { list: action.cartPriceRules.list, total: action.cartPriceRules.total } }
    case CARTPRICERULES_LOADED:
      return { ...state, cartPriceRules: { list: action.cartPriceRules.list, total: action.cartPriceRules.total } }
    default:
      return state
  }
}
