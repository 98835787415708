
//export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
//export const CATEGORIES_LOADED = 'CATEGORIES_LOADED'
//export const CATEGORIES_FAILED = 'CATEGORIES_FAILED'

//export const FETCH_ALL_CATEGORIES = 'FETCH_ALL_CATEGORIES'
//export const ALL_CATEGORIES_LOADED = 'ALL_CATEGORIES_LOADED'
//export const ALL_CATEGORIES_FAILED = 'ALL_CATEGORIES_FAILED'

//export const WEB_CATEGORIES_LOADED = 'WEB_CATEGORIES_LOADED'
//export const WEB_CATEGORIES_FAILED = 'WEB_CATEGORIES_FAILED'

export const FETCH_MINI_BANNER = 'FETCH_MINI_BANNER'
export const MINI_BANNER_LOADED = 'MINI_BANNER_LOADED'
export const MINI_BANNER_FAILED = 'CATEGORIES_SLIDER_FAILDED'
/*
export const fetchCategories = () => {
  return {
    type: FETCH_CATEGORIES
  }
}
*/
/*
export const fetchAllCategories = () => {
  return {
    type: FETCH_ALL_CATEGORIES
  }
}
*/
export const fetchMiniBanner = (page, size, filter, query) => {
  return {
    type: FETCH_MINI_BANNER,
    page,
    size,
    filter,
    query
  }
}

