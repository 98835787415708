import { VENDORS_FETCHED, VENDOR_SET_PRISTINE } from '../actions/vendor'

const INITIAL_STATE = {
  vendors: { data: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VENDORS_FETCHED:
      return { ...state, vendors: action.vendors, pristine: false }
    case VENDOR_SET_PRISTINE:
      return { ...state, pristine: action.pristine }
    default:
      return state
  }
}
