import { queryString, request } from '../util'

const url = '/api/vendors'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  findByName (size, text) {
      return request(`${url}/find/name/size/${size}${queryString.stringify({ text })}`)
  },
  listByPage (page, size, filter, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  checkName (name) {
    return request(`${url}/check/${name}`)
  }
}
