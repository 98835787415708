export const FETCH_CONTENT = 'FETCH_CONTENT'
export const CONTENT_PAGE_LOADED = 'CONTENT_PAGE_LOADED'
export const CONTENT_PAGE_FAILED = 'CONTENT_PAGE_FAILED'

export const fetchContent = (page, size, filter, query) => {
  return {
    type: FETCH_CONTENT,
    page,
    size,
    filter,
    query
  }
}
