export const FETCH_BUTTON_LAYOUTS = 'FETCH_BUTTON_LAYOUTS'
export const BUTTON_LAYOUTS_LOADED = 'BUTTON_LAYOUTS_LOADED'
export const BUTTON_LAYOUTS_FAILED = 'BUTTON_LAYOUTS_FAILED'

export const fetchButtonLayouts = (page, size, filter, query) => {
  return {
    type: FETCH_BUTTON_LAYOUTS,
    page,
    size,
    filter,
    query
  }
}
