import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import authService from '../../../../services/auth'
import formatter from '../../../../util/formatter'
import notification from '../../../../util/notification'
import sessionStorage from '../../../../util/sessionStorage'
import { fetchWebTile } from '../../../../states/actions'

// UI
import { Active, Button, List, Page, Pager, SearchInput, Spin } from '../../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const filterKey = 'collection.layout.filter'
const pageSize = 20

class WebTile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      tiles: [],
      loading: false,
      searchText: '',
      total: 0
    }
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchWebTile()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { currentPage, loading } = this.state

    const { tiles } = this.props
    // console.log(tiles)
    const columns = [
      {
        key: 'name',
        title: 'Name',
        width: 21
      },
      {
        key: 'active',
        title: 'Active',
        width: 3,
        render: (row) => <Active active={row.active === 1} />
      }
    ]

    return (
      <Page.Content>
        <Page.Header title='Web Tiles' description='Arrange different tile layouts in 3 pillars (For WEB only)' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Web Tiles' onChange={(value) => this.findCollections(value)} />
              </Col>

              <Col offset={12} lg={6} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/home/tiles/web/add'>
                    <Button>Add Tiles Layout</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} loading={loading} rows={tiles} link={this.canRead() ? '/home/tiles/web/<_id>' : ''} />
                {/* <div className='wd-collection-list'>
                  {tiles.map((item) => {
                    const { _id, active, name, layout } = item
                    const Row = (props) => {
                      return (
                        <div className={formatter.toClassName(['wd-collection-card', props.className])}>
                          <div className='wd-collection-active'>
                            <div className='wd-collection-active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />
                          </div>

                          <div className='wd-collection-info'>
                            <div className='name'>{name}</div>

                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={_id} to={`/home/tiles/web/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div> */}
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={tiles.length}
            totalText={`Total ${tiles.length} layouts`}
            current={currentPage}
            onChange={(page) => this.fetchStores(page)}
            style={{marginTop: '15px'}}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  async fetchWebTile () {
    try {
      this.props.fetchWebTile()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load tiles successfully. Please try again later.')
    }
  }

  // findCollections (text) {
  //   const { currentPage, searchText } = this.state
  //   this.fetchCollections(searchText !== text ? 1 : currentPage, text)
  //   this.setState({ searchText: text })
  // }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createWebTiles')
  }

  canList () {
    return this.hasAccess('listWebTiles')
  }

  canRead () {
    return this.hasAccess('readWebTiles')
  }
}

const mapDispatchToProps = {
  fetchWebTile
}

const mapStateToProps = (state) => {
  return { ...state.Tile }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebTile)
