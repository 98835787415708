import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'

import 'antd/lib/date-picker/style/css'
import 'antd/lib/form/style/css'
import './styles.css'

export default class WDDateRangePicker extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { datePicker, fieldDecorator, form, formItem = {}, readOnly } = this.props
    const { getFieldDecorator } = form
    const { id, options = {} } = fieldDecorator
    const { format: dateFormat } = datePicker
    const { initialValue: [from, to] } = options
    formItem.className = formatter.toClassName(['wd-datepicker', formItem.className])

    return (
      <Form.Item hasFeedback={!readOnly} {...formItem}>
        {readOnly ? (
          <span className='ant-form-text'>
            {dateFormat ? formatter.toDate(from, dateFormat) : formatter.toStandardDate(from)} — {dateFormat ? formatter.toDate(to, dateFormat) : formatter.toStandardDate(to)}
          </span>
        ) : (
          getFieldDecorator(id, options)(<DatePicker.RangePicker {...datePicker} />)
        )}
      </Form.Item>
    )
  }
}

WDDateRangePicker.propTypes = {
  datePicker: PropTypes.object,
  fieldDecorator: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formItem: PropTypes.object,
  readOnly: PropTypes.bool.isRequired
}
