export const FETCH_TOPFAVOURITE = 'FETCH_TOPFAVOURITE'
export const TOPFAVOURITE_LOADED = 'TOPFAVOURITE_LOADED'
export const TOPFAVOURITE_FAILED = 'TOPFAVOURITE_FAILED'
export const TOPFAVOURITE_GROUPS_LOADED = 'TOPFAVOURITE_GROUPS_LOADED'
export const FETCH_TOPFAVOURITE_GROUP = 'FETCH_TOPFAVOURITE_GROUP'

export const fetchTopFavouriteGroups = (page, size, filter, query) => {
  return {
    type: FETCH_TOPFAVOURITE_GROUP,
    page,
    size,
    filter,
    query
  }
}

export const fetchTopFavourite = (page, size, filter, query) => {
  return {
    type: FETCH_TOPFAVOURITE,
    page,
    size,
    filter,
    query
  }
}
