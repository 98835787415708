import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import authService from '../../../../services/auth'
import formatter from '../../../../util/formatter'
import notification from '../../../../util/notification'
import sessionStorage from '../../../../util/sessionStorage'
import { fetchTemplates } from '../../../../states/actions'

// UI
import { Button, Page, Pager, SearchInput, Spin } from '../../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Form from 'antd/lib/form'

import './styles.css'

const filterKey = 'collection.template.filter'
const pageSize = 20

class CollectionPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      showAddCollection: false,
      total: 0
    }
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchTemplates()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { currentPage, loading, showAddCollection } = this.state

    const { templates } = this.props
    const { list, total } = templates

    return (
      <Page.Content>
        <Page.Header title='Collection Pages' description='Categorise products/promotions with collection page' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Page' onChange={(value) => this.findTemplates(value)} />
              </Col>

              <Col offset={12} lg={6} style={{textAlign: 'right'}}>
                {this.canAdd() ? (<Link to='/pages/collection/add'>
                  <Button /* onClick={() => this.setState({showAddCollection: true})} */>Add Page</Button>
                </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='wd-collection-list'>
                  {list.map((item) => {
                    const { _id, name, image: thumbUrl = '' } = item
                    const Row = (props) => {
                      return (
                        <div className={formatter.toClassName(['wd-template-card', props.className])}>
                          <img className='wd-template-img' alt='' src={thumbUrl || '/img/default-image.png'} />

                          <div className='wd-collection-info'>
                            <div className='name'>{name}</div>

                            <div className='remark'> { }</div>
                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={_id} to={`/pages/collection/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>

          <Modal
            visible={showAddCollection}
            width={720}
            title='Add Collection Page'
            onOk={() => this.addCollection()}
            onCancel={() => this.setState({ showAddCollection: false })}
            footer={[
              <Button key='back' onClick={() => this.setState({ showAddCollection: false })}>Cancel</Button>,
              this.canSave() ? <Button key='submit' type='primary' onClick={() => this.addCollection()}>Add</Button> : null
            ]}>
            <Form layout='vertical'>
              <Row gutter={16}>
                <Col md={24}>
                  cc
                </Col>
              </Row>
            </Form>
          </Modal>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} collections`}
            current={currentPage}
            onChange={(page) => this.fetchTemplates(page)}
            style={{marginTop: '15px'}}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  addCollection () {
    this.setState({ showAddCollection: false })
  }

  changeActive (value) {
    const { currentPage, filter, searchText } = this.state

    if (value === 'Yes') {
      filter.active = true
    } else if (value === 'No') {
      filter.active = false
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchCollections(currentPage, searchText)
  }

  async fetchTemplates (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchTemplates(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load collections successfully. Please try again later.')
    }
  }

  findTemplates (text) {
    const { currentPage, searchText } = this.state
    this.fetchTemplates(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createCollection')
  }

  canList () {
    return this.hasAccess('listCollection')
  }

  canRead () {
    return this.hasAccess('readCollection')
  }

  canSave () {
    return this.hasAccess('createCollection')
  }
}

const mapDispatchToProps = {
  fetchTemplates
}

const mapStateToProps = (state) => {
  return { ...state.Template }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CollectionPage)
