import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
import BannerLinkTypes, { BannerLinkTypesList } from '../../../constants/banner/linkTypes'
import { authService, brandService, buttonLayoutService, buttonConfigService, productService } from '../../../services'
import { fetchAllCategories, fetchStores, fetchTemplates } from '../../../states/actions'
import { formatter, notification, validator } from '../../../util'

// UI
import { Button, DatePicker, Input, Page, Panel, Select, Spin, Switch, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

class FeaturedButton extends Component {
  constructor (props) {
    super(props)
    const noOfColumns = 0
    const noOfRows = 0
    this.state = {
      brands: [],
      buttons: this.getDefaultButtons(noOfColumns, noOfRows),
      item: {},
      loading: false,
      loadingImage: false,
      modal: {
        cidx: -1,
        ridx: -1,
        item: {},
        show: false
      },
      noOfColumns,
      noOfRows,
      products: { data: [], total: 0 },
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }
    this.fetchProducts = debounce(this.fetchProducts, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.fetchAllBrands()
      this.fetchCollections()
      this.fetchCategories()
      this.fetchProducts()
      this.fetchStores()

      if (this.isEdit()) {
        this.fetchButton()
      }
    } else {
      authService.unauthorizeAction('/buttons')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 8, md: 4, lg: 4 },
      wrapperCol: { sm: 16, md: 18, lg: 18 }
    }
    const dateFormItemLayout = {
      labelCol: { sm: 8, md: 4, lg: 4 },
      wrapperCol: { sm: 16, md: 8, lg: 6 }
    }
    const selectFormItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { categories, form, history, stores, templates } = this.props
    const { brands, item, loading, loadingImage, modal, products, showBrandList, showCategoryList, showPageInput, showProductList, showStoreList, showTemplateList } = this.state
    const { getFieldValue } = form
    const { item: modalItem, show: modalShow } = modal
    const { data: productList } = products
    const from = formatter.toShortDate(getFieldValue('show_from'))
    const to = formatter.toShortDate(getFieldValue('show_to'))

    return (
      <Page.Content>
        <Page.Header title='Button Layout' description='Upload background image, set timeline, and add buttons to it.'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={this.handleDelete}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={this.handleSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Layout Details'>
                <div className='button-layout-panel'>
                  <div className='button-layout-panel-left'>
                    <div className='button-layout-image-uploader'>
                      <UploadHolder
                        img={{
                          alt: 'Button Layout Background Image',
                          src: item.image ? item.image : '/img/default-image.png'
                        }}
                        loading={loadingImage}
                        readOnly={!this.canSave()}
                        height={180}
                        width={248}
                        upload={{
                          action: '/api/button/layouts/upload/image',
                          beforeUpload: this.checkImage,
                          data: { _id: item._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: this.handleUploadImage,
                          showUploadList: false
                        }}
                      />
                    </div>

                    <div className='button-layout-image-hint'>
                      Size: 580px x 420px (JPG or PNG) <br />
                      Maximum file size: 2 MB
                    </div>

                    {this.canDeletePhoto() ? (
                      <div className='button-layout-image-button'>
                        <Button disabled={loading} onClick={this.handleDeleteImage}>Delete Background Image</Button>
                      </div>
                    ) : null}
                  </div>

                  <div className='button-layout-panel-right'>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 64 characters' },
                            { max: 64, message: 'Name must be between 2 and 64 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        autoFocus: true,
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Please enter remark' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        autosize: { minRows: 2, maxRows: 5 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    <DatePicker
                      datePicker={{
                        allowClear: false,
                        placeholder: 'Select a date',
                        style: { width: '100%' }
                      }}
                      fieldDecorator={{
                        id: 'show_from',
                        options: {
                          initialValue: item.show_from ? moment(item.show_from) : moment(),
                          rules: [
                            { required: true, message: 'Please select a date' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...dateFormItemLayout,
                        label: 'From'
                      }}
                      readOnly={!this.canSave()}
                    />

                    <DatePicker
                      datePicker={{
                        allowClear: false,
                        placeholder: 'Select a date',
                        style: { width: '100%' }
                      }}
                      fieldDecorator={{
                        id: 'show_to',
                        options: {
                          initialValue: item.show_to ? moment(item.show_to) : moment().add(1, 'day'),
                          rules: [
                            { required: true, message: 'Please select a date' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...dateFormItemLayout,
                        label: 'To'
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'web',
                        options: {
                          initialValue: item.web === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Web'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 3
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Button layout is visible on web from ${from} to ${to} when set to 'Yes'`}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'mobile',
                        options: {
                          initialValue: item.mobile === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Mobile'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 4
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Button layout is visible on mobile from ${from} to ${to} when set to 'Yes'`}
                    />
                  </div>
                </div>
              </Panel>

              <Panel title='Button Configurations'>
                {this.getButtonLayout()}
              </Panel>

              <Modal
                title='Button Details'
                visible={modalShow}
                width={720}
                footer={[
                  this.canDelete() ? (modalItem._id > 0 ? <Button disabled={loading} key='delete' onClick={this.handleDeleteButton}>Delete</Button> : null) : null,
                  <Button disabled={loading} key='back' onClick={this.hideButtonModal}>Cancel</Button>,
                  this.canSave() ? <Button disabled={loading} key='submit' type='primary' onClick={this.handleSaveButton}>Save</Button> : null
                ]}
                onCancel={this.hideButtonModal}
              >
                <Spin loading={loading}>
                  <div className='button-image-uploader'>
                    <UploadHolder
                      img={{
                        alt: 'Button Image',
                        src: modalItem.image ? modalItem.image : '/img/default-image.png'
                      }}
                      loading={loadingImage}
                      readOnly={!this.canSave()}
                      height={64}
                      width={64}
                      upload={{
                        action: '/api/button/configs/upload/image',
                        beforeUpload: this.checkImage,
                        data: {
                          _id: modalItem._id > 0 ? modalItem._id : undefined,
                          lid: item._id
                        },
                        disabled: loading,
                        headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                        name: 'image',
                        onChange: this.handleUploadButtonImage,
                        showUploadList: false
                      }}
                    />
                  </div>

                  <div className='button-image-hint'>
                    Size: 128px x 128px (JPG or PNG or GIF) <br />
                    Maximum file size: 2 MB
                  </div>

                  <Input
                    fieldDecorator={{
                      id: 'button.name',
                      options: {
                        initialValue: modalItem.name,
                        rules: [
                          { min: 2, message: 'Name must be between 2 and 64 characters' },
                          { max: 64, message: 'Name must be between 2 and 64 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter name' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'Name'
                    }}
                    input={{
                      autoFocus: true,
                      tabIndex: 4
                    }}
                    readOnly={!this.canSave()}
                  />

                  <FormItem
                    {...formItemLayout}
                    label={(
                      <span>
                        Link&nbsp;
                        <Tooltip title='Link button to a product or external custom page'>
                          <Icon type='question-circle-o' />
                        </Tooltip>
                      </span>
                    )}
                  >
                    <Row gutter={8}>
                      <Col md={8}>
                        <Select
                          fieldDecorator={{
                            id: 'button.link_type',
                            options: {
                              initialValue: modalItem.link_type || BannerLinkTypes.NONE
                            }
                          }}
                          form={form}
                          formItem={{
                            ...selectFormItemLayout,
                            hasFeedback: false
                          }}
                          list={BannerLinkTypesList}
                          listFunc={({ name, value }) => (
                            <Option key={value} value={value}>{name}</Option>
                          )}
                          readOnly={!this.canSave()}
                          select={{
                            onChange: this.changeLinkType,
                            style: { width: '100%' }
                          }}
                        />
                      </Col>

                      <Col md={16}>
                        {showBrandList ? (
                          <Select
                            fieldDecorator={{
                              id: 'button.link_value',
                              options: {
                                initialValue: modalItem.link_value ? modalItem.link_value.toString() : undefined,
                                rules: [
                                  { required: true, message: 'Please select a brand' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={brands}
                            listFunc={(brand) => (
                              <Option className='text-wrap' key={brand.option_id} value={brand.option_id.toString()}>{brand.name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              placeholder: 'Select a brand',
                              showSearch: true,
                              style: { width: '100%' },
                              filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                          />
                        ) : null}

                        {showTemplateList ? (
                          <Select
                            fieldDecorator={{
                              id: 'button.link_value',
                              options: {
                                initialValue: modalItem.link_value ? modalItem.link_value.toString() : undefined,
                                rules: [
                                  { required: true, message: 'Please select a collection' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={templates.list}
                            listFunc={(template) => (
                              <Option key={template._id} value={template._id.toString()}>{template.name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              placeholder: 'Select a collection',
                              showSearch: true,
                              style: { width: '100%' },
                              filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                          />
                        ) : null}

                        {showCategoryList ? (
                          <Select
                            fieldDecorator={{
                              id: 'button.link_value',
                              options: {
                                initialValue: modalItem.link_value ? modalItem.link_value.toString() : undefined,
                                rules: [
                                  { required: true, message: 'Please select a category' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={categories}
                            listFunc={(category) => (
                              <Option className='text-wrap' key={category.id} value={category.id.toString()}>{category.name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              placeholder: 'Select a category',
                              showSearch: true,
                              style: { width: '100%' },
                              dropdownMatchSelectWidth: false,
                              filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                          />
                        ) : null}

                        {showStoreList ? (
                          <Select
                            fieldDecorator={{
                              id: 'button.link_value',
                              options: {
                                initialValue: modalItem.link_value ? modalItem.link_value.toString() : undefined,
                                rules: [
                                  { required: true, message: 'Please select a store' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={stores.list}
                            listFunc={(store) => (
                              <Option key={store._id} value={store.vendor_id.toString()}>{store.vendor_name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              placeholder: 'Select a store',
                              showSearch: true,
                              style: { width: '100%' },
                              filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                          />
                        ) : null}

                        {showProductList ? (
                          <Select
                            fieldDecorator={{
                              id: 'button.link_value',
                              options: {
                                initialValue: modalItem.link_value,
                                rules: [
                                  { required: true, message: 'Please select a product' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={productList}
                            listFunc={(product) => {
                              const { name, sku } = product

                              return (
                                <Option key={sku} value={sku}>
                                  <div className='text-wrap'>{name}</div>

                                  <div className='wd-trending-option-subtitle'>SKU: {sku}</div>
                                </Option>
                              )
                            }}
                            readOnly={!this.canSave()}
                            select={{
                              filterOption: false,
                              onSearch: this.fetchProducts,
                              placeholder: 'Select a product',
                              showSearch: true,
                              dropdownMatchSelectWidth: false,
                              style: { width: '100%' }
                            }}
                          />
                        ) : null}

                        {showPageInput ? (
                          <Input
                            fieldDecorator={{
                              id: 'button.link_value',
                              options: {
                                initialValue: modalItem.link_value,
                                rules: [
                                  { required: true, message: 'Please enter custom page URL' },
                                  { whitespace: true, message: 'Please enter custom page URL' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            input={{
                              placeholder: 'Enter custom page URL',
                              tabIndex: 5
                            }}
                            readOnly={!this.canSave()}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </FormItem>

                  <Switch
                    fieldDecorator={{
                      id: 'button.active',
                      options: {
                        initialValue: modalItem.active === 1 || false,
                        valuePropName: 'checked'
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'Visible'
                    }}
                    input={{
                      checkedChildren: 'Yes',
                      unCheckedChildren: 'No',
                      tabIndex: 6
                    }}
                    readOnly={!this.canSave()}
                    tooltip={`Button is visible when set to 'Yes'`}
                  />
                </Spin>
              </Modal>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkImage = (file) => {
    if (!validator.isImageMiniBanner(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG, PNG or GIF format.')
      return false
    }

    return true
  }

  changeLinkType = (value) => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    const { item } = modal
    const showList = {
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }

    if (value === BannerLinkTypes.BRAND) {
      showList.showBrandList = true
    } else if (value === BannerLinkTypes.CATEGORY) {
      showList.showCategoryList = true
    } else if (value === BannerLinkTypes.COLLECTION) {
      showList.showTemplateList = true
    } else if (value === BannerLinkTypes.STORE) {
      showList.showStoreList = true
    } else if (value === BannerLinkTypes.PRODUCT) {
      showList.showProductList = true
    } else if (value === BannerLinkTypes.CUSTOM_PAGE) {
      showList.showPageInput = true
    }

    resetFields(['button.link_value'])
    item.link_type = value
    item.link_value = undefined
    this.setState({ modal, ...showList })
  }

  fetchAllBrands = async () => {
    try {
      const item = await brandService.getAllBrands()
      this.setState({ brands: item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
    }
  }

  fetchButton = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const [item, configs] = await Promise.all([
        buttonLayoutService.getLayout(id),
        buttonConfigService.listButtonsByLayoutId(id)
      ])
      const { rows, columns } = item
      const buttons = this.getDefaultButtons(columns, rows, configs)
      this.setState({ buttons, item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load button layout successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchCategories = async () => {
    try {
      this.props.fetchAllCategories()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  fetchCollections = async () => {
    try {
      this.props.fetchTemplates(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load collections successfully. Please try again later.')
    }
  }

  fetchProducts = async (text) => {
    try {
      const products = await productService.listProductsByPage(1, 100, {}, text)
      this.setState({ products })
    } catch (e) {
      console.error(e)
    }
  }

  fetchStores = async () => {
    try {
      this.props.fetchStores(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load stores successfully. Please try again later.')
    }
  }

  getDefaultButtons = (noOfColumns, noOfRows, configs) => {
    configs = configs || []
    const rows = []

    for (let i = 0; i < noOfRows; i++) {
      const columns = []

      for (let j = 1; j < noOfColumns + 1; j++) {
        const button = configs.find(({ col_idx: cidx, row_idx: ridx }) => cidx === j - 1 && ridx === i)
        columns.push(button || { _id: -1 * (i * noOfColumns + j) })
      }

      rows.push(columns)
    }

    return rows
  }

  getButtonLayout = () => {
    const { buttons } = this.state

    return (
      <div className='button-layout-container'>
        {buttons.map((row, ridx) => {
          return (
            <div className='button-layout-row' key={ridx}>
              {row.map((button, cidx) => {
                const { _id, active, image_thumb: thumbUrl, name } = button

                return (
                  <div
                    className='button-layout-column'
                    key={_id}
                    onClick={this.showButtonModal(cidx, ridx, button)}
                  >
                    <div className='active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />

                    {_id < 0 ? (
                      <div className='container'>
                        <div className='add-icon-container'>
                          <Icon className='add-icon' type='plus-circle-o' theme='outlined' />
                        </div>

                        <div className='label'>Add Button</div>
                      </div>
                    ) : (
                      <div className='container'>
                        <img className='image' alt='' src={thumbUrl || '/img/default-image.png'} />

                        <div className='label'>{name}</div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  handleDelete = () => {
    const { history, match } = this.props

    confirm({
      title: 'Confirm to delete this button layout?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const { params } = match
          const { id } = params
          const response = await buttonLayoutService.removeLayout(id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Button layout deleted successfully.')
            history.replace('/home/buttons')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete button layout successfully. Please try again later.')
        }
      }
    })
  }

  handleSave = () => {
    const { form, match } = this.props
    const { validateFields } = form

    validateFields(['name', 'remark', 'show_from', 'show_to', 'mobile', 'web'], async (errors, values) => {
      if (!errors) {
        const { remark, show_from: from, show_to: to, mobile, web } = values
        // values.active = active ? 1 : 0
        values.mobile = mobile ? 1 : 0
        values.web = web ? 1 : 0
        values.remark = remark || ''
        values.show_from = formatter.toISOString(moment.utc(from).startOf('day'))
        values.show_to = formatter.toISOString(moment.utc(to).endOf('day'))
        this.setState({ loading: true })

        try {
          const { params } = match
          const { id } = params
          const response = await buttonLayoutService.saveLayout(id, values)
          this.setState({ loading: false })

          if (response._id) {
            notification.show('success', 'Saved successfully', 'Button layout saved successfully.')
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save button layout successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleDeleteButton = () => {
    const that = this
    const { buttons, modal } = this.state

    confirm({
      title: 'Confirm to delete this button?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const { cidx, ridx, item } = modal
          const { _id } = item
          const response = await buttonConfigService.removeButton(_id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Button deleted successfully.')
            buttons[ridx][cidx] = { _id: -1 * _id }
            that.setState({ buttons })
            that.hideButtonModal()
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete button successfully. Please try again later.')
        }
      }
    })
  }

  handleSaveButton = () => {
    const { form, match } = this.props
    const { buttons, modal } = this.state
    const { validateFields } = form

    validateFields(['button.name', 'button.link_type', 'button.link_value', 'button.active'], async (errors, values) => {
      if (!errors) {
        const { params } = match
        const { cidx, ridx, item } = modal
        const { button } = values
        const { active } = button
        const { id: blid } = params
        const { _id } = item
        const newValues = Object.assign({}, {
          ...item,
          ...button,
          active: active ? 1 : 0,
          row_idx: ridx,
          col_idx: cidx,
          button_layout_id: blid
        })
        delete newValues._id
        this.setState({ loading: true })

        try {
          if (_id > 0) {
            delete newValues.button_layout_id
            const response = await buttonConfigService.saveButton(_id, newValues)
            this.setState({ loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Button saved successfully.')
              buttons[ridx][cidx] = { ...buttons[ridx][cidx], ...newValues }
              this.setState({ buttons })
              this.hideButtonModal()
            }
          } else {
            const response = await buttonConfigService.addButton(newValues)
            this.setState({ loading: false })

            if (response._id) {
              const { _id, image, image_thumb } = response
              notification.show('success', 'Saved successfully', 'Button saved successfully.')
              buttons[ridx][cidx] = { ...newValues, _id, image, image_thumb }
              this.setState({ buttons })
              this.hideButtonModal()
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save button successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleDeleteImage = () => {
    const that = this
    const { match } = this.props
    const { item } = this.state

    confirm({
      title: 'Confirm to delete this background image?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const { params } = match
          const { id } = params
          that.setState({ loadingImage: true })
          const response = await buttonLayoutService.removeImage(id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Background image deleted successfully.')
            item.image = response.url
            item.image_thumb = response.thumbUrl
            that.setState({ item, loadingImage: false })
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete background image successfully. Please try again later.')
        }
      }
    })
  }

  handleUploadImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  handleUploadButtonImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { buttons, modal } = this.state
      const { cidx, ridx, item } = modal
      const { url, thumbUrl } = response
      item.image = url
      item.image_thumb = thumbUrl
      buttons[ridx][cidx].image = url
      buttons[ridx][cidx].image_thumb = thumbUrl
      this.setState({ buttons, modal, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hideButtonModal = () => {
    const { form } = this.props
    const { resetFields } = form
    const modal = {
      cidx: -1,
      ridx: -1,
      item: {},
      show: false
    }
    const showList = {
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }
    resetFields(['button.name', 'button.link_type', 'button.link_value', 'button.active'])
    this.setState({ modal, ...showList })
  }

  showButtonModal = (cidx, ridx, button) => () => {
    const modal = {
      cidx,
      ridx,
      item: Object.assign({}, button),
      show: true
    }
    const { link_type: type } = button
    const showList = {
      showBrandList: type === BannerLinkTypes.BRAND,
      showCategoryList: type === BannerLinkTypes.CATEGORY,
      showPageInput: type === BannerLinkTypes.CUSTOM_PAGE,
      showProductList: type === BannerLinkTypes.PRODUCT,
      showStoreList: type === BannerLinkTypes.STORE,
      showTemplateList: type === BannerLinkTypes.COLLECTION
    }
    this.setState({ modal, ...showList })
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canDelete = () => {
    return this.hasAccess('deleteButton')
  }

  canRead = () => {
    return this.hasAccess('readButton')
  }

  canSave = () => {
    return this.hasAccess(this.isEdit() ? 'updateButton' : 'createButton')
  }

  canUpload = () => {
    return this.canSave()
  }

  canDeletePhoto = () => {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto = () => {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit = () => {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchStores,
  fetchTemplates
}

const mapStateToProps = (state) => {
  return {
    ...state.ButtonLayout,
    ...state.Categories,
    ...state.Store,
    ...state.Template
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeaturedButton))
