import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { authService, buttonLayoutService } from '../../../services'
import { fetchButtonLayouts } from '../../../states/actions/button/layout'
import { formatter, notification, sessionStorage, validator } from '../../../util'

// UI
import { Button, DatePicker, Input, Page, Pager, SearchInput, Select as WDSelect, Spin, Switch, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'button.filter'
const pageSize = 20
const Option = Select.Option

class FeaturedButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      item: {},
      loading: false,
      loadingImage: false,
      searchText: '',
      showModal: false
    }
    this.findLayouts = debounce(this.findLayouts, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchLayouts({ loading: true, currentPage, filter, searchText, sort })
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 8, md: 4, lg: 4 },
      wrapperCol: { sm: 16, md: 18, lg: 18 }
    }
    const { form, layouts } = this.props
    const { currentPage, filter, item, loading, loadingImage, showModal } = this.state
    const { getFieldValue } = form
    const { list, total } = layouts
    const { active, web } = filter
    const from = formatter.toShortDate(getFieldValue('show_from'))
    const to = formatter.toShortDate(getFieldValue('show_to'))

    return (
      <Page.Content>
        <Page.Header title={`Button Layouts (${total})`} description='Display buttons in different layouts (Only one active button layout at a time)' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Button Layouts' onChange={this.findLayouts} />
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={5}>
                    <Tooltip title='Visible'>
                      {/* <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        onChange={this.changeActive}
                        placeholder='Visible'
                        style={{ width: '100%' }}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select> */}
                      <Select
                        allowClear
                        defaultValue={typeof web === 'boolean' ? (web ? 'Web' : 'Mobile') : undefined}
                        disabled={loading}
                        onChange={this.changeActive}
                        placeholder='Visible'
                        style={{ width: '100%' }}
                      >
                        <Option value='Web'>Web only</Option>
                        <Option value='Mobile'>Mobile only</Option>
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} style={{ textAlign: 'right' }}>
                {this.canAdd() ? (
                  <Button onClick={this.showAddModal}>Add Layout</Button>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='button-layout-list'>
                  {list.map((item) => {
                    const { _id, active, name, show_from: from, show_to: to, rows, columns, image_thumb: thumbUrl, mobile, web } = item
                    const isValidDate = moment().isBetween(from, to)
                    const Row = ({ className }) => (
                      <div className={formatter.toClassName(['list-item', className])}>
                        <div className='active'>
                          <div className='active-badge' style={{ backgroundColor: mobile || web ? '#27CD7F' : '#C1C1C1' }} />
                        </div>

                        <img className='image' alt='' src={thumbUrl || '/img/default-image.png'} />

                        <div className='info-container'>
                          <div className='left'>
                            <div className='name'>{name}</div>

                            <div className='date' style={{ color: isValidDate ? '#27CD7F' : '#C1C1C1' }}>{formatter.toShortDate(from)} - {formatter.toShortDate(to)}</div>
                          </div>

                          <div className='right'>
                            <div className='count'>
                              <div className='value'>{rows}</div>

                              <div className='label'>Rows</div>
                            </div>

                            <div className='count'>
                              <div className='value'>{columns}</div>

                              <div className='label'>Cols</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )

                    return this.canRead() ? (
                      <Link key={_id} to={`/home/buttons/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} button layouts`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          <Form>
            <Modal
              title='Add Button Layout'
              visible={showModal}
              width={720}
              footer={[
                <Button key='back' onClick={this.hideAddModal}>Cancel</Button>,
                <Button key='submit' type='primary' onClick={this.handleSave}>Save</Button>
              ]}
              onCancel={this.hideAddModal}
            >
              <div className='button-layout-image-uploader'>
                <UploadHolder
                  img={{
                    alt: 'Button Layout Background Image',
                    src: item.image ? item.image : '/img/default-image.png'
                  }}
                  loading={loadingImage}
                  readOnly={!this.canSave()}
                  height={180}
                  width={248}
                  upload={{
                    action: '/api/button/layouts/upload/image',
                    beforeUpload: this.checkImage,
                    data: { _id: item._id },
                    disabled: loading,
                    headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                    name: 'image',
                    onChange: this.handleUploadImage,
                    showUploadList: false
                  }}
                />
              </div>

              <div className='button-layout-image-hint'>
                Size: 580px x 420px (JPG or PNG) <br />
                Maximum file size: 2 MB
              </div>

              <Input
                fieldDecorator={{
                  id: 'name',
                  options: {
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 64 characters' },
                      { max: 64, message: 'Name must be between 2 and 64 characters' },
                      { required: true, message: 'Please enter name' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  }
                }}
                form={form}
                formItem={{
                  ...formItemLayout,
                  label: 'Name'
                }}
                input={{
                  autoFocus: true,
                  tabIndex: 1
                }}
                readOnly={!this.canSave()}
              />

              <TextArea
                fieldDecorator={{
                  id: 'remark',
                  options: {
                    rules: [
                      { min: 2, message: 'Remark must be between 2 and 256 characters' },
                      { max: 256, message: 'Remark must be between 2 and 256 characters' },
                      { whitespace: true, message: 'Please enter remark' }
                    ]
                  }
                }}
                form={form}
                formItem={{
                  ...formItemLayout,
                  label: 'Remark'
                }}
                input={{
                  autosize: { minRows: 1, maxRows: 3 },
                  tabIndex: 2
                }}
                readOnly={!this.canSave()}
              />

              <Row>
                <Col md={12} lg={12}>
                  <WDSelect
                    fieldDecorator={{
                      id: 'rows',
                      options: {
                        initialValue: item.rows,
                        rules: [
                          { required: true, message: 'Please select no. of rows' },
                          { validator: this.checkCount }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...{
                        labelCol: { sm: 8, md: 8, lg: 8 },
                        wrapperCol: { sm: 16, md: 14, lg: 14 }
                      },
                      hasFeedback: false,
                      label: (
                        <span>
                          Rows&nbsp;
                          <Tooltip title='Layout = No. of rows x No. of columns'>
                            <Icon type='question-circle-o' />
                          </Tooltip>
                        </span>
                      )
                    }}
                    list={this.getDimensionOptions()}
                    listFunc={({ name, value }) => (
                      <Option key={value} value={value}>{name}</Option>
                    )}
                    readOnly={!this.canSave()}
                    select={{
                      style: { width: '100%' }
                    }}
                  />
                </Col>

                <Col md={12} lg={12}>
                  <WDSelect
                    fieldDecorator={{
                      id: 'columns',
                      options: {
                        initialValue: item.columns,
                        rules: [
                          { required: true, message: 'Please select no. of columns' },
                          { validator: this.checkCount }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...{
                        labelCol: { sm: 8, md: 6, lg: 6 },
                        wrapperCol: { sm: 16, md: 14, lg: 14 }
                      },
                      hasFeedback: false,
                      label: (
                        <span>
                          Columns&nbsp;
                          <Tooltip title='Layout = No. of rows x No. of columns'>
                            <Icon type='question-circle-o' />
                          </Tooltip>
                        </span>
                      )
                    }}
                    list={this.getDimensionOptions()}
                    listFunc={({ name, value }) => (
                      <Option key={value} value={value}>{name}</Option>
                    )}
                    readOnly={!this.canSave()}
                    select={{
                      style: { width: '100%' }
                    }}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} lg={12}>
                  <DatePicker
                    datePicker={{
                      allowClear: false,
                      placeholder: 'Select a date',
                      style: { width: '100%' }
                    }}
                    fieldDecorator={{
                      id: 'show_from',
                      options: {
                        initialValue: moment(),
                        rules: [
                          { required: true, message: 'Please select a date' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...{
                        labelCol: { sm: 8, md: 8, lg: 8 },
                        wrapperCol: { sm: 16, md: 14, lg: 14 }
                      },
                      label: 'From'
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>

                <Col md={12} lg={12}>
                  <DatePicker
                    datePicker={{
                      allowClear: false,
                      placeholder: 'Select a date',
                      style: { width: '100%' }
                    }}
                    fieldDecorator={{
                      id: 'show_to',
                      options: {
                        initialValue: moment().add(1, 'day'),
                        rules: [
                          { required: true, message: 'Please select a date' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...{
                        labelCol: { sm: 8, md: 6, lg: 6 },
                        wrapperCol: { sm: 16, md: 14, lg: 14 }
                      },
                      label: 'To'
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} lg={12}>
                  <Switch
                    fieldDecorator={{
                      id: 'web',
                      options: {
                        initialValue: false,
                        valuePropName: 'checked'
                      }
                    }}
                    form={form}
                    formItem={{
                      ...{
                        labelCol: { sm: 8, md: 8, lg: 8 },
                        wrapperCol: { sm: 16, md: 14, lg: 14 }
                      },
                      label: 'Web'
                    }}
                    input={{
                      checkedChildren: 'Yes',
                      unCheckedChildren: 'No',
                      tabIndex: 3
                    }}
                    readOnly={!this.canSave()}
                    tooltip={`Button layout is visible on web from ${from} to ${to} when set to 'Yes'`}
                  />
                </Col>

                <Col md={12} lg={12}>
                  <Switch
                    fieldDecorator={{
                      id: 'mobile',
                      options: {
                        initialValue: false,
                        valuePropName: 'checked'
                      }
                    }}
                    form={form}
                    formItem={{
                      ...{
                        labelCol: { sm: 8, md: 6, lg: 6 },
                        wrapperCol: { sm: 16, md: 14, lg: 14 }
                      },
                      label: 'Mobile'
                    }}
                    input={{
                      checkedChildren: 'Yes',
                      unCheckedChildren: 'No',
                      tabIndex: 3
                    }}
                    readOnly={!this.canSave()}
                    tooltip={`Button layout is visible on mobile from ${from} to ${to} when set to 'Yes'`}
                  />
                </Col>
              </Row>
            </Modal>
          </Form>
        </Page.Body>
      </Page.Content>
    )
  }

  checkCount = (rule, value, callback) => {
    const errors = []

    if (parseInt(value, 10) < 1) {
      const { field } = rule
      errors.push(`Please select no. of ${field}`)
    }

    callback(errors)
  }

  checkImage = (file) => {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  // changeActive = (value) => {
  //   const { filter, searchText, sort } = this.state

  //   if (value === 'Yes') {
  //     filter.active = true
  //   } else if (value === 'No') {
  //     filter.active = false
  //   } else {
  //     delete filter.active
  //   }

  //   this.setState({ filter })
  //   this.fetchLayouts({ loading: true, currentPage: 1, filter, sort, searchText })
  // }

  changeActive = (value) => {
    const { filter, searchText, sort } = this.state

    if (value === 'Web') {
      delete filter.mobile
      filter.web = true
    } else if (value === 'Mobile') {
      delete filter.web
      filter.mobile = true
    } else {
      delete filter.web
      delete filter.mobile
    }

    this.setState({ filter })
    this.fetchLayouts({ loading: true, currentPage: 1, filter, sort, searchText })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchLayouts({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchLayouts = ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      this.setState({ currentPage, loading })
      sessionStorage.setObject(filterKey, filter)
      this.props.fetchButtonLayouts(currentPage, pageSize, filter, searchText)
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load button layouts successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findLayouts = (text) => {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchLayouts({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, sort, searchText: text })
    this.setState({ searchText: text })
  }

  getDimensionOptions = () => {
    const noOfOptions = 5
    const options = []

    for (let i = 1; i <= noOfOptions; i++) {
      options.push({ name: i.toString(), value: i.toString() })
    }

    return options
  }

  handleSave = () => {
    const { form, history } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        const { image, image_thumb } = item
        const { active, remark, show_from: from, show_to: to, web, mobile } = values
        // values.active = active ? 1 : 0
        values.web = web ? 1 : 0
        values.mobile = mobile ? 1 : 0
        values.remark = remark || ''
        values.show_from = moment(from).startOf('day')
        values.show_to = moment(to).endOf('day')
        values.image = image || ''
        values.image_thumb = image_thumb || ''
        this.setState({ loading: true })

        try {
          const response = await buttonLayoutService.addLayout(values)
          this.setState({ loading: false })

          if (response._id) {
            notification.show('success', 'Saved successfully', 'Button layout saved successfully.')
            // history.push(`/buttons/${response._id}`)
            this.hideAddModal()
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save button layout successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hideAddModal = () => {
    const { form } = this.props
    const { resetFields } = form
    resetFields()
    this.setState({ showModal: false })
  }

  showAddModal = () => {
    const { form } = this.props
    const { resetFields } = form
    resetFields()
    this.setState({ showModal: true })
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canAdd = () => {
    return this.hasAccess('createButton')
  }

  canList = () => {
    return this.hasAccess('listButton')
  }

  canRead = () => {
    return this.hasAccess('readButton')
  }

  canSave = () => {
    return this.hasAccess('createButton')
  }
}

const mapDispatchToProps = {
  fetchButtonLayouts
}

const mapStateToProps = (state) => {
  return { ...state.ButtonLayout }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeaturedButton))
