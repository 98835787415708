export const FETCH_COLLECTION = 'FETCH_COLLECTION'
export const COLLECTION_LOADED = 'COLLECTION_LOADED'
export const COLLECTION_FAILED = 'COLLECTION_FAILED'

export const fetchCollections = (page, size, filter, query) => {
  return {
    type: FETCH_COLLECTION,
    page,
    size,
    filter,
    query
  }
}
