export default {
  STATUS_APPROVED: 1,
  STATUS_PENDING: 2,
  STATUS_NOT_APPROVED: 3
}

export const ReviewStatusList = [
  { name: 'Approved', value: 1 },
  { name: 'Pending', value: 2 },
  { name: 'Not Approved', value: 3 }
]

export const CommentStatus = {
  STATUS_PENDING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3
}

export const CommentStatusList = [
  { name: 'Pending', value: 1 },
  { name: 'Approved', value: 2 },
  { name: 'Rejected', value: 3 }
]
