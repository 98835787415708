export default {
  ALL: 'all',
  ANDROID: 'android',
  IOS: 'ios'
}

export const NotificationAudiencesList = [
  { name: 'All', value: 'all' },
  { name: 'Android', value: 'android' },
  { name: 'iOS', value: 'ios' }
]
