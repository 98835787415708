import request from '../util/request'
import queryString from '../util/queryString'

const url = '/api/products/'

export default {
  addProduct (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getProduct (id) {
    return request(url + id)
  },
  getProducts () {
    return request(`${url}all`)
  },
  removeProduct (id) {
    return request(url + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveProduct (id, values) {
    return request(url + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  listProductsByPage (page, size, filter, text) {
    return request(`${url}page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  listFilteredProductsByPage (page, size, filter, text) {
    return request(`${url}filterproduct/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text: JSON.stringify(text) })}`)
  },
  listPromotionProductsByPage (page, size, filter, text) {
    return request(`${url}page/promotion/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  removeImage (id, iid) {
    return request(`${url}${id}/images/${iid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveImage (id, iid, values) {
    return request(`${url}${id}/images/${iid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getMagentoImage (sku) {
    return request(`${url}image/${sku}`)
  }
}
