import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

export default class WDTimeline extends Component {
  render () {
    const { description, items, title } = this.props

    return (
      <div className='wd-timeline'>
        {items ? (
          items.map((item, idx) => (
            <Row className='wd-timeline-item' key={idx}>
              <Col md={4} xs={3} sm={5}>
                {idx === 0 ? <div className='wd-timeline-now' /> : <div className='wd-timeline-past' />}

                {(items.length > 0 && idx >= 0 && idx !== (items.length - 1)) ? <div className='wd-timeline-line' /> : null}
              </Col>

              <Col md={20} xs={12} sm={12}>
                <div className='wd-timeline-title'>{item[title]}</div>
                <div className='wd-timeline-desc'>{formatter.toStandardDate(item[description])}</div>
              </Col>
            </Row>
          ))
        ) : null}
      </div>
    )
  }
}

WDTimeline.propTypes = {
  description: PropTypes.string,
  items: PropTypes.array,
  title: PropTypes.string
}
