import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
import BannerLinkTypes, { BannerLinkTypesList } from '../../../constants/banner/linkTypes'
import { authService, carouselService, brandService, productService, storeService } from '../../../services'
import { fetchAllCategories, fetchStores, fetchTemplates } from '../../../states/actions'
import { formatter, notification, validator } from '../../../util'

// UI
import { Button, DatePicker, Input, Page, Panel, Select, Spin, Switch, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

class Slider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brands: [],
      item: {},
      loading: false,
      loadingImage: false,
      loadingWebImage: false,
      products: { data: [], total: 0 },
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }
    this.fetchProducts = debounce(this.fetchProducts, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.fetchAllBrands()
      this.fetchCollections()
      this.fetchAllCategories()
      this.fetchProducts()
      // this.fetchStores()
      this.fetchAllStores()

      if (this.isEdit()) {
        this.fetchBanner()
      }
    } else {
      authService.unauthorizeAction('/sliders')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 14, md: 18 }
    }
    const formShortItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 8, md: 8 }
    }
    const selectFormItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { categories, form, history, templates } = this.props
    const { brands, item, loading, loadingImage, loadingWebImage, products, stores, showBrandList, showCategoryList, showPageInput, showProductList, showStoreList, showTemplateList } = this.state
    const { getFieldValue } = form
    const { data: productList } = products
    const from = formatter.toShortDate(getFieldValue('show_from'))
    const to = formatter.toShortDate(getFieldValue('show_to'))

    return (
      <Page.Content>
        <Page.Header title='Slider Banner' description='Upload a banner, set link, timeline and position for it.'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={this.handleDelete}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={this.handleSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Banner Details'>
                <div className='slider-panel'>
                  <div className='slider-panel-top'>
                    <div className='slider-column'>
                      <div className='slider-image-title'>Mobile Banner</div>
                      <div className='slider-image-uploader'>
                        <UploadHolder
                          img={{
                            alt: 'Mobile Banner',
                            src: item.image ? item.image : ''
                          }}
                          loading={loadingImage}
                          readOnly={!this.canSave()}
                          height={200}
                          width={320}
                          upload={{
                            action: '/api/carousels/upload/image',
                            beforeUpload: this.checkImage,
                            data: { _id: item._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image',
                            onChange: this.handleUploadImage,
                            showUploadList: false
                          }}
                        />
                      </div>

                      <div className='slider-image-hint'>
                        Size: 580px x 420px (JPG or PNG) <br />
                        Maximum file size: 2 MB
                      </div>
                    </div>

                    <div className='web slider-column'>
                      <div className='web slider-image-title'>Web Banner</div>
                      <div className='web slider-image-uploader'>
                        <UploadHolder
                          img={{
                            alt: 'Web Banner',
                            src: item.web_image ? item.web_image : ''
                          }}
                          loading={loadingWebImage}
                          readOnly={!this.canSave()}
                          height={200}
                          width={320}
                          upload={{
                            action: '/api/carousels/upload/web_image',
                            beforeUpload: this.checkImage,
                            data: { _id: item._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image',
                            onChange: this.handleUploadWebImage,
                            showUploadList: false
                          }}
                        />
                      </div>

                      <div className='web slider-image-hint'>
                        Size: 1160px x 300px (JPG or PNG) <br />
                        Maximum file size: 2 MB
                      </div>
                    </div>
                  </div>

                  <div className='slider-panel-bottom'>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 64 characters' },
                            { max: 64, message: 'Name must be between 2 and 64 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Please enter remark' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        autosize: { minRows: 2, maxRows: 5 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    <FormItem
                      {...formItemLayout}
                      label={(
                        <span>
                          Link&nbsp;
                          <Tooltip title='Link button to a product or external custom page'>
                            <Icon type='question-circle-o' />
                          </Tooltip>
                        </span>
                      )}
                    >
                      <Row gutter={8}>
                        <Col md={8}>
                          <Select
                            fieldDecorator={{
                              id: 'link_type',
                              options: {
                                initialValue: item.link_type || BannerLinkTypes.NONE
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={BannerLinkTypesList}
                            listFunc={({ name, value }) => (
                              <Option key={value} value={value}>{name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              onChange: this.changeLinkType,
                              style: { width: '100%' }
                            }}
                          />
                        </Col>

                        <Col md={16}>
                          {showBrandList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a brand' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={brands}
                              listFunc={(brand) => (
                                <Option className='text-wrap' key={brand.option_id} value={brand.option_id.toString()}>{brand.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a brand',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showTemplateList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a collection' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={templates.list}
                              listFunc={(template) => (
                                <Option key={template._id} value={template._id.toString()}>{template.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a collection',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showCategoryList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a category' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={categories}
                              listFunc={(category) => (
                                <Option className='text-wrap' key={category.id} value={category.id.toString()}>{category.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a category',
                                showSearch: true,
                                style: { width: '100%' },
                                dropdownMatchSelectWidth: false,
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showStoreList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? parseInt(item.link_value) : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a store' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={stores}
                              listFunc={(store) => {
                                return <Option key={store.id} value={store.id}>{(store.name !== null ? store.name : '') + ' (' + store.vendor_id + ')' }</Option>
                              }}
                              select={{
                                placeholder: 'Select a store',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showProductList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value,
                                  rules: [
                                    { required: true, message: 'Please select a product' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={productList}
                              listFunc={(product) => {
                                const { name, sku } = product

                                return (
                                  <Option key={sku} value={sku}>
                                    <div className='text-wrap'>{name}</div>

                                    <div className='wd-trending-option-subtitle'>SKU: {sku}</div>
                                  </Option>
                                )
                              }}
                              readOnly={!this.canSave()}
                              select={{
                                filterOption: false,
                                onSearch: this.fetchProducts,
                                placeholder: 'Select a product',
                                showSearch: true,
                                dropdownMatchSelectWidth: false,
                                style: { width: '100%' }
                              }}
                            />
                          ) : null}

                          {showPageInput ? (
                            <Input
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value,
                                  rules: [
                                    { required: true, message: 'Please enter custom page URL' },
                                    { whitespace: true, message: 'Please enter custom page URL' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              input={{
                                placeholder: 'Enter custom page URL',
                                tabIndex: 3
                              }}
                              readOnly={!this.canSave()}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </FormItem>

                    <DatePicker
                      datePicker={{
                        allowClear: false,
                        placeholder: 'Select a date',
                        style: { width: '100%' }
                      }}
                      fieldDecorator={{
                        id: 'show_from',
                        options: {
                          initialValue: item.show_from ? moment(item.show_from) : moment(),
                          rules: [
                            { required: true, message: 'Please select a date' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formShortItemLayout,
                        label: 'From'
                      }}
                      readOnly={!this.canSave()}
                    />

                    <DatePicker
                      datePicker={{
                        allowClear: false,
                        placeholder: 'Select a date',
                        style: { width: '100%' }
                      }}
                      fieldDecorator={{
                        id: 'show_to',
                        options: {
                          initialValue: item.show_to ? moment(item.show_to) : moment().add(1, 'day'),
                          rules: [
                            { required: true, message: 'Please select a date' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formShortItemLayout,
                        label: 'To'
                      }}
                      readOnly={!this.canSave()}
                    />

                    {this.getPositionSelect(formItemLayout, this.isEdit(), item.position)}

                    <Switch
                      fieldDecorator={{
                        id: 'web',
                        options: {
                          initialValue: item.web === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Web'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 4
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Banner is visible on web from ${from} to ${to} when set to 'Yes'`}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'mobile',
                        options: {
                          initialValue: item.mobile === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Mobile'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 5
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Banner is visible on mobile from ${from} to ${to} when set to 'Yes'`}
                    />
                  </div>
                </div>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  changeLinkType = (value) => {
    const { form } = this.props
    const { item } = this.state
    const { resetFields } = form
    const showList = {
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }

    if (value === BannerLinkTypes.BRAND) {
      showList.showBrandList = true
    } else if (value === BannerLinkTypes.CATEGORY) {
      showList.showCategoryList = true
    } else if (value === BannerLinkTypes.COLLECTION) {
      showList.showTemplateList = true
    } else if (value === BannerLinkTypes.STORE) {
      showList.showStoreList = true
    } else if (value === BannerLinkTypes.PRODUCT) {
      showList.showProductList = true
    } else if (value === BannerLinkTypes.CUSTOM_PAGE) {
      showList.showPageInput = true
    }

    resetFields(['link_value'])
    item.link_type = value
    item.link_value = undefined
    this.setState({ item, ...showList })
  }

  checkImage = (file) => {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  fetchAllBrands = async () => {
    try {
      const item = await brandService.getAllBrands()
      this.setState({ brands: item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
    }
  }

  fetchBanner = async () => {
    try {
      this.setState({ loading: true })
      const item = await carouselService.get(this.props.match.params.id)
      const { link_type } = item
      this.setState({
        item,
        loading: false,
        showBrandList: link_type === BannerLinkTypes.BRAND,
        showCategoryList: link_type === BannerLinkTypes.CATEGORY,
        showPageInput: link_type === BannerLinkTypes.CUSTOM_PAGE,
        showProductList: link_type === BannerLinkTypes.PRODUCT,
        showStoreList: link_type === BannerLinkTypes.STORE,
        showTemplateList: link_type === BannerLinkTypes.COLLECTION
      })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load banner successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchAllCategories = async () => {
    try {
      this.props.fetchAllCategories()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  fetchCollections = async () => {
    try {
      this.props.fetchTemplates(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load collections successfully. Please try again later.')
    }
  }

  fetchProducts = async (text) => {
    try {
      const products = await productService.listProductsByPage(1, 100, {}, text)
      this.setState({ products })
    } catch (e) {
      console.error(e)
    }
  }

  fetchStores = async () => {
    try {
      this.props.fetchStores(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load stores successfully. Please try again later.')
    }
  }

  async fetchAllStores () {
    try {
      this.setState({ loading: true })
      const item = await storeService.getAllVendors()
      this.setState({ stores: item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load store successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  getPositionSelect = (formShortItemLayout, isEdit, position) => {
    const { carousels, form } = this.props
    const { total } = carousels
    const defaultPosition = isEdit ? position : total + 1
    const list = []

    for (let i = 1; i <= total; i++) {
      list.push(i)
    }

    return (
      <Select
        fieldDecorator={{
          id: 'position',
          options: {
            initialValue: defaultPosition
          }
        }}
        form={form}
        formItem={{
          ...formShortItemLayout,
          hasFeedback: false,
          label: 'Position'
        }}
        list={list}
        listFunc={(position) => {
          return <Option key={position} value={position}>{position}</Option>
        }}
        select={{
          showSearch: true,
          placeholder: 'Select a position'
        }}
      />
    )
  }

  handleDelete = async () => {
    const { history, match } = this.props
    const carousels = await carouselService.listByPage(1, 100, {})
    const { data: list, total } = carousels

    confirm({
      title: 'Confirm to delete this banner?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          let id = match.params.id
          let updateList = []
          let oldPosition = 0

          for (let i = 0; i < total; i++) {
            const { _id, position } = list[i]
            const newItem = { id: _id }

            if (id === _id.toString()) {
              oldPosition = position
            } else {
              if (oldPosition > 0 && position > oldPosition) {
                newItem.position = i
              } else {
                newItem.position = i + 1
              }

              updateList.push(newItem)
            }
          }

          const response = await carouselService.remove(id, { list: updateList })

          if (response) {
            notification.show('success', 'Deleted successfully', 'Banner deleted successfully.')
            history.replace('/home/sliders')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete', 'Unable to delete banner. Please try again later.')
        }
      }
    })
  }

  handleSave = async () => {
    
    const { form, history, carousels } = this.props
    const { validateFields } = form
    const { list } = carousels
    const { item } = this.state
    let bannerId = item._id
    let newCarousels = carousels

    if (newCarousels.total <= 0 ) {
      newCarousels = await carouselService.listByPage(1, 105, {})
    }
    if (newCarousels.total > 99 && bannerId === undefined) {   
      notification.show('error', 'Unable to save', 'Maximum of 100 banners.')
      return
    }
    
    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        values.orgId = item.orgId
        values.remark = values.remark || ''
        values.show_from = formatter.toISOString(moment.utc(values.show_from).startOf('day'))
        values.show_to = formatter.toISOString(moment.utc(values.show_to).endOf('day'))
        this.setState({ loading: true })

        try {
          // console.log('web_image', item.web_image.url, 2, item.web_image)
          // console.log('thumb', item.web_image.thumbUrl, 2, item.web_image_thumb)
          values.image = item.image ? (item.image.url || item.image) : ''
          values.image_thumb = item.image ? (item.image.thumbUrl || item.image_thumb) : ''
          values.web_image = item.web_image ? (item.web_image.url || item.web_image) : ''
          values.web_image_thumb = item.web_image_thumb ? (item.web_image.thumbUrl || item.web_image_thumb) : ''
          // values.active = values.active ? 1 : 0
          values.web = values.web ? 1 : 0
          values.mobile = values.mobile ? 1 : 0

          if (this.isEdit()) {
            let id = item._id
            let oldPosition = item.position || 0
            const { position: newPosition } = values
            const positionChanged = newPosition !== oldPosition
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                const newItem = { id: _id }
                if (_id === id) {
                  newItem.position = newPosition
                } else {
                  if (newPosition < oldPosition) {
                    if (itemPosition < newPosition || itemPosition > oldPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx + 2
                    }
                  } else {
                    if (itemPosition < oldPosition || itemPosition > newPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx
                    }
                  }
                }

                return newItem
              })
            }
            const response = await carouselService.save(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Banner saved successfully.')
            }
          } else {
            const { position: newPosition } = values
            const positionChanged = newPosition < newCarousels.total + 1
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                return { id: _id, position: itemPosition < newPosition ? idx + 1 : idx + 2 }
              })
            }
            const response = await carouselService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Banner saved successfully.')
              history.replace(`/home/sliders/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save banner successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  handleUploadWebImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingWebImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.web_image = response.url
      item.web_image_thumb = response.thumbUrl
      this.setState({ item, loadingWebImage: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canDelete = () => {
    return this.hasAccess('deleteSlider')
  }

  canRead = () => {
    return this.hasAccess('readSlider')
  }

  canSave = () => {
    return this.hasAccess(this.isEdit() ? 'updateSlider' : 'createSlider')
  }

  canUpload = () => {
    return this.canSave()
  }

  canDeletePhoto = () => {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto = () => {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit = () => {
    return this.props.match.params.id !== 'add'
  }

}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchStores,
  fetchTemplates
}

const mapStateToProps = (state) => {
  return {
    ...state.Carousel,
    ...state.Categories,
    ...state.Store,
    ...state.Template
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Slider))
