import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_BUTTON_LAYOUTS, BUTTON_LAYOUTS_LOADED, BUTTON_LAYOUTS_FAILED } from '../../actions/button/layout'
import buttonLayoutService from '../../../services/button/layout'

function * fetchButtonlayouts (params) {
  try {
    const layouts = yield buttonLayoutService.listLayoutsByPage(params.page, params.size, params.filter, params.query)
    yield put({ type: BUTTON_LAYOUTS_LOADED, layouts })
  } catch (e) {
    yield put({ type: BUTTON_LAYOUTS_FAILED, message: e.message })
  }
}

function * watchButtonLayout () {
  yield takeEvery(FETCH_BUTTON_LAYOUTS, fetchButtonlayouts)
}

export default watchButtonLayout
