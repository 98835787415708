import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_REDEMPTIONS, REDEMPTIONS_LOADED, REDEMPTIONS_FAILED } from '../actions/redemption'
import redemptionService from '../../services/redemption'

// Generators
function * fetchRedemptions (params) {
  try {
    const redemptions = yield call(() => redemptionService.listRedemptionsByPage(params.page, params.size, params.filter, params.sort, params.query))
    yield put({ type: REDEMPTIONS_LOADED, redemptions })
  } catch (e) {
    yield put({ type: REDEMPTIONS_FAILED, message: e.message })
  }
}

function * watchVoucher () {
  yield takeEvery(FETCH_REDEMPTIONS, fetchRedemptions)
}

export default watchVoucher
