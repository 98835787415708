import { ADMINS_FETCHED, ADMIN_SET_PRISTINE } from '../actions/admin'

const INITIAL_STATE = {
  admins: { data: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADMINS_FETCHED:
      return { ...state, admins: action.admins, pristine: false }
    case ADMIN_SET_PRISTINE:
      return { ...state, pristine: action.pristine }
    default:
      return state
  }
}
