import { ALL_STORE_LOADED, STORE_LOADED } from '../actions/store'

const INITIAL_STATE = {
  all_stores: [],
  stores: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_STORE_LOADED:
      return { ...state, all_stores: action.all_stores }
    case STORE_LOADED:
      return { ...state, stores: { list: action.stores.data, total: action.stores.total } }
    default:
      return state
  }
}
