import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { authService, productSoldService } from '../../../services'
//import { vendorsFetched } from '../../../states/actions/vendor'
import { productFetched } from '../../../states/actions/product'
import { formatter, notification, sessionStorage } from '../../../util'

// UI
import { Button, List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const filterKey = 'productSold.filter'
const pageKey = 'productSold.page'
const pageSize = 20

class ProductSold extends Component {
  constructor (props) {
    super(props)
    const { productSold: { data: list, total } } = props
    const currentPage = sessionStorage.getItem(pageKey)
    this.state = {
      currentPage: currentPage ? parseInt(currentPage, 10) : 1,
      filter: sessionStorage.getObject(filterKey),
      list,
      loading: false,
      searchText: '',
      total
    }
    this.findProducts = debounce(this.findProducts, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { productSold: { data: list, total } } = nextProps
    return { ...prevState, list, total }
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter } = this.state
      this.fetchProducts({ currentPage, filter })
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { currentPage, list, loading, total } = this.state
    const columns = [
      {
        key: 'entity_id',
        title: 'Product ID',
        width: 2
        //render: (row) => <ListIcon file='admin.svg' name={row.Id} />
      },
      {
        key: 'name',
        title: 'Pruduct Name',
        width: 6,
        render: (row) => <div style={{ width:'100%'}}><div style={{flexShrink: 1}}>{row.name}</div></div>
      },
      {
        key: 'sku',
        title: 'Sku',
        width: 4
      },
      {
        key: 'sold_amount',
        title: 'Sold Quantity',
        width: 4,
      },
      {
        key: 'addon_sold',
        title: 'Addon Sold Quantity',
        width: 4,
      },
      {
        key: 'total_sold',
        title: 'Total Sold Quantity',
        width: 4
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Products (${total})`} description='Products Sold Quantity Setting' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Products' onChange={this.findProducts} tabIndex={1} />
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} loading={loading} rows={list} link={this.canRead() ? '/settings/productsSold/<entity_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} Products`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText } = this.state
    this.fetchProducts({ loading: true, currentPage, filter, searchText })
  }

  fetchProducts = async ({ loading = false, currentPage = 1, filter = {}, searchText }) => {
    try {
      const { productFetched, productSoldPristine } = this.props
      sessionStorage.setObject(filterKey, filter)
      sessionStorage.setItem(pageKey, currentPage)
      this.setState({ currentPage, loading: loading || productSoldPristine })
      const response = await productSoldService.listByPage(currentPage, pageSize, filter, searchText)
      this.setState({ list: response.data, loading: false, total: response.total })
      productFetched(response)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load products successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findProducts = (text) => {
    const { currentPage, filter, searchText } = this.state
    this.fetchProducts({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, searchText: text })
    this.setState({ searchText: text })
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canAdd = () => {
    return this.hasAccess('createProductsSold')
  }

  canList = () => {
    return this.hasAccess('listProductsSold')
  }

  canRead = () => {
    return this.hasAccess('readProductsSold')
  }
}

const mapDispatchToProps = {
  productFetched
}

const mapStateToProps = (state) => {
  return { ...state.Product }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSold)
