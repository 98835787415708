import { TRENDING_GROUPS_LOADED, TRENDINGS_LOADED } from '../actions/trending'

const INITIAL_STATE = {
  trendingGroups: { list: [], total: 0 },
  trendings: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRENDING_GROUPS_LOADED:
      return { ...state, trendingGroups: { list: action.trendings.list, total: action.trendings.total } }
    case TRENDINGS_LOADED:
      return { ...state, trendings: { list: action.trendings.list, total: action.trendings.total } }
    default:
      return state
  }
}
