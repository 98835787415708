import request from '../util/request'
import queryString from '../util/queryString'

const url = '/api/categories'
const webUrl = '/api/web-categories'
const sliderUrl = '/api/categories-slider'

export default {
  list () {
    return request(`${url}/master`)
  },
  listAll () {
    return request(url)
  },
  getHighlight (id) {
    return request(`${url}/${id}/highlights`)
  },
  addBanner (values) {
    return request(`${url}/banners`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  saveBanner (id, values) {
    return request(`${url}/banners/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  addBrand (values) {
    return request(`${url}/brands`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  removeBrand (id) {
    return request(`${url}/brands/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveBrand (id, values) {
    return request(`${url}/brands/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },

  /** Web Categories */
  listWebByPage (page, size, filter, text) {
    return request(`${webUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  addWebCategory (values) {
    return request(webUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getWebById (id) {
    return request(`${webUrl}/${id}`)
  },
  saveWebCategory (id, values) {
    return request(`${webUrl}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  removeWebCategory (id, values) {
    return request(`${webUrl}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },

  /** Categories Slider */
  listSliderByPage (page, size, filter, text) {
    return request(`${sliderUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  addSliderCategory (values) {
    return request(sliderUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getSliderById (id) {
    return request(`${sliderUrl}/${id}`)
  },
  saveSliderCategory (id, values) {
    return request(`${sliderUrl}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  removeSliderCategory (id, values) {
    return request(`${sliderUrl}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  }
}
