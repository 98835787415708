import { call, put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_REVIEW_TITLE_LIST,
  REVIEW_TITLE_LOADED,
  REVIEW_TITLE_FAILED,
  FETCH_REVIEW_LIST,
  REVIEW_LOADED,
  REVIEW_FAILED,
  FETCH_REVIEW_COMMENT_LIST,
  REVIEW_COMMENT_LOADED,
  REVIEW_COMMENT_FAILED
} from '../actions/review'
import { reviewTitleService, reviewService, reviewCommentService } from '../../services'

// Generators
function * fetchReviewTitle (params) {
  try {
    const reviewTitles = yield call(() => reviewTitleService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: REVIEW_TITLE_LOADED, reviewTitles })
  } catch (e) {
    yield put({ type: REVIEW_TITLE_FAILED, message: e.message })
  }
}

function * fetchReviews (params) {
  try {
    const reviews = yield call(() => reviewService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: REVIEW_LOADED, reviews })
  } catch (e) {
    yield put({ type: REVIEW_FAILED, message: e.message })
  }
}

function * fetchReviewsComment (params) {
  try {
    const reviewComments = yield call(() => reviewCommentService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: REVIEW_COMMENT_LOADED, reviewComments })
  } catch (e) {
    yield put({ type: REVIEW_COMMENT_FAILED, message: e.message })
  }
}

function * watchReview () {
  yield takeEvery(FETCH_REVIEW_TITLE_LIST, fetchReviewTitle)
  yield takeEvery(FETCH_REVIEW_LIST, fetchReviews)
  yield takeEvery(FETCH_REVIEW_COMMENT_LIST, fetchReviewsComment)
}

export default watchReview
