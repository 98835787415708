import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { adminService, authService, moduleService, permissionService, productSoldService } from '../../../services'
import { notification } from '../../../util'

// UI
import { Button, Input, Page, Panel, Spin, Switch, Select } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'
import { magento } from '../../../config'

const confirm = Modal.confirm
const Option = Select.Option

class ProductSoldPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      products: [],
      productsValidateStatus: undefined
    }
    //this.checkEmailExists = debounce(this.checkEmailExists, 500)
    this.findProducts = debounce(this.findProducts, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.findProducts()

      if (this.isEdit()) {
        this.fetchProduct()
      }
    } else {
      authService.unauthorizeAction('/settings/productsSold')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 7, md: 7 },
      wrapperCol: { sm: 15, md: 15 }
    }
    const { form, history } = this.props
    const { item, loading, products, productsValidateStatus } = this.state
    /*let defaultVendor

    if (!this.canSave()) {
      defaultVendor = products.find(({ entity_id }) =>  entity_id === item.v_id) || {}
    }*/

    return (
      <Page.Content>
        <Page.Header title='Product Details' description='Setting for Products Sold Quantity'>
          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Basic Information'>
                <Row>
                  <Col lg={12}>
                    <img className='product-image-medium' style={{display: 'flex', marginBottom:'15px'}} alt='' src={item.thumbnail ? magento.media + item.thumbnail : '/img/default-image.png'} />
                    <Input
                      fieldDecorator={{
                        id: 'product_name',
                        options: {
                          initialValue: item.name,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Product Name'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />
                    <Input
                      fieldDecorator={{
                        id: 'entity_id',
                        options: {
                          initialValue: item.entity_id,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Product Id'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />

                    <Input
                      fieldDecorator={{
                        id: 'sku',
                        options: {
                          initialValue: item.sku,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Sku'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />

                    <Input
                      fieldDecorator={{
                        id: 'sold_amount',
                        options: {
                          initialValue: item.sold_amount,
                          /*rules: [
                            //{ min: 1, message: 'Min. Quantity must be integer' },
                            { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) }
                          ]*/
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Sold Quantity'
                      }}
                      input={{
                        tabIndex: 7
                      }}
              
                      readOnly
                    />

                    <Input
                      fieldDecorator={{
                        id: 'addon_sold',
                        options: {
                          initialValue: item.addon_sold,
                          rules: [
                            //{ min: 1, message: 'Min. Quantity must be integer' },
                            { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Addon Sold Quantity'
                      }}
                      input={{
                        tabIndex: 7
                      }}
              
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'total_sold',
                        options: {
                          initialValue: item.total_sold,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Total Sold Quantity'
                      }}
                      input={{
                        tabIndex: 8,            
                      }}
                      readOnly
                    />

                  </Col>
                </Row>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }


  /*checkEmail (rule, value, callback) {
    if (value && value.length > 0 && !validator.isEmail(value)) {
      callback('E-mail is invalid')
    }

    this.checkEmailExists(rule, value, callback)
  }*/


  /*checkInteger (rule, value, callback) {
    const { item } = this.state
    
    if (item.minPrice == null && item.min_quantity == null) {
      callback('At least one field must be filled')
    }
    else if (item.min_quantity == null && item.minPrice != null) {
        let decimal = ( /^\d+(.\d{0,2})?$/)
        decimal.test(value)
        callback('Min. Quantity must be decimal')
      }
    else if (item.minPrice == null && item.min_quantity) {
        let integer = (/^[0-9\b]+$/)
        integer.test(value)
        callback('Min. Quantity must be integer')
      }
    
    callback()
  }*/

  checkInteger (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^[0-9\b]+$/).test(value)) {
      callback('Min. Quantity must be integer')
    }
    callback()
  }

  checkDecimal (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^\d+(.\d{0,2})?$/).test(value)) {
      callback('Min. Price(RM) must be decimal')
    }
    callback()
  }

  /*checkEmailExists (rule, value, callback) {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const { email } = this.state.item
        const response = await adminService.checkEmail(value)

        if (response.exists !== false && email !== value) {
          callback('E-mail already exists')
        }
      }

      callback()
    })
  }*/

  async fetchProduct () {
    try {
      this.setState({ loading: true })
      const item = await productSoldService.get(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load product successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async findProducts (value) {
    try {
      this.setState({ productsValidateStatus: 'validating' })
      productSoldService
        .findByName(100, value || '')
        .then((products) => {
          this.setState({ products, productsValidateStatus: undefined })
        } )
    } catch (e) {
      console.error(e)
    }
  }

  /*handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await adminService.removeAdmin(match.params.id)

          if (response._id) {
            notification.show('success', 'Deleted successfully', 'User deleted successfully.')
            history.replace('/settings/admins')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete user successfully. Please try again later.')
        }
      }
    })
  }*/

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })
        try {
          if (this.isEdit()) {
            values.sku = item.sku
            const response = await productSoldService.save(item.entity_id, values)
            this.setState({ item: { ...item, ...values }, loading: false })
            if (response.product_id) {
              notification.show('success', 'Saved successfully', 'Settings saved successfully.')
              if (this.canRead()) {
                this.findProducts()
          
                if (this.isEdit()) {
                  this.fetchProduct()
                }
              }
            } else {
              notification.show('error', 'Unable to save successfully', 'Unable to save settings successfully. Please try again later.')
              this.setState({ loading: false })
            }
          } /*else {
            const response = await vendorService.add(values)
            this.setState({ loading: false })

            if (response.vendor_id) {
              this.setState({ item: { ...item, ...values, vendor_id: response.vendor_id } })
              notification.show('success', 'Saved successfully', 'User saved successfully.')
              history.replace(`/settings/admins/${response.vendor_id}`)
            } else {
              notification.show('error', 'Unable to save successfully', 'Unable to save user successfully. Please try again later.')
              this.setState({ loading: false })
            }
          }*/
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save settings successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteProductsSold')
  }

  canRead () {
    return this.hasAccess('readProductsSold')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateProductsSold' : 'createProductsSold')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ProductSoldPage))
