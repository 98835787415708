import {
  CATEGORIES_LOADED,
  ALL_CATEGORIES_LOADED,
  WEB_CATEGORIES_LOADED,
  CATEGORIES_SLIDER_LOADED
} from '../actions/category'

const INITIAL_STATE = {
  categories: [],
  masterCategories: [],
  webCategories: { list: [], total: 0 },
  categoriesSlider: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ALL_CATEGORIES_LOADED:
      return { ...state, categories: action.categories }
    case CATEGORIES_LOADED:
      return { ...state, masterCategories: action.categories }
    case WEB_CATEGORIES_LOADED:
      return { ...state, webCategories: { list: action.webCategories.data, total: action.webCategories.total } }
    case CATEGORIES_SLIDER_LOADED:
      return { ...state, categoriesSlider: { list: action.categoriesSlider.data, total: action.categoriesSlider.total } }
    default:
      return state
  }
}
