export const FETCH_PROMOTIONS = 'FETCH_PROMOTIONS'
export const PROMOTIONS_LOADED = 'PROMOTIONS_LOADED'
export const PROMOTIONS_FAILED = 'PROMOTIONS_FAILED'
export const PROMOTION_GROUPS_LOADED = 'PROMOTION_GROUPS_LOADED'
export const FETCH_PROMOTION_GROUP = 'FETCH_PROMOTION_GROUP'

export const FETCH_PROMOTIONS_LABEL = 'FETCH_PROMOTIONS_LABEL'
export const PROMOTIONS_LABEL_LOADED = 'PROMOTIONS_LABEL_LOADED'
export const PROMOTIONS_LABEL_FAILED = 'PROMOTIONS_LABEL_FAILED'
export const PROMOTION_LABEL_GROUPS_LOADED = 'PROMOTION_LABEL_GROUPS_LOADED'
export const FETCH_PROMOTION_LABEL_GROUP = 'FETCH_PROMOTION_LABEL_GROUP'

export const fetchPromotionGroups = (page, size, filter, query) => {
  return {
    type: FETCH_PROMOTION_GROUP,
    page,
    size,
    filter,
    query
  }
}

export const fetchPromotions = (page, size, filter, query) => {
  return {
    type: FETCH_PROMOTIONS,
    page,
    size,
    filter,
    query
  }
}

export const fetchPromotionLabelGroups = (page, size, filter, query) => {
  return {
    type: FETCH_PROMOTION_LABEL_GROUP,
    page,
    size,
    filter,
    query
  }
}

export const fetchPromotionsLabel = (page, size, filter, query) => {
  return {
    type: FETCH_PROMOTIONS_LABEL,
    page,
    size,
    filter,
    query
  }
}
