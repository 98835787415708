import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_BANNERS, BANNERS_LOADED, BANNERS_FAILED } from '../actions/banner'
import bannerService from '../../services/banner'

function * fetchBanners (params) {
  try {
    const banners = yield call(() => bannerService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: BANNERS_LOADED, banners })
  } catch (e) {
    yield put({ type: BANNERS_FAILED, message: e.message })
  }
}

function * watchBanners () {
  yield takeEvery(FETCH_BANNERS, fetchBanners)
}

export default watchBanners
