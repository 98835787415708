
import BannerList from './containers/Banner/List'
import BannerPage from './containers/Banner/Page'
import Dashboard from './containers/Dashboard'
import IntroductionList from './containers/Intro/List'
import IntroductionPage from './containers/Intro/Page'
import CategoryList from './containers/Category/List'
import CategoryPage from './containers/Category/Page'
import CategorySlideList from './containers/CategorySlide/List'
import CategorySlidePage from './containers/CategorySlide/Page'
import MiniBannerList from './containers/MiniBanner/List'
import MiniBannerPage from './containers/MiniBanner/Page'
import CollectionPageList from './containers/Collection/Template/List'
import CollectionPagePage from './containers/Collection/Template/Page'
import CustomPageList from './containers/CustomPage/List'
import CustomPagePage from './containers/CustomPage/Page'
import FeaturedOnList from './containers/FeaturedOn/List'
import FeaturedOnPage from './containers/FeaturedOn/Page'
import FeaturedStoreList from './containers/FeaturedStore/List'
import FeaturedStorePage from './containers/FeaturedStore/Page'
import ButtonList from './containers/Button/List'
import ButtonPage from './containers/Button/Page'
import PopupAdList from './containers/PopupAd/List'
import PopupAdPage from './containers/PopupAd/Page'
import NotificationList from './containers/Notification/List'
import NotificationPage from './containers/Notification/Page'
import RecommendationPage from './containers/Recommendation/Page'
import ReviewList from './containers/Review/Reviews/List'
import ReviewPage from './containers/Review/Reviews/Page'
import ReviewTitleList from './containers/Review/PredefinedTitle/List'
import ReviewTitlePage from './containers/Review/PredefinedTitle/Page'
import ReviewCommentList from './containers/Review/Comments/List'
import SliderList from './containers/Slider/List'
import SliderPage from './containers/Slider/Page'
import MobileTileList from './containers/Tile/Mobile/List'
import MobileTilePage from './containers/Tile/Mobile/Page'
import TopBrandList from './containers/TopBrand/List'
import TopBrandPage from './containers/TopBrand/Page'
import TrendingProductList from './containers/TrendingProduct/List'
import TrendingProductPage from './containers/TrendingProduct/Page'
import WebTileList from './containers/Tile/Web/List'
import WebTilePage from './containers/Tile/Web/Page'

import AdminList from './containers/Admin/List'
import AdminPage from './containers/Admin/Page'
import RoleList from './containers/Role/List'
import RolePage from './containers/Role/Page'
import VendorList from './containers/Vendor/List'
import VendorPage from './containers/Vendor/Page'
import CelebList from './containers/Celeb/List'
import CelebPage from './containers/Celeb/Page'
import AuditList from './containers/Audit/List'
import PromotionList from './containers/PromotionProduct/List'
import PromotionPage from './containers/PromotionProduct/Page'
import CartPriceRuleList from './containers/CartPriceRule/List'
import CartPriceRulePage from './containers/CartPriceRule/Page'
import TopFavourite from './containers/TopFavourite/List'
import TopFavouriteProduct from './containers/TopFavourite/Page'

import TopFavouriteCartPriceRules from './containers/TopFavouriteCartPriceRules/List'
import TopFavouriteCartPriceRulesProduct from './containers/TopFavouriteCartPriceRules/Page'

import ProductSold from './containers/ProductSold/List'
import ProductSoldPage from './containers/ProductSold/Page'

import PromotionLabel from './containers/Promotion/List'
import PromotionLabelPage from './containers/Promotion/Page'

import ContentManagementSystemList from './containers/ContentManagementSystem/List'
import ContentManagementSystemPage from './containers/ContentManagementSystem/Page'

import RedemptionList from './containers/Redemption/List'
import RedemptionPage from './containers/Redemption/Page'

import Rewards from './containers/RewardMision/List'
import RewardsDetail from './containers/RewardMision/Page'

export const routes = [
  {
    component: Dashboard,
    exact: true,
    icon: 'dashboard.svg',
    menu: true,
    name: 'Dashboard',
    path: '/'
  },
  {
    icon: 'popup.svg',
    menu: true,
    name: 'Home Screen',
    path: '/home',
    submenu: [
      {
        name: 'Sliders',
        path: '/home/sliders',
        permission: 'listAppIntro'
      },
      {
        name: 'Buttons',
        path: '/home/buttons',
        permission: 'listButton'
      },
      /* {
        name: 'Mobile Tiles',
        path: '/home/tiles/mobile',
        permission: 'listMobileTiles'
      }, */
      {
        name: 'Web Tiles',
        path: '/home/tiles/web',
        permission: 'listWebTiles'
      },
      {
        name: 'Banners',
        path: '/home/banners',
        permission: 'listBanner'
      },
      {
        name: 'Mini Banners',
        path: '/home/mini-banner',
        permission: 'listMiniBanner'
      },
      {
        name: 'Categories',
        path: '/home/categories',
        permission: 'listCategory'
      },
      {
        name: 'New In Store',
        path: '/home/stores',
        permission: 'listStore'
      },
      {
        name: 'Top Brands',
        path: '/home/brands',
        permission: 'listBrand'
      },
      {
        name: 'Trending',
        path: '/home/trendings',
        permission: 'listTrendingProduct'
      },
      {
        name: 'Featured On',
        path: '/home/featured-on',
        // permission: 'list'
      },
      {
        name: 'Promotion',
        path: '/home/promotion',
        permission: 'listPromotionProduct'
      }
    ]
  },
  {
    component: SliderList,
    exact: true,
    icon: 'banner.svg',
    name: 'Slider',
    path: '/home/sliders',
    permission: 'listSlider'
  },
  {
    component: SliderPage,
    exact: true,
    path: '/home/sliders/:id'
  },
  {
    component: ButtonList,
    exact: true,
    icon: 'menu.svg',
    name: 'Buttons',
    path: '/home/buttons',
    permission: 'listButton'
  },
  {
    component: ButtonPage,
    exact: true,
    path: '/home/buttons/:id'
  },
  {
    component: MobileTileList,
    exact: true,
    icon: 'product.svg',
    name: 'Mobile Tiles',
    path: '/home/tiles/mobile',
    permission: 'listMobileTiles'
  },
  {
    component: MobileTilePage,
    exact: true,
    path: '/home/tiles/mobile/:id'
  },
  {
    component: WebTileList,
    exact: true,
    icon: 'product.svg',
    name: 'Web Tiles',
    path: '/home/tiles/web',
    permission: 'listWebTiles'
  },
  {
    component: WebTilePage,
    exact: true,
    path: '/home/tiles/web/:id'
  },
  {
    component: BannerList,
    exact: true,
    icon: 'product.svg',
    name: 'Banners',
    path: '/home/banners',
    permission: 'listBanner'
  },
  {
    component: BannerPage,
    exact: true,
    path: '/home/banners/:id'
  },
  {
    component: MiniBannerList,
    exact: true,
    icon: 'product.svg',
    name: 'Mini Bannners',
    path: '/home/mini-banner',
    permission: 'listMiniBanner'
  },
  {
    component: MiniBannerPage,
    exact: true,
    path: '/home/mini-banner/:id'
  },
  {
    component: CategorySlideList,
    exact: true,
    path: '/home/categories',
    permission: 'listCategory'
  },
  {
    component: CategorySlidePage,
    exact: true,
    path: '/home/categories/:id'
  },
  {
    component: TopBrandList,
    exact: true,
    icon: 'top.svg',
    name: 'Top Brands',
    path: '/home/brands',
    permission: 'listBrand'
  },
  {
    component: TopBrandPage,
    exact: true,
    path: '/home/brands/:id'
  },
  {
    component: TrendingProductList,
    exact: true,
    icon: 'hot.svg',
    name: 'Trending',
    path: '/home/trendings',
    permission: 'listTrendingProduct'
  },
  {
    component: TrendingProductPage,
    exact: true,
    path: '/home/trendings/:id'
  },
  {
    component: PromotionList,
    exact: true,
    icon: 'hot.svg',
    name: 'Promotion',
    path: '/home/promotion',
    permission: 'listPromotionProduct'
  },
  {
    component: PromotionPage,
    exact: true,
    path: '/home/promotion/:id'
  },
  {
    component: FeaturedStoreList,
    exact: true,
    icon: 'shop.svg',
    name: 'Official Stores',
    path: '/home/stores',
    permission: 'listStore'
  },
  {
    component: FeaturedStorePage,
    exact: true,
    path: '/home/stores/:id'
  },
  {
    component: FeaturedOnList,
    exact: true,
    icon: 'shop.svg',
    name: 'Featured On',
    path: '/home/featured-on',
    // permission: 'listStore'
  },
  {
    component: FeaturedOnPage,
    exact: true,
    path: '/home/featured-on/:id'
  },
  {
    icon: 'top.svg',
    menu: true,
    name: 'Highlights',
    path: '/highlight',
    submenu: [
      {
        name: 'App Intro',
        path: '/highlight/app',
        permission: 'listAppIntro'
      },
      {
        name: 'Pop-up Ad',
        path: '/highlight/ads',
        permission: 'listPopupAd'
      }
    ]
  },
  {
    component: IntroductionList,
    exact: true,
    icon: 'intro.svg',
    name: 'App Intro',
    path: '/highlight/app'
  },
  {
    component: IntroductionPage,
    exact: true,
    path: '/highlight/app/:id'
  },
  {
    component: PopupAdList,
    exact: true,
    icon: 'popup.svg',
    name: 'Pop-up Ads',
    path: '/highlight/ads'
  },
  {
    component: PopupAdPage,
    exact: true,
    path: '/highlight/ads/:id'
  },
  {
    icon: 'intro.svg',
    menu: true,
    name: 'Catalog',
    path: '/catalog',
    submenu: [
      {
        name: 'Categories',
        path: '/catalog/categories',
        permission: 'listCategoryHighlight'
      }
    ]
  },
  {
    component: CategoryList,
    exact: true,
    icon: 'category-banner.svg',
    name: 'Category',
    path: '/catalog/categories',
    permission: 'listCategoryHighlight'
  },
  {
    component: CategoryPage,
    exact: true,
    path: '/catalog/categories/:id'
  },
  {
    icon: 'product.svg',
    menu: true,
    name: 'Landing Pages',
    path: '/pages',
    submenu: [
      {
        name: 'Collections',
        path: '/pages/collection',
        permission: 'listCollection'
      },
      {
        name: 'Custom',
        path: '/pages/custom',
        permission: 'listCustomPage'
      },
      {
        name: 'Content',
        path: '/pages/content-management',
        permission: 'listCustomPage'
      }
    ]
  },
  
  {
    component: CollectionPageList,
    exact: true,
    icon: 'product.svg',
    name: 'Collections',
    path: '/pages/collection',
    permission: 'listCollection'
  },
  {
    component: CollectionPagePage,
    exact: true,
    path: '/pages/collection/:id'
  },
  {
    component: CustomPageList,
    exact: true,
    icon: 'product.svg',
    name: 'Collections',
    path: '/pages/custom',
    permission: 'listCustomPage'
  },
  {
    component: CustomPagePage,
    exact: true,
    path: '/pages/custom/:id'
  },
  {
    component: ContentManagementSystemList,
    exact: true,
    icon: 'product.svg',
    name: 'Content Management',
    path: '/pages/content-management',
    permission: 'listCustomPage'
  },
  {
    component: ContentManagementSystemPage,
    exact: true,
    path: '/pages/content-management/:id'
  },
  {
    component: NotificationList,
    exact: true,
    icon: 'notify.svg',
    menu: true,
    name: 'Notifications',
    path: '/notifications',
    permission: 'listNotification'
  },
  {
    component: NotificationPage,
    exact: true,
    path: '/notifications/:id'
  },
  {
    icon: 'review.svg',
    menu: true,
    name: 'Review',
    path: '/review',
    submenu: [
      {
        name: 'Predefined Title',
        path: '/review/title',
        permission: 'listReviewTitle'
      },
      {
        name: 'Reviews',
        path: '/review/reviews',
        permission: 'listReviews'
      },
      {
        name: 'Comments',
        path: '/review/comment',
        permission: 'listReviews'
      }
    ]
  },
  {
    component: ReviewTitleList,
    exact: true,
    icon: 'review.svg',
    name: 'Review Predefined Title',
    path: '/review/title',
    permission: 'listReviewTitle'
  },
  {
    component: ReviewTitlePage,
    exact: true,
    path: '/review/title/:id'
  },
  {
    component: ReviewList,
    exact: true,
    name: 'Reviews',
    path: '/review/reviews',
    permission: 'listReviews'
  },
  {
    component: ReviewPage,
    exact: true,
    path: '/review/reviews/:id'
  },
  {
    component: ReviewCommentList,
    exact: true,
    name: 'Review Comment',
    path: '/review/comment',
    permission: 'listReviews'
  },
  {
    component: CartPriceRuleList,
    exact: true,
    icon: 'dashboard.svg',
    menu: true,
    name: 'Cart Price Rules',
    path: '/cartPriceRule',
    permission: 'listCartPriceRules'
  },
  {
    component: CartPriceRulePage,
    exact: true,
    path: '/cartPriceRule/:id'
  },
  {
    icon: 'popup.svg',
    menu: true,
    name: 'Top Favourite',
    path: '/favourite',
    submenu: [
      {
        name: 'Vote',
        path: '/favourite/topFavourite',
        permission: 'listCartPriceRules'
      },
      {
        name: 'LamboYES items',
        path: '/favourite/topFavouriteCartPriceRules',
        permission: 'listCartPriceRules'
      }
    ]
  },
  {
    component: TopFavourite,
    exact: true,
    icon: 'dashboard.svg',
    //menu: true,
    name: 'Top Favourite',
    path: '/favourite/topFavourite',
    permission: 'listCartPriceRules'
  },
  {
    component: TopFavouriteProduct,
    exact: true,
    path: '/favourite/topFavourite/:id'
  },
  {
    component: TopFavouriteCartPriceRules,
    exact: true,
    icon: 'dashboard.svg',
    //menu: true,
    name: 'Top Favourite',
    path: '/favourite/topFavouriteCartPriceRules',
    permission: 'listCartPriceRules'
  },
  {
    component: TopFavouriteCartPriceRulesProduct,
    exact: true,
    path: '/favourite/topFavouriteCartPriceRules/:id'
  },
  {
    component: PromotionLabel,
    exact: true,
    icon: 'promotion.svg',
    menu: true,
    name: 'Promotion',
    path: '/promotionLabel',
    permission: 'listPromotionLabel'
  },
  {
    component: PromotionLabelPage,
    exact: true,
    path: '/promotionLabel/:id'
  },
  {
    component: RedemptionList,
    exact: true,
    icon: 'currency-exchange.svg',
    menu: true,
    name: 'Redemptions',
    path: '/redemption',
    permission: 'listRedemptions'
  },
  {
    component: RedemptionPage,
    exact: true,
    path: '/redemption/:id'
  },
  {
    icon: 'setting.svg',
    menu: true,
    name: 'Settings',
    path: '/settings',
    submenu: [
      {
        name: 'Users',
        path: '/settings/admins',
        permission: 'listAdmin'
      },
      {
        name: 'Roles',
        path: '/settings/roles',
        permission: 'listRole'
      },
      {
        name: 'Vendors',
        path: '/settings/vendors',
        permission: 'listVendor'
      },
      {
        name: 'Celebs',
        path: '/settings/celebs',
        permission: 'listCeleb'
      },
      {
        name: 'Products Sold',
        path: '/settings/productsSold',
        permission: 'listProductsSold'
      },
      {
        name: 'Rewards Mission',
        path: '/rewardMission',
        permission: 'listRewardsMission'
      }
    ]
  },
  {
    component: AdminList,
    exact: true,
    icon: 'admin.svg',
    name: 'Users',
    path: '/settings/admins'
  },
  {
    component: AdminPage,
    exact: true,
    path: '/settings/admins/:id'
  },
  {
    component: RoleList,
    exact: true,
    name: 'Roles',
    path: '/settings/roles'
  },
  {
    component: RolePage,
    exact: true,
    path: '/settings/roles/:id'
  },
  {
    component: VendorList,
    exact: true,
    name: 'Vendors',
    path: '/settings/vendors'
  },
  {
    component: VendorPage,
    exact: true,
    path: '/settings/vendors/:id'
  },
  {
    component: CelebList,
    exact: true,
    name: 'Celebs',
    path: '/settings/celebs'
  },
  {
    component: CelebPage,
    exact: true,
    path: '/settings/celebs/:id'
  },
  {
    component: ProductSold,
    exact: true,
    name: 'Vendors',
    path: '/settings/productsSold'
  },
  {
    component: ProductSoldPage,
    exact: true,
    path: '/settings/productsSold/:id'
  },
  {
    component: Rewards,
    exact: true,
    name: 'Rewards',
    path: '/rewardMission'
    
  },
  {
    component: RewardsDetail,
    exact: true,
    path: '/rewardMission/:id'
    
  },
  {
    component: RecommendationPage,
    exact: true,
    path: '/settings/recommendation'
  },
  {
    icon: 'log.svg',
    menu: true,
    name: 'Logs',
    path: '/logs',
    submenu: [
      {
        name: 'Audit',
        path: '/logs/audits',
        permission: 'listAuditLog'
      }
    ]
  },
  {
    component: AuditList,
    exact: true,
    icon: 'log.svg',
    name: 'Audit',
    path: '/logs/audits'
  }
]
