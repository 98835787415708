import request from '../util/request'
import queryString from '../util/queryString'

const url = '/api/stores/'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(url + id)
  },
  getAllVendors () {
    return request(url + 'vendors')
  },
  remove (id, values) {
    return request(url + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  save (id, values) {
    return request(url + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, text) {
    return request(`${url}page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  removePhoto (id, pid) {
    return request(`${url}${id}/photos/${pid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  savePhoto (id, pid, values) {
    return request(`${url}${id}/photos/${pid}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  }
}
