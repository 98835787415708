import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
import NotificationAudiences, { NotificationAudiencesList } from '../../../constants/notification/audiences'
import BannerLinkTypes, { BannerLinkTypesList } from '../../../constants/banner/linkTypes'
import { authService, brandService, notificationService, productService, storeService } from '../../../services'
import { fetchAllCategories, fetchStores, fetchTemplates } from '../../../states/actions'
import { formatter, notification, validator } from '../../../util'

// UI
import { Button, Input, Page, Panel, Select, Spin, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: { organization: {} },
      loading: false,
      loadingImage: false,
      products: { data: [], total: 0 },
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }
    this.fetchProducts = debounce(this.fetchProducts, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.fetchAllBrands()
      this.fetchCollections()
      this.fetchAllCategories()
      this.fetchProducts()
      // this.fetchStores()
      this.fetchAllStores()
      if (this.isEdit()) {
        this.fetchNotification()
      }
    } else {
      authService.unauthorizeAction('/notifications')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 14, md: 18 }
    }
    const selectFormItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { categories, form, history, templates } = this.props
    const { brands, item, loading, loadingImage, products, stores, showBrandList, showCategoryList, showPageInput, showProductList, showStoreList, showTemplateList } = this.state
    const { getFieldDecorator } = form
    const { data: productList } = products
    const { photo = {} } = item
    const photoUploaded = !!photo.thumbUrl

    return (
      <Page.Content>
        <Page.Header title='Notification' description='Set title and content for notification (Picture is optional)'>

          {this.isEdit() && this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSend()}>Send Notification</Button>
          ) : null}

          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Notification Details' subtitle={this.isEdit() && item.sentAt ? `Last Sent At: ${formatter.toStandardDate(item.sentAt)}` : 'Pending To Send'}>
                <Row>
                  <Col lg={10}>
                    <div className='notify-image-uploader'>
                      <UploadHolder
                        img={{
                          alt: 'Notification Image',
                          src: item.image ? item.image : ''
                        }}
                        loading={loadingImage}
                        height={180}
                        width={340}
                        readOnly={!this.canSave()}
                        upload={{
                          action: '/api/notifications/upload/image',
                          beforeUpload: (file) => this.checkImage(file),
                          data: { _id: item._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: (info) => this.handleUploadImage(info),
                          showUploadList: false
                        }}
                      />
                    </div>
                    <div className='notify-image-hint'>
                      Size: 720px x 400px (JPG or PNG) <br />
                      Maximum file size: 2 MB <br />
                      ** Picture notification only works in Android **
                    </div>
                    {this.isEdit() ? null : photoUploaded ? (
                      <div style={{ marginTop: 18, textAlign: 'center' }}>
                        <Button onClick={() => this.handleDeleteImage()} style={{ margin: 0 }}>Delete Photo</Button>
                      </div>
                    ) : null}
                  </Col>

                  <Col lg={14}>
                    <Select
                      fieldDecorator={{
                        id: 'audience',
                        options: {
                          initialValue: item.audience || NotificationAudiences.ALL
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        hasFeedback: false,
                        label: (
                          <span>
                                Audience&nbsp;
                            <Tooltip title='Send to everyone or particular segment'>
                              <Icon type='question-circle-o' />
                            </Tooltip>
                          </span>
                        )
                      }}
                      readOnly={!this.canSave()}
                      list={NotificationAudiencesList}
                      listFunc={({ name, value }) => (
                        <Option key={value} value={value}>{name}</Option>
                      )}
                    />
                    <Input
                      fieldDecorator={{
                        id: 'title',
                        options: {
                          initialValue: item.title,
                          rules: [
                            { min: 2, message: 'Title must be between 2 and 60 characters' },
                            { max: 60, message: 'Title must be between 2 and 60 characters' },
                            { required: true, message: 'Please enter title' },
                            { whitespace: true, message: 'Please enter title' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Title'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'content',
                        options: {
                          initialValue: item.content,
                          rules: [
                            { min: 2, message: 'Content must be between 2 and 256 characters' },
                            { max: 256, message: 'Content must be between 2 and 256 characters' },
                            { required: true, message: 'Please enter content' },
                            { whitespace: true, message: 'Please enter content' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Content'
                      }}
                      input={{
                        autosize: { minRows: 5, maxRows: 10 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    <FormItem
                      {...formItemLayout}
                      label={(
                        <span>
                          Link&nbsp;
                          <Tooltip title='Link button to a product or external custom page'>
                            <Icon type='question-circle-o' />
                          </Tooltip>
                        </span>
                      )}
                    >
                      <Row gutter={8}>
                        <Col md={8}>
                          <Select
                            fieldDecorator={{
                              id: 'link_type',
                              options: {
                                initialValue: item.link_type || BannerLinkTypes.NONE
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={BannerLinkTypesList}
                            listFunc={({ name, value }) => (
                              <Option key={value} value={value}>{name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              onChange: this.changeLinkType,
                              style: { width: '100%' }
                            }}
                          />
                        </Col>

                        <Col md={16}>
                          {showBrandList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a brand' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={brands}
                              listFunc={(brand) => (
                                <Option className='text-wrap' key={brand.option_id} value={brand.option_id.toString()}>{brand.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a brand',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showTemplateList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a collection' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={templates.list}
                              listFunc={(template) => (
                                <Option className='text-wrap' key={template._id} value={template._id.toString()}>{template.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a collection',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showCategoryList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a category' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={categories}
                              listFunc={(category) => (
                                <Option className='text-wrap' key={category.id} value={category.id.toString()}>{category.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a category',
                                showSearch: true,
                                style: { width: '100%' },
                                dropdownMatchSelectWidth: false,
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showStoreList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a store' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={stores}
                              listFunc={(store) => {
                                return <Option className='text-wrap' key={store.id} value={store.id}>{(store.name !== null ? store.name : '') + ' (' + store.vendor_id + ')' }</Option>
                              }}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a store',
                                showSearch: true,
                                style: { width: '100%' },
                                dropdownMatchSelectWidth: false,
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showProductList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value,
                                  rules: [
                                    { required: true, message: 'Please select a product' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              list={productList}
                              listFunc={(product) => {
                                const { name, sku } = product

                                return (
                                  <Option key={sku} value={sku}>
                                    <div className='text-wrap'>{name}</div>

                                    <div className='wd-trending-option-subtitle'>SKU: {sku}</div>
                                  </Option>
                                )
                              }}
                              readOnly={!this.canSave()}
                              select={{
                                filterOption: false,
                                onSearch: this.fetchProducts,
                                placeholder: 'Select a product',
                                showSearch: true,
                                dropdownMatchSelectWidth: false,
                                style: { width: '100%' }
                              }}
                            />
                          ) : null}

                          {showPageInput ? (
                            <Input
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value,
                                  rules: [
                                    { required: true, message: 'Please enter custom page URL' },
                                    { whitespace: true, message: 'Please enter custom page URL' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItemLayout,
                                hasFeedback: false
                              }}
                              input={{
                                placeholder: 'Enter custom page URL',
                                tabIndex: 3
                              }}
                              readOnly={!this.canSave()}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </FormItem>

                    <FormItem
                      {...formItemLayout}
                      label={(
                        <span>
                          Send At&nbsp;
                          <Tooltip title='Set a time to send this notification'>
                            <Icon type='question-circle-o' />
                          </Tooltip>
                        </span>
                      )}
                    >
                      {!this.canSave() ? (
                        <span className='ant-form-text'>{item.sendAt}</span>
                      ) : (
                        getFieldDecorator('sendAt', {
                          initialValue: item.sendAt ? moment(item.sendAt) : undefined
                        })(
                          <DatePicker
                            allowClear
                            disabledDate={(currentDate) => {
                              return moment().startOf('day').isAfter(currentDate)
                            }}
                            format='YYYY-MM-DD HH:mm'
                            placeholder='Select Date & Time'
                            showTime={{
                              format: 'HH:mm'
                            }}
                            style={{ width: '100%' }}
                          />
                        )
                      )}
                    </FormItem>

                    <Input
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Remark cannot contain only spaces' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        tabIndex: 3
                      }}
                      readOnly={!this.canSave()}
                    />
                  </Col>
                </Row>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  changeLinkType = (value) => {
    const { form } = this.props
    const { item } = this.state
    const { resetFields } = form
    const showList = {
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }

    if (value === BannerLinkTypes.BRAND) {
      showList.showBrandList = true
    } else if (value === BannerLinkTypes.CATEGORY) {
      showList.showCategoryList = true
    } else if (value === BannerLinkTypes.COLLECTION) {
      showList.showTemplateList = true
    } else if (value === BannerLinkTypes.STORE) {
      showList.showStoreList = true
    } else if (value === BannerLinkTypes.PRODUCT) {
      showList.showProductList = true
    } else if (value === BannerLinkTypes.CUSTOM_PAGE) {
      showList.showPageInput = true
    }

    resetFields(['link_value'])
    item.link_type = value
    item.link_value = undefined
    this.setState({ item, ...showList })
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  fetchAllBrands = async () => {
    try {
      const item = await brandService.getAllBrands()
      this.setState({ brands: item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
    }
  }

  fetchAllCategories = async () => {
    try {
      this.props.fetchAllCategories()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  fetchCollections = async () => {
    try {
      this.props.fetchTemplates(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load collections successfully. Please try again later.')
    }
  }

  fetchProducts = async (text) => {
    try {
      const products = await productService.listProductsByPage(1, 100, {}, text)
      this.setState({ products })
    } catch (e) {
      console.error(e)
    }
  }

  fetchStores = async () => {
    try {
      this.props.fetchStores(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load stores successfully. Please try again later.')
    }
  }

  async fetchAllStores () {
    try {
      this.setState({ loading: true })
      const item = await storeService.getAllVendors()
      this.setState({ stores: item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load store successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async fetchNotification () {
    try {
      this.setState({ loading: true })
      const item = await notificationService.getNotification(this.props.match.params.id)
      const { link_type: linkType } = item
      this.setState({
        item,
        loading: false,
        showBrandList: linkType === BannerLinkTypes.BRAND,
        showCategoryList: linkType === BannerLinkTypes.CATEGORY,
        showPageInput: linkType === BannerLinkTypes.CUSTOM_PAGE,
        showProductList: linkType === BannerLinkTypes.PRODUCT,
        showStoreList: linkType === BannerLinkTypes.STORE,
        showTemplateList: linkType === BannerLinkTypes.COLLECTION
      })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load notification successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this notification?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await notificationService.removeNotification(match.params.id)
          if (response) {
            notification.show('success', 'Deleted successfully', 'Notification deleted successfully.')
            history.replace('/notifications')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete notification successfully. Please try again later.')
        }
      }
    })
  }

  handleSend () {
    const { match } = this.props

    confirm({
      title: 'Are you sure you want to send this notification to everyone?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await notificationService.sendNotification(match.params.id)

          if (response._id) {
            notification.show('success', 'Sent successfully', 'Notification sent successfully.')
          }
        } catch (e) {
          notification.show('error', 'Unable to send successfully', 'Unable to send notification successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        values.sendAt = values.sendAt ? formatter.toISOString(moment.utc(values.sendAt).second(0).millisecond(0)) : undefined
        values.remark = values.remark || ''
        this.setState({ loading: true })

        try {
          values.image = item.image ? (item.image.url || item.image) : ''
          values.image_thumb = item.image ? (item.image.thumbUrl || item.image_thumb) : ''

          if (this.isEdit()) {
            const response = await notificationService.saveNotification(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Notification saved successfully.')
            }
          } else {
            const response = await notificationService.addNotification(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Notification saved successfully.')
              history.replace(`/notifications/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save notification successfully. Please try again later.')
        }
      }
    })
  }

  handleDeleteImage () {
    const that = this
    const { item } = this.state

    confirm({
      title: 'Are you sure you want to delete this image?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          notification.show('success', 'Deleted successfully', 'Photo deleted successfully.')
          item.photo = {}
          that.setState({ item })
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete photo successfully. Please try again later.')
        }
      }
    })
  }

  handleUploadImage (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      console.log('done', info.file)
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteNotification')
  }

  canRead () {
    return this.hasAccess('readNotification')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateNotification' : 'createNotification')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchStores,
  fetchTemplates
}

const mapStateToProps = (state) => {
  return { ...state.Notifications,
    ...state.Categories,
    ...state.Store,
    ...state.Template
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Notifications))
