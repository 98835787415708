import { all, fork } from 'redux-saga/effects'

import watchAd from './ad'
import watchAudit from './audit'
import watchBanner from './banner'
import watchBrand from './brand'
import watchButtonLayout from './button/layout'
import watchCategory from './category'
import watchCarousel from './carousel'
import watchCollection from './collection'
import watchFeaturedOn from './featuredOn'
import watchIntro from './intro'
import watchNotification from './notification'
import watchReview from './review'
import watchStore from './store'
import watchSummary from './dashboard'
import watchTemplate from './template'
import watchTile from './tile'
import watchTrending from './trending'
import watchCustomPage from './custom'
import watchPromotion from './promotion'
import watchMiniBanner from './miniBanner'
import watchCartPriceRule from './cartPriceRule'
import watchTopFavourite from './topFavourite'
import watchTopFavouriteCartPriceRule from './topFavouriteCartPriceRule'
import watchCustomManagementSystem from './customManagementSystem'
import watchRedemption from './redemption'
import watchRewardMission from './rewardMission'

export default function * root () {
  yield all([
    fork(watchAd),
    fork(watchAudit),
    fork(watchBanner),
    fork(watchBrand),
    fork(watchButtonLayout),
    fork(watchCategory),
    fork(watchCarousel),
    fork(watchCollection),
    fork(watchCustomPage),
    fork(watchFeaturedOn),
    fork(watchIntro),
    fork(watchNotification),
    fork(watchReview),
    fork(watchStore),
    fork(watchSummary),
    fork(watchTemplate),
    fork(watchTile),
    fork(watchTrending),
    fork(watchPromotion),
    fork(watchMiniBanner),
    fork(watchCartPriceRule),
    fork(watchTopFavourite),
    fork(watchTopFavouriteCartPriceRule),
    fork(watchCustomManagementSystem),
    fork(watchRedemption),
    fork(watchRewardMission)
  ])
}
