import { PROMOTION_GROUPS_LOADED, PROMOTIONS_LOADED, PROMOTION_LABEL_GROUPS_LOADED, PROMOTIONS_LABEL_LOADED } from '../actions/promotion'

const INITIAL_STATE = {
  promotionGroups: { list: [], total: 0 },
  promotions: { list: [], total: 0 },
  promotionLabelGroups: { list: [], total: 0 },
  promotionsLabel: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROMOTION_GROUPS_LOADED:
      return { ...state, promotionGroups: { list: action.promotions.list, total: action.promotions.total } }
    case PROMOTIONS_LOADED:
      return { ...state, promotions: { list: action.promotions.list, total: action.promotions.total } }
    case PROMOTION_LABEL_GROUPS_LOADED:
      return { ...state, promotionLabelGroups: { list: action.promotions.list, total: action.promotions.total } }
    case PROMOTIONS_LABEL_LOADED:
      return { ...state, promotionsLabel: { list: action.promotions.list, total: action.promotions.total } }
    default:
      return state
  }
}
