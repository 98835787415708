import { BRAND_LOADED } from '../actions/brand'

const INITIAL_STATE = {
  brands: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BRAND_LOADED:
      return { ...state, brands: { list: action.brands.data, total: action.brands.total } }
    default:
      return state
  }
}
