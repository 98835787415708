import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Submenu from './Submenu'
import authService from '../../services/auth'

import './styles.css'

export default class Menu extends Component {
  render () {
    const { onChange, routes } = this.props

    return (
      <div className='Menu-list'>
        {routes
          .filter((r) => (r.menu))
          .map((route, idx) => {
            let { component, icon, name, path, submenu } = route

            return (
              <div key={idx}>
                {this.showMenu(route) ? (
                  submenu ? (
                    <Submenu key={idx} route={route} onChange={onChange} />
                  ) : (
                    component ? (
                      <NavLink key={idx} to={path} className='Menu-item' activeClassName='Menu-item-selected' exact={path === '/'} onClick={onChange}>
                        <img src={process.env.PUBLIC_URL + '/icon/' + icon} className='Menu-item-icon' alt={name} />
                        <div className='Menu-item-label'>{name} </div>
                        <span className='Menu-tooltip'>{name}</span>
                      </NavLink>
                    ) : (
                      <div className='Menu-header'><div className='Menu-item-title'>{name}</div></div>
                    )
                  )
                ) : null}
              </div>
            )
          })
        }
        <div className='Menu-list-bottom' />
      </div>
    )
  }

  showMenu (route) {
    const { permission } = route

    if (permission) {
      return authService.hasAccess(permission)
    }

    return true
  }
}

Menu.propTypes = {
  routes: PropTypes.array.isRequired
}
