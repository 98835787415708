import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import LabelTitles, { LabelTitleList, ReviewColors } from '../../../../constants/review/labelTitle'
import { authService, reviewTitleService } from '../../../../services'
import { formatter, notification, validator } from '../../../../util'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch } from '../../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

class ReviewTitlePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      showTagColor: false,
      tagColor: ''
    }
    this.onChangeTitle = this.onChangeTitle.bind(this)
    this.checkKeyExists = debounce(this.checkKeyExists, 500)
  }

  componentDidMount () {
    if (this.canRead()) {

      if (this.isEdit()) {
        this.fetchReviewTitle()
      }
    } else {
      authService.unauthorizeAction('/review/title')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 14, md: 18 }
    }
    const formShortItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 8, md: 8 }
    }
    const selectFormItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { form, history } = this.props
    const { item, loading, showTagColor, tagColor } = this.state
    const { getFieldValue } = form

    return (
      <Page.Content>
        <Page.Header title='Predefined Title' description='Set a label, key, type for predefined title.'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={this.handleDelete}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={this.isEdit() ? this.handleSave : this.confirmSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Title Details'>
                <div className='review-panel'>
                  <div className='review-panel-bottom'>
                    <Input
                      fieldDecorator={{
                        id: 'label',
                        options: {
                          initialValue: item.label,
                          rules: [
                            { min: 2, message: 'Label must be between 2 and 32 characters' },
                            { max: 32, message: 'Label must be between 2 and 32 characters' },
                            { required: true, message: 'Please enter label' },
                            { whitespace: true, message: 'Please enter label' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Label'
                      }}
                      input={{
                        tabIndex: 1,
                        onChange: this.onChangeTitle
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'label_key',
                        options: {
                          initialValue: item.label_key,
                          rules: [
                            { min: 2, message: 'Key must be between 2 and 32 characters' },
                            { max: 32, message: 'Key must be between 2 and 32 characters' },
                            { required: true, message: 'Please enter key' },
                            { whitespace: true, message: 'Please enter key' },
                            { validator: this.checkKeyExists }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: (
                          <span>
                            Key&nbsp;
                            <Tooltip
                              title={`Key is an unique key identifier meant for internal use only. Key value couldn't be change once saved and will not show at front end application.`}
                              overlayClassName='review-tooltip'
                            >
                              <Icon type='info-circle-o' />
                            </Tooltip>
                          </span>
                        )
                      }}
                      input={{
                        tabIndex: 2,
                        disabled: true
                      }}
                      readOnly={this.isEdit()}
                    />

                    <FormItem
                      {...formItemLayout}
                      label={(
                        <span>
                          Type
                        </span>
                      )}
                    >
                      <Row gutter={8}>
                        <Col md={8}>
                          <Select
                            fieldDecorator={{
                              id: 'label_type',
                              options: {
                                initialValue: item.label_type,
                                rules: [
                                  { required: true, message: 'Please select type' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItemLayout,
                              hasFeedback: false
                            }}
                            list={LabelTitleList}
                            listFunc={({ name, value }) => (
                              <Option key={value} value={value}>{name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              placeholder: 'Select Type',
                              onChange: this.changeLabelType,
                              style: { width: '100%' }
                            }}
                          />
                        </Col>

                      </Row>
                    </FormItem>

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Active'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 5
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Predefined title is visible when set to 'Yes'`}
                    />
                  </div>
                </div>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  changeLabelType = (value) => {
    let tagColor = ''

    if (value === LabelTitles.Moderate) {
      tagColor = ReviewColors.YELLOW
    } else if (value === LabelTitles.Good) {
      tagColor = ReviewColors.GREEN
    } else if (value === LabelTitles.Bad) {
      tagColor = ReviewColors.RED
    }

    this.setState({ showTagColor: true, tagColor })
  }

  checkKeyExists = (rule, value, callback) => {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const response = await reviewTitleService.checkKey(value)

        if (response.exists !== false) {
          callback(new Error('Key already exists'))
        }
      }

      callback()
    })
  }

  onChangeTitle = (e) => {
    if (!this.isEdit()) {
      const { form } = this.props
      const { setFieldsValue } = form
      let value = e.target.value
      value = value.toLowerCase().trim().replace(/[^A-Z0-9]+/ig, '')
      setFieldsValue({ label_key: value })
    } else return
  }

  fetchReviewTitle = async () => {
    try {
      this.setState({ loading: true })
      const item = await reviewTitleService.get(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load title successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete = async () => {
    const { history, match } = this.props

    confirm({
      title: 'Confirm to delete this title?',
      content: 'Press OK to continue, Cancel to return',
      async onOk () {
        try {
          const response = await reviewTitleService.remove(match.params.id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Title deleted successfully.')
            history.replace('/review/title')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete title successfully. Please try again later.')
        }
      }
    })
  }

  confirmSave = () => {
    const { form, history } = this.props
    const { validateFields } = form
    const that = this
    validateFields(async (errors, values) => {
      if (!errors) {
        confirm({
          title: 'Key value are permanently set once saved. It will not change when editing.',
          content: 'Press OK to continue, Cancel to return',
          iconType: 'exclamation-circle',
          onOk () {
            that.handleSave()
          }
        })
      }
    })
  }

  handleSave = () => {
    const { form, history } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })

        try {
          if (values.label_type === LabelTitles.Moderate) {
            values.tag_color = ReviewColors.YELLOW
          } else if (values.label_type === LabelTitles.Good) {
            values.tag_color = ReviewColors.GREEN
          } else if (values.label_type === LabelTitles.Bad) {
            values.tag_color = ReviewColors.RED
          }
          values.active = values.active ? 1 : 0

          if (this.isEdit()) {
            const response = await reviewTitleService.save(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Title saved successfully.')
            }
          } else {
            const response = await reviewTitleService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Title saved successfully.')
              history.replace(`/review/title/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save title successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canDelete = () => {
    return this.hasAccess('deleteReviewTitle')
  }

  canRead = () => {
    return this.hasAccess('readReviewTitle')
  }

  canSave = () => {
    return this.hasAccess(this.isEdit() ? 'updateReviewTitle' : 'createReviewTitle')
  }

  canUpload = () => {
    return this.canSave()
  }

  isEdit = () => {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return {
    ...state.Review
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReviewTitlePage))
