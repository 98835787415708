import { WEBTILE_LOADED } from '../actions/tile'

const INITIAL_STATE = {
  tiles: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WEBTILE_LOADED:
      return { ...state, tiles: action.tiles }
    default:
      return state
  }
}
