import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class SmallCard extends Component {
  render () {
    const { className, color, icon, label, total, value } = this.props

    return (
      <div className={formatter.toClassName(['wd-small-card', className])}>
        <div className='wd-card-title' style={{background: color}}>
          {icon ? <img src={process.env.PUBLIC_URL + '/icon/' + icon} className='wd-card-icon' alt='Icon' /> : null}

          <div>
            <div className='wd-card-value'>{ value } { total ? <span className='wd-card-total'>/ { total || 0}</span> : null }</div>

            <div className='wd-card-label'>{ label }</div>
          </div>
        </div>
      </div>
    )
  }
}

SmallCard.propTypes = {
  className: PropTypes.string
}
