import { COLLECTION_LOADED } from '../actions/collection'

const INITIAL_STATE = {
  collections: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COLLECTION_LOADED:
      return { ...state, collections: { list: action.collection.data, total: action.collection.total } }
    default:
      return state
  }
}
