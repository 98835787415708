import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { authService, brandService } from '../../../services'
import { formatter, notification, validator } from '../../../util'

// UI
import { Button, DatePicker, Input, Page, Panel, Select, Spin, Switch, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm
const Option = Select.Option

class FeaturedBrand extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brands: [],
      item: {},
      loading: false,
      loadingImage: false
    }
  }

  componentDidMount () {
    if (this.canRead()) {
      this.fetchAllBrands()

      if (this.isEdit()) {
        this.fetchBrand()
      }
    } else {
      authService.unauthorizeAction('/brand')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 15, md: 15 }
    }
    const formShortItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 8, md: 8 }
    }
    const { form, history } = this.props
    const { brands, item, loading, loadingImage } = this.state
    const { getFieldValue } = form
    const from = formatter.toShortDate(getFieldValue('show_from'))
    const to = formatter.toShortDate(getFieldValue('show_to'))

    return (
      <Page.Content>
        <Page.Header title='Top Brand' description='Upload a brand logo and link it to Lamboplace brand'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Brand Details'>
                <Row>
                  <Col lg={7} className='brand-image'>
                    <div className='brand-image-uploader'>
                      <UploadHolder
                        img={{
                          alt: 'Brand',
                          src: item.image ? item.image : ''
                        }}
                        loading={loadingImage}
                        readOnly={!this.canSave()}
                        height={240}
                        width={240}
                        upload={{
                          action: '/api/brands/upload/image',
                          beforeUpload: (file) => this.checkImage(file),
                          data: { _id: item._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: (info) => this.handleUploadImage(info),
                          showUploadList: false
                        }}
                      />
                    </div>

                    <div className='brand-image-hint'>
                      Size: 280px x 170px (JPG or PNG) <br />
                      Maximum file size: 2 MB
                    </div>
                  </Col>

                  <Col lg={17}>
                    <Select
                      fieldDecorator={{
                        id: 'brand_id',
                        options: {
                          initialValue: item.brand_id,
                          rules: [
                            { required: true, message: 'Please select a brand' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        hasFeedback: false,
                        label: 'Brand'
                      }}
                      readOnly={!this.canSave()}
                      list={brands}
                      listFunc={(brand) => {
                        return <Option key={brand.option_id} value={brand.option_id}>{(brand.name !== null ? brand.name : '')}</Option>
                      }}
                      select={{
                        placeholder: 'Select a brand',
                        showSearch: true,
                        style: { width: '100%' },
                        filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'brand_name',
                        options: {
                          initialValue: item.brand_name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 64 characters' },
                            { max: 128, message: 'Name must be between 2 and 64 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Please enter remark' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        autosize: { minRows: 2, maxRows: 5 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    <DatePicker
                      datePicker={{
                        allowClear: false,
                        placeholder: 'Select a date',
                        style: { width: '100%' }
                      }}
                      fieldDecorator={{
                        id: 'show_from',
                        options: {
                          initialValue: item.show_from ? moment(item.show_from) : moment().startOf('day'),
                          rules: [
                            { required: true, message: 'Please select a date' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formShortItemLayout,
                        label: 'From'
                      }}
                      readOnly={!this.canSave()}
                    />

                    <DatePicker
                      datePicker={{
                        allowClear: false,
                        placeholder: 'Select a date',
                        style: { width: '100%' }
                      }}
                      fieldDecorator={{
                        id: 'show_to',
                        options: {
                          initialValue: item.show_to ? moment(item.show_to) : moment().add(1, 'day').endOf('day'),
                          rules: [
                            { required: true, message: 'Please select a date' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formShortItemLayout,
                        label: 'To'
                      }}
                      readOnly={!this.canSave()}
                    />

                    {this.getPositionSelect(formShortItemLayout, this.isEdit(), item.position)}

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Visible'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 3
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Brand is visible from ${from} to ${to} when set to 'Yes'`}
                    />
                  </Col>
                </Row>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkDecimal (rule, value, callback) {
    if (value && value.length > 0 && !validator.isDecimal(value)) {
      callback('Can contain only numbers')
    }

    callback()
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  async fetchAllBrands () {
    try {
      this.setState({ loading: true })
      const item = await brandService.getAllBrands()
      this.setState({ brands: item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async fetchBrand () {
    try {
      this.setState({ loading: true })
      const item = await brandService.get(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brand successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  getPositionSelect (formShortItemLayout, isEdit, position) {
    const { form, brands } = this.props
    const { total } = brands
    const defaultPosition = isEdit ? position : total + 1
    const list = []

    for (let i = 1; i <= total + 1; i++) {
      list.push(i)
    }

    return (
      <Select
        fieldDecorator={{
          id: 'position',
          options: {
            initialValue: defaultPosition
          }
        }}
        form={form}
        formItem={{
          ...formShortItemLayout,
          hasFeedback: false,
          label: 'Position'
        }}
        list={list}
        listFunc={(position) => {
          return <Option key={position} value={position}>{position}</Option>
        }}
        select={{
          showSearch: true,
          placeholder: 'Select a position'
        }}
      />
    )
  }

  handleDelete () {
    const { history, match, brands } = this.props
    const { list, total } = brands

    confirm({
      title: 'Confirm to delete this brand?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          let id = match.params.id
          let updateList = []
          let oldPosition = 0

          for (let i = 0; i < total; i++) {
            const { _id, position } = list[i]
            const newItem = { id: _id }

            if (id === _id.toString()) {
              oldPosition = position
            } else {
              if (oldPosition > 0 && position > oldPosition) {
                newItem.position = i
              } else {
                newItem.position = i + 1
              }

              updateList.push(newItem)
            }
          }

          const response = await brandService.remove(match.params.id, { list: updateList })

          if (response) {
            notification.show('success', 'Deleted successfully', 'Product deleted successfully.')
            history.replace('/home/brands')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete product successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    // const { history } = this.props
    // const { validateFields } = this.props.form
    const { history, brands } = this.props
    const { validateFields } = this.props.form
    const { list, total } = brands

    /* if (total > 29) {
      notification.show('error', 'Unable to save successfully', 'Maximum of 30 top brands.')
      return
    } */

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        values.orgId = item.orgId
        values.remark = values.remark || ''
        values.show_from = formatter.toISOString(moment.utc(values.show_from).startOf('day'))
        values.show_to = formatter.toISOString(moment.utc(values.show_to).endOf('day'))
        this.setState({ loading: true })

        try {
          values.image = item.image ? (item.image.url || item.image) : ''
          values.image_thumb = item.image ? (item.image.thumbUrl || item.image_thumb) : ''
          values.active = values.active ? 1 : 0

          if (this.isEdit()) {
            let id = item._id
            let oldPosition = item.position || 0
            const { position: newPosition } = values
            const positionChanged = newPosition !== oldPosition
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                const newItem = { id: _id }

                if (_id === id) {
                  newItem.position = newPosition
                } else {
                  if (newPosition < oldPosition) {
                    if (itemPosition < newPosition || itemPosition > oldPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx + 2
                    }
                  } else {
                    if (itemPosition < oldPosition || itemPosition > newPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx
                    }
                  }
                }

                return newItem
              })
            }

            const response = await brandService.save(item._id, values)
            console.log('ok', { ...values })
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Brand saved successfully.')
            }
          } else {
            const { position: newPosition } = values
            const positionChanged = newPosition < total + 1
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                return { id: _id, position: itemPosition < newPosition ? idx + 1 : idx + 2 }
              })
            }

            const response = await brandService.add(values)
            console.log('ok', { ...values })
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Brand saved successfully.')
              history.replace(`${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save brand successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      console.log('done', info.file)
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteBrand')
  }

  canRead () {
    return this.hasAccess('readBrand')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateBrand' : 'createBrand')
  }

  canUpload () {
    return this.canSave()
  }

  canDeletePhoto () {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto () {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Brand }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeaturedBrand))
