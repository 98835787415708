import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TOPFAVOURITE, TOPFAVOURITE_LOADED, TOPFAVOURITE_FAILED, TOPFAVOURITE_GROUPS_LOADED, FETCH_TOPFAVOURITE_GROUP } from '../actions/topFavourite'
import topFavouriteService from '../../services/topFavourite'

// Generators
function * fetchTopFavourite (params) {
  try {
    const topFavourite = yield call(() => topFavouriteService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TOPFAVOURITE_LOADED, topFavourite })
  } catch (e) {
    yield put({ type: TOPFAVOURITE_FAILED, message: e.message })
  }
}

function * fetchTopFavouriteGroups (params) {
  try {
    const topFavourite = yield call(() => topFavouriteService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TOPFAVOURITE_GROUPS_LOADED, topFavourite })
  } catch (e) {
    yield put({ type: TOPFAVOURITE_FAILED, message: e.message })
  }
}

function * watchTopFavourite () {
  yield takeEvery(FETCH_TOPFAVOURITE, fetchTopFavourite)
  yield takeEvery(FETCH_TOPFAVOURITE_GROUP, fetchTopFavouriteGroups)
}

export default watchTopFavourite
