import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { adminService, authService, roleService } from '../../../services'
import { adminsFetched } from '../../../states/actions/admin'
import { notification, sessionStorage } from '../../../util'

// UI
import { Active, Button, List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'admin.filter'
const pageKey = 'admin.page'
const pageSize = 20
const Option = Select.Option

class Admin extends Component {
  constructor (props) {
    super(props)
    const { admins: { data: list, total } } = props
    const currentPage = sessionStorage.getItem(pageKey)
    this.state = {
      currentPage: currentPage ? parseInt(currentPage, 10) : 1,
      filter: sessionStorage.getObject(filterKey),
      list,
      loading: false,
      roles: [],
      searchText: '',
      total
    }
    this.findAdmins = debounce(this.findAdmins, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { admins: { data: list, total } } = nextProps
    return { ...prevState, list, total }
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter } = this.state
      this.fetchAdmins({ currentPage, filter })
      this.findRoles()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {

    const { currentPage, filter, list, loading, roles, total } = this.state
    const { active, role } = filter
    const columns = [
      {
        title: '',
        width: 1,
        render: (row) => <ListIcon file='admin.svg' name={row.name} />
      },
      {
        key: 'name',
        title: 'Name',
        width: 7
      },
      {
        key: 'email',
        title: 'E-mail',
        width: 7
      },
      {
        key: 'roleName',
        title: 'Role',
        width: 7
      },
      {
        key: 'active',
        title: 'Active',
        width: 2,
        render: (row) => <Active active={row.active === 1} />
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Users (${total})`} description='Manage console users' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Users' onChange={(value) => this.findAdmins(value)} tabIndex={1} />
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={8}>
                    <Select
                      allowClear
                      defaultValue={Array.isArray(role) ? undefined : role}
                      disabled={loading}
                      onChange={(value) => this.changeRole(value)}
                      placeholder='Role'
                      style={{ width: '100%' }}
                    >
                      {roles.map(({ _id, name }) => (
                        <Option key={_id} value={_id}>{name}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col lg={8}>
                    <Tooltip title='Active'>
                      <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        onChange={(value) => this.changeActive(value)}
                        placeholder='Active'
                        style={{ width: '100%' }}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/settings/admins/add'>
                    <Button>Add Admin</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} loading={loading} rows={list} link={this.canRead() ? '/settings/admins/<_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} users`}
            current={currentPage}
            onChange={(currentPage) => this.changePage(currentPage)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeActive (value) {
    const { filter, searchText } = this.state

    if (value === 'Yes') {
      filter.active = true
    } else if (value === 'No') {
      filter.active = false
    } else {
      delete filter.active
    }

    this.fetchAdmins({ loading: true, currentPage: 1, filter, searchText })
  }

  changePage (currentPage) {
    const { filter, searchText } = this.state
    this.fetchAdmins({ loading: true, currentPage, filter, searchText })
  }

  changeRole (value) {
    const { filter, searchText } = this.state

    if (value) {
      filter.role = value
    } else {
      delete filter.role
    }

    this.fetchAdmins({ loading: true, currentPage: 1, filter, searchText })
  }

  async fetchAdmins ({ loading = false, currentPage = 1, filter = {}, searchText }) {
    try {
      const { adminsFetched, pristine } = this.props
      sessionStorage.setObject(filterKey, filter)
      sessionStorage.setItem(pageKey, currentPage)
      this.setState({ currentPage, loading: loading || pristine })
      const response = await adminService.listAdminsByPage(currentPage, pageSize, filter, searchText)
      this.setState({ list: response.data, loading: false, total: response.total })
      adminsFetched(response)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load users successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findAdmins (text) {
    const { currentPage, filter, searchText } = this.state
    this.fetchAdmins({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, searchText: text })
    this.setState({ searchText: text })
  }

  async findRoles (value) {
    try {
      roleService
        .findByName(100, value || '')
        .then((roles) => {
          this.setState({ roles })
        })
    } catch (e) {
      console.error(e)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createAdmin')
  }

  canList () {
    return this.hasAccess('listAdmin')
  }

  canRead () {
    return this.hasAccess('readAdmin')
  }
}

const mapDispatchToProps = {
  adminsFetched
}

const mapStateToProps = (state) => {
  return { ...state.Admin }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)
