import { TOPFAVOURITE_GROUPS_LOADED, TOPFAVOURITE_LOADED } from '../actions/topFavourite'

const INITIAL_STATE = {
  topFavouriteGroups: { list: [], total: 0 },
  topFavourite: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOPFAVOURITE_GROUPS_LOADED:
      return { ...state, topFavouriteGroups: { list: action.topFavourite.list, total: action.topFavourite.total } }
    case TOPFAVOURITE_LOADED:
      return { ...state, topFavourite: { list: action.topFavourite.list, total: action.topFavourite.total } }
    default:
      return state
  }
}
