export const FETCH_BRAND = 'FETCH_BRAND'
export const BRAND_LOADED = 'BRAND_LOADED'
export const BRAND_FAILED = 'BRAND_FAILED'

export const fetchBrands = (page, size, filter, query) => {
  return {
    type: FETCH_BRAND,
    page,
    size,
    filter,
    query
  }
}
