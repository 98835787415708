import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import authService from '../../../../services/auth'
import { fetchReviewsCommentList } from '../../../../states/actions/review'
import { CommentStatus, CommentStatusList } from '../../../../constants/review/reviews'
import { formatter, notification, sessionStorage } from '../../../../util'

// UI
import { Button, Page, Pager, SearchInput, Spin, List } from '../../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'reviewComment.filter'
const pageSize = 20
const Option = Select.Option

class ReviewCommentList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
    this.fetchReviewsComments = debounce(this.fetchReviewsComments, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchReviewsComments()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { reviewComments } = this.props
    const { currentPage, filter, loading } = this.state
    const { status } = filter
    const { list, total } = reviewComments
    const columns = [
      {
        key: 'id',
        title: 'ID',
        width: 1,
      },
      {
        key: 'created_at',
        title: 'Commented On',
        width: 4,
        render: (row) => formatter.toLocalStandardDate(row.created_at)
      },
      {
        key: 'm',
        title: 'Product',
        width: 8,
        render: (row) => <div className='review-product'>{row.product_name}</div>
      },
      {
        key: 'nickname',
        title: 'Nickname',
        width: 3,
        render: (row) => <div>{row.nickname}</div>
      },
      {
        key: 'message',
        title: 'Comment',
        width: 6,
        render: (row) => <div className='review-title'>{row.message}</div>
      },
      {
        key: 'status',
        title: 'Status',
        width: 2,
        render: (row) => (
          row.status === CommentStatus.STATUS_PENDING
            ? <div style={{color: '#fcc35d'}}>Pending</div>
            : row.status === CommentStatus.STATUS_APPROVED
              ? <div style={{color: '#3dc67d'}}>Approved</div>
              : <div style={{color: '#ff5656'}}>Rejected</div>
        )
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Comments (${total})`} description='All comments' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={3}>
                <Tooltip title='Status'>
                  <Select
                    defaultValue={status ? status : 'All'}
                    disabled={loading}
                    onChange={(value) => this.changeStatus(value)}
                    placeholder='Status'
                    style={{ width: '100%' }}
                  >
                    <Option value='All'>All</Option>
                    {CommentStatusList.map(({ name, value }) => (
                      <Option value={value}>{name}</Option>
                    ))}
                  </Select>
                </Tooltip>
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} rows={list} link={this.canRead() ? '/review/reviews/<review_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} comments`}
            current={currentPage}
            onChange={(page) => this.fetchReviewsComments(page)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeStatus (value) {
    const { currentPage, filter } = this.state

    if (value === 'All') {
      delete filter.status
    } else if (value !== undefined) {
      filter.status = value
    } else {
      delete filter.status
    }

    this.setState({ filter })
    this.fetchReviewsComments(currentPage)
  }

  async fetchReviewsComments (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchReviewsCommentList(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load comments successfully. Please try again later.')
    }
  }

  findReviews (text) {
    const { currentPage, searchText } = this.state
    this.fetchReviewsComments(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createReviews')
  }

  canList () {
    return this.hasAccess('listReviews')
  }

  canRead () {
    return this.hasAccess('readReviews')
  }
}

const mapDispatchToProps = {
  fetchReviewsCommentList
}

const mapStateToProps = (state) => {
  return { ...state.Review }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewCommentList)
