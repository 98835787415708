import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_INTRO, INTRO_LOADED, INTRO_FAILED } from '../actions/intro'
import introService from '../../services/intro'

// Generators
function * fetchIntro (params) {
  try {
    const intro = yield call(() => introService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: INTRO_LOADED, intro })
  } catch (e) {
    yield put({ type: INTRO_FAILED, message: e.message })
  }
}

function * watchIntro () {
  yield takeEvery(FETCH_INTRO, fetchIntro)
}

export default watchIntro
