import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, customPageService } from '../../../services'
import { notification, uploader, validator } from '../../../util'

// UI
import { Button, Input, Page, Panel, Spin, Switch } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Upload from 'antd/lib/upload'

import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import 'braft-editor/dist/index.css'

import './styles.css'
import 'react-quill/dist/quill.snow.css'

import { lamboplace } from '../../../config'

const confirm = Modal.confirm
const { Item: FormItem } = Form

class CustomPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      content: '',
      editorState: BraftEditor.createEditorState(null),
      item: {},
      loading: false,
      roles: [],
      roleValidateStatus: undefined
    }
  }

  componentDidMount () {
    if (this.canRead()) {
      if (this.isEdit()) {
        this.fetchPage()
      }
    } else {
      authService.unauthorizeAction('/page/custom')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 7, md: 6 },
      wrapperCol: { sm: 15, md: 16 }
    }
    const formEditorLayout = {
      labelCol: { sm: 7, md: 5 },
      wrapperCol: { sm: 15, md: 18 }
    }
    const { form, history } = this.props
    const { fullUrl, item, loading } = this.state
    const { getFieldDecorator } = form

    const uploadContentProps = uploader.getProps('/api/custom-pages/upload/image', {
      data: { _id: item.id },
      // disabled: loadingImage,
      onError: this.handleUploadImageError,
      // onStart: this.handleAddImage,
      onSuccess: this.handleUploadContentImage
    })

    const controls = ['undo', 'redo', 'separator',
      'bold', 'italic', 'underline', 'text-color', 'separator',
      'link', 'separator',
      'clear', 'fullscreen']

    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload
            accept='image/*'
            showUploadList={false}
            {...uploadContentProps}
          >
            <button type='button' className='control-item button upload-button' data-title='Inset Image'>
              <Icon type='picture' theme='filled' />
            </button>
          </Upload>
        )
      }
    ]

    return (
      <Page.Content>
        <Page.Header title='Custom Page Details' description='A custom page with seo title'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Page Details' subtitle={fullUrl ? <div style={{ fontSize: '10pt', fontWeight: 'normal' }}><b>Link</b> &nbsp; <span style={{ padding: '2px 15px', background: '#eee', borderRadius: '8px' }}>{fullUrl}</span></div> : null}>
                <Row>
                  <Col lg={20}>
                    <Input
                      fieldDecorator={{
                        id: 'title',
                        options: {
                          initialValue: item.title,
                          rules: [
                            { min: 2, message: 'Title must be between 2 and 128 characters' },
                            { max: 128, message: 'Title must be between 2 and 128 characters' },
                            { required: true, message: 'Please enter title' },
                            { whitespace: true, message: 'Please enter title' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Title'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'seo_title',
                        options: {
                          initialValue: item.seo_title,
                          rules: [
                            { min: 2, message: 'SEO title must be between 2 and 256 characters' },
                            { max: 256, message: 'SEO title must be between 2 and 256 characters' },
                            { required: true, message: 'Please enter seo title' },
                            { whitespace: true, message: 'SEO title cannot contain only spaces' }
                          ],
                          onChange: (e) => this.handleSeoChange(e)
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'SEO Title'
                      }}
                      input={{
                        tabIndex: 9,
                        placeholder: 'e.g. this-is-a-custom-page'
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'standard_layout',
                        options: {
                          initialValue: typeof item.standard_layout !== 'undefined' ? item.standard_layout === 1 : true,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Header & Footer'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 10
                      }}
                      readOnly={!this.canSave()}
                      tooltip="Page is with standard header and footer when set to 'Yes'"
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: typeof item.active !== 'undefined' ? item.active === 1 : true,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Active'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 10
                      }}
                      readOnly={!this.canSave()}
                      tooltip="Page is available when set to 'Yes'"
                    />
                  </Col>
                </Row>
              </Panel>
              <Panel title='Page Content'>
                {/* <ReactQuill theme='snow' modules={modules} value={this.state.content} onChange={(v) => this.handleContent(v)} placeholder={'Your content here...'} /> */}

                <FormItem
                  {...formEditorLayout}
                  label='Content'
                >
                  {getFieldDecorator('content', {
                    validateTrigger: 'onBlur',
                    rules: [
                      { required: true, message: 'Please enter content' }
                    ]
                  })(
                    <BraftEditor
                      language='en'
                      className='editor'
                      contentClassName='editor-content'
                      controls={controls}
                      extendControls={extendControls}
                      id='editor-with-color-picker'
                      onChange={this.handleChange}
                      readOnly={!this.canSave()}
                    />)}
                </FormItem>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  handleChange = (editorState) => {
    this.setState({ editorState })
  }

  handleUploadContentImage = (result) => {
    setTimeout(() => {
      this.props.form.setFieldsValue({
        content: ContentUtils.insertMedias(this.state.editorState, [{
          type: 'IMAGE',
          url: lamboplace.uc + result.url // assetService.load(result.url)
        }])
      })
    }, 200)
  }

  uploadHandler = (param) => {
    if (!param.file) {
      return false
    }

    this.setState({
      editorState: ContentUtils.insertMedias(this.state.editorState, [{
        type: 'IMAGE',
        url: URL.createObjectURL
      }])
    })
  }

  handleSeoChange (v) {
    const url = `${lamboplace.domain}/content/${v.target.value}`
    this.setState({ fullUrl: url })
  }

  async fetchPage () {
    try {
      this.setState({ loading: true })
      const item = await customPageService.get(this.props.match.params.id)
      this.setState({ item, loading: false, content: item.content, fullUrl: `${lamboplace.domain}/content/${item.seo_title}` })

      setTimeout(() => {
        this.props.form.setFieldsValue({
          content: BraftEditor.createEditorState(item.content)
        })
      }, 1000)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load page successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this page?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await customPageService.remove(match.params.id)

          if (response._id) {
            notification.show('success', 'Deleted successfully', 'Page deleted successfully.')
            history.replace('/pages/custom')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete page successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })
        values.standard_layout = values.standard_layout ? 1 : 0
        values.active = values.active ? 1 : 0
        values.content = values.content.toHTML()
        values.content = values.content.replace(/"/g, '\\"')

        try {
          if (this.isEdit()) {
            const response = await customPageService.save(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Page saved successfully.')
            }
          } else {
            const response = await customPageService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Page saved successfully.')
              history.replace(`/pages/custom/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save page successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteCustomPage')
  }

  canRead () {
    return this.hasAccess('readCustomPage')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateCustomPage' : 'createCustomPage')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Admin }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CustomPage))
