import { INTRO_LOADED } from '../actions/intro'

const INITIAL_STATE = {
  intro: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INTRO_LOADED:
      return { ...state, intro: { list: action.intro.data, total: action.intro.total } }
    default:
      return state
  }
}
