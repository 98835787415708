import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Button from '../Button'
import Spin from '../Spin'
import Upload from 'antd/lib/upload'

import './styles.css'

export default class BannerUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { className, height = 374, img, loading = false, readOnly = false, width = 240, upload, radius = 0 } = this.props
    upload.className = formatter.toClassName(['wd-upload-uploader', upload.className, readOnly ? 'disabled' : ''])
    upload.disabled = readOnly || upload.disabled

    return (
      <div className={formatter.toClassName(['wd-upload', className])} style={{ width: `${width + 2}px`, height: `${height + 2}px` }}>
        <Upload {...upload}>
          <Spin loading={loading} blur>
            <div>
              {loading ? null : (
                <div className='wd-upload-trigger' style={{ width: `${width}px`, height: `${height}px` }}>
                  <Button ghost style={{ margin: 0 }}>Click to change</Button>
                </div>
              )}

              {img.src ? (
                <img className='wd-upload-image' {...img} alt={img.alt || 'Image'} style={{ width: `${width}px`, height: `${height}px`, borderRadius: `${radius}px` }} />
              ) : (
                <img className='wd-upload-image' alt='' src='/icon/banner.svg' style={{ width: `${width}px`, height: `${height}px`, borderRadius: `${radius}px` }} />
              )}
            </div>
          </Spin>
        </Upload>
      </div>
    )
  }
}

BannerUpload.propTypes = {
  className: PropTypes.string,
  img: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  upload: PropTypes.object.isRequired
}
