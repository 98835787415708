import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_CAROUSELS, CAROUSELS_LOADED, CAROUSELS_FAILED } from '../actions/carousel'
import carouselService from '../../services/carousel'

function * fetchCarousels (params) {
  try {
    const carousels = yield call(() => carouselService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: CAROUSELS_LOADED, carousels })
  } catch (e) {
    yield put({ type: CAROUSELS_FAILED, message: e.message })
  }
}

function * watchCarousels () {
  yield takeEvery(FETCH_CAROUSELS, fetchCarousels)
}

export default watchCarousels
