import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { AuditActionsList } from '../../../constants/audit/actions'
import { authService, adminService } from '../../../services'
import { fetchAuditLogs } from '../../../states/actions/audit'
import { formatter, notification, sessionStorage } from '../../../util'

// UI
import { List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'

import './styles.css'

const filterKey = 'audit.filter'
const pageSize = 100
const Option = Select.Option

class Audit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      admins: [],
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      loading: false,
      loadingAdmins: false,
      searchText: '',
      showModal: false
    }
    this.findAdmins = debounce(this.findAdmins, 500)
    this.findAuditLogs = debounce(this.findAuditLogs, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchAuditLogs({ loading: true, currentPage, filter, searchText, sort })
      this.findAdmins()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { auditLogs } = this.props
    const { admins, currentPage, filter, loading, loadingAdmins } = this.state
    const { list, total } = auditLogs
    const { action, modified_by: modifiedBy } = filter
    const columns = [
      {
        title: '',
        width: 1,
        render: (row) => <ListIcon file='log.svg' name={row.id.toString()} />
      },
      {
        key: 'modified_at',
        title: 'Date',
        width: 4,
        render: (row) => formatter.toStandardDate(row.modified_at)
      },
      {
        key: 'modified_by',
        title: 'User',
        width: 3
      },
      {
        key: 'action',
        title: 'Action',
        width: 7,
        render: (row) => formatter.capitalize(this.formatAction(row.action, row.table_name))
      },
     /*  {
        key: 'table_name',
        title: 'Module',
        width: 4,
        render: (row) => <div className='wd-audit-list-wrap-text'>{row.table_name}</div>
      }, */
      {
        key: 'affected_value',
        title: 'Log Details',
        width: 9,
        render: (row) => <div className='wd-audit-list-wrap-text'>{row.affected_value}</div>
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Audit Logs (${total})`} description='Display data modification history' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Audit Logs' onChange={this.findAuditLogs} />
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={6}>
                    <Select
                      allowClear
                      defaultValue={action}
                      disabled={loading}
                      onChange={this.changeAction}
                      placeholder='Action'
                      style={{ width: '100%' }}
                    >
                      {AuditActionsList.map(({ name, value }) => (
                        <Option key={name} value={value}>{name}</Option>
                      ))}
                    </Select>
                  </Col>

                  <Col lg={12}>
                    <Select
                      allowClear
                      defaultValue={modifiedBy}
                      disabled={loading}
                      filterOption={false}
                      loading={loadingAdmins}
                      onChange={this.changeAdmin}
                      onSearch={this.findAdmins}
                      placeholder='User'
                      showSearch
                      style={{ width: '100%' }}
                    >
                      {admins.map(({ _id, name }) => (
                        <Option className='text-wrap' key={_id} value={name}>{name}</Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} rows={list} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} records`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeAction = (value) => {
    const { filter, searchText, sort } = this.state

    if (value) {
      filter.action = value
    } else {
      delete filter.action
    }

    this.fetchAuditLogs({ loading: true, currentPage: 1, filter, sort, searchText })
  }

  changeAdmin = (value) => {
    const { filter, searchText, sort } = this.state

    if (value) {
      filter.modified_by = value
    } else {
      delete filter.modified_by
    }

    this.fetchAuditLogs({ loading: true, currentPage: 1, filter, sort, searchText })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchAuditLogs({ loading: true, currentPage, filter, sort, searchText })
  }

  fetchAuditLogs = ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      this.setState({ currentPage, loading })
      sessionStorage.setObject(filterKey, filter)
      this.props.fetchAuditLogs(currentPage, pageSize, filter, searchText)
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load audit logs successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findAuditLogs = (text) => {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchAuditLogs({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, sort, searchText: text })
    this.setState({ searchText: text })
  }

  findAdmins = (value) => {
    this.setState({ loadingAdmins: true })
    adminService
      .findAdminsByName(20, value || '')
      .then((admins) => {
        this.setState({ admins, loadingAdmins: false })
      })
  }

  formatAction = (action, module) => {
    return action.replace('insert', 'add').replace('update', 'modify') + ' ' + module.replace('mobile', '').replace('lambo', '').replace(/_/g, ' ')
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canList = () => {
    return this.hasAccess('listAuditLog')
  }
}

const mapDispatchToProps = {
  fetchAuditLogs
}

const mapStateToProps = (state) => {
  return { ...state.Audit }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Audit))
