import request from '../util/request'
import queryString from '../util/queryString'

const miniBannerUrl = '/api/mini-banner'

export default {
  /** Mini Banner */
  listSliderByPage (page, size, filter, text) {
    return request(`${miniBannerUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  addMiniBanner (values) {
    return request(miniBannerUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getMiniBannerById (id) {
    return request(`${miniBannerUrl}/${id}`)
  },
  saveMiniBanner (id, values) {
    return request(`${miniBannerUrl}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  removeMiniBanner (id, values) {
    return request(`${miniBannerUrl}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  }
}
