import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_COLLECTION, COLLECTION_LOADED, COLLECTION_FAILED, FETCH_TEMPLATE, TEMPLATE_LOADED, TEMPLATE_FAILED } from '../actions'
import { collectionService, templateService } from '../../services'

// Generators
function * fetchCollection (params) {
  try {
    const collection = yield call(() => collectionService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: COLLECTION_LOADED, collection })
  } catch (e) {
    yield put({ type: COLLECTION_FAILED, message: e.message })
  }
}

// Generators
function * fetchTemplates (params) {
  try {
    const templates = yield call(() => templateService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TEMPLATE_LOADED, templates })
  } catch (e) {
    yield put({ type: TEMPLATE_FAILED, message: e.message })
  }
}

function * watchCollection () {
  yield takeEvery(FETCH_COLLECTION, fetchCollection)
  yield takeEvery(FETCH_TEMPLATE, fetchTemplates)
}

export default watchCollection
