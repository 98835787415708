import { queryString, request } from '../../util'

const url = '/api/button/layouts'

export default {
  addLayout (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getLayout (id) {
    return request(`${url}/${id}`)
  },
  removeLayout (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveLayout (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  listLayoutsByPage (page, size, filter, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), text })}`)
  },
  removeImage (id) {
    return request(`${url}/${id}/image`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
