import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { authService, featuredOnService } from '../../../services/'
import { notification, validator } from '../../../util'
import { fetchFeaturedOn } from '../../../states/actions'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

class FeaturedOn extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      loadingImage: false
    }
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchFeaturedOn()
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 14, md: 18 }
    }
    const formShortItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 8, md: 8 }
    }
    const selectFormItemLayout = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { form, history } = this.props
    const { item, loading, loadingImage } = this.state
    const { getFieldValue } = form

    return (
      <Page.Content>
        <Page.Header title='Featured On' description='Upload logo, set link and position for it.'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={this.handleDelete}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={this.handleSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Featured on Details'>
                <div className='banner-panel'>
                  <div className='banner-panel-left'>
                    <div className='banner-image-uploader'>
                      <UploadHolder
                        img={{
                          alt: 'Company Logo',
                          src: item.image ? item.image : ''
                        }}
                        loading={loadingImage}
                        readOnly={!this.canSave()}
                        height={240}
                        width={240}
                        upload={{
                          action: '/api/featured-on/upload/image',
                          beforeUpload: this.checkImage,
                          data: { _id: item._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: this.handleUploadImage,
                          showUploadList: false
                        }}
                      />
                    </div>

                    <div className='banner-image-hint'>
                      Size: 500px x 500px (JPG or PNG) <br />
                      Maximum file size: 2 MB
                    </div>
                  </div>

                  <div className='banner-panel-right'>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 64 characters' },
                            { max: 64, message: 'Name must be between 2 and 64 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'link_value',
                        options: {
                          initialValue: item.link_value || '',
                          rules: [
                            { whitespace: true, message: 'Please enter link'}
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        hasFeedback: false,
                        label: 'Link'
                      }}
                      input={{
                        placeholder: 'https://wwww.lamboplace.com',
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    {this.getPositionSelect(formItemLayout, this.isEdit(), item.position)}

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Visible'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 4
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Logo is visible when set to 'Yes'`}
                    />
                  </div>
                </div>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkImage = (file) => {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  getPositionSelect = (formShortItemLayout, isEdit, position) => {
    const { featuredOn, form } = this.props
    const { total } = featuredOn
    const defaultPosition = isEdit ? position : total + 1
    const list = []

    for (let i = 1; i <= total; i++) {
      list.push(i)
    }

    return (
      <Select
        fieldDecorator={{
          id: 'position',
          options: {
            initialValue: defaultPosition
          }
        }}
        form={form}
        formItem={{
          ...formShortItemLayout,
          // hasFeedback: false,
          label: 'Position'
        }}
        list={list}
        listFunc={(position) => {
          return <Option key={position} value={position}>{position}</Option>
        }}
        select={{
          // showSearch: true,
          placeholder: 'Select a position',
          tabIndex: 3
        }}
      />
    )
  }

  fetchFeaturedOn = async () => {
    try {
      this.setState({ loading: true })
      const item = await featuredOnService.get(this.props.match.params.id)
      this.setState({ loading: false, item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load featured on successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete = () => {
    const { featuredOn, history, match } = this.props
    const { list, total } = featuredOn

    confirm({
      title: 'Confirm to delete this logo?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          let id = match.params.id
          let updateList = []
          let oldPosition = 0

          for (let i = 0; i < total; i++) {
            const { _id, position } = list[i]
            const newItem = { id: _id }

            if (id === _id.toString()) {
              oldPosition = position
            } else {
              if (oldPosition > 0 && position > oldPosition) {
                newItem.position = i
              } else {
                newItem.position = i + 1
              }

              updateList.push(newItem)
            }
          }

          const response = await featuredOnService.remove(id, { list: updateList })

          if (response) {
            notification.show('success', 'Deleted successfully', 'Logo deleted successfully.')
            history.replace('/home/featured-on')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete logo successfully. Please try again later.')
        }
      }
    })
  }

  handleSave = () => {
    const { featuredOn, form, history } = this.props
    const { validateFields } = form
    const { list, total } = featuredOn

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })

        try {
          values.image = item.image ? (item.image.url || item.image) : ''
          values.image_thumb = item.image ? (item.image.thumbUrl || item.image_thumb) : ''
          values.active = values.active ? 1 : 0

          if (this.isEdit()) {
            let id = item._id
            let oldPosition = item.position || 0
            const { position: newPosition } = values
            const positionChanged = newPosition !== oldPosition
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item
                const newItem = { id: _id }

                if (_id === id) {
                  newItem.position = newPosition
                } else {
                  if (newPosition < oldPosition) {
                    if (itemPosition < newPosition || itemPosition > oldPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx + 2
                    }
                  } else {
                    if (itemPosition < oldPosition || itemPosition > newPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx
                    }
                  }
                }

                return newItem
              })
            }
            const response = await featuredOnService.save(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Banner saved successfully.')
            }
          } else {
            const { position: newPosition } = values
            const positionChanged = newPosition < total + 1
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, position: itemPosition } = item

                return { id: _id, position: itemPosition < newPosition ? idx + 1 : idx + 2 }
              })
            }

            const response = await featuredOnService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Featured on saved successfully.')
              history.replace(`/home/featured-on/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save featured on successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canDelete = () => {
    return this.hasAccess('deleteBanner')
  }

  canRead = () => {
    return this.hasAccess('readBanner')
  }

  canSave = () => {
    return this.hasAccess(this.isEdit() ? 'updateBanner' : 'createBanner')
  }

  canUpload = () => {
    return this.canSave()
  }

  canDeletePhoto = () => {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto = () => {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit = () => {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.FeaturedOn }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FeaturedOn))
