import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { adminService, authService, moduleService, permissionService, vendorService } from '../../../services'
import { notification } from '../../../util'

// UI
import { Button, Input, Page, Panel, Spin, Switch, Select } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm
const Option = Select.Option

class Vendor extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      vendors: [],
      vendorValidateStatus: undefined
    }
    //this.checkEmailExists = debounce(this.checkEmailExists, 500)
    this.findVendors = debounce(this.findVendors, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.findVendors()

      if (this.isEdit()) {
        this.fetchVendor()
      }
    } else {
      authService.unauthorizeAction('/settings/vendors')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 7, md: 7 },
      wrapperCol: { sm: 15, md: 15 }
    }
    const { form, history } = this.props
    const { item, loading, vendors, vendorValidateStatus } = this.state
    /*let defaultVendor

    if (!this.canSave()) {
      defaultVendor = vendors.find(({ entity_id }) =>  entity_id === item.v_id) || {}
    }*/

    return (
      <Page.Content>
        <Page.Header title='Vendor Details' description='Setting for Minimum Quality & Minimum Price'>
          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Basic Information'>
                <Row>
                  <Col lg={12}>
                    <Input
                      fieldDecorator={{
                        id: 'store_name',
                        options: {
                          initialValue: item.store_name,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Store Name'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />
                    <Input
                      fieldDecorator={{
                        id: 'username',
                        options: {
                          initialValue: item.username,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Username'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />

                    <Input
                      fieldDecorator={{
                        id: 'firstname',
                        options: {
                          initialValue: item.firstname,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'First Name'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />

                    <Input
                      fieldDecorator={{
                        id: 'lastname',
                        options: {
                          initialValue: item.lastname,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Last Name'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />
                    <Input
                      fieldDecorator={{
                        id: 'email',
                        options: {
                          initialValue: item.email,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Email'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly
                    />
                    <Input
                      fieldDecorator={{
                        id: 'min_quantity',
                        options: {
                          initialValue: item.min_quantity,
                          rules: [
                            //{ min: 1, message: 'Min. Quantity must be integer' },
                            { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Min. Quantity'
                      }}
                      input={{
                        tabIndex: 7
                      }}
              
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'min_price',
                        options: {
                          initialValue: item.minPrice,
                          rules: [
                            //{ min: 1, message: 'Min. Price must be integer' },
                            { validator: (rule, value, callback) => this.checkDecimal(rule, value, callback) }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Min. Price (RM)'
                      }}
                      input={{
                        tabIndex: 8,            
                      }}
                      readOnly={!this.canSave()}
                    />

                  </Col>
                </Row>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }


  /*checkEmail (rule, value, callback) {
    if (value && value.length > 0 && !validator.isEmail(value)) {
      callback('E-mail is invalid')
    }

    this.checkEmailExists(rule, value, callback)
  }*/


  /*checkInteger (rule, value, callback) {
    const { item } = this.state
    
    if (item.minPrice == null && item.min_quantity == null) {
      callback('At least one field must be filled')
    }
    else if (item.min_quantity == null && item.minPrice != null) {
        let decimal = ( /^\d+(.\d{0,2})?$/)
        decimal.test(value)
        callback('Min. Quantity must be decimal')
      }
    else if (item.minPrice == null && item.min_quantity) {
        let integer = (/^[0-9\b]+$/)
        integer.test(value)
        callback('Min. Quantity must be integer')
      }
    
    callback()
  }*/

  checkInteger (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^[0-9\b]+$/).test(value)) {
      callback('Min. Quantity must be integer')
    }
    callback()
  }

  checkDecimal (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^\d+(.\d{0,2})?$/).test(value)) {
      callback('Min. Price(RM) must be decimal')
    }
    callback()
  }

  /*checkEmailExists (rule, value, callback) {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const { email } = this.state.item
        const response = await adminService.checkEmail(value)

        if (response.exists !== false && email !== value) {
          callback('E-mail already exists')
        }
      }

      callback()
    })
  }*/

  async fetchVendor () {
    try {
      this.setState({ loading: true })
      const item = await vendorService.get(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load vendor successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async findVendors (value) {
    try {
      this.setState({ vendorValidateStatus: 'validating' })
      vendorService
        .findByName(100, value || '')
        .then((vendors) => {
          this.setState({ vendors, vendorValidateStatus: undefined })
        } )
    } catch (e) {
      console.error(e)
    }
  }

  /*handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await adminService.removeAdmin(match.params.id)

          if (response._id) {
            notification.show('success', 'Deleted successfully', 'User deleted successfully.')
            history.replace('/settings/admins')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete user successfully. Please try again later.')
        }
      }
    })
  }*/

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })
        try {
          if (this.isEdit()) {
            const response = await vendorService.save(item.entity_id, values)
            this.setState({ item: { ...item, ...values }, loading: false })
            if (response.vendor_id) {
              notification.show('success', 'Saved successfully', 'Settings saved successfully.')
            } else {
              notification.show('error', 'Unable to save successfully', 'Unable to save settings successfully. Please try again later.')
              this.setState({ loading: false })
            }
          } /*else {
            const response = await vendorService.add(values)
            this.setState({ loading: false })

            if (response.vendor_id) {
              this.setState({ item: { ...item, ...values, vendor_id: response.vendor_id } })
              notification.show('success', 'Saved successfully', 'User saved successfully.')
              history.replace(`/settings/admins/${response.vendor_id}`)
            } else {
              notification.show('error', 'Unable to save successfully', 'Unable to save user successfully. Please try again later.')
              this.setState({ loading: false })
            }
          }*/
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save settings successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteVendor')
  }

  canRead () {
    return this.hasAccess('readVendor')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateVendor' : 'createVendor')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Vendor }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Vendor))
