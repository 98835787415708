import { TOPFAVOURITE_CARTPRICERULE_GROUPS_LOADED, TOPFAVOURITE_CARTPRICERULE_LOADED } from '../actions/topFavouriteCartPriceRule'

const INITIAL_STATE = {
  topFavouriteCartPriceRulesGroups: { list: [], total: 0 },
  topFavouriteCartPriceRules: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOPFAVOURITE_CARTPRICERULE_GROUPS_LOADED:
      return { ...state, topFavouriteCartPriceRulesGroups: { list: action.topFavouriteCartPriceRules.list, total: action.topFavouriteCartPriceRules.total } }
    case TOPFAVOURITE_CARTPRICERULE_LOADED:
      return { ...state, topFavouriteCartPriceRules: { list: action.topFavouriteCartPriceRules.list, total: action.topFavouriteCartPriceRules.total } }
    default:
      return state
  }
}


