import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TEMPLATE, TEMPLATE_LOADED, TEMPLATE_FAILED } from '../actions/template'
import templateService from '../../services/template'

function * fetchTemplates (params) {
  try {
    const templates = yield call(() => templateService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TEMPLATE_LOADED, templates })
  } catch (e) {
    yield put({ type: TEMPLATE_FAILED, message: e.message })
  }
}

function * watchTemplate () {
  yield takeEvery(FETCH_TEMPLATE, fetchTemplates)
}

export default watchTemplate
