export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const NOTIFICATIONS_LOADED = 'NOTIFICATIONS_LOADED'
export const NOTIFICATIONS_FAILED = 'NOTIFICATIONS_FAILED'

export const fetchNotifications = (page, size, filter, sort, query) => {
  return {
    type: FETCH_NOTIFICATIONS,
    page,
    size,
    filter,
    sort,
    query
  }
}
