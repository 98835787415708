import { localStorage, notification, request, validator } from '../util'

const url = '/public/api/'

export default {
  getCurrentToken () {
    return localStorage.getItem('token')
  },
  getCurrentUser () {
    return localStorage.getObject('user')
  },
  hasAccess (permission) {
    try {
      if (permission) {
        const permissions = localStorage.getObject('permissions', [])
        return permissions.indexOf(permission) > -1
      }

      return false
    } catch (e) {
      return false
    }
  },
  isSignedIn () {
    const token = localStorage.getItem('token')
    return token && !validator.isEmptyString(token)
  },
  resetPassword (email) {
    try {
      const result = request(url + 'reset-password/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  signIn (email, password, newPassword, rememberMe) {
    try {
      const result = request(url + 'login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password, newPassword, rememberMe })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  signOut () {
    try {
      const result = request(url + 'logout/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  unauthorizeAction (redirectPath, duration, callback) {
    if (typeof callback === 'function') {
      callback()
    } else {
      notification.show('error', 'You do not have permission to perform this action.')
      setTimeout(() => window.location.replace(redirectPath || '/'), duration || 3000)
    }
  }
}
