import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { magento } from '../../config'
import { authService,productService,promotionEventsService } from '../../services'
import { formatter, notification, sessionStorage, validator } from '../../util'
import PropTypes from 'prop-types'



import { DatePicker, Input, List, Page, Pager, Panel, SearchInput, Select as WDSelect, Spin, Switch as WDSwitch } from '..'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import OptionModal from "antd/lib/modal";
import Select from 'antd/lib/select'


import './styles.css'
import { message } from 'antd'

const newfilterKey = 'product.filter'
const pageKey = 'product.page'
const pageSize = 100
const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option
export default class WDSelectSearch extends Component {
  static get Option () {
    return Select.Option
  }

  constructor (props) {
    super(props)
    let isPromo = this.props.isPromotion
    this.state = {
      currentPage: 1,
      maxPosition: 0,
      loading: false,
      modal: {
        isAdd: true,
        selectedProduct: {},
        show: false
      },
      optionModal: {
        isAddOptionModal: true,
        selectedProductOptionModal: {},
        showOptionModal: false
      },
      param: {  
        brand: "",
        category: "",
        keyword: "",
        newArrival: "",
        discountFrom:  0,
        discountTo: 0,
        discountPeriodFrom:  "",
        discountPeriodTo: "",
        priceFrom: 0,
        priceTo: 0,
        isPromo,
      },
      products: { data: [], total: 0 },
      productImagePreview: '',
      searchText: '',
      sort: {},
    }
    this.fetchProducts = debounce(this.fetchProducts, 500)
    this.updateData = this.updateData.bind(this);
  }

  render () {
    const { isPromotion, fieldDecorator, filterKey={}, form, formItem = {}, onDoubleClick =() =>{}, readOnly = false, select = {}, button={}, list = [], listFunc } = this.props
    const { getFieldDecorator } = form
    const { id, options = {} } = fieldDecorator
    let { initialValue } = options
    formItem.className = formatter.toClassName(['wd-select', formItem.className])
    initialValue = Array.isArray(initialValue) ? initialValue.map((item) => formatter.capitalize(item, false)).join(', ') : formatter.capitalize(options.initialValue, false)


    const { currentPage, loading, modal, optionModal, param, products, productImagePreview, promotionGroup, promotions } = this.state
    const { getFieldValue } = form
    const { active } = sessionStorage.getObject(filterKey)
    const { isAdd, selectedProduct, show } = modal
    const { isAddOptionModal, selectedProductOptionModal, showOptionModal } = optionModal
    const { data: productList } = products


    const modalItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 16, md: 16 }
    }

    const formItemLayer = {
      labelCol: { sm: 24, md: 24 },
      wrapperCol: { sm: 24, md: 24 }
    };



    const columns = [
      {
        title: 'Sku',
        key: 'sku',
        width: 2,
      },
      {
        title: 'Image',
        cssClassName: 'center-header',
        align: 'center', 
        key: 'thumbnail',
        render: (row) => {
          return(
          <img
            className={"option-modal-img"}
            alt=""
            src={
              row.thumbnail
                ? magento.media + row.thumbnail
                : "/img/default-image.png"
            }
          />
          )
        },
                  
        width: 5,         
      },
      {
        title: 'Name',
        key: 'name',
        width: 3,
      },
      {
        title: 'Brand',
        key: 'brand',
        width: 3,
      },
      {
        title: 'Category',
        key: 'category',
        width: 3,
      },
      {
        title: 'Price',
        key: 'price',
        width: 2,
      },
      {
        title: 'Discount',
        key: 'discount',
        width: 2,
      },
      {
        title: 'Discount period',
        key: 'discount_period',
        width: 4,
      }
    ]

    return (
      <Page.Content>
        <Page.Body>
          <Form.Item hasFeedback={!readOnly} {...formItem}>
            <span className='product-search' style={{display:"flex"}}>
            {readOnly ? (
              <span className='ant-form-text'>{initialValue}</span>
            ) : (
              getFieldDecorator(id, options)(<Select className='search-bar' placeholder='Select a product' filterOption={false} showSearch={true} {...select} form={form} >{list.map(listFunc)}</Select>)
            ) }
            {/*<button {...button}><Icon type='search'/></button>*/}
            <button style={{fontSize:"14px !important"}} onClick={() =>this.showOptionModal(true)} className={"fa-search "} icon={"search"} placeholder={"search"} ><Icon type='search'/></button>
            </span>
          </Form.Item>
          <OptionModal
            visible={showOptionModal}
            width={"80%"}
            title={isAdd ? "Add Product" : "Edit Product"}
            onOk={() => this.hideOptionModal()}
            onCancel={() => this.hideOptionModal()}
            footer={[
            ]}
          >
            <div className="OptionModal">
            <Form layout="vertical" >
                
                  <h1>Product Filter</h1>
                  <Row>
                    <Col lg={7}>
                      <Input
                        fieldDecorator={{
                          id: "brand"
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayer,
                          label: "Brand"
                        }}
                        onChangeEvent={value =>this.updateData("brand",value)}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col lg={7}>
                      <Input
                        fieldDecorator={{
                          id: "category"
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayer,
                          label: "Category"
                        }}
                        onChangeEvent={(value) =>this.updateData("category",value)}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col lg={7}>
                      <Input
                        fieldDecorator={{
                          id: "keyword"
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayer,
                          label: "Keyword"
                        }}
                        onChangeEvent={(value) =>this.updateData("keyword",value)}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col lg={3}>
                      <div className="ant-row ant-form-item wd-input"> 
                        <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 show-label">
                          New arrival?
                        </div>                      
                      <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        style={{ width: '100%' }}
                        onChange={value => this.updateData("newArrival",value)}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select>
                      </div>
                    </Col>  
                    
                  </Row>
                  <Row>
                    <Col lg={7}>
                      <Col lg={11}>
                        <Input
                          fieldDecorator={{
                            id: "discountfrom",
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "Discount"
                          }}
                          onChangeEvent={(value) =>this.updateData("discountFrom",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="ant-row ant-form-item wd-input"> 
                          <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                            a
                          </div>
                          <div >
                            -
                          </div>
                        </div>
                      </Col>
                      
                      <Col lg={11} className="to-hid-label">
                        <Input
                          fieldDecorator={{
                            id: "discountto"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "to",
                            //style:{ visibility:"hidden" }
                          }}
                          onChangeEvent={(value) =>this.updateData("discountTo",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                    </Col>
                    <Col lg={10}> 
                      <Col lg={11}>
                        <DatePicker
                          datePicker={{
                            placeholder: "From",
                            style: { width: "100%" },
                            onChange: value => this.updateData("discountPeriodFrom",value)
                          }}
                          fieldDecorator={{
                            id: "discountshow_from",
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "Discount Period"
                          }}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="ant-row ant-form-item wd-input"> 
                          <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                            a
                          </div>
                          <div >
                            -
                          </div>
                        </div>
                      </Col>
                      <Col lg={11} className="to-hid-label"> 
                        <DatePicker
                          datePicker={{
                            placeholder: "To",
                            style: { width: "100%" },
                            onChange: value => this.updateData("discountPeriodTo",value)
                          }}
                          fieldDecorator={{
                            id: "discountshow_to"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "To"
                          }}
                          readOnly={readOnly}
                        />
                      </Col>
                    </Col>
                    <Col lg={7}>
                      <Col lg={11}>
                        <Input
                          fieldDecorator={{
                            id: "pricefrom"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "Price range"
                          }}
                          onChangeEvent={(value) =>this.updateData("priceFrom",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="ant-row ant-form-item wd-input"> 
                          <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                            a
                          </div>
                          <div >
                            -
                          </div>
                        </div>
                      </Col>
                      <Col lg={11} className="to-hid-label">
                        <Input
                          fieldDecorator={{
                            id: "priceto"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "to"
                          }}
                          onChangeEvent={(value) =>this.updateData("priceTo",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                    </Col>
                  </Row>  
                      <button className="wd-button" onClick={()=>this.showProductTable()} >
                        search
                      </button>
                      <Row>
                        <Col>
                            <List cols={columns} rows={products.data} onDoubleClick={(data)=>this.selectFilterProduct(data)}/>
                            <Pager
                              size={pageSize}
                              total={products.total}
                              totalText={`Total ${products.total} products`}
                              current={currentPage}
                              onChange={currentPage => this.changeProductPage(currentPage)}
                              style={{ marginTop: "15px" }}
                            />
                        </Col>
                      </Row>
            </Form>
            </div>
          </OptionModal>
        </Page.Body>
      </Page.Content>
      
    )
  }



  updateData(label, value) {
    const { param } = this.state;
    if(label=="discountPeriodFrom" || label=="discountPeriodTo" || label=="newArrival"){
      param[label] = value;
    } else {
      param[label] = value.target.value;
    }
    
    this.setState({ param });
  }

  changeProductPage (currentPage) {
    const { filterKey } = this.props
    this.newfetchProducts({ loading: true, currentPage, filterKey})
  }

  async fetchProducts() {
    try {
      const { modal,param } = this.state;
      const { selectedProduct } = modal;
      //const { sku } = selectedProduct;
      const products = await productService.listFilteredProductsByPage(
        1,
        pageSize,
        {},
        param 
      );
      this.setState({ products });
    } catch (e) {
      console.error(e);
    }
  }

  async newfetchProducts({ loading = false, currentPage = 1, filter = {}}) {
    try {
      const { modal,param } = this.state;
      const { selectedProduct } = modal;
      sessionStorage.setObject(newfilterKey, filter)
      sessionStorage.setItem(pageKey, currentPage)
      this.setState({ currentPage, loading: loading})
      //const { sku } = selectedProduct;
      const products = await productService.listFilteredProductsByPage(
        currentPage,
        pageSize,
        {},
        param 
      );
      this.setState({ products, loading: false})

      //this.setState({ products });
    } catch (e) {
      console.error(e);
    }
  }

  hideOptionModal() {
    let isPromo = this.props.isPromotion
    const { form } = this.props;
    const { optionModal } = this.state;
    optionModal.showOptionModal = false;
    this.setState({ optionModal });
    setTimeout(() => {
      form.resetFields();
      this.setState({
        optionModal: {
          isAddOptionModal: true,
          selectedProductOptionModal: {},
          showOptionModal: false
        },
        param: {
          brand: "",
          category: "",
          keyword: "",
          newArrival: "",
          discountFrom: 0,
          discountTo: 0,
          discountPeriodFrom: "",
          discountPeriodTo: "",
          priceFrom: 0,
          priceTo: 0,
          isPromo
        },
        currentPage: 1
      });
    }, 100);
  }

  showOptionModal(isAdd, selectedProductOptionModal) {
    const { optionModal } = this.state;
    let productImagePreview = "/img/default-image.png";
    optionModal.isAdd = isAdd;
    optionModal.showOptionModal = true;
    this.fetchProducts();
    this.setState({ optionModal });

    if (isAdd === false) {
      const {
        _id,
        active,
        image,
        show_from: from,
        show_to: to,
        position,
        product_sku: sku
      } = selectedProductOptionModal;
      productImagePreview = image
        ? magento.media + image
        : "/img/default-image.png";
        optionModal.selectedProductOptionModal = {
        id: _id,
        active,
        from,
        position,
        sku,
        to
      };
    }

    this.setState({ optionModal, productImagePreview });
  }

  async showProductTable() {
    await this.fetchProducts();
  }

  async selectFilterProduct(data) {
    const { onDoubleClick = () => {}, modal={} } = this.props
    this.hideOptionModal();
    onDoubleClick(data.sku,data);   
  }

  /*async selectProduct (value, option) {
    const { modal } = this.state
    const { selectedProduct } = modal
    selectedProduct.sku = value
    this.setState({ modal })
    const image = await productService.getMagentoImage(value)

    if (image) {
      const { file } = image
      this.setState({ productImagePreview: file })
    }
  }*/

}

WDSelectSearch.propTypes = {
  fieldDecorator: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formItem: PropTypes.object,
  readOnly: PropTypes.bool,
  select: PropTypes.object,
  button: PropTypes.object,
  list: PropTypes.array,
  listFunc: PropTypes.func
}
