export const FETCH_STORE = 'FETCH_STORE'
export const STORE_LOADED = 'STORE_LOADED'
export const STORE_FAILED = 'STORE_FAILED'

export const FETCH_ALL_STORE = 'FETCH_ALL_STORE'
export const ALL_STORE_LOADED = 'ALL_STORE_LOADED'
export const ALL_STORE_FAILED = 'ALL_STORE_FAILED'

export const fetchAllStores = () => {
  return {
    type: FETCH_ALL_STORE
  }
}

export const fetchStores = (page, size, filter, query) => {
  return {
    type: FETCH_STORE,
    page,
    size,
    filter,
    query
  }
}
