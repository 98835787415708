import { REWARD_MISSIONS_LOADED, REWARD_MISSIONS_GROUPS_LOADED } from '../actions/rewardMission'

const INITIAL_STATE = {
  rewardMissionGroups: { list: [], total: 0 },
  rewardMissions: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REWARD_MISSIONS_GROUPS_LOADED:
      return { ...state, rewardMissionGroups: { list: action.rewardMissions.list, total: action.rewardMissions.total } }
    case REWARD_MISSIONS_LOADED:
      return { ...state, rewardMissions: { list: action.rewardMissions.list, total: action.rewardMissions.total } }
    default:
      return state
  }
}
