import {
  REVIEW_TITLE_LOADED,
  REVIEW_LOADED,
  REVIEW_COMMENT_LOADED
} from '../actions/review'

const INITIAL_STATE = {
  reviewTitles: { list: [], total: 0 },
  reviews: { list: [], total: 0 },
  reviewComments: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REVIEW_TITLE_LOADED:
      return { ...state, reviewTitles: { list: action.reviewTitles.data, total: action.reviewTitles.total } }
    case REVIEW_LOADED:
      return { ...state, reviews: { list: action.reviews.data, total: action.reviews.total } }
    case REVIEW_COMMENT_LOADED:
      return { ...state, reviewComments: { list: action.reviewComments.data, total: action.reviewComments.total } }
    default:
      return state
  }
}
