import { FEATURED_ON_LOADED } from '../actions/featuredOn'

const INITIAL_STATE = {
  featuredOn: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEATURED_ON_LOADED:
      return { ...state, featuredOn: { list: action.featuredOn.data, total: action.featuredOn.total } }
    default:
      return state
  }
}
