import React, { Component } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import authService from '../../services/auth'
import { localStorage, sessionStorage } from '../../util'
import { routes } from '../../routes'

// UI
import Menu from '../../components/Menu'
import Icon from 'antd/lib/icon'

import './styles.css'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false,
      showUser: false
    }
  }

  componentDidMount () {
    // const root = document.getElementById('root')
    // root.addEventListener('click', (e) => this.handleAnyClick(e))
  }

  componentWillUnmount () {
    // const root = document.getElementById('root')
    // root.removeEventListener('click', (e) => this.handleAnyClick(e))
  }

  handleAnyClick (e) {
    if (!(e.target && e.target.outerHTML.includes('anticon'))) {
      this.setState({ showUser: false })
    }
  }

  render () {
    const { showMenu, showUser } = this.state
    const currentUser = authService.getCurrentUser()
    const { name } = currentUser

    return (
      <Router>
        <div className='App'>
          <div className={'App-menu' + (showMenu ? '' : ' App-menu-hide')}>
            <div className='App-title'>
              <span style={{ fontWeight: 800 }}>Lambo</span><span style={{color: '#EDAA34'}}>place</span>

              <div className='App-title-sub'>Unified Console</div>
            </div>

            <span className='Menu-hamburger' onClick={() => this.showLeftMenu()}> ☰ </span>

            <Menu routes={routes} onChange={() => this.showLeftMenu()} />
          </div>

          <div className='App-topbar'>
            <div onClick={() => this.showUserProfile()}>
              <Icon type='user' />&nbsp; {name} &nbsp;<Icon type='down' className='App-expand-icon' />
            </div>
          </div>

          <div className='User-Profile' style={{ display: showUser ? '' : 'none' }}>
            <div onClick={() => this.handleLogout()} className='submenu'>
              Logout
            </div>
          </div>

          <div className='App-content'>
            {routes.map((route, idx) => (
              <Route
                key={idx}
                component={route.component}
                path={route.path}
                exact={route.exact}
              />
            ))}
          </div>
        </div>
      </Router>
    )
  }

  async handleLogout () {
    const response = await authService.signOut()

    if (response) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = '/login'
    }
  }

  showLeftMenu () {
    this.setState({ showMenu: !this.state.showMenu })
    window.scrollTo(0, 0)
  }

  showUserProfile () {
    this.setState({ showUser: !this.state.showUser })
  }
}

export default App
