module.exports = {
  isDecimal (str) {
    return /^(?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/.test(str)
  },
  isDigit (str) {
    return /^\d+$/.test(str)
  },
  isEmail (str) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(str)
  },
  isEmptyString (str) {
    return this.isString(str) && str.length < 1
  },
  isImage (file) {
    return ['image/jpeg', 'image/png'].indexOf(file.type) > -1
  },
  isImageMiniBanner (file) {
    return ['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) > -1
  },
  isString (str) {
    return typeof str === 'string'
  },
  numOfDecimalPoints (num, str) {
    const regex = new RegExp(`(\\.\\d{${num + 1},})$`)
    return !regex.test(str)
  }
}
