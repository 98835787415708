import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Icon from 'antd/lib/icon'

import './styles.css'

export default class WDPager extends Component {
  constructor (props) {
    super(props)
    const { size = 1, total = 0 } = this.props
    const totalPage = Math.ceil(total / size)
    this.state = {
      currentPage: 1,
      totalPage
    }
  }

  // componentWillReceiveProps (nextProps) {
  //   const { current, size = 1, total = 0 } = nextProps
  //   let { currentPage } = this.state
  //   const totalPage = Math.ceil(total / size)

  //   if (current !== currentPage) {
  //     currentPage = current
  //   }

  //   this.setState({ currentPage, totalPage })
  // }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { current, size = 1, total = 0 } = nextProps
    let { currentPage } = prevState
    const totalPage = Math.ceil(total / size)

    if (current !== currentPage) {
      currentPage = current
    }

    return { ...prevState, currentPage, totalPage }
  }

  render () {
    const { className = '', style, totalText } = this.props
    const { currentPage, totalPage } = this.state
    const buildPager = () => {
      let renderPagerList = []
      let setGap = false

      if (currentPage > 1) {
        renderPagerList.push(<li key={-1}><button onClick={this.handlePreviousPage} className='wd-button-circle-ghost'><Icon type='left' /></button></li>)
      }

      for (let page = 1; page <= totalPage; page++) {
        if (currentPage === page) {
          renderPagerList.push(<li key={page}><button className='wd-button-circle'>{page}</button></li>)
          setGap = true
        } else if (page === 1 || page === totalPage || currentPage - 1 === page || currentPage + 1 === page) {
          renderPagerList.push(<li key={page}><button className='wd-button-circle-ghost' onClick={this.handleChangePage(page)}>{page}</button></li>)
          setGap = true
        } else {
          if (setGap) renderPagerList.push(<li key={page}>...</li>)
          setGap = false
        }
      }

      if (currentPage < totalPage) {
        renderPagerList.push(<li key={totalPage + 1}><button onClick={this.handleNextPage} className='wd-button-circle-ghost'><Icon type='right' /></button></li>)
      }

      return renderPagerList
    }

    return (
      <div className={formatter.toClassName(['wd-pager-container', className])} style={style}>
        <ul className={formatter.toClassName(['wd-pager', className])}>
          {buildPager()}
        </ul>

        {totalText ? <div className='wd-pager-text'>{totalText}</div> : null}

        <div style={{ clear: 'both' }} />
      </div>
    )
  }

  changePage (page) {
    const { onChange } = this.props
    this.setState({ currentPage: page })
    onChange(page)
  }

  handleChangePage = (page) => () => {
    this.changePage(page)
  }

  handleNextPage = () => {
    const { currentPage } = this.state
    this.changePage(currentPage + 1)
  }

  handlePreviousPage = () => {
    const { currentPage } = this.state
    this.changePage(currentPage - 1)
  }
}

WDPager.propTypes = {
  current: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.object,
  total: PropTypes.number.isRequired,
  totalText: PropTypes.string
}
