import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import authService from '../../../../services/auth'
import { fetchReviewsList } from '../../../../states/actions'
import ReviewStatus, { ReviewStatusList } from '../../../../constants/review/reviews'
import { formatter, notification, sessionStorage } from '../../../../util'

// UI
import { Button, Page, Pager, SearchInput, Spin, List } from '../../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'review.filter'
const pageSize = 20
const Option = Select.Option

class ReviewList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
    this.fetchReviews = debounce(this.fetchReviews, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchReviews()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { reviews } = this.props
    const { currentPage, filter, loading } = this.state
    const { active, status } = filter
    const { list, total } = reviews
    const columns = [
      // {
      //   key: 'id',
      //   title: 'ID',
      //   width: 1,
      // },
      {
        key: 'created_at',
        title: 'Reviewed On',
        width: 4,
        render: (row) => formatter.toStandardDate(row.created_at)
      },
      {
        key: 'product_name',
        title: 'Product',
        width: 9,
        render: (row) => (<div><div className='review-product'>{row.product_name}</div><div className='review-sku'>{row.product_sku}</div></div>)
      },
      {
        key: 'nickname',
        title: 'Nickname',
        width: 3,
      },
      {
        key: 'review_title',
        title: 'Title',
        width: 6,
        render: (row) => {
          const title = formatter.toNotApplicableText(row.review_title, 'title')
          const isNA = title !== row.review_title
          const style = `review-title ${isNA ? 'review-title-na' : ''}`
          return (
            <div className={style}>{title}</div>
          )
        }
      },
      // {
      //   key: 'review_detail',
      //   title: 'Review',
      //   width: 4,
      //   render: (row) => {
      //     const detail1 = formatter.toShortenText(row.review_detail, 45)
      //     const detail2 = formatter.toNotApplicableText(detail1, 'review')
      //     const isNA = detail2 !== detail1
      //     const style = `review-title ${isNA ? 'review-title-na' : ''}`
      //     return (
      //       <div className={style}>{detail2}</div>
      //     )
      //   }
      // },
      {
        key: 'status_id',
        title: 'Status',
        width: 2,
        render: (row) => (
          row.status_id === ReviewStatus.STATUS_APPROVED
            ? <div style={{color: '#3dc67d'}}>Approved</div>
            : row.status_id === ReviewStatus.STATUS_NOT_APPROVED
              ? <div style={{color: '#ff5656'}}>Not Approved</div>
              : <div style={{color: '#fcc35d'}}>Pending</div>
        )
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Reviews (${total})`} description='All reviews' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <SearchInput placeholder='Search Product, SKU' onChange={(value) => this.findReviews(value)} />
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={8}>
                    <Tooltip title='Status'>
                      <Select
                        defaultValue={status || 'All'}
                        disabled={loading}
                        onChange={(value) => this.changeStatus(value)}
                        placeholder='Status'
                        style={{ width: '100%' }}
                      >
                        <Option value='All'>All</Option>
                        {ReviewStatusList.map(({ name, value }) => (
                          <Option value={value}>{name}</Option>
                        ))}
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} rows={list} link={this.canRead() ? '/review/reviews/<review_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} titles`}
            current={currentPage}
            onChange={(page) => this.fetchReviews(page)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeStatus (value) {
    const { currentPage, filter } = this.state

    if (value === 'All') {
      delete filter.status_id
    } else if (value !== undefined) {
      filter.status_id = value
    } else {
      delete filter.status_id
    }

    this.setState({ filter })
    this.fetchReviews(currentPage)
  }

  async fetchReviews (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchReviewsList(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load predefined titles successfully. Please try again later.')
    }
  }

  findReviews (text) {
    const { currentPage, searchText } = this.state
    this.fetchReviews(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createReviews')
  }

  canList () {
    return this.hasAccess('listReviews')
  }

  canRead () {
    return this.hasAccess('readReviews')
  }
}

const mapDispatchToProps = {
  fetchReviewsList
}

const mapStateToProps = (state) => {
  return { ...state.Review }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewList)
