import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_REWARD_MISSIONS, REWARD_MISSIONS_LOADED, REWARD_MISSIONS_FAILED, REWARD_MISSIONS_GROUPS_LOADED, FETCH_REWARD_MISSIONS_GROUP } from '../actions/rewardMission'
import {rewardMissionService} from '../../services'

// Generators
function * fetchRewardMissions (params) {
  try {
    const rewardMissions = yield call(() => rewardMissionService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: REWARD_MISSIONS_LOADED, rewardMissions })
  } catch (e) {
    yield put({ type: REWARD_MISSIONS_FAILED, message: e.message })
  }
}

function * fetchRewardMissionsGroups (params) {
  try {
    const rewardMissions = yield call(() => rewardMissionService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: REWARD_MISSIONS_GROUPS_LOADED, rewardMissions })
  } catch (e) {
    yield put({ type: REWARD_MISSIONS_FAILED, message: e.message })
  }
}


function * watchRewardMission () {
  yield takeEvery(FETCH_REWARD_MISSIONS, fetchRewardMissions)
  yield takeEvery(FETCH_REWARD_MISSIONS_GROUP, fetchRewardMissionsGroups)
}

export default watchRewardMission
