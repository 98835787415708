export const FETCH_SUMMARY = 'FETCH_SUMMARY'
export const SUMMARY_LOADED = 'SUMMARY_LOADED'
export const SUMMARY_FAILED = 'SUMMARY_FAILED'

export const FETCH_CHART = 'FETCH_CHART'
export const CHART_LOADED = 'CHART_LOADED'
export const CHART_FAILED = 'CHART_FAILED'

export const FETCH_KEYWORDS = 'FETCH_KEYWORDS'
export const KEYWORDS_LOADED = 'KEYWORDS_LOADED'
export const KEYWORDS_FAILED = 'KEYWORDS_FAILED'

export const fetchSummary = () => {
  return {
    type: FETCH_SUMMARY
  }
}

export const fetchChart = () => {
  return {
    type: FETCH_CHART
  }
}

export const fetchKeywords = () => {
  return {
    type: FETCH_KEYWORDS
  }
}
