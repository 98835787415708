import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// UI
import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

export default class List extends React.Component {
  constructor (props) {
    super(props)
    const { defaultSort = {} } = props
    this.state = {
      defaultSort,
      pristine: true,
      sorting: {}
    }
  }

  render () {
    const { cols, emptyMessage, hideColumns, highlightIf, link, loading = true, onClick, onDoubleClick, rows, showEmptyMessage = true, cssClassName="" } = this.props
    const { defaultSort, pristine, sorting } = this.state

    const actualLink = (row) => {
      let actualLink = link || ''

      if (actualLink.indexOf('<') >= 0) {
        const key = /<(.*?)>/g.exec(actualLink)
        actualLink = actualLink.replace(/<.*?>/, row[key[1]])
      }

      return actualLink
    }

    const highlight = (row) => {
      if (highlightIf && row[highlightIf.key] === highlightIf.value) {
        return true
      }

      return false
    }

    const listRow = (row) => (
      <Row gutter={8} type='flex' justify='space-around' align='middle'>
        {cols.map((col, idx) => (
          <Col span={col.width} className='wd-list-col' key={idx} style={{ justifyContent: col.align || 'flex-start' }}>
            <div >
              {col.render ? (
                col.render(row)
              ) : col.key ? (
                row[col.key]
              ) : ''}
            </div>
          </Col>
        ))}
      </Row>
    )

    return (
      <div className='wd-list-container'>
        <ul className='wd-list-body'>
          {!hideColumns ? (
            <li className='wd-list-header'>
              <Row gutter={8}>
                {cols.map((col, idx) => {
                  const { key, onSort, title, width, cssClassName="" } = col
                  const sortKey = pristine ? defaultSort[key] : sorting[idx]

                  return typeof onSort === 'function' ? (
                    <Col key={idx} span={width} className={cssClassName}>
                      <div className='wd-list-header-sort' onClick={(e) => this.handleSort(e, idx, key, onSort)}>
                        <span>{title}</span>

                        <span className='wd-list-header-caret'>
                          <span role="img" aria-label='caret-up' className={sortKey === 1 ?'icon icon-sort-asc active':'icon icon-sort-asc'} style={{ color: sortKey === 1 ? 'var(--themeColorAlt)' : 'black' }} > <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-up" width="3em" height="3em" fill="currentColor" aria-hidden="true"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg></span>

                          <span role="img" aria-label='caret-down' className={sortKey === -1 ?'icon icon-sort-desc active':'icon icon-sort-desc'} style={{ color: sortKey === -1 ? 'var(--themeColorAlt)' : 'black' }} > <svg viewBox="0 0 1024 1024" focusable="false" data-icon="caret-down" width="3em" height="3em" fill="currentColor" aria-hidden="true"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg></span>
                        </span>
                      </div>
                    </Col>
                  ) : (
                    <Col key={idx} span={width} className={cssClassName}>{title} </Col>
                  )
                })}
              </Row>
            </li>
          ) : null}

          {showEmptyMessage && (!loading && rows.length < 1) ? (
            <Alert message={emptyMessage || 'The list is empty. Try adding something into it.'} showIcon type='info' />
          ) : null}

          {rows.map((row, idx) => (
            <li className={'wd-list-row' + (highlight(row) ? ' wd-list-row-highlight' : '')} key={idx}>
              {link || onClick ? (
                <Link to={link ? actualLink(row) : '#'} onClick={(e) => this.handleClick(e, row)}>
                  {listRow(row)}
                </Link>
              ) : onDoubleClick ? (
                <Link to={ '#'} onDoubleClick={(e) => this.handleDoubleClick(e, row)}>
                  {listRow(row)}
                </Link>
              ) : listRow(row)} 
            </li>
          ))}

        </ul>
      </div>
    )
  }

  handleClick (e, row) {
    const { onClick } = this.props

    if (typeof onClick === 'function') {
      e.preventDefault()
      onClick(row)
    }
  }

  handleDoubleClick (e, row) {
    const { onDoubleClick } = this.props

    if (typeof onDoubleClick === 'function') {
      e.preventDefault()
      onDoubleClick(row)
    }
  }

  handleSort (e, idx, key, onSort) {
    if (typeof onSort === 'function') {
      const { defaultSort, pristine, sorting } = this.state
      let order = pristine ? defaultSort[key] : sorting[idx] || 0
      order = order === 1 ? -1 : order === -1 ? 0 : 1
      this.setState({ pristine: false, sorting: { [idx]: order } })
      onSort(key, order)
    }
  }
}

List.propTypes = {
  align: PropTypes.string,
  cols: PropTypes.array.isRequired,
  defaultSort: PropTypes.object,
  emptyMessage: PropTypes.string,
  hideColumns: PropTypes.bool,
  highlight: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  rows: PropTypes.array.isRequired,
  showEmptyMessage: PropTypes.bool
}
