import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { authService, celebService, storeService } from '../../../services'
import { notification, validator } from '../../../util'

// UI
import { Button, Input, Page, Panel, Spin, Switch, Select, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm
const Option = Select.Option

class Celeb extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      celebs: [],
      celebValidateStatus: undefined,
      loadingImage: false,
      loadingImageThumb: false,
    }
    //this.checkEmailExists = debounce(this.checkEmailExists, 500)
    this.findCelebs = debounce(this.findCelebs, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.findCelebs()
      this.fetchAllStores()

      if (this.isEdit()) {
        this.fetchCeleb()
      }
    } else {
      authService.unauthorizeAction('/settings/celebs')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 6, md: 4 },
      wrapperCol: { sm: 14, md: 18 }
    }
    const { form, history } = this.props
    const { item, loading, celebs, celebValidateStatus, loadingImage, loadingImageThumb, stores } = this.state
   
    /*let defaultVendor

    if (!this.canSave()) {
      defaultVendor = vendors.find(({ entity_id }) =>  entity_id === item.v_id) || {}
    }*/

    return (
      <Page.Content>
        <Page.Header title='Celeb Details' description='Add new celeb'>
        {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}
          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Basic Information'>
                <div className='slider-panel'>
                  <div className='slider-panel-top'>
                    <div className='slider-column'>
                      <div className='slider-image-title'>Celeb Image</div>
                      <div className='slider-image-uploader'>
                        <UploadHolder
                          img={{
                            alt: 'Celeb Image',
                            src: item.image ? item.image : ''
                          }}
                          loading={loadingImage}
                          readOnly={!this.canSave()}
                          height={200}
                          width={320}
                          upload={{
                            action: '/api/celebs/upload/image',
                            beforeUpload: this.checkImage,
                            data: { _id: item._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image',
                            onChange: this.handleUploadImage,
                            showUploadList: false
                          }}
                        />
                      </div>

                      <div className='slider-image-hint'>
                        Size: 360px x 480px (JPG or PNG) <br />
                        Maximum file size: 2 MB
                      </div>
                    </div>
                    <div className='slider-column'>
                      <div className='slider-image-title'>Celeb Image Thumb</div>
                      <div className='slider-image-uploader'>
                        <UploadHolder
                          img={{
                            alt: 'Celeb Image Thumb',
                            src: item.image_thumb ? item.image_thumb : ''
                          }}
                          loading={loadingImageThumb}
                          readOnly={!this.canSave()}
                          height={200}
                          width={320}
                          upload={{
                            action: '/api/celebs/upload/image_thumb',
                            beforeUpload: this.checkImage,
                            data: { _id: item._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image_thumb',
                            onChange: this.handleUploadImageThumb,
                            showUploadList: false
                          }}
                        />
                      </div>

                      <div className='slider-image-hint'>
                        Size: 238px x 317px (JPG or PNG) <br />
                        Maximum file size: 2 MB
                      </div>
                    </div>

                  </div>
                  <div className='slider-panel-bottom'>
                    <Input
                      fieldDecorator={{
                        id: 'celeb_name',
                        options: {
                          initialValue: item.celeb_name,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Celeb Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      
                    />
                    <Select
                      fieldDecorator={{
                        id: 'vendor_id',
                        options: {
                          initialValue: item.vendor_id,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Vendor',
                        hasFeedback: false
                      }}
                      list={stores}
                      listFunc={(store) => {
                        return <Option className='text-wrap' key={store.id} value={store.id}>{(store.name !== null ? store.name : '') + ' (' + store.vendor_id + ')' }</Option>
                      }}
                      select={{
                        placeholder: 'Select a vendor',
                        showSearch: true,
                        style: { width: '100%' },
                        filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    />
                    <Input
                      fieldDecorator={{
                        id: 'tags',
                        options: {
                          initialValue: item.tags,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Tags'
                      }}
                      input={{
                        tabIndex: 6
                      }}
              
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'celeb_desc',
                        options: {
                          initialValue: item.celeb_desc,
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Description'
                      }}
                      input={{
                        autosize: { minRows: 2, maxRows: 10 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />             
                    {this.getPositionSelect(formItemLayout, this.isEdit(), item.position)}
                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Active'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 4
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'featured',
                        options: {
                          initialValue: item.featured === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Featured'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 4
                      }}
                      readOnly={!this.canSave()}
                    />
                  </div>
                </div>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }


  /*checkEmail (rule, value, callback) {
    if (value && value.length > 0 && !validator.isEmail(value)) {
      callback('E-mail is invalid')
    }

    this.checkEmailExists(rule, value, callback)
  }*/


  /*checkInteger (rule, value, callback) {
    const { item } = this.state
    
    if (item.minPrice == null && item.min_quantity == null) {
      callback('At least one field must be filled')
    }
    else if (item.min_quantity == null && item.minPrice != null) {
        let decimal = ( /^\d+(.\d{0,2})?$/)
        decimal.test(value)
        callback('Min. Quantity must be decimal')
      }
    else if (item.minPrice == null && item.min_quantity) {
        let integer = (/^[0-9\b]+$/)
        integer.test(value)
        callback('Min. Quantity must be integer')
      }
    
    callback()
  }*/

  checkInteger (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^[0-9\b]+$/).test(value)) {
      callback('Min. Quantity must be integer')
    }
    callback()
  }

  checkDecimal (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^\d+(.\d{0,2})?$/).test(value)) {
      callback('Min. Price(RM) must be decimal')
    }
    callback()
  }

  /*checkEmailExists (rule, value, callback) {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const { email } = this.state.item
        const response = await adminService.checkEmail(value)

        if (response.exists !== false && email !== value) {
          callback('E-mail already exists')
        }
      }

      callback()
    })
  }*/

  async fetchCeleb () {
    try {
      this.setState({ loading: true })
      const item = await celebService.get(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load celeb successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async findCelebs (value) {
    try {
      this.setState({ celebValidateStatus: 'validating' })
      celebService
        .findByName(100, value || '')
        .then((celebs) => {
          this.setState({ celebs, celebValidateStatus: undefined })
        } )
    } catch (e) {
      console.error(e)
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this celeb?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await celebService.remove(match.params.id)

          if (response._id) {
            notification.show('success', 'Deleted successfully', 'Celeb deleted successfully.')
            history.replace('/settings/celebs')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete celeb successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history, celebs } = this.props
    const { validateFields } = this.props.form
    const { data, total} = celebs

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })
        try {
          values.image = item.image ? (item.image.url || item.image) : ''
          values.image_thumb = item.image ? (item.image.thumbUrl || item.image_thumb) : ''
          values.active = values.active ? 1 : 0
          values.featured = values.featured ? 1 : 0

          if (this.isEdit()) {
            let id = item._id
            let oldPosition = item.position || 0
            const { position: newPosition } = values
            const positionChanged = newPosition !== oldPosition
            values.positionChanged = positionChanged

            if (positionChanged) {
              values.list = data.map((item, idx) => {
                const { _id, position: itemPosition } = item
                const newItem = { id: _id }
                if (_id === id) {
                  newItem.position = newPosition
                } else {
                  if (newPosition < oldPosition) {
                    if (itemPosition < newPosition || itemPosition > oldPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx + 2
                    }
                  } else {
                    if (itemPosition < oldPosition || itemPosition > newPosition) {
                      newItem.position = idx + 1
                    } else {
                      newItem.position = idx
                    }
                  }
                }

                return newItem
              })
            }
            const response = await celebService.save(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })
            if (response._id > 0 && response.countVendor <= 0) {
              notification.show('success', 'Saved successfully', 'Celeb saved successfully.')
            } else if (response._id > 0  && response.countVendor > 0) {
              notification.show('error', 'Unable to save successfully', 'Vendor exists.')
            }
           
          } else {
              const { position: newPosition } = values
              const positionChanged = newPosition < total + 1
              values.positionChanged = positionChanged

              if (positionChanged) {
                values.list = data.map((item, idx) => {
                  const { _id, position: itemPosition } = item
                  return { id: _id, position: itemPosition < newPosition ? idx + 1 : idx + 2 }
                })
              }

              const response = await celebService.add(values)
              this.setState({ loading: false })

              if (response._id > 0) {
                this.setState({ item: { ...item, ...values, _id: response._id } })
                notification.show('success', 'Saved successfully', 'Celeb saved successfully.')
                history.replace(`/settings/celebs/${response._id}`)
              }
              else if (response.countVendor > 0) {
                notification.show('error', 'Unable to save successfully', 'Vendor exists.')
              }
            }
           
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save settings successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  async fetchAllStores () {
    try {
      this.setState({ loading: true })
      const item = await storeService.getAllVendors()
      this.setState({ stores: item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load store successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  getPositionSelect = (formShortItemLayout, isEdit, position) => {
    
    const { form, celebs } = this.props
    const { total } = celebs
    const defaultPosition = isEdit ? position : total + 1
    const list = []
  
    for (let i = 1; i <= total; i++) {
      list.push(i)
    }

    return (
      <Select
        fieldDecorator={{
          id: 'position',
          options: {
            initialValue: defaultPosition
          }
        }}
        form={form}
        formItem={{
          ...formShortItemLayout,
          hasFeedback: false,
          label: 'Position'
        }}
        list={list}
        listFunc={(position) => {
          return <Option key={position} value={position}>{position}</Option>
        }}
        select={{
          showSearch: true,
          placeholder: 'Select a position'
        }}
      />
    )
  }

  checkImage = (file) => {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  handleUploadImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  handleUploadImageThumb = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImageThumb: true })
    if (status === 'done') {
      const { item } = this.state
      item.image_thumb = response.url
      this.setState({ item, loadingImageThumb: false })
    } else {
      console.log('status', status)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteCeleb')
  }

  canRead () {
    return this.hasAccess('readCeleb')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateCeleb' : 'createCeleb')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Celeb }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Celeb))
