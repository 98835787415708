export default {
  NONE: 'none',
  BRAND: 'brand',
  CATEGORY: 'category',
  COLLECTION: 'collection',
  STORE: 'store',
  PRODUCT: 'product',
  CUSTOM_PAGE: 'url'
}

export const BannerLinkTypesList = [
  { name: 'None', value: 'none' },
  { name: 'Brand', value: 'brand' },
  { name: 'Category', value: 'category' },
  { name: 'Collection', value: 'collection' },
  { name: 'Store', value: 'store' },
  { name: 'Product', value: 'product' },
  { name: 'Custom Page', value: 'url' }
]
