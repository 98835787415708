export const CELEBS_FETCHED = 'CELEBS_FETCHED'
export const CELEB_SET_PRISTINE = 'CELEB_SET_PRISTINE'

export const celebsFetched = (celebs) => {
  return {
    type: CELEBS_FETCHED,
    celebs
  }
}

export const celebSetPristine = (pristine) => {
  return {
    type: CELEB_SET_PRISTINE,
    pristine
  }
}

