import moment from 'moment'
import numeral from 'numeral'

export default {
  capitalize (str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.split(' ')
      return words.map((word) => word.charAt(0).toUpperCase() + (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1))).join(' ')
    }

    return ''
  },
  toClassName (arr) {
    if (arr && arr.length > 0) {
      return arr.filter((str) => typeof str === 'string' && str).join(' ')
    }

    return ''
  },
  toDate (str, format) {
    if ((typeof str === 'string' || str) && typeof format === 'string') {
      return moment(str).format(format)
    }

    return ''
  },
  toISOString (str) {
    return this.toDate(str, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
  },
  toPrice (number, currency = 'RM') {
    if (typeof number === 'number') {
      return `${currency} ${numeral(number).format('0,0.00')}`
    }

    return 0
  },
  toLocalDate (str, format) {
    if ((typeof str === 'string' || str) && typeof format === 'string') {
      return moment.parseZone(str).local().format(format)
    }

    return ''
  },
  toLocalStandardDate (str, showSeconds = false) {
    return this.toLocalDate(str, `D MMM YYYY hh:mm${showSeconds ? ':ss' : ''} A`)
  },
  toShortDate (str) {
    return this.toDate(str, 'D MMM YYYY')
  },
  toStandardDate (str) {
    return this.toDate(str, 'D MMM YYYY hh:mm A')
  },
  toUrl (url) {
    return url.toLowerCase().replace(/\s+/g, '-')
  },
  toShortenText (str, range = 30) {
    let length = str.length
    let index = str.indexOf(' ', range)
    index = index === -1 ? length : index
    return length <= range ? str : str.substring(0, index) + (index === length ? '' : '...')
  },
  toNotApplicableText (str, text) {
    if (/NN\/AA/.test(str)) {
      return `(No ${this.capitalize(text)})`
    }

    return str
  }
}
