export const FETCH_CARTPRICERULES = 'FETCH_CARTPRICERULES'
export const CARTPRICERULES_LOADED = 'CARTPRICERULES_LOADED'
export const CARTPRICERULES_FAILED = 'CARTPRICERULES_FAILED'
export const CARTPRICERULES_GROUPS_LOADED = 'CARTPRICERULES_GROUPS_LOADED'
export const FETCH_CARTPRICERULES_GROUP = 'FETCH_CARTPRICERULES_GROUP'

export const fetchCartPriceRuleGroups = (page, size, filter, query) => {
  return {
    type: FETCH_CARTPRICERULES_GROUP,
    page,
    size,
    filter,
    query
  }
}

export const fetchCartPriceRules = (page, size, filter, query) => {
  return {
    type: FETCH_CARTPRICERULES,
    page,
    size,
    filter,
    query
  }
}
