import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { authService, moduleService, permissionService, roleService } from '../../../services'
import { notification } from '../../../util'

// UI
import { Button, Input, Page, Panel, Spin, Switch } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm

class Role extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      modules: []
    }
    this.checkNameExists = debounce(this.checkNameExists, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      if (this.isEdit()) {
        this.fetchRole()
      } else {
        this.fetchModules()
      }
    } else {
      authService.unauthorizeAction('/settings/roles')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 7, md: 7 },
      wrapperCol: { sm: 15, md: 15 }
    }
    const { form, history } = this.props
    const { item, loading, modules } = this.state
    const { getFieldDecorator } = form

    return (
      <Page.Content>
        <Page.Header title='Role Details' description='Enter role name & set permissions'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={this.handleDelete}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={this.handleSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Basic Information'>
                <Row>
                  <Col lg={12}>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 128 characters' },
                            { max: 128, message: 'Name must be between 2 and 128 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' },
                            { validator: this.checkNameExists }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />
                  </Col>

                  <Col lg={12}>
                    <Input
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Remark cannot contain only spaces' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />
                  </Col>
                </Row>
              </Panel>

              <Panel title='Permission Information'>
                <div className='wd-role-permission-list'>
                  <Row gutter={8}>
                    <Col className='header' xs={24} md={24} lg={9}>Module</Col>

                    <Col className='header' xs={8} md={5} lg={3}>List</Col>

                    <Col className='header' xs={8} md={5} lg={3}>Create</Col>

                    <Col className='header' xs={8} md={5} lg={3}>Read</Col>

                    <Col className='header' xs={8} md={5} lg={3}>Update</Col>

                    <Col className='header' xs={8} md={4} lg={3}>Delete</Col>
                  </Row>

                  {modules.map((module, midx) => {
                    const { _id, name, description, permission } = module

                    return (
                      <Row key={_id} gutter={8}>
                        <Col className='content' xs={24} md={24} lg={9}>
                          {getFieldDecorator(`modules[${midx}]._id`, {
                            initialValue: _id
                          })(
                            <input type='hidden' />
                          )}

                          {getFieldDecorator(`modules[${midx}].permission._id`, {
                            initialValue: permission._id
                          })(
                            <input type='hidden' />
                          )}

                          <div className='title'>{name}</div>

                          <div className='subtitle'>{description}</div>
                        </Col>

                        <Col xs={8} md={5} lg={3}>
                          <Switch
                            fieldDecorator={{
                              id: `modules[${midx}].permission.can_list`,
                              options: {
                                initialValue: typeof permission.can_list !== 'undefined' ? !!permission.can_list : true,
                                valuePropName: 'checked'
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              label: ''
                            }}
                            input={{
                              checkedChildren: 'Yes',
                              unCheckedChildren: 'No',
                              tabIndex: 3
                            }}
                            readOnly={!this.canSave()}
                            tooltip={`Can view '${name} Listing' when set to 'Yes'`}
                          />
                        </Col>

                        <Col xs={8} md={5} lg={3}>
                          <Switch
                            fieldDecorator={{
                              id: `modules[${midx}].permission.can_create`,
                              options: {
                                initialValue: typeof permission.can_create !== 'undefined' ? !!permission.can_create : true,
                                valuePropName: 'checked'
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              label: ''
                            }}
                            input={{
                              checkedChildren: 'Yes',
                              unCheckedChildren: 'No',
                              tabIndex: 4
                            }}
                            readOnly={!this.canSave()}
                            tooltip={`Can create '${name}' when set to 'Yes'`}
                          />
                        </Col>

                        <Col xs={8} md={5} lg={3}>
                          <Switch
                            fieldDecorator={{
                              id: `modules[${midx}].permission.can_read`,
                              options: {
                                initialValue: typeof permission.can_read !== 'undefined' ? !!permission.can_read : true,
                                valuePropName: 'checked'
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              label: ''
                            }}
                            input={{
                              checkedChildren: 'Yes',
                              unCheckedChildren: 'No',
                              tabIndex: 5
                            }}
                            readOnly={!this.canSave()}
                            tooltip={`Can read '${name}' when set to 'Yes'`}
                          />
                        </Col>

                        <Col xs={8} md={5} lg={3}>
                          <Switch
                            fieldDecorator={{
                              id: `modules[${midx}].permission.can_update`,
                              options: {
                                initialValue: typeof permission.can_update !== 'undefined' ? !!permission.can_update : true,
                                valuePropName: 'checked'
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              label: ''
                            }}
                            input={{
                              checkedChildren: 'Yes',
                              unCheckedChildren: 'No',
                              tabIndex: 6
                            }}
                            readOnly={!this.canSave()}
                            tooltip={`Can update '${name}' when set to 'Yes'`}
                          />
                        </Col>

                        <Col xs={8} md={4} lg={3}>
                          <Switch
                            fieldDecorator={{
                              id: `modules[${midx}].permission.can_delete`,
                              options: {
                                initialValue: typeof permission.can_delete !== 'undefined' ? !!permission.can_delete : true,
                                valuePropName: 'checked'
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              label: ''
                            }}
                            input={{
                              checkedChildren: 'Yes',
                              unCheckedChildren: 'No',
                              tabIndex: 7
                            }}
                            readOnly={!this.canSave()}
                            tooltip={`Can delete '${name}' when set to 'Yes'`}
                          />
                        </Col>
                      </Row>
                    )
                  })}
                </div>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkNameExists = (rule, value, callback) => {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const { name } = this.state.item
        const response = await roleService.checkName(value)

        if (response.exists !== false && name !== value) {
          callback(new Error('Name already exists'))
        }
      }

      callback()
    })
  }

  fetchRole = async () => {
    try {
      this.setState({ loading: true })
      const { match } = this.props
      const { id } = match.params
      const [item, { data: modules }, { data: permissions }] = await Promise.all([
        roleService.get(id),
        moduleService.listByPage(1, 0, {}),
        permissionService.listByPage(1, 0, { role_id: id })
      ])
      modules.map((module) => {
        const permission = permissions.find(({ module_id: moduleId }) => moduleId === module._id)
        module.permission = permission
        return module
      })
      this.setState({ item, loading: false, modules })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load role successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchModules = async () => {
    try {
      moduleService
        .listByPage(1, 0, {})
        .then((response) => {
          const { data } = response
          this.setState({ modules: data.map((module) => {
            module.permission = { can_list: 0, can_create: 0, can_read: 0, can_update: 0, can_delete: 0 }
            return module
          }) })
        })
    } catch (e) {
      console.error(e)
    }
  }

  handleDelete = () => {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this role?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await roleService.remove(match.params.id)

          if (response._id) {
            notification.show('success', 'Deleted successfully', 'Role deleted successfully.')
            history.replace('/settings/roles')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete role successfully. Please try again later.')
        }
      }
    })
  }

  handleSave = () => {
    const { form, history } = this.props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })

        try {
          if (this.isEdit()) {
            const response = await roleService.save(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Role saved successfully.')
            }
          } else {
            const response = await roleService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              const { setFieldsValue } = form
              const { _id, permissions } = response
              const { modules } = values
              values.modules = modules.map((module) => {
                const permission = permissions.find(({ module_id: moduleId }) => moduleId === module._id)
                module.permission._id = permission._id
                return module
              })
              setFieldsValue({ modules: values.modules })
              this.setState({ item: { ...item, ...values, _id } })
              notification.show('success', 'Saved successfully', 'Role saved successfully.')
              history.replace(`/settings/roles/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save role successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canDelete = () => {
    return this.props.match.params.id !== '1' && this.hasAccess('deleteRole')
  }

  canRead = () => {
    return this.hasAccess('readRole')
  }

  canSave = () => {
    return this.hasAccess(this.isEdit() ? 'updateRole' : 'createRole')
  }

  isEdit = () => {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Role }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Role))
