import { REDEMPTIONS_LOADED } from '../actions/redemption'

const INITIAL_STATE = {
  redemptions: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REDEMPTIONS_LOADED:
      return { ...state, redemptions: { list: action.redemptions.data, total: action.redemptions.total } }
    default:
      return state
  }
}
