export const FETCH_FEATURED_ON = 'FETCH_FEATURED_ON'
export const FEATURED_ON_LOADED = 'FEATURED_ON_LOADED'
export const FEATURED_ON_FAILED = 'FEATURED_ON_FAILED'

export const fetchFeaturedOn = (page, size, filter, query) => {
  return {
    type: FETCH_FEATURED_ON,
    page,
    size,
    filter,
    query
  }
}
