import React, { Component } from 'react'
import { connect } from 'react-redux'
import ColoPicker from 'rc-color-picker'

import authService from '../../../../services/auth'
import collectionService from '../../../../services/collection'
import brandService from '../../../../services/brand'
import notification from '../../../../util/notification'
import validator from '../../../../util/validator'
import CollectionLayoutTypes from '../../../../constants/collection/layouts'
import { fetchAllCategories, fetchAllStores, fetchTemplates } from '../../../../states/actions'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch } from '../../../../components'
import UploadHolder from '../../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'
import 'rc-color-picker/assets/index.css'

const confirm = Modal.confirm
const Option = Select.Option

const LinkMethod = [
  {
    name: 'Brand',
    value: 'brand'
  },
  {
    name: 'Category',
    value: 'category'
  },
  {
    name: 'Collection Page',
    value: 'template'
  },
  {
    name: 'Custom Page (External)',
    value: 'url'
  },
  {
    name: 'Store',
    value: 'store'
  }
]

class CollectionPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brands: [],
      columns: [],
      item: { products: [], dateRange: {} },
      items: [],
      loading: false,
      loadingImage: false,
      organizations: [],
      selectedColor: null,
      selectedLayout: 'c-2-2',
      selectedColumn: {
        title: '',
        description: '',
        image: '',
        color: '',
        link_type: '',
        link_value: '',
        position_col: 0,
        position_row: 0
      },
      showColumnModal: false,
      showProductList: false,
      showPageInput: false,
      vendors: []
    }
  }

  componentDidMount () {
    const { selectedLayout } = this.state

    if (this.canRead()) {
      if (this.isEdit()) {
        this.fetchLayout()
      } else {
        const matched = CollectionLayoutTypes.filter(l => l.value === selectedLayout)
        this.setState({ columns: [], items: [], item: {} })
        this.joinColumns(matched[0].columns, [])
        this.props.form.resetFields()
      }
      this.fetchAllBrands()
      this.fetchAllStores()
      this.fetchTemplates()
      this.fetchCategories()
    } else {
      authService.unauthorizeAction('/store')
    }
  }

  selectColor (color) {
    this.setState({ selectedColor: color.color })
  }

  render () {
    const { categories, form, history, templates, all_stores: stores } = this.props
    const { item, loading, loadingImage, selectedColor = '#174B9D', selectedColumn, selectedLayout, showColumnModal, columns = { left: [], right: [] }, brands } = this.state
    const formItemLayout = {
      labelCol: { sm: 4, md: 4 },
      wrapperCol: { sm: 19, md: 19 }
    }

    const renderColumn = (column, isFullColumn, index) => {
      return <div key={`${column.title}${index}`} className={isFullColumn ? 'layout-column-quarter' : 'layout-column-full'}
        onClick={() => this.toggleColumnModal(column)}>
        <div className='title'>{ column.title && column.title !== '' ? column.title : 'Title' }</div>
        <div className='desc'>{ column.description && column.description !== '' ? column.description : 'Description' }</div>
        <img className='image' alt='' src={column.image || '/img/default-image.png'} />
      </div>
    }

    return (
      <Page.Content>
        <Page.Header title='Mobile Tile Layout' description='Choose a layout and assign the collection/link to each column'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Layout Details'>
                <Row>
                  <Col lg={18}>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 128 characters' },
                            { max: 128, message: 'Name must be between 2 and 128 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Select
                      fieldDecorator={{
                        id: 'layout',
                        options: {
                          initialValue: item.layout || selectedLayout,
                          rules: [
                            { required: true, message: 'Please select a layout' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        hasFeedback: false,
                        label: 'Layout'
                      }}
                      readOnly={!this.canSave()}
                      list={CollectionLayoutTypes}
                      listFunc={(layout) => {
                        return <Option key={layout.value} value={layout.value}>{(layout.name !== null ? layout.name : '') }</Option>
                      }}
                      select={{
                        onChange: (value) => this.changeLayout(value)
                      }}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active && item.active === 1,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Active'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 7
                      }}
                      readOnly={!this.canSave()}
                    />

                  </Col>
                </Row>
              </Panel>
              <div className='layout-section-title'>Columns Configuration</div>
              <div className='layout-image-hint'>
                Size: 400px x 400px (JPG or PNG), Maximum file size: 2 MB
              </div>

              <div className='layout-container'>
                <div className='layout-container-half'>
                  { columns.filter(col => col.side === 'left').map((col, index) => (
                    renderColumn(col, columns.filter(col => col.side === 'left').length > 1, index)
                  )) }
                </div>
                <div className='layout-container-half'>
                  { columns.filter(col => col.side === 'right').map((col, index) => (
                    renderColumn(col, columns.filter(col => col.side === 'right').length > 1, index)
                  )) }
                </div>
              </div>

              <Modal visible={showColumnModal}
                width={860}
                title='Configuration' onOk={() => this.toggleColumnModal()}
                onCancel={() => this.toggleColumnModal()}
                footer={[
                  <Button key='back' onClick={() => this.toggleColumnModal()}>Cancel</Button>,
                  this.canSave() ? <Button key='submit' type='primary' onClick={() => this.saveColumn()}>Apply</Button> : null
                ]}>
                <Form layout='vertical'>
                  <Row gutter={16}>
                    <Col offset={1} md={6}>
                      <div style={{marginTop: '25px'}}>
                        <UploadHolder
                          img={{
                            alt: 'Image',
                            src: selectedColumn.image || ''
                          }}
                          loading={loadingImage}
                          readOnly={!this.canSave()}
                          height={180}
                          width={180}
                          upload={{
                            action: '/api/collections/upload/image',
                            beforeUpload: (file) => this.checkImage(file),
                            data: { _id: selectedColumn._id },
                            disabled: loading,
                            headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                            name: 'image',
                            onChange: (info) => this.handleUploadImage(info),
                            showUploadList: false
                          }}
                        />
                      </div>
                    </Col>
                    <Col offset={2} md={15}>
                      <Input
                        fieldDecorator={{
                          id: 'title',
                          options: {
                            // initialValue: selectedColumn.title,
                            rules: [
                              { min: 2, message: 'Title must be between 2 and 128 characters' },
                              { max: 128, message: 'Title must be between 2 and 128 characters' },
                              { required: true, message: 'Please enter Title' },
                              { whitespace: true, message: 'Please enter Title' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Title'
                        }}
                        input={{
                          tabIndex: 1
                        }}
                        readOnly={!this.canSave()}
                      />

                      <Input
                        fieldDecorator={{
                          id: 'description',
                          options: {
                            // initialValue: selectedColumn.description,
                            rules: [
                              { min: 2, message: 'Description must be between 2 and 128 characters' },
                              { max: 128, message: 'Description must be between 2 and 128 characters' },
                              { whitespace: true, message: 'Please enter Description' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Description'
                        }}
                        input={{
                          tabIndex: 1
                        }}
                        readOnly={!this.canSave()}
                      />

                      {/* <Select
                        fieldDecorator={{
                          id: 'link_type',
                          options: {
                            initialValue: selectedColumn.link_type || '',
                            rules: [
                              { required: true, message: 'Please select a link type' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          hasFeedback: false,
                          label: 'Link To'
                        }}
                        readOnly={!this.canSave()}
                        list={LinkMethod}
                        listFunc={(link) => {
                          return <Option key={link.value} value={link.value}>{(link.name !== null ? link.name : '') }</Option>
                        }}
                        select={{
                          onChange: (value) => this.changeLinkType(value)
                        }}
                      /> */}

                      {/* { selectedColumn.link_type === 'url'
                        ? <Input
                          fieldDecorator={{
                            id: 'url_value',
                            options: {
                              initialValue: selectedColumn.link_value || '',
                              rules: [
                                { min: 1, message: 'URL must be between 1 and 128 characters' },
                                { max: 128, message: 'URL must be between 1 and 128 characters' },
                                { required: true, message: 'Please enter URL' },
                                { whitespace: true, message: 'Please enter URL' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            label: 'URL'
                          }}
                          input={{
                            tabIndex: 4
                          }}
                          readOnly={!this.canSave()}
                        />
                        : selectedColumn.link_type === 'template'
                          ? <Select
                            fieldDecorator={{
                              id: 'template_value',
                              options: {
                                initialValue: this.getTemplateName(selectedColumn.link_value) || '',
                                rules: [
                                  { required: true, message: 'Please select a page' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              hasFeedback: false,
                              label: 'Page'
                            }}
                            readOnly={!this.canSave()}
                            list={templates.list}
                            listFunc={(template) => {
                              return <Option key={template._id} value={template._id}>{(template.name !== null ? template.name : '') }</Option>
                            }}

                          />
                          : selectedColumn.link_type === 'brand'
                            ? <Select
                              fieldDecorator={{
                                id: 'brand_value',
                                options: {
                                  initialValue: parseInt(selectedColumn.link_value, 10) || '',
                                  rules: [
                                    { required: true, message: 'Please select a brand' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                hasFeedback: false,
                                label: 'Brand'
                              }}
                              readOnly={!this.canSave()}
                              list={brands}
                              listFunc={(brand) => {
                                return <Option key={brand.brand_id} value={brand.option_id}>{(brand.name !== null ? brand.name : '') }</Option>
                              }}
                            />
                            : <Select
                              fieldDecorator={{
                                id: 'category_value',
                                options: {
                                  initialValue: this.getCategoryName(selectedColumn.link_value) || '',
                                  rules: [
                                    { required: true, message: 'Please select a category' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                hasFeedback: false,
                                label: 'Category'
                              }}
                              readOnly={!this.canSave()}
                              list={categories}
                              listFunc={(category) => {
                                return <Option key={category.id} value={category.id}>{(category.name !== null ? category.name : '') }</Option>
                              }}

                            />
                      } */}

                      <Select
                        fieldDecorator={{
                          id: 'link_type',
                          options: {
                            // initialValue: selectedColumn.link_type,
                            rules: [
                              { required: true, message: 'Please select a link type' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          hasFeedback: false,
                          label: 'Link To'
                        }}
                        readOnly={!this.canSave()}
                        list={LinkMethod}
                        listFunc={({ name, value }) => {
                          return <Option key={value} value={value}>{name}</Option>
                        }}
                        select={{
                          onChange: (value) => this.changeLinkType(value)
                        }}
                      />

                      {selectedColumn.link_type === 'brand' ? (
                        <Select
                          fieldDecorator={{
                            id: 'brand_value',
                            options: {
                              // initialValue: parseInt(selectedColumn.link_value, 10) || undefined,
                              rules: [
                                { required: true, message: 'Please select a brand' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            hasFeedback: false,
                            label: 'Brand'
                          }}
                          readOnly={!this.canSave()}
                          list={brands}
                          listFunc={({ brand_id: bid, option_id: oid, name }) => {
                            return <Option key={bid} value={oid}>{name}</Option>
                          }}
                          select={{
                            placeholder: 'Select a brand',
                            showSearch: true,
                            style: { width: '100%' },
                            filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }}
                        />
                      ) : selectedColumn.link_type === 'category' ? (
                        <Select
                          fieldDecorator={{
                            id: 'category_value',
                            options: {
                              // initialValue: parseInt(selectedColumn.link_value, 10) || undefined,
                              rules: [
                                { required: true, message: 'Please select a category' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            hasFeedback: false,
                            label: 'Category'
                          }}
                          readOnly={!this.canSave()}
                          list={categories}
                          listFunc={({ id, name }) => {
                            return <Option key={id} value={id}>{name}</Option>
                          }}
                        />
                      ) : selectedColumn.link_type === 'template' ? (
                        <Select
                          fieldDecorator={{
                            id: 'template_value',
                            options: {
                              // initialValue: parseInt(selectedColumn.link_value, 10) || undefined,
                              rules: [
                                { required: true, message: 'Please select a page' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            hasFeedback: false,
                            label: 'Page'
                          }}
                          readOnly={!this.canSave()}
                          list={templates.list}
                          listFunc={({ _id, name }) => {
                            return <Option key={_id} value={_id}>{name}</Option>
                          }}
                        />
                      ) : selectedColumn.link_type === 'url' ? (
                        <Input
                          fieldDecorator={{
                            id: 'url_value',
                            options: {
                              // initialValue: selectedColumn.link_value,
                              rules: [
                                { min: 1, message: 'URL must be between 1 and 128 characters' },
                                { max: 128, message: 'URL must be between 1 and 128 characters' },
                                { required: true, message: 'Please enter URL' },
                                { whitespace: true, message: 'Please enter URL' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            label: 'URL'
                          }}
                          input={{
                            tabIndex: 4
                          }}
                          readOnly={!this.canSave()}
                        />
                      ) : selectedColumn.link_type === 'store' ? (
                        <Select
                          fieldDecorator={{
                            id: 'store_value',
                            options: {
                              rules: [
                                { required: true, message: 'Please select a store' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            hasFeedback: false,
                            label: 'Store'
                          }}
                          readOnly={!this.canSave()}
                          list={stores}
                          listFunc={({ id, vendor_id: vid, name }) => {
                            return <Option key={id} value={id}>{(name !== null ? name : '') + ' (' + vid + ') '}</Option>
                          }}
                          select={{
                            placeholder: 'Select a store',
                            showSearch: true,
                            style: { width: '100%' },
                            filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }}
                        />
                      ) : null}

                      <Form.Item label='Color' labelCol={{ sm: 4, md: 4 }} wrapperCol={{ sm: 19, md: 19 }}>
                        <ColoPicker color={selectedColor} placement='bottomLeft' onChange={(color) => this.selectColor(color)} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  async changeLayout (value) {
    const { items } = this.state
    const selectedLayout = CollectionLayoutTypes.filter(layout => layout.value === value)

    this.setState({ selectedLayout: selectedLayout[0].value })

    if (items.length > 0) {
      this.joinColumns(selectedLayout[0].columns, items)
    } else {
      const columns = selectedLayout[0].columns
      this.setState({ columns })
    }
  }

  // changeLinkType (value) {
  //   const { selectedColumn } = this.state
  //   const { categories, templates } = this.props
  //   const { setFieldsValue } = this.props.form

  //   selectedColumn.link_type = value

  //   if (value === 'template') {
  //     selectedColumn.link_value = templates.list[0].name
  //     setFieldsValue({
  //       template_value: 1
  //     })
  //   } else if (value === 'category') {
  //     // console.log('categories', categories)
  //     // selectedColumn.link_value = categories ? categories.name : ''
  //     setFieldsValue({
  //       category_value: 1
  //     })
  //   } else {
  //     selectedColumn.link_value = 'http://'
  //     setFieldsValue({
  //       url_value: 'http://'
  //     })
  //   }

  //   this.setState({selectedColumn})
  // }

  changeLinkType (value) {
    const { selectedColumn } = this.state
    const { setFieldsValue } = this.props.form
    selectedColumn.link_type = value

    if (value === 'url') {
      // selectedColumn.link_value = 'http://'
      setFieldsValue({
        url_value: 'http://'
      })
    } else if (value === 'brand') {
      // selectedColumn.link_value = undefined
      setFieldsValue({
        brand_value: undefined
      })
    } else if (value === 'category') {
      // selectedColumn.link_value = undefined
      setFieldsValue({
        category_value: undefined
      })
    } else if (value === 'template') {
      // selectedColumn.link_value = undefined
      setFieldsValue({
        template_value: undefined
      })
    } else if (value === 'store') {
      // selectedColumn.link_value = undefined
      setFieldsValue({
        store_value: undefined
      })
    }

    this.setState({ selectedColumn })
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }
    return true
  }

  async fetchLayout () {
    try {
      this.setState({ loading: true })

      const item = await collectionService.get(this.props.match.params.id)
      const items = await collectionService.getColumns(this.props.match.params.id)

      const matched = CollectionLayoutTypes.filter(l => l.value === item.layout)
      const columns = matched[0].columns

      this.joinColumns(columns, items)

      this.setState({ item, items, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load tile layout successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchAllStores = async () => {
    try {
      this.props.fetchAllStores()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load stores successfully. Please try again later.')
    }
  }

  fetchAllBrands = async () => {
    try {
      const item = await brandService.getAllBrands()
      this.setState({ brands: item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
    }
  }

  fetchTemplates () {
    try {
      this.props.fetchTemplates(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load templates successfully. Please try again later.')
    }
  }

  fetchCategories () {
    try {
      this.props.fetchAllCategories()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  joinColumns (columns, configuredColumns = []) {
    if (configuredColumns && configuredColumns.length > 0) {
      columns.map((col, index) => {
        const matched = configuredColumns.filter(i => (i.position_col === col.position_col && i.position_row === col.position_row))

        if (matched.length > 0) {
          col._id = matched[0]._id
          col.title = matched[0].title
          col.description = matched[0].description
          col.color = matched[0].color
          col.image = matched[0].image
          col.link_type = matched[0].link_type
          col.link_value = matched[0].link_value
        }

        return col
      })
    }

    this.setState({ columns })
  }

  getTemplateName (value) {
    const { templates } = this.props

    for (var i = 0; i < templates.list.length; i++) {
      const template = templates.list[i]
      if (template._id.toString() === value.toString()) {
        return template.name
      }
    }
  }

  getCategoryName (value) {
    const { categories } = this.props
    value = value || ''

    for (var i = 0; i < categories.length; i++) {
      const category = categories[i]

      if (category.id.toString() === value.toString()) {
        return category.name
      }
    }
  }

  saveColumn () {
    const { columns, selectedColor = '#174B9D', selectedColumn, selectedPosition } = this.state
    const { validateFields } = this.props.form

    validateFields((err, values) => {
      if (err) {
        return
      }

      columns.map((col, index) => {
        const matched = col.position_col === selectedPosition.position_col && col.position_row === selectedPosition.position_row

        if (matched) {
          col.title = values.title
          col.description = values.description
          col.color = selectedColor
          col.link_type = values.link_type

          if (values.link_type === 'brand') {
            col.link_value = values.brand_value
          } else if (values.link_type === 'category') {
            if (parseInt(values.category_value, 10)) {
              col.link_value = values.category_value
            } else {
              col.link_value = selectedColumn.link_value
            }
          } else if (values.link_type === 'template') {
            col.link_value = values.template_value
          } else if (values.link_type === 'url') {
            col.link_value = values.url_value
          } else if (values.link_type === 'store') {
            col.link_value = values.store_value
          }
        }

        return col
      })
      this.setState({ columns })
      this.toggleColumnModal()
    })
  }

  async toggleColumnModal (params) {
    this.setState({
      showColumnModal: !this.state.showColumnModal
    })

    const selectedColumn = {
      ...params
    }

    if (params) {
      const { setFieldsValue } = this.props.form

      this.setState({
        selectedColumn,
        selectedColor: params.color || '#174B9D',
        selectedLinkValue: params.link_value,
        selectedPosition: {
          position_row: params.position_row,
          position_col: params.position_col
        }
      })

      setTimeout(async () => {
        const { description, link_type, link_value, title } = params
        setFieldsValue({ title, description, link_type })

        if (link_type === 'brand') {
          setFieldsValue({ brand_value: parseInt(link_value, 10) })
        } else if (link_type === 'category') {
          setFieldsValue({ category_value: parseInt(link_value, 10) })
        } else if (link_type === 'template') {
          setFieldsValue({ template_value: parseInt(link_value, 10) })
        } else if (link_type === 'url') {
          setFieldsValue({ url_value: link_value })
        } else if (link_type === 'store') {
          setFieldsValue({ store_value: parseInt(link_value, 10) })
        }
      }, 100)
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Confirm to delete this layout?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await collectionService.remove(match.params.id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Layout deleted successfully.')
            history.replace('/home/tiles/mobile')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete layout successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form
    const { columns } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })

        values.active = values.active ? 1 : 0

        try {
          const data = {}
          data.name = values.name
          data.layout = values.layout
          data.active = values.active
          data.columns = columns

          if (this.isEdit()) {
            const response = await collectionService.save(item._id, data)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Layout saved successfully.')
            }
          } else {
            const response = await collectionService.add(data)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Layout saved successfully.')
              history.replace(`/home/tiles/mobile/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save layout successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { columns, selectedColumn, selectedPosition } = this.state

      columns.map((col, index) => {
        const matched = col.position_col === selectedPosition.position_col && col.position_row === selectedPosition.position_row

        if (matched) {
          col.image = response.url
        }

        return col
      })

      selectedColumn.image = response.url

      this.setState({ columns, loadingImage: false, selectedColumn })
    } else {
      console.log('status', status)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteMobileTiles')
  }

  canRead () {
    return this.hasAccess('readMobileTiles')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateMobileTiles' : 'createMobileTiles')
  }

  canUpload () {
    return this.canSave()
  }

  canDeletePhoto () {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto () {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchAllStores,
  fetchTemplates
}

const mapStateToProps = (state) => {
  return {
    ...state.Template,
    ...state.Categories,
    ...state.Store
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CollectionPage))
