import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_NOTIFICATIONS, NOTIFICATIONS_LOADED, NOTIFICATIONS_FAILED } from '../actions/notification'
import notificationService from '../../services/notification'

// Generators
function * fetchNotifications (params) {
  try {
    const notifications = yield call(() => notificationService.listNotificationsByPage(params.page, params.size, params.filter, params.sort, params.query))
    yield put({ type: NOTIFICATIONS_LOADED, notifications })
  } catch (e) {
    yield put({ type: NOTIFICATIONS_FAILED, message: e.message })
  }
}

function * watchNotifications () {
  yield takeEvery(FETCH_NOTIFICATIONS, fetchNotifications)
}

export default watchNotifications
