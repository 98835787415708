export const PRODUCT_FETCHED = 'PRODUCT_FETCHED'
export const PRODUCT_SET_PRISTINE = 'PRODUCT_SET_PRISTINE'

export const productFetched = (products) => {
  return {
    type: PRODUCT_FETCHED,
    products
  }
}

export const productSetPristine = (products) => {
  return {
    type: PRODUCT_SET_PRISTINE,
    products
  }
}

