import request from '../util/request'
import queryString from '../util/queryString'

const url = '/api/notifications/'

export default {
  addNotification (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getNotification (id) {
    return request(url + id)
  },
  removeNotification (id) {
    return request(url + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveNotification (id, values) {
    return request(url + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  listNotificationsByPage (page, size, filter, sort, text) {

    //return request(`${url}page/${page}/size/${size}${queryString.stringify({ urlObject })}`)
    return request(`${url}page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter),sort: JSON.stringify(sort), text  })}`)
  },
  sendNotification (id) {
    return request(`${url}/${id}/send`)
  }
}
