export const FETCH_ADS = 'FETCH_ADS'
export const ADS_LOADED = 'ADS_LOADED'
export const ADS_FAILED = 'ADS_FAILED'

export const fetchAds = (page, size, filter, query) => {
  return {
    type: FETCH_ADS,
    page,
    size,
    filter,
    query
  }
}
