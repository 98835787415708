import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_TOPFAVOURITE_CARTPRICERULE, TOPFAVOURITE_CARTPRICERULE_LOADED, TOPFAVOURITE_CARTPRICERULE_FAILED, TOPFAVOURITE_CARTPRICERULE_GROUPS_LOADED , FETCH_TOPFAVOURITE_CARTPRICERULE_GROUP } from '../actions/topFavouriteCartPriceRule'
import topFavouriteCartPriceRuleService from '../../services/topFavouriteCartPriceRule'

// Generators
function * fetchTopFavouriteCartPriceRule (params) {
  try {
    const topFavouriteCartPriceRules = yield call(() => topFavouriteCartPriceRuleService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TOPFAVOURITE_CARTPRICERULE_LOADED, topFavouriteCartPriceRules })
  } catch (e) {
    yield put({ type: TOPFAVOURITE_CARTPRICERULE_FAILED, message: e.message })
  }
}

function * fetchTopFavouriteCartPriceRuleGroups (params) {
  try {
    const topFavouriteCartPriceRules = yield call(() => topFavouriteCartPriceRuleService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: TOPFAVOURITE_CARTPRICERULE_GROUPS_LOADED, topFavouriteCartPriceRules })
  } catch (e) {
    yield put({ type: TOPFAVOURITE_CARTPRICERULE_FAILED, message: e.message })
  }
}

function * watchTopFavouriteCartPriceRule () {
  yield takeEvery(FETCH_TOPFAVOURITE_CARTPRICERULE, fetchTopFavouriteCartPriceRule)
  yield takeEvery(FETCH_TOPFAVOURITE_CARTPRICERULE_GROUP, fetchTopFavouriteCartPriceRuleGroups)
}

export default watchTopFavouriteCartPriceRule
