import { PRODUCT_FETCHED, PRODUCT_SET_PRISTINE } from '../actions/product'

const INITIAL_STATE = {
  productSold: { data: [], total: 0 },
  productSoldPristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCT_FETCHED:
      return { ...state, productSold: action.products, productSoldPristine: false }
    case PRODUCT_SET_PRISTINE:
      return { ...state, productSoldPristine: action.products }
    default:
      return state
  }
}
