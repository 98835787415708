export const FETCH_TEMPLATE = 'FETCH_TEMPLATE'
export const TEMPLATE_LOADED = 'TEMPLATE_LOADED'
export const TEMPLATE_FAILED = 'TEMPLATE_FAILED'

export const fetchTemplates = (page, size, filter, query) => {
  return {
    type: FETCH_TEMPLATE,
    page,
    size,
    filter,
    query
  }
}
