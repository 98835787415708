import { BANNERS_LOADED } from '../actions/banner'

const INITIAL_STATE = {
  banners: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BANNERS_LOADED:
      return { ...state, banners: { list: action.banners.data, total: action.banners.total } }
    default:
      return state
  }
}
