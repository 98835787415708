
import {
  //CATEGORIES_LOADED,
  //ALL_CATEGORIES_LOADED,
  //WEB_CATEGORIES_LOADED,
  MINI_BANNER_LOADED
} from '../actions/miniBanner'

const INITIAL_STATE = {
  //categories: [],
  //masterCategories: [],
  //webCategories: { list: [], total: 0 },
  miniBanner: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /*
    case ALL_CATEGORIES_LOADED:
      return { ...state, categories: action.categories }
    case CATEGORIES_LOADED:
      return { ...state, masterCategories: action.categories }
    case WEB_CATEGORIES_LOADED:
      return { ...state, webCategories: { list: action.webCategories.data, total: action.webCategories.total } }
    */
    case MINI_BANNER_LOADED:
      return { ...state, miniBanner: { list: action.miniBanner.data, total: action.miniBanner.total } }
    default:
      return state
  }
}

