import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { adminService, authService, roleService } from '../../../services'
import { notification, validator } from '../../../util'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'

const confirm = Modal.confirm
const Option = Select.Option

class Admin extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      roles: [],
      roleValidateStatus: undefined
    }
    this.checkEmailExists = debounce(this.checkEmailExists, 500)
    this.findRoles = debounce(this.findRoles, 500)
  }

  componentDidMount () {
    if (this.canRead()) {
      this.findRoles()

      if (this.isEdit()) {
        this.fetchAdmin()
      }
    } else {
      authService.unauthorizeAction('/settings/admins')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 7, md: 7 },
      wrapperCol: { sm: 15, md: 15 }
    }
    const { form, history } = this.props
    const { item, loading, roles, roleValidateStatus } = this.state
    let defaultRole
    if (!this.canSave()) {
      defaultRole = roles.find(({ _id }) => _id === item.role) || {}
    }

    return (
      <Page.Content>
        <Page.Header title='User Details' description='Select role, enter name & e-mail. (Password optional)'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Basic Information'>
                <Row>
                  <Col lg={12}>
                    <Select
                      fieldDecorator={{
                        id: 'role',
                        options: {
                          initialValue: this.canSave() ? item.role : defaultRole.name
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Role',
                        validateStatus: roleValidateStatus
                      }}
                      readOnly={!this.canSave()}
                      select={{
                        filterOption: false,
                        onSearch: (value) => this.findRoles(value),
                        placeholder: 'Select a role',
                        showSearch: true,
                        style: { width: '100%' }
                      }}
                      list={roles}
                      listFunc={({ _id, name }) => (
                        <Option key={_id} value={_id}>{name}</Option>
                      )}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 128 characters' },
                            { max: 128, message: 'Name must be between 2 and 128 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 6
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'email',
                        options: {
                          initialValue: item.email,
                          rules: [
                            { required: true, message: 'Please enter e-mail' },
                            { whitespace: true, message: 'Please enter e-mail' },
                            // { validator: (rule, value, callback) => this.checkEmail(rule, value, callback) }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'E-mail'
                      }}
                      input={{
                        tabIndex: 7
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'password',
                        options: {
                          rules: [
                            { min: 8, message: 'Password must be at least 8 characters' },
                            { validator: (rule, value, callback) => this.checkPassword(rule, value, callback) }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Password'
                      }}
                      input={{
                        tabIndex: 8,
                        type: 'password',
                        autoComplete: 'new-password'
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Input
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Remark cannot contain only spaces' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        tabIndex: 9
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: typeof item.active !== 'undefined' ? !!item.active : true,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Active'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 10
                      }}
                      readOnly={!this.canSave()}
                      tooltip="User can login when set to 'Yes'"
                    />
                  </Col>
                </Row>
              </Panel>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkEmail (rule, value, callback) {
    if (value && value.length > 0 && !validator.isEmail(value)) {
      callback('E-mail is invalid')
    }

    this.checkEmailExists(rule, value, callback)
  }

  checkPassword (rule, value, callback) {
    if (value && value.length > 0) {
      if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*/.test(value)) {
        callback('Please enter at least 1 number, 1 lowercase letter and 1 uppercase letter')
      }
    }

    callback()
  }

  checkEmailExists (rule, value, callback) {
    return new Promise(async (resolve, reject) => {
      if (value && value.trim().length > 0) {
        const { email } = this.state.item
        const response = await adminService.checkEmail(value)

        if (response.exists !== false && email !== value) {
          callback('E-mail already exists')
        }
      }

      callback()
    })
  }

  async fetchAdmin () {
    try {
      this.setState({ loading: true })
      const item = await adminService.getAdmin(this.props.match.params.id)
      this.setState({ item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load user successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async findRoles (value) {
    try {
      this.setState({ roleValidateStatus: 'validating' })
      roleService
        .findByName(100, value || '')
        .then((roles) => {
          this.setState({ roles, roleValidateStatus: undefined })
        })
    } catch (e) {
      console.error(e)
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await adminService.removeAdmin(match.params.id)

          if (response._id) {
            notification.show('success', 'Deleted successfully', 'User deleted successfully.')
            history.replace('/settings/admins')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete user successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { item } = this.state
        this.setState({ loading: true })

        try {
          if (this.isEdit()) {
            const response = await adminService.saveAdmin(item._id, values)
            this.setState({ item: { ...item, ...values }, loading: false })
            if (response._id) {
              notification.show('success', 'Saved successfully', 'User saved successfully.')
            } else {
              notification.show('error', 'Unable to save successfully', 'Unable to save user successfully. Please try again later.')
              this.setState({ loading: false })
            }
          } else {
            const response = await adminService.addAdmin(values)
            this.setState({ loading: false })

            if (response._id) {
              this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'User saved successfully.')
              history.replace(`/settings/admins/${response._id}`)
            } else {
              notification.show('error', 'Unable to save successfully', 'Unable to save user successfully. Please try again later.')
              this.setState({ loading: false })
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save user successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteAdmin')
  }

  canRead () {
    return this.hasAccess('readAdmin')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateAdmin' : 'createAdmin')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { ...state.Admin }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Admin))
