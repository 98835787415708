import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { authService } from '../../../services'
import { formatter, notification } from '../../../util'
import { fetchRedemptions } from '../../../states/actions'

//UI
import { Button, List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

const pageSize = 20

class RedemptionList extends Component {
    constructor(props) {
        super(props)
        const {} = props
        this.state = {
            currentPage: 1,
            filter: {},
            list: [],
            loading: false,
            searchText: '',
            searchSort: '',
            total: 0,
            sort: {key:'title',order:0}
        }
        this.findRedemptions = debounce(this.findRedemptions, 500)
    }

    componentDidMount() {
        if (this.canList()) {
            this.fetchRedemptions()
        } else {
            authService.unauthorizeAction()
        }
    }

    render() {
        const { redemptions } = this.props
        const { currentPage, loading } = this.state
        const { total } = redemptions
        const columns = [

            {
              key: 'name',
              title: 'Name',
              width: 4,
              render: (row) => (<div><b>{row.name}</b><div style={{marginTop: 4, paddingRight: 10, fontSize: '9pt', color: '#999'}}></div></div>),
              onSort: (key,order)=>this.sortRedemptions(key,order) 
            },
            {
              key:'type',
              title: 'Type',
              width: 2,
              render: (row) => (<div>{row.type}</div>),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'redeemPoint',
              title: 'Redeem point',
              width: 3,
              render: (row) => (<div>{row.redeemPoint}</div>),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'discount_amount',
              title: 'Discount',
              width: 2,
              render: (row) => (<div>{row.discount_amount}</div>),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'createdAt',
              title: 'Created On',
              width: 4,
              render: (row) => formatter.toStandardDate(row.createdAt),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'updatedAt',
              title: 'Last Updated',
              width: 4,
              render: (row) => formatter.toStandardDate(row.updatedAt),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'valid_day',
              title: 'Validity',
              width: 2,
              render: (row) => (<div>{row.valid_day}</div>),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'amount',
              title: 'Quantity',
              width: 2,
              render: (row) => (<div>{row.amount}</div>),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            },
            {
              key: 'qty',
              title: 'Stock',
              width: 1,
              render: (row) => (<div>{row.qty}</div>),
              onSort: (key,order)=>this.sortRedemptions(key,order)
            }
          ]

        return (
            <Page.Content>
                <Page.Header title={`Redemptions (${total})`} description='Manage redemptions' />
                <Page.Body>
                  <Page.Filter>
                    <Row gutter={8}>
                      <Col lg={6}>
                        <SearchInput placeholder='Search Redemptions' onChange={(value) => this.findRedemptions(value)} tabIndex={1} />
                      </Col>

                      <Col lg={18} style={{textAlign: 'right'}}>
                        {this.canAdd() ? (
                          <Link to='/redemption/add'>
                            <Button>Add Redemption</Button>
                          </Link>
                        ) : null}
                      </Col>
                    </Row>
                  </Page.Filter>

                  <Row>
                    <Col>
                      <Spin loading={loading} blur>
                        <List cols={columns}  rows={redemptions.list} link={this.canRead() ? '/redemption/<_id>' : ''} />
                      </Spin>
                    </Col>
                  </Row>

                  <Pager
                    size={pageSize}
                    total={total}
                    totalText={`Total ${total} redemptions`}
                    current={currentPage}
                    onChange={(page) => this.fetchRedemptions(page)}
                    style={{ marginTop: '15px' }}
                  />

                </Page.Body>
            </Page.Content>
        )
    }

    async fetchRedemptions (page = 1, text) {
      try {
        const { filter, searchText, sort } = this.state
        this.setState({ currentPage: page })
        this.props.fetchRedemptions(page, pageSize, filter, sort, (typeof text === 'string' ? text : searchText))
      } catch (e) {
        notification.show('error', 'Unable to load successfully', 'Unable to load redemptions successfully. Please try again later.')
      }
    }

    findRedemptions (text) {
      const { currentPage, searchText } = this.state
      this.fetchRedemptions(searchText !== text ? 1 : currentPage, text)
      this.setState({ searchText: text })
    }

    sortRedemptions (key, order) {
      const { currentPage, searchText, sort} = this.state
  
      sort.key = key
      sort.order = order
      
      this.setState({sort})
      this.fetchRedemptions(currentPage,searchText)
    }
    
    hasAccess = (permission) => {
        return authService.hasAccess(permission)
    }

    canAdd () {
        return this.hasAccess('createRedemptions')
      }

    canList = () => {
        return this.hasAccess('listRedemptions')
    }

    canRead () {
        return this.hasAccess('readRedemptions')
    }
}

const mapDispatchToProps = {
  fetchRedemptions
}

const mapStateToProps = (state) => {
  return { ...state.Redemption }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionList)