import React, { Component } from 'react'
import { connect } from 'react-redux'
import ColoPicker from 'rc-color-picker'

import authService from '../../../../services/auth'
import { brandService, storeService, tileService } from '../../../../services'
import notification from '../../../../util/notification'
import validator from '../../../../util/validator'
import WebTileLayout from '../../../../constants/webTile/layout'
import { fetchWebTile, fetchAllCategories, fetchStores, fetchTemplates } from '../../../../states/actions'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch } from '../../../../components'
import UploadHolder from '../../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'

import './styles.css'
import 'rc-color-picker/assets/index.css'

const confirm = Modal.confirm
const Option = Select.Option

const LinkMethod = [
  {
    name: 'Brand',
    value: 'brand'
  },
  {
    name: 'Category',
    value: 'category'
  },
  {
    name: 'Collection Page',
    value: 'collection'
  },
  {
    name: 'Store',
    value: 'store'
  },
  {
    name: 'Custom Page (External)',
    value: 'url'
  }
]

class WebTilePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brands: [],
      item: {},
      layout: [[{ size: '3' }], [{ size: '3' }], [{ size: '3' }]],
      loading: false,
      loadingImage: false,
      loadingWebImage: false,
      selectedColumn: {},
      showColumnModal: false,
      sizes: []
    }
  }

  componentDidMount () {
    if (this.canRead()) {
      if (this.isEdit()) {
        this.fetchLayout()
      }
      this.fetchWebTile()
      this.fetchAllBrands()
      this.fetchTemplates()
      this.fetchCategories()
      // this.fetchStores()
      this.fetchAllStores()
    } else {
      authService.unauthorizeAction('/store')
    }
  }

  renderColumns2 (columns, colIdx) {
    //console.log('columns',columns)
    return (
      <div className='tile-container-col'>
        {columns.map((column, idx) => {
          const { size } = column
          column.colIdx = colIdx
          column.rowIdx = idx
          // console.log(`column[${colIdx}][${idx}]`, column)
          return (
            <div
              key={`row${idx}`}
              className={size === '1'
                ? 'tile-column-one-third' : size === '2'
                  ? 'tile-column-two-third' : size === '1.5'? 'tile-column-half' : 'tile-column-full'}
              onClick={() => this.showModal(column)}
            >
              <img className='image' alt='' src={column && column.image ? column.image : '/img/default-image.png'} />
            </div>
          )
        })}
      </div>
    )
  }

  render () {
    const { categories, form, history, templates } = this.props
    const { brands, item, layout, loading, loadingImage, loadingWebImage, selectedColumn, showColumnModal, sizes, stores } = this.state
    const formItemLayout = {
      labelCol: { sm: 4, md: 4 },
      wrapperCol: { sm: 19, md: 19 }
    }
    const formColumnLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 18, md: 18 }
    }

    return (
      <Page.Content>
        <Page.Header title='Web Tile Layout' description='Choose a layout and assign the collection/link to each column'>
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Layout Details'>
                <Row>
                  <Col lg={18}>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 128 characters' },
                            { max: 128, message: 'Name must be between 2 and 128 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Active'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 7
                      }}
                      readOnly={!this.canSave()}
                    />

                  </Col>
                </Row>
              </Panel>

              <div className='tile-section-title'>Columns Configuration</div>
              <div className='tile-image-hint'>
                {/* Size: 400px x 400px (JPG or PNG), Maximum file size: 2 MB */}
              </div>

              <Row>
                <div>
                  {layout.map((columns, idx) => {
                    return (
                      <Col key={`col${idx}`} lg={8} xl={8}>
                        {this.isEdit() ? null : (
                          <Select
                            fieldDecorator={{
                              id: `column[${idx}]`,
                              options: {
                                initialValue: sizes[idx] || '3',
                                rules: [
                                  { required: true, message: 'Please select a layout' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formColumnLayout,
                              hasFeedback: false,
                              label: 'Layout'
                            }}
                            readOnly={!this.canSave()}
                            select={{
                              onChange: (value) => this.changeLayout(value, idx)
                            }}
                            list={WebTileLayout}
                            listFunc={(layout) => {
                              return <Option key={layout.value} value={layout.value}>{(layout.name !== null ? layout.name : '') }</Option>
                            }}
                          />
                        )}

                        {this.renderColumns2(columns, idx)}
                      </Col>
                    )
                  })}
                </div>
              </Row>

              <Modal visible={showColumnModal}
                width={860}
                title='Configuration' onOk={loading ? () => this.hideModal() : null}
                onCancel={() => this.hideModal()}
                footer={[
                  <Button key='back' disabled={loading} onClick={() => this.hideModal()}>Cancel</Button>,
                  this.canSave() ? <Button key='submit' type='primary' disabled={loading} onClick={() => this.handleSaveColumn()}>Apply</Button> : null
                ]}
              >
                <Form layout='vertical'>
                  <div className='tile-upload-image'>
                    <div className='tile-image-column'>
                      <div className='slider-image-title'>Normal Image</div>
                      <UploadHolder
                        img={{
                          alt: 'Image',
                          src: selectedColumn.image || ''
                        }}
                        loading={loadingImage}
                        readOnly={!this.canSave()}
                        height={180}
                        width={180}
                        radius={0}
                        upload={{
                          action: '/api/tiles/upload/image',
                          beforeUpload: (file) => this.checkImage(file),
                          data: { _id: item._id, tileId: selectedColumn._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: (info) => this.handleUploadImage(info),
                          showUploadList: false
                        }}
                      />

                      <div className='tile-image-hint'>
                        {
                          selectedColumn.size === '1' ? 'Size: 376px x 92px (JPG or PNG)' : (
                            selectedColumn.size === '2' ? 'Size: 376px x 185px (JPG or PNG)' : (
                              selectedColumn.size === '1.5' ? 'Size: 376px x 139px (JPG or PNG)' : (
                                'Size: 376px x 287px (JPG or PNG)'
                              )
                            )
                          )
                        } <br />
                        Maximum file size: 2 MB
                      </div>
                    </div>

                    <div className='tile-image-column'>
                      <div className='slider-image-title'>Responsive Image</div>
                      <UploadHolder
                        img={{
                          alt: 'Responsive Image',
                          src: selectedColumn.web_image || ''
                        }}
                        loading={loadingWebImage}
                        readOnly={!this.canSave()}
                        height={100}
                        width={170}
                        radius={8}
                        upload={{
                          action: '/api/tiles/upload/web_image',
                          beforeUpload: (file) => this.checkImage(file),
                          data: { _id: item._id, tileId: selectedColumn._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: (info) => this.handleUploadWebImage(info),
                          showUploadList: false
                        }}
                      />

                      <div className='tile-image-hint'>
                        {
                          'Size: 510px x 275px (JPG or PNG)'
                        } <br />
                        Maximum file size: 2 MB
                      </div>
                    </div>
                  </div>

                  <Row>
                    <Col offset={3} md={18}>
                      <Input
                        fieldDecorator={{
                          id: 'column.name',
                          options: {
                            initialValue: selectedColumn.name,
                            rules: [
                              { min: 2, message: 'Name must be between 2 and 128 characters' },
                              { max: 128, message: 'Name must be between 2 and 128 characters' },
                              { required: true, message: 'Please enter Name' },
                              { whitespace: true, message: 'Please enter Name' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          label: 'Name'
                        }}
                        input={{
                          tabIndex: 1
                        }}
                        readOnly={!this.canSave()}
                      />

                      <Select
                        fieldDecorator={{
                          id: 'column.link_type',
                          options: {
                            initialValue: selectedColumn.link_type || '',
                            rules: [
                              { required: true, message: 'Please select a link type' }
                            ]
                          }
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayout,
                          hasFeedback: false,
                          label: 'Link To'
                        }}
                        readOnly={!this.canSave()}
                        list={LinkMethod}
                        listFunc={(link) => {
                          return <Option key={link.value} value={link.value}>{(link.name !== null ? link.name : '') }</Option>
                        }}
                        select={{
                          onChange: (value) => this.changeLinkType(value)
                        }}
                      />

                      { selectedColumn.link_type === 'url'
                        ? <Input
                          fieldDecorator={{
                            id: 'column.link_value',
                            options: {
                              initialValue: selectedColumn.link_value || '',
                              rules: [
                                { min: 1, message: 'URL must be between 1 and 128 characters' },
                                { max: 128, message: 'URL must be between 1 and 128 characters' },
                                { required: true, message: 'Please enter URL' },
                                { whitespace: true, message: 'Please enter URL' }
                              ]
                            }
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayout,
                            label: 'URL'
                          }}
                          input={{
                            tabIndex: 4
                          }}
                          readOnly={!this.canSave()}
                        />
                        : selectedColumn.link_type === 'collection'
                          ? <Select
                            fieldDecorator={{
                              id: 'column.link_value',
                              options: {
                                initialValue: parseInt(selectedColumn.link_value, 10) || '',
                                rules: [
                                  { required: true, message: 'Please select a page' }
                                ]
                              }
                            }}
                            form={form}
                            formItem={{
                              ...formItemLayout,
                              hasFeedback: false,
                              label: 'Page'
                            }}
                            readOnly={!this.canSave()}
                            list={templates.list}
                            listFunc={(template) => {
                              return <Option key={template._id} value={template._id}>{(template.name !== null ? template.name : '') }</Option>
                            }}

                          />
                          : selectedColumn.link_type === 'brand'
                            ? <Select
                              fieldDecorator={{
                                id: 'column.link_value',
                                options: {
                                  initialValue: parseInt(selectedColumn.link_value, 10) || '',
                                  rules: [
                                    { required: true, message: 'Please select a brand' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                hasFeedback: false,
                                label: 'Brand'
                              }}
                              readOnly={!this.canSave()}
                              list={brands}
                              listFunc={(brand) => {
                                return <Option key={brand.brand_id} value={brand.option_id}>{(brand.name !== null ? brand.name : '') }</Option>
                              }}
                              select={{
                                placeholder: 'Select a brand',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}

                            />
                            : selectedColumn.link_type === 'store'
                              ? <Select
                                fieldDecorator={{
                                  id: 'column.link_value',
                                  options: {
                                    initialValue: parseInt(selectedColumn.link_value, 10) || '',
                                    rules: [
                                      { required: true, message: 'Please select a store' }
                                    ]
                                  }
                                }}
                                form={form}
                                formItem={{
                                  ...formItemLayout,
                                  hasFeedback: false,
                                  label: 'Store'
                                }}
                                readOnly={!this.canSave()}
                                list={stores}
                                listFunc={(vendor) => {
                                  return <Option key={vendor.id} value={vendor.id}>{(vendor.name !== null ? vendor.name : '') + ' (' + vendor.vendor_id + ')' }</Option>
                                }}
                                select={{
                                  placeholder: 'Select a store',
                                  showSearch: true,
                                  style: { width: '100%' },
                                  filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}

                              />
                              : <Select
                                fieldDecorator={{
                                  id: 'column.link_value',
                                  options: {
                                    initialValue: parseInt(selectedColumn.link_value, 10) || '',
                                    rules: [
                                      { required: true, message: 'Please select a category' }
                                    ]
                                  }
                                }}
                                form={form}
                                formItem={{
                                  ...formItemLayout,
                                  hasFeedback: false,
                                  label: 'Category'
                                }}
                                readOnly={!this.canSave()}
                                list={categories}
                                listFunc={(category) => {
                                  return <Option key={category.id} value={category.id}>{(category.name !== null ? category.name : '') }</Option>
                                }}
                              />
                      }

                    </Col>
                  </Row>
                </Form>
              </Modal>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  changeLayout (value, colIdx) {
    const { layout } = this.state
    const columns = layout[colIdx]
    //const rowLayout = value.match(/\d+(\.\d{1,2})?/) || []
    //console.log(rowLayout)
    const rowLayout = value.split(",")
    rowLayout.map((size, idx) => {
      const column = columns[idx]

      if (column) {
        column.size = size
      } else {
        columns.push({ size })
      }
    })

    if (columns.length > rowLayout.length) {
      columns.splice(rowLayout.length)
    }
  }

  changeLinkType (value) {
    const { selectedColumn } = this.state
    const { setFieldsValue, resetFields } = this.props.form

    selectedColumn.link_type = value
    resetFields(['column.link_value'])

    if (value === 'url') {
      selectedColumn.link_value = 'http://'
      setFieldsValue({
        url_value: 'http://'
      })
    }

    // this.setState({selectedColumn})
  }

  checkImage (file) {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }
    return true
  }

  async fetchLayout () {
    try {
      this.setState({ loading: true })
      const { layout, sizes } = this.state

      const tiles = await tileService.get(this.props.match.params.id)

      tiles.map((item) => {
        const { col_idx, row_idx, size } = item
        layout[col_idx][row_idx] = item
        if (sizes[col_idx]) {
          sizes[col_idx] += `,${size}`
        } else {
          sizes[col_idx] = size
        }

        return item
      })

      const item = await tileService.getTileGroup(this.props.match.params.id)
      this.setState({ item, layout, loading: false, sizes })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load tile layout successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchWebTile () {
    try {
      this.props.fetchWebTile()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load web tiles successfully. Please try again later.')
    }
  }

  fetchAllBrands = async () => {
    try {
      const item = await brandService.getAllBrands()
      this.setState({ brands: item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
    }
  }

  fetchTemplates () {
    try {
      this.props.fetchTemplates(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load templates successfully. Please try again later.')
    }
  }

  fetchStores = async () => {
    try {
      this.props.fetchStores(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load stores successfully. Please try again later.')
    }
  }

  async fetchAllStores () {
    try {
      this.setState({ loading: true })
      const item = await storeService.getAllVendors()
      this.setState({ stores: item, loading: false })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load store successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchCategories () {
    try {
      this.props.fetchAllCategories()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  hideModal () {
    const { form } = this.props
    const { resetFields } = form
    resetFields(['column.name', 'column.link_type', 'column.link_value'])
    this.setState({ showColumnModal: false })
  }

  showModal (column) {
    this.setState({ selectedColumn: column, showColumnModal: true })

    if (column) {
      const { form } = this.props
      const { setFieldsValue } = form
      const { name } = column
      setTimeout(() => {
        setFieldsValue({
          'column.name': name
        })
      }, 100)
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Confirm to delete this layout?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await tileService.remove(match.params.id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Layout deleted successfully.')
            history.replace('/home/tiles/web')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete layout successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history } = this.props
    const { validateFields } = this.props.form

    validateFields(['name', 'active'], async (errors, values) => {
      if (!errors) {
        const { layout, item } = this.state
        this.setState({ loading: true })

        values.active = values.active ? 1 : 0
        values.rows = layout

        try {
          if (this.isEdit()) {
            const response = await tileService.save(item._id, values)
            this.setState({ loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Layout saved successfully.')
            }
          } else {
            const response = await tileService.add(values)
            this.setState({ loading: false })

            if (response._id) {
              // this.setState({ item: { ...item, ...values, _id: response._id } })
              notification.show('success', 'Saved successfully', 'Layout saved successfully.')
              history.replace('/home/tiles/web')
              // history.replace(`/home/tiles/web/${response._id}`)
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save layout successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleSaveColumn () {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['column.name', 'column.link_type', 'column.link_value'], async (errors, values) => {
      if (!errors) {
        try {
          const { layout, selectedColumn } = this.state
          const { colIdx, rowIdx } = selectedColumn
          const { column } = values

          if (this.isEdit()) {
            layout[colIdx][rowIdx] = { ...selectedColumn, ...column }
            this.hideModal()
          } else {
            layout[colIdx][rowIdx] = { ...selectedColumn, ...column }
            this.hideModal()
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save layout successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage (info) {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { layout, selectedColumn } = this.state

      layout.map((columns, index) => {
        columns.map((row, idx) => {
          const matched = row.colIdx === selectedColumn.colIdx && row.rowIdx === selectedColumn.rowIdx

          if (matched) {
            row.image = response.url
          }

          return row
        })
      })

      selectedColumn.image = response.url
      this.setState({ layout, loadingImage: false, selectedColumn })
    } else {
      console.log('status', status)
    }
  }

  handleUploadWebImage (info) {
    const { status, response } = info.file
    this.setState({ loadingWebImage: true })

    if (status === 'done') {
      const { layout, selectedColumn } = this.state

      layout.map((columns, index) => {
        columns.map((row, idx) => {
          const matched = row.colIdx === selectedColumn.colIdx && row.rowIdx === selectedColumn.rowIdx

          if (matched) {
            row.web_image = response.url
          }

          return row
        })
      })

      selectedColumn.web_image = response.url
      this.setState({ layout, loadingWebImage: false, selectedColumn })
    } else {
      console.log('status', status)
    }
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canDelete () {
    return this.hasAccess('deleteWebTiles')
  }

  canRead () {
    return this.hasAccess('readWebTiles')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateWebTiles' : 'createWebTiles')
  }

  canUpload () {
    return this.canSave()
  }

  canDeletePhoto () {
    return this.isEdit() ? this.canDelete() : false
  }

  canSavePhoto () {
    return this.isEdit() ? this.canSave() : false
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchWebTile,
  fetchAllCategories,
  fetchStores,
  fetchTemplates
}

const mapStateToProps = (state) => {
  return {
    ...state.Store,
    ...state.Tile,
    ...state.Template,
    ...state.Categories
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(WebTilePage))
