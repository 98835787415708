export const FETCH_TRENDINGS = 'FETCH_TRENDINGS'
export const TRENDINGS_LOADED = 'TRENDING_LOADED'
export const TRENDINGS_FAILED = 'TRENDING_FAILED'
export const TRENDING_GROUPS_LOADED = 'TRENDING_GROUPS_LOADED'
export const FETCH_TRENDING_GROUPS = 'FETCH_TRENDING_GROUP'

export const fetchTrendingGroups = (page, size, filter, query) => {
  return {
    type: FETCH_TRENDING_GROUPS,
    page,
    size,
    filter,
    query
  }
}

export const fetchTrendings = (page, size, filter, query) => {
  return {
    type: FETCH_TRENDINGS,
    page,
    size,
    filter,
    query
  }
}
