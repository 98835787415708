export const FETCH_TOPFAVOURITE_CARTPRICERULE = 'FETCH_TOPFAVOURITE_CARTPRICERULE'
export const TOPFAVOURITE_CARTPRICERULE_LOADED = 'TOPFAVOURITE_CARTPRICERULE_LOADED'
export const TOPFAVOURITE_CARTPRICERULE_FAILED = 'TOPFAVOURITE_CARTPRICERULE_FAILED'
export const TOPFAVOURITE_CARTPRICERULE_GROUPS_LOADED = 'TOPFAVOURITE_CARTPRICERULE_GROUPS_LOADED'
export const FETCH_TOPFAVOURITE_CARTPRICERULE_GROUP = 'FETCH_TOPFAVOURITE_CARTPRICERULE_GROUP'

export const fetchTopFavouriteCartPriceRuleGroups = (page, size, filter, query) => {
  return {
    type: FETCH_TOPFAVOURITE_CARTPRICERULE_GROUP,
    page,
    size,
    filter,
    query
  }
}

export const fetchTopFavouriteCartPriceRule = (page, size, filter, query) => {
  return {
    type: FETCH_TOPFAVOURITE_CARTPRICERULE,
    page,
    size,
    filter,
    query
  }
}
