export const ROLES_FETCHED = 'ROLES_FETCHED'
export const ROLE_SET_PRISTINE = 'ROLE_SET_PRISTINE'

export const rolesFetched = (roles) => {
  return {
    type: ROLES_FETCHED,
    roles
  }
}

export const roleSetPristine = (pristine) => {
  return {
    type: ROLE_SET_PRISTINE,
    pristine
  }
}
