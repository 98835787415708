export const FETCH_BANNERS = 'FETCH_BANNERS'
export const BANNERS_LOADED = 'BANNERS_LOADED'
export const BANNERS_FAILED = 'BANNERS_FAILED'

export const fetchBanners = (page, size, filter, query) => {
  return {
    type: FETCH_BANNERS,
    page,
    size,
    filter,
    query
  }
}
