import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import authService from '../../../../services/auth'
import formatter from '../../../../util/formatter'
import notification from '../../../../util/notification'
import sessionStorage from '../../../../util/sessionStorage'
import { fetchCollections } from '../../../../states/actions'
import CollectionLayoutTypes from '../../../../constants/collection/layouts'

// UI
import { Button, Page, Pager, SearchInput, Spin } from '../../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const filterKey = 'collection.layout.filter'
const pageSize = 20

class MobileTitle extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchCollections()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { currentPage, loading } = this.state

    const { collections } = this.props
    const { list, total } = collections

    return (
      <Page.Content>
        <Page.Header title='Mobile Tiles' description='Arrange collection links in four different layouts (For mobile app only)' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Mobile Tile' onChange={(value) => this.findCollections(value)} />
              </Col>

              <Col offset={12} lg={6} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/home/tiles/mobile/add'>
                    <Button>Add Tiles Layout</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='wd-collection-list'>
                  {list.map((item) => {
                    const { _id, active, name, layout } = item
                    const Row = (props) => {
                      return (
                        <div className={formatter.toClassName(['wd-collection-card', props.className])}>
                          <div className='wd-collection-active'>
                            <div className='wd-collection-active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />
                          </div>

                          <img className='wd-collection-img' alt='' src={`/icon/layout/${CollectionLayoutTypes.filter(type => type.value === layout)[0].image}`} />

                          <div className='wd-collection-info'>
                            <div className='name'>{name}</div>

                            <div className='remark'> { CollectionLayoutTypes.filter(type => type.value === layout)[0].name }</div>

                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={_id} to={`/home/tiles/mobile/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} layouts`}
            current={currentPage}
            onChange={(page) => this.fetchStores(page)}
            style={{marginTop: '15px'}}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeActive (value) {
    const { currentPage, filter, searchText } = this.state

    if (value === 'Yes') {
      filter.active = true
    } else if (value === 'No') {
      filter.active = false
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchCollections(currentPage, searchText)
  }

  async fetchCollections (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchCollections(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load collections successfully. Please try again later.')
    }
  }

  findCollections (text) {
    const { currentPage, searchText } = this.state
    this.fetchCollections(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createMobileTiles')
  }

  canList () {
    return this.hasAccess('listMobileTiles')
  }

  canRead () {
    return this.hasAccess('readMobileTiles')
  }
}

const mapDispatchToProps = {
  fetchCollections
}

const mapStateToProps = (state) => {
  return { ...state.Collection }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileTitle)
