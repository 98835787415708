import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

export default class WDSwitch extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { fieldDecorator, form, formItem = {}, input = {}, readOnly = false, tooltip = '' } = this.props
    const { getFieldDecorator } = form
    const { id, options = {} } = fieldDecorator
    const { checkedChildren, unCheckedChildren } = input
    formItem.className = formatter.toClassName(['wd-switch', formItem.className])

    return (
      <Form.Item {...formItem}>
        <Tooltip placement='bottom' title={tooltip}>
          {readOnly ? (
            <span className='ant-form-text'>{options.initialValue ? checkedChildren : unCheckedChildren}</span>
          ) : (
            getFieldDecorator(id, options)(<Switch {...input} />)
          )}
        </Tooltip>
      </Form.Item>
    )
  }
}

WDSwitch.propTypes = {
  fieldDecorator: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formItem: PropTypes.object,
  input: PropTypes.object,
  readOnly: PropTypes.bool,
  tooltip: PropTypes.string
}
