import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class Active extends Component {
  render () {
    const { active, color = {} } = this.props
    const { activeColor, inactiveColor } = Object.assign({
      activeColor: '#17b248',
      inactiveColor: 'red'
    }, color)

    return (
      <div style={{ color: active ? activeColor : inactiveColor }}>◉</div>
    )
  }
}

Active.propTypes = {
  active: PropTypes.bool.isRequired,
  color: PropTypes.object
}
