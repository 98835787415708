import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Button from '../Button'
import Spin from '../Spin'
import Icon from 'antd/lib/icon'
import Upload from 'antd/lib/upload'

import './styles.css'

export default class ImageUpload extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { className, img, loading = false, readOnly = false, upload } = this.props
    upload.className = formatter.toClassName(['wd-imageupload-uploader', upload.className, readOnly ? 'disabled' : ''])
    upload.disabled = readOnly || upload.disabled

    return (
      <div className={formatter.toClassName(['wd-imageupload', className])}>
        <Upload {...upload}>
          <Spin loading={loading} blur>
            <div>
              {loading ? null : (
                <div className='wd-imageupload-trigger'>
                  <Button ghost style={{ margin: 0 }}>Click to change</Button>
                </div>
              )}

              {img.src ? (
                <img className='wd-imageupload-image' {...img} alt={img.alt || 'Image'} />
              ) : (
                <Icon className='wd-imageupload-icon' type='upload' />
              )}
            </div>
          </Spin>
        </Upload>
      </div>
    )
  }
}

ImageUpload.propTypes = {
  className: PropTypes.string,
  img: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  upload: PropTypes.object.isRequired
}
