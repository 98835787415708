import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_CONTENT, CONTENT_PAGE_LOADED, CONTENT_PAGE_FAILED } from '../actions'
import { contentManagementSystemService } from '../../services'

// Generators
function * fetchContent (params) {
  try {
    const pages = yield call(() => contentManagementSystemService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: CONTENT_PAGE_LOADED, pages })
  } catch (e) {
    yield put({ type: CONTENT_PAGE_FAILED, message: e.message })
  }
}

function * watchCustomManagementSystem () {
  yield takeEvery(FETCH_CONTENT, fetchContent)
}

export default watchCustomManagementSystem
