import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_CARTPRICERULES, FETCH_CARTPRICERULES_GROUP, CARTPRICERULES_LOADED, CARTPRICERULES_GROUPS_LOADED, CARTPRICERULES_FAILED } from '../actions/cartPriceRule'
import promotionService from '../../services/cartPriceRule'

// Generators
function * fetchCartPriceRules (params) {
  try {
    const cartPriceRules = yield call(() => promotionService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: CARTPRICERULES_LOADED, cartPriceRules })
  } catch (e) {
    yield put({ type: CARTPRICERULES_FAILED, message: e.message })
  }
}

function * fetchCartPriceRuleGroups (params) {
  try {
    const cartPriceRules = yield call(() => promotionService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: CARTPRICERULES_GROUPS_LOADED, cartPriceRules })
  } catch (e) {
    yield put({ type: CARTPRICERULES_FAILED, message: e.message })
  }
}

function * watchCartPriceRule () {
  yield takeEvery(FETCH_CARTPRICERULES, fetchCartPriceRules)
  yield takeEvery(FETCH_CARTPRICERULES_GROUP, fetchCartPriceRuleGroups)
}

export default watchCartPriceRule
