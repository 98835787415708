import { CAROUSELS_LOADED } from '../actions/carousel'

const INITIAL_STATE = {
  carousels: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CAROUSELS_LOADED:
      return { ...state, carousels: { list: action.carousels.data, total: action.carousels.total } }
    default:
      return state
  }
}
