import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Panel extends Component {
  render () {
    const { children, className, subtitle, style, title } = this.props

    return (
      <div className={formatter.toClassName(['wd-panel', className])} style={style}>
        <div className='wd-panel-title' style={{ display: title ? '' : 'none' }}>
          { title }

          <div style={{float: 'right'}}>{subtitle}</div>
        </div>

        <div className='wd-panel-body'>
          { children }
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  className: PropTypes.string
}
