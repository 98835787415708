import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_PROMOTIONS, FETCH_PROMOTION_GROUP, PROMOTIONS_LOADED, PROMOTION_GROUPS_LOADED, PROMOTIONS_FAILED,
FETCH_PROMOTIONS_LABEL, FETCH_PROMOTION_LABEL_GROUP, PROMOTIONS_LABEL_LOADED, PROMOTION_LABEL_GROUPS_LOADED, PROMOTIONS_LABEL_FAILED } from '../actions/promotion'
import promotionService from '../../services/promotion'
import promotionEventsService from '../../services/promotionEvents'

// Generators
function * fetchPromotions (params) {
  try {
    const promotions = yield call(() => promotionService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: PROMOTIONS_LOADED, promotions })
  } catch (e) {
    yield put({ type: PROMOTIONS_FAILED, message: e.message })
  }
}

function * fetchPromotionGroups (params) {
  try {
    const promotions = yield call(() => promotionService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: PROMOTION_GROUPS_LOADED, promotions })
  } catch (e) {
    yield put({ type: PROMOTIONS_FAILED, message: e.message })
  }
}

function * fetchPromotionsLabel (params) {
  try {
    const promotions = yield call(() => promotionEventsService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: PROMOTIONS_LABEL_LOADED, promotions })
  } catch (e) {
    yield put({ type: PROMOTIONS_LABEL_FAILED, message: e.message })
  }
}

function * fetchPromotionLabelGroups (params) {
  try {
    const promotions = yield call(() => promotionEventsService.listGroupByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: PROMOTION_LABEL_GROUPS_LOADED, promotions })
  } catch (e) {
    yield put({ type: PROMOTIONS_LABEL_FAILED, message: e.message })
  }
}

function * watchPromotion () {
  yield takeEvery(FETCH_PROMOTIONS, fetchPromotions)
  yield takeEvery(FETCH_PROMOTION_GROUP, fetchPromotionGroups)
  yield takeEvery(FETCH_PROMOTIONS_LABEL, fetchPromotionsLabel)
  yield takeEvery(FETCH_PROMOTION_LABEL_GROUP, fetchPromotionLabelGroups)
}

export default watchPromotion
