import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchWebCategories } from '../../../states/actions'
import { formatter, notification, sessionStorage } from '../../../util'

// UI
import { Button, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'webCategory.filter'
const pageSize = 100
const Option = Select.Option

class CategorySlide extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
    this.fetchWebCategories = debounce(this.fetchWebCategories, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchWebCategories()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { webCategories } = this.props
    const { currentPage, filter, loading } = this.state
    const { list, total } = webCategories
    const { active } = filter

    return (
      <Page.Content>
        <Page.Header title={`Categories (${total})`} description='Display categories shortcut with images (For WEB only)' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Categories' onChange={(value) => this.findWebCategories(value)} />
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={5}>
                    <Tooltip title='Visible'>
                      <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        onChange={(value) => this.changeActive(value)}
                        placeholder='Visible'
                        style={{ width: '100%' }}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/home/categories/add'>
                    <Tooltip title='Maximum 24 categories'>
                      <Button disabled={this.isMaxNumber()}>Add Category</Button>
                    </Tooltip>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='wd-web-category-list'>
                  {list.map((item) => {
                    const { _id, active, name, image_thumb: thumbUrl = '', position } = item
                    const Row = (props) => {
                      return (
                        <div className={formatter.toClassName(['wd-web-category-card', props.className])}>
                          <div className='wd-web-category-active'>
                            <div className='wd-web-category-active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />
                          </div>

                          <img className='wd-web-category-img' alt='' src={thumbUrl || '/img/default-image.png'} />

                          <div className='wd-web-category-info'>
                            <div className='name'>{name}</div>

                            <div className='remark'><img className='wd-web-category-order' alt='' src={'/icon/list-order.svg'} /> {position}</div>
                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={_id} to={`/home/categories/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} categories`}
            current={currentPage}
            onChange={(page) => this.fetchWebCategories(page)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeActive (value) {
    const { currentPage, filter, searchText } = this.state

    if (value === 'Yes') {
      filter.active = true
    } else if (value === 'No') {
      filter.active = false
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchWebCategories(currentPage, searchText)
  }

  async fetchWebCategories (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchWebCategories(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  findWebCategories (text) {
    const { currentPage, searchText } = this.state
    this.fetchWebCategories(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createStore')
  }

  canList () {
    return this.hasAccess('listStore')
  }

  canRead () {
    return this.hasAccess('readStore')
  }

  isMaxNumber () {
    const { webCategories } = this.props
    const { loading } = this.state
    const { total } = webCategories

    if (loading) {
      return true
    }

    return total > 23
  }
}

const mapDispatchToProps = {
  fetchWebCategories
}

const mapStateToProps = (state) => {
  return { ...state.Categories }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategorySlide)
