import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

export class Body extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-body', className])}>
        { this.props.children }
      </div>
    )
  }
}

Body.propTypes = {
  className: PropTypes.string
}

export class Content extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-content', className])}>
        { this.props.children }
      </div>
    )
  }
}

Content.propTypes = {
  className: PropTypes.string
}

export class Filter extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-filter', className])}>
        { this.props.children }
      </div>
    )
  }
}

Filter.propTypes = {
  className: PropTypes.string
}

export class Header extends Component {
  render () {
    const { children, className, compact, description, title } = this.props

    return compact ? (
      <div className={formatter.toClassName(['wd-header header-compact', className])}>
        <Row>
          {title ? <Col sm={24} md={children ? 8 : 24} ><h2>{title}</h2></Col> : null}
          {children ? <Col sm={24} md={title ? 16 : 24} className='header-action'>{children}</Col> : null}
        </Row>
      </div>
    ) : (
      <div className={formatter.toClassName(['wd-header', className])}>
        {title ? <h2>{title}</h2> : null}

        <Row>
          {description ? <Col sm={24} md={children ? 8 : 24} ><div className='header-desc'>{description}</div></Col> : null}
          {children ? <Col sm={24} md={description ? 16 : 24} className='header-action'>{children}</Col> : null}
        </Row>
      </div>
    )
  }
}

Header.propTypes = {
  className: PropTypes.string
}

export class Panel extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-panel', className])}>
        { this.props.children }
      </div>
    )
  }
}

Panel.propTypes = {
  className: PropTypes.string
}

export class Tab extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-tab', className])}>
        { this.props.children }
      </div>
    )
  }
}

Tab.propTypes = {
  className: PropTypes.string
}

export class Table extends Component {
  render () {
    const { className } = this.props

    return (
      <div className={formatter.toClassName(['wd-section', className])}>
        { this.props.children }
      </div>
    )
  }
}

Table.propTypes = {
  className: PropTypes.string
}

export default { Body, Content, Filter, Header, Panel, Tab, Table }
