import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Spin extends Component {
  render () {
    const { blur, children, className, loading } = this.props

    return (
      <div className={formatter.toClassName(['wd-spin', className])}>
        <div className={loading ? 'wd-loader' : 'wd-loader-hide'} />

        <div className={loading ? ('wd-loading' + (blur ? ', wd-loading-blur' : '')) : ''} >
          <div className='wd-component'>{ children }</div>
        </div>
      </div>
    )
  }
}

Spin.propTypes = {
  className: PropTypes.string
}
