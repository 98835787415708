import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import authService from '../../../../services/auth'
import { fetchReviewsTitleList } from '../../../../states/actions'
import labelTitles, { LabelTitleList } from '../../../../constants/review/labelTitle'
import { formatter, notification, sessionStorage } from '../../../../util'

// UI
import { Button, Page, Pager, SearchInput, Spin } from '../../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'reviewTitle.filter'
const pageSize = 20
const Option = Select.Option

class ReviewTitleList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
    this.fetchReviewsTitleList = debounce(this.fetchReviewsTitleList, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchReviewsTitleList()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { reviewTitles } = this.props
    const { currentPage, filter, loading } = this.state
    const { active, label_type: labelType } = filter
    const { list, total } = reviewTitles

    return (
      <Page.Content>
        <Page.Header title={`Predefined Title (${total})`} description='Show predefined title for user when user write an review' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={3}>
                {/* <SearchInput placeholder='Search Label' onChange={(value) => this.findAds(value)} /> */}
                <Tooltip title='Type'>
                  <Select
                    defaultValue={labelType ? labelType : 'All'}
                    disabled={loading}
                    onChange={(value) => this.changeType(value)}
                    placeholder='Type'
                    style={{ width: '100%' }}
                  >
                    <Option value='All'>All</Option>
                    {LabelTitleList.map(({ name, value }) => (
                      <Option value={value}>{name}</Option>
                    ))}
                  </Select>
                </Tooltip>
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={5}>
                    <Tooltip title='Visible'>
                      <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        onChange={(value) => this.changeActive(value)}
                        placeholder='Visible'
                        style={{ width: '100%' }}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              <Col lg={9} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/review/title/add'>
                    <Button>Add Title</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='wd-review-list'>
                  {list.map((item) => {
                    const { _id, active, label, type, tag_color: tagColor } = item
                    const Row = (props) => {
                      return (
                        <div className={formatter.toClassName(['wd-review-card', props.className])}>
                          <div className='wd-review-active'>
                            <div className='wd-review-active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />
                          </div>

                          <div className='wd-review-label'>
                            <div style={{ border: `2px solid #${tagColor}`, padding: '5px 10px', borderRadius: 5 }}>
                              <div className='name' style={{ color: `#${tagColor}`}}>{label}</div>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={_id} to={`/review/title/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} titles`}
            current={currentPage}
            onChange={(page) => this.fetchReviewsTitleList(page)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeActive (value) {
    const { currentPage, filter, searchText } = this.state

    if (value === 'Yes') {
      filter.active = true
    } else if (value === 'No') {
      filter.active = false
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchReviewsTitleList(currentPage, searchText)
  }

  changeType (value) {
    const { currentPage, filter } = this.state

    if (value === 'All') {
      delete filter.label_type
    } else if (value !== undefined) {
      filter.label_type = value
    } else {
      delete filter.label_type
    }

    this.setState({ filter })
    this.fetchReviewsTitleList(currentPage)
  }

  async fetchReviewsTitleList (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchReviewsTitleList(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load predefined titles successfully. Please try again later.')
    }
  }

  findReviews (text) {
    const { currentPage, searchText } = this.state
    this.fetchReviewsTitleList(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createReviewTitle')
  }

  canList () {
    return this.hasAccess('listReviewTitle')
  }

  canRead () {
    return this.hasAccess('readReviewTitle')
  }
}

const mapDispatchToProps = {
  fetchReviewsTitleList
}

const mapStateToProps = (state) => {
  return { ...state.Review }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewTitleList)
