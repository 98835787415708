export const FETCH_REDEMPTIONS = 'FETCH_REDEMPTIONS'
export const REDEMPTIONS_LOADED = 'REDEMPTIONS_LOADED'
export const REDEMPTIONS_FAILED = 'REDEMPTIONS_FAILED'

export const fetchRedemptions = (page, size, filter, sort, query) => {
  return {
    type: FETCH_REDEMPTIONS,
    page,
    size,
    filter,
    sort,
    query
  }
}
