import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
//import "moment-timezone";
import { magento } from '../../../config'
import { authService, productService, promotionService, rewardMissionService } from '../../../services'
import { formatter, notification, sessionStorage, validator } from '../../../util'

// UI
import { Button, DatePicker, Input, List, Page, Pager, Panel, SearchInput, Select as WDSelect, Spin, Switch as WDSwitch } from '../../../components'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'
import { Form as FormMain } from 'antd';
import Modal from 'antd/lib/modal'
import OptionModal from "antd/lib/modal"
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import { Checkbox } from 'antd'
import './styles.css'
import WDSelectSearch from "../../../components/SelectSearch";
import AntButton from 'antd/lib/button'
import P from 'pino'

const filterKey = 'promotion.filter'
const pageSize = 100
const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option
const newfilterKey = 'product.filter'
const pageKey = 'product.page'

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
class RewardsDetail extends Component {
  constructor (props) {
    super(props)
    let isPromo = true
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      maxPosition: 0,
      loading: false,
      modal: {
        isAdd: true,
        selectedProduct: {},
        show: false
      },
      optionModal: {
        isAddOptionModal: true,
        selectedProductOptionModal: {},
        showOptionModal: false
      },
      param: {  
        brand: "",
        category: "",
        keyword: "",
        newArrival: "",
        discountFrom:  0,
        discountTo: 0,
        discountPeriodFrom:  "",
        discountPeriodTo: "",
        priceFrom: 0,
        priceTo: 0,
        isPromo,
      },
      products: { data: [], total: 0 },
      productImagePreview: '',
      searchText: '',
      sort: {},
      promotionGroup: { name: '' },
      rewardMissionItem:  []
    }
    this.changeLoginDays = this.changeLoginDays.bind(this)
    this.changeStep = this.changeStep.bind(this)
    //this.fetchProducts = debounce(this.fetchProducts, 500)

  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchPromotionGroup()
      this.fetchPromotionGroupItem()
      if (this.isEdit()) {
        this.fetchPromotion({ loading: true, currentPage, filter, searchText, sort })
      }
    } else {
      authService.unauthorizeAction('/promotion')
    }
  }

  render () {
    const modalItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 16, md: 16 }
    }
    const { form, history, readOnly=false } = this.props
    const { currentPage, filter, loading, modal, optionModal, products, productImagePreview, promotionGroup, promotions, rewardMissionItem } = this.state
    const { getFieldValue } = form

    const { active } = filter
    const { isAdd, selectedProduct, show } = modal
    const { isAddOptionModal, selectedProductOptionModal, showOptionModal } = optionModal
    const { data: productList } = products

    const formItemFullLayout = {
      labelCol: { sm: 4, md: 4 },
      wrapperCol: { sm: 20, md: 20 }
    }

    const formItemLayout = {
      labelCol: { sm: 7, md: 8 },
      wrapperCol: { sm: 14, md: 14 }
    }

    const formItemLayer = {
      labelCol: { sm: 24, md: 24 },
      wrapperCol: { sm: 24, md: 24 }
    }

    const columns = [
      {
        width: 1,

        render: row => {
          const { isChecked } = this.state;
          return (
           undefined
          );
        }
      },
      {
        title: 'Sku',
        key: 'sku',
        width: 2,
      },
      {
        title: 'Image',
        cssClassName: 'center-header',
        align: 'center', 
        key: 'thumbnail',
        render: (row) => {
          return(
          <img
            className={"option-modal-img"}
            alt=""
            src={
              row.thumbnail
                ? magento.media + row.thumbnail
                : "/img/default-image.png"
            }
          />
          )
        },
                  
        width: 4,         
      },
      {
        title: 'Name',
        key: 'name',
        width: 3,
      },
      {
        title: 'Brand',
        key: 'brand',
        width: 3,
      },
      {
        title: 'Category',
        key: 'category',
        width: 3,
      },
      {
        title: 'Price',
        key: 'price',
        width: 2,
      },
      {
        title: 'Discount',
        key: 'discount',
        width: 2,
      },
      {
        title: 'Discount period',
        key: 'discount_period',
        width: 4,
      }
    ]


    const totalStep = rewardMissionItem && rewardMissionItem.length > 0 ? rewardMissionItem.filter(x => !x.isDelete).length : 0

    return (
      <Page.Content>
        <Page.Header title={`Reward Mission Detail`} description='Manage Reward Mission'>

          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>
        <Page.Body>
          <Form>
            <Panel title='Reward Mission Group'>
              <Row>
                <Col lg={20}>
                  <Input
                    fieldDecorator={{
                      id: 'name',
                      options: {
                        initialValue: promotionGroup.name,
                        rules: [
                          { min: 2, message: 'Name must be between 2 and 128 characters' },
                          { max: 128, message: 'Name must be between 2 and 128 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter name' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemFullLayout,
                      label: 'Name'
                    }}
                    input={{
                      tabIndex: 1
                    }}
                    readOnly={!this.canSave()}
                  />

                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <DatePicker
                    datePicker={{
                      allowClear: false,
                      placeholder: 'Select a date',
                      style: { width: '100%' },
                      onChange: (v) => this.updateDate('show_from', v)
                    }}
                    fieldDecorator={{
                      id: 'show_from',
                      options: {
                        initialValue: promotionGroup.show_from ? moment(promotionGroup.show_from)/*.tz("Asia/Kuala_Lumpur")*/ : moment().startOf('day'),
                        rules: [
                          { required: true, message: 'Please select a date' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'From'
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>
                <Col lg={10}>
                  <DatePicker
                    datePicker={{
                      allowClear: false,
                      placeholder: 'Select a date',
                      style: { width: '100%' },
                      onChange: (v) => this.updateDate('show_to', v)
                    }}
                    fieldDecorator={{
                      id: 'show_to',
                      options: {
                        initialValue: promotionGroup.show_to ? moment(promotionGroup.show_to)/*.tz("Asia/Kuala_Lumpur")*/ : moment().add(1, 'day').startOf('day'),
                        rules: [
                          { required: true, message: 'Please select a date' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'To'
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>
              </Row>
            </Panel>

            {
              rewardMissionItem && Array.isArray(rewardMissionItem) && rewardMissionItem.length > 0?
                rewardMissionItem.map((rewardMissionItemItem, index) => {
                  const {limitAmount, type, reward_id, amount, prefix_login_value, prefix_order_value, prefix_review_value, step, condition_type, newId, _id, isDelete} = rewardMissionItemItem
                  let sectionFirstIndex = 0, sectionLastIndex = rewardMissionItem.length - 1

                  return(
                    <Panel 
                      title={`Step ${step}`} 
                      key={`rulesOptions_${_id? _id: newId}`} style={{display: isDelete? "none" : ''}}>
                        
                      <Row className='rewards-days' style={{alignItems:'center', display:'flex', marginBottom: '5px'}}>  
                        <Col lg={5}>
                          <label>Condition Type</label>
                        </Col>                  
                        <Col lg={2}>
                          <Select
                            allowClear
                            defaultValue={condition_type && condition_type === 1 ? 'And' : condition_type && condition_type === 2 ? 'Or' : null}
                            disabled={loading}
                            onChange={(value) => this.changeConditionValue(_id, newId, value)}
                            placeholder='Days'
                            style={{ width: '100%' }}
                            
                          >
                            <Option value='1'>And</Option>
                            <Option value='2'>Or</Option>
                          </Select>
                        </Col>
                      </Row>


                      {
                        isDelete?
                          undefined
                        :  
                        condition_type?
                          <div>
                            <Row>
                              <Col lg={12}>
                                  <Row className='rewards-days' style={{alignItems:'center', display:'flex'}}>                     
                                    <Col lg={10}>
                                      <label>Continuous Login</label>
                                    </Col>
                                    <Col lg={5}>
                                      <Select
                                      allowClear
                                      defaultValue={prefix_login_value}
                                      disabled={loading}
                                      /*onChange={(value) => this.changeActive(value)}*/
                                      onChange={(value) => this.changeLoginDays(_id, newId, value)}
                                      placeholder='Days'
                                      style={{ width: '100%' }}
                                        >
                                        <Option key={`cl${_id ? _id : newId}_1`} value='1'>1</Option>
                                        <Option key={`cl${_id ? _id : newId}_2`} value='2'>2</Option>
                                        <Option key={`cl${_id ? _id : newId}_3`} value='3'>3</Option>
                                        <Option key={`cl${_id ? _id : newId}_4`} value='4'>4</Option>
                                        <Option key={`cl${_id ? _id : newId}_5`} value='5'>5</Option>
                                        <Option key={`cl${_id ? _id : newId}_6`} value='6'>6</Option>
                                        <Option key={`cl${_id ? _id : newId}_7`} value='7'>7</Option>
                                        <Option key={`cl${_id ? _id : newId}_8`} value='8'>8</Option>
                                        <Option key={`cl${_id ? _id : newId}_9`} value='9'>9</Option>
                                        <Option key={`cl${_id ? _id : newId}_10`} value='10'>10</Option>
                                        <Option key={`cl${_id ? _id : newId}_11`} value='11'>11</Option>
                                        <Option key={`cl${_id ? _id : newId}_12`} value='12'>12</Option>
                                        <Option key={`cl${_id ? _id : newId}_13`} value='13'>13</Option>
                                        <Option key={`cl${_id ? _id : newId}_14`} value='14'>14</Option>
                                        <Option key={`cl${_id ? _id : newId}_15`} value='15'>15</Option>
                                        <Option key={`cl${_id ? _id : newId}_16`} value='16'>16</Option>
                                        <Option key={`cl${_id ? _id : newId}_17`} value='17'>17</Option>
                                        <Option key={`cl${_id ? _id : newId}_18`} value='18'>18</Option>
                                        <Option key={`cl${_id ? _id : newId}_19`} value='19'>19</Option>
                                        <Option key={`cl${_id ? _id : newId}_20`} value='20'>20</Option>
                                        <Option key={`cl${_id ? _id : newId}_21`} value='21'>21</Option>
                                        <Option key={`cl${_id ? _id : newId}_22`} value='22'>22</Option>
                                        <Option key={`cl${_id ? _id : newId}_23`} value='23'>23</Option>
                                        <Option key={`cl${_id ? _id : newId}_24`} value='24'>24</Option>
                                        <Option key={`cl${_id ? _id : newId}_25`} value='25'>25</Option>
                                        <Option key={`cl${_id ? _id : newId}_26`} value='26'>26</Option>
                                        <Option key={`cl${_id ? _id : newId}_27`} value='27'>27</Option>
                                        <Option key={`cl${_id ? _id : newId}_28`} value='28'>28</Option>
                                        <Option key={`cl${_id ? _id : newId}_29`} value='29'>29</Option>
                                        <Option key={`cl${_id ? _id : newId}_30`} value='30'>30</Option>
                                        <Option key={`cl${_id ? _id : newId}_31`} value='31'>31</Option>
                                      </Select>
                                    </Col>
                                  </Row>

                                  <Row className='rewards-days' style={{alignItems:'center', display:'flex'}}>                     
                                    <Col lg={10}>
                                      <label>Order Minimum Purchase (RM)</label>
                                    </Col>
                                    <Col lg={5}>
                                      <div className='order-item'>
                                      <Input
                                        onChangeEvent={(value)=> this.changeOrderAmount(_id, newId, value)}
                                        fieldDecorator={{
                                          id: _id? `orderSpend${_id}` : `orderSpend${newId}`,
                                          options: {
                                            initialValue: prefix_order_value,
                                            rules: [
                                              //{ min: 1, message: 'Min. Quantity must be integer' },
                                              { validator: (rule, value, callback) => this.checkDecimal(rule, value, callback) }
                                            ]
                                          }
                                        }}

                                        
                                        form={form}
                                        formItem={{
                                          ...formItemLayout
                                        }}
                                        input={{
                                          tabIndex: 6
                                        }}
                                      />
                                      </div> 
                                    </Col>
                                  </Row>

                                  <Row className='rewards-days' style={{alignItems:'center', display:'flex'}}>                     
                                    <Col lg={10}>
                                      <label>Minimum Review</label>
                                    </Col>
                                    <Col lg={5}>
                                      <div className='order-item'>
                                      <Input
                                        onChangeEvent={(value)=> this.changeReviewAmount(_id, newId, value)}
                                        fieldDecorator={{
                                          id: _id? `reviewReward${_id}` : `reviewReward${newId}`,
                                          options: {
                                            initialValue: prefix_review_value,
                                            rules: [
                                              //{ min: 1, message: 'Min. Quantity must be integer' },
                                              { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) }
                                            ]
                                          }
                                        }}
                                        form={form}
                                        formItem={{
                                          ...formItemLayout
                                        }}
                                        input={{
                                          tabIndex: 6
                                        }}
                                      />
                                      </div> 
                                    </Col>
                                  </Row> 
                              </Col>

                              <Col lg={12}>
                                <Row className='rewards-days' style={{alignItems:'center', display:'flex'}}>                     
                                <Col lg={10}>
                                  <label>Reward Amount</label>
                                </Col>
                                <Col lg={5}>
                                  <div className='order-item'>
                                  <Input
                                    onChangeEvent={(value)=> this.changeRewardAmount(_id, newId, value)}
                                    fieldDecorator={{
                                      id: _id? `rewardAmount${_id}` : `rewardAmount${newId}`,
                                      options: {
                                        initialValue: amount,
                                        rules: [
                                          //{ min: 1, message: 'Min. Quantity must be integer' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) }
                                        ]
                                      }
                                    }}
                                    form={form}
                                    formItem={{
                                      ...formItemLayout
                                    }}
                                    input={{
                                      tabIndex: 6
                                    }}
                                  />
                                  </div> 
                                </Col>
                              </Row>   

                              <Row className='rewards-days' style={{alignItems:'center', display:'flex'}}>                     
                                <Col lg={10}>
                                  <label>Limit</label>
                                </Col>
                                <Col lg={5}>
                                  <div className='order-item'>
                                  <Input
                                    onChangeEvent={(value)=> this.changeLimit(_id, newId, value)}
                                    fieldDecorator={{
                                      id: _id? `limitAmount${_id}` : `limitAmount${newId}`,
                                      options: {
                                        initialValue: limitAmount,
                                        rules: [
                                          //{ min: 1, message: 'Min. Quantity must be integer' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) }
                                        ]
                                      }
                                    }}
                                    form={form}
                                    formItem={{
                                      ...formItemLayout
                                    }}
                                    input={{
                                      tabIndex: 6
                                    }}
                                  />
                                  </div> 
                                </Col>
                              </Row> 
                              </Col>
                            </Row>
                               

                            

                            <Row style={{display:'flex', alignItems:'center'}}>
                            <Button disabled={loading} onClick={() => this.deleteRow(_id, newId)}>Delete</Button>    
                              <div className='position' style={{marginLeft: '10px'}} >
                                { index !== sectionFirstIndex
                                  ? <AntButton type='primary' icon='caret-up' size='small' onClick={(e) => {
                                      e.stopPropagation()
                                      this.changeStep(_id, newId, step - 1 )
                                    }} />
                                  : null
                                }
                                { index !== sectionLastIndex
                                  ? <AntButton style={{marginLeft: '5px'}} type='primary' icon='caret-down' size='small' onClick={(e) => {
                                      e.stopPropagation()
                                      this.changeStep(_id, newId, step + 1 )
                                    }} />
                                  : null
                                }
                              </div>
                            </Row>      
                          </div>
                        :
                          undefined
                      }
                    </Panel>
                  )
                })
              :
              undefined
            }   

            {
              <Button disabled={loading} onClick={() => this.addNewRow()}>Add</Button>
            }     
            
          </Form>
        </Page.Body>
      </Page.Content>
    )
  }

  checkInteger (rule, value, callback) {
    const { item } = this.state
    if (value && !( /^[0-9\b]+$/).test(value)) {
      callback('Must be integer')
    }
    callback()
  }

  /* Add function */

  addNewRow(type){
    const {rewardMissionItem} = this.state
    const id = this.props.match.params.id

    /* const {type, reward_id, amount, prefix_login_value, prefix_order_value, prefix_review_value, step, condition} = rewardMissionItemItem */
    if(rewardMissionItem && Array.isArray(rewardMissionItem) && rewardMissionItem.length > 0){
      let newStepId = 1
       for(let temp = rewardMissionItem.length - 1; temp >= 0; temp--){
        if(rewardMissionItem[temp] && !rewardMissionItem[temp].isDelete){
          newStepId = rewardMissionItem[temp].step + 1
          break
        }
       }
       rewardMissionItem.push({reward_id:id,isNew: true, newId:new Date().getTime(), prefix_login_value:null, prefix_order_value:null, prefix_review_value:null, step:newStepId, condition_type:null, amount: null, limitAmount: null, total_reward_amount: null})
    }else{
      rewardMissionItem.push({reward_id:id,isNew: true, newId:new Date().getTime(), prefix_login_value:null, prefix_order_value:null, prefix_review_value:null, step:1, condition_type:null, amount: null, limitAmount: null, total_reward_amount: null})
    }
   
       

    this.setState({rewardMissionItem})
  }

  /* Add function - end */

  /* change function */
  changeConditionValue(id, tempId, value){
    const {rewardMissionItem} = this.state
    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        rewardMissionItem[findInex].condition_type = value
        rewardMissionItem[findInex]['isChange'] = true
      }
    }else if(tempId){
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].condition_type = value
      }
    }
    this.setState({rewardMissionItem})
  }
  changeLoginDays(id, tempId, value){
    const {rewardMissionItem} = this.state
    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        rewardMissionItem[findInex].prefix_login_value = value
        rewardMissionItem[findInex]['isChange'] = true
      }
    }else if(tempId){

      
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].prefix_login_value = value
      }
    }
    this.setState({rewardMissionItem})
  }

  changeOrderAmount(id, tempId, value){

    const {rewardMissionItem} = this.state
    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        rewardMissionItem[findInex].prefix_order_value = value.target.value
        rewardMissionItem[findInex]['isChange'] = true
      }
    }else if(tempId){

      
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].prefix_order_value = value.target.value
      }
    }
    this.setState({rewardMissionItem})
  }

  changeReviewAmount(id, tempId, value){

    const {rewardMissionItem} = this.state
    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        rewardMissionItem[findInex].prefix_review_value = value.target.value
        rewardMissionItem[findInex]['isChange'] = true
      }
    }else if(tempId){

      
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].prefix_review_value = value.target.value
      }
    }

    this.setState({rewardMissionItem})
  }

  changeRewardAmount(id, tempId, value){

    const {rewardMissionItem} = this.state
    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        rewardMissionItem[findInex].amount = value.target.value
        rewardMissionItem[findInex]['isChange'] = true
      }
    }else if(tempId){

      
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].amount = value.target.value
      }
    }

    this.setState({rewardMissionItem})
  }

  changeLimit(id, tempId, value){

    const {rewardMissionItem} = this.state
    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        rewardMissionItem[findInex].limitAmount = value.target.value
        rewardMissionItem[findInex]['isChange'] = true
      }
    }else if(tempId){

      
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].limitAmount = value.target.value
      }
    }

    this.setState({rewardMissionItem})
  }

  changeStep(id, tempId, value){
    const {rewardMissionItem} = this.state

    Array.prototype.move = function(from,to){
      this.splice(to,0,this.splice(from,1)[0]);
      return this;
    };

    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)
      if(findInex > -1){
        rewardMissionItem[findInex].step = 2
        rewardMissionItem[findInex]['isChange'] = true

        rewardMissionItem.move(findInex,value-1);//["a", "d", "b", "c", "e"]
      }
    }else if(tempId){
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem[findInex].step = value
      }
      rewardMissionItem.move(findInex,value-1);//["a", "d", "b", "c", "e"]
    }
    
    this.setState({rewardMissionItem}, ()=>{this.sortStep()})
  }

  sortStep(){
    const {rewardMissionItem} = this.state
    let newIndex = 1
    for(let tempI = 0; tempI < rewardMissionItem.length; tempI++){
      if(rewardMissionItem[tempI] && !rewardMissionItem[tempI].isDelete){
        rewardMissionItem[tempI].step = newIndex
        rewardMissionItem[tempI]['isChange'] = true
        newIndex++
      }
    }
    this.setState({rewardMissionItem})
  }
  /* change function - end */

  /* delete function  */
  deleteRow(id, tempId){
    this.setState({loading : true})
    const {rewardMissionItem} = this.state

    if(id){
      let findInex = rewardMissionItem.findIndex(x => x && x._id && x._id === id)

      if(findInex > -1){
        delete  rewardMissionItem[findInex]['isNew'];
        delete  rewardMissionItem[findInex]['isChange'];
        rewardMissionItem[findInex]['isDelete'] = true
      }
    }else if(tempId){
      let findInex = rewardMissionItem.findIndex(x => x && x.newId && x.newId === tempId)

      if(findInex > -1){
        rewardMissionItem.splice(findInex, 1)
      }
    }

    this.setState({rewardMissionItem}, ()=>{this.arrangeData(id, tempId)})
    this.setState({loading : false})
  }

  arrangeData(id, tempId){
    const {rewardMissionItem} = this.state
    let newIndex = 1
    for(let tempI = 0; tempI < rewardMissionItem.length; tempI++){
      if(rewardMissionItem[tempI] && !rewardMissionItem[tempI].isDelete){
        rewardMissionItem[tempI].step = newIndex
        rewardMissionItem[tempI]['isChange'] = true
        newIndex++
      }
    }
    this.setState({rewardMissionItem})
  }
  /* delete function - end  */

  checkDecimal (rule, value, callback) {
    if (value && value.length > 0 && !validator.isDecimal(value)) {
      callback(new Error('Can contain only numbers'))
    } 

    callback()
  }

  async fetchPromotion ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) {
    try {
      
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load Promotion products successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async fetchPromotionGroup () {
    const id = this.props.match.params.id
    try {
      let group = await rewardMissionService.getGroup(id)
      group.show_from = formatter.toISOString(group.show_from)
      group.show_to= formatter.toISOString(group.show_to)
      this.setState({ promotionGroup: group })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load Rewards mission successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  async fetchPromotionGroupItem () {
    const id = this.props.match.params.id
    try {
      let groupItem = await rewardMissionService.getGroupItem(id)
      /*const data = {login: [], order: [], review: []}
      if(groupItem && Array.isArray(groupItem) && groupItem.length > 0){
        for(let tempI = 0; tempI < groupItem.length; tempI++){
          if(groupItem[tempI] && groupItem[tempI].type){
            switch (groupItem[tempI].type) {
              case 1:
                data.login.push(groupItem[tempI])
                break;
              case 2:
                data.order.push(groupItem[tempI])
                break;
              default:
                data.review.push(groupItem[tempI])
            }
          }
        }
      }*/
      this.setState({rewardMissionItem: groupItem})
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load Rewards mission item successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete () {
    const { history, match } = this.props

    confirm({
      title: 'Are you sure you want to delete this Reward Mission group?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await rewardMissionService.removeGroup(match.params.id)
          if (response) {
            notification.show('success', 'Deleted successfully', 'Reward mission group deleted successfully.')
            history.replace('/rewardMission')
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete reward mission group successfully. Please try again later.')
        }
      }
    })
  }

  handleSave () {
    const { history } = this.props
    const { validateFields, getFieldsError } = this.props.form
    const { promotionGroup } = this.state

    validateFields();

    //const { getFieldDecorator, validateFields, getFieldsError } = props.form;

    const validationErrors = Object.values(getFieldsError());

    validateFields(['show_from', 'show_to', 'name'], async (errors, values) => {

      if (validationErrors.find(e => e !== undefined)){
        return
      }


      const { match } = this.props
      values.show_from = values.show_from ? formatter.toISOString(moment(values.show_from).hour(0).minute(0).second(0).millisecond(0)) : undefined
      values.show_to = values.show_to ? formatter.toISOString(moment(values.show_to).hour(23).minute(59).second(59).millisecond(0)) : undefined
      this.setState({ loading: true })

      const {rewardMissionItem} = this.state

      try {
        if (this.isEdit()) {
          values['rewardMissionItem'] = rewardMissionItem
          const response = await rewardMissionService.saveGroup(match.params.id, values)
          this.setState({ loading: false })

          promotionGroup.name = values.name
          promotionGroup.show_from = values.show_from
          promotionGroup.show_to = values.show_to
      
          this.setState({ promotionGroup })

          this.fetchPromotionGroupItem()

          if (response._id) {
            notification.show('success', 'Saved successfully', 'Reward mission group saved successfully.')
          }else if(response.message){
            notification.show('error', response.message || 'Something wrong. Please try again later.')
          }
        } else {
          if (!errors) {
            values['rewardMissionItem'] = rewardMissionItem
            const response = await rewardMissionService.addGroup(values)
            this.setState({ loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Reward mission group saved successfully.')
              window.location.replace(`/rewardMission/${response._id}`)
            }else if(response.message){
              notification.show('error', response.message || 'Something wrong. Please try again later.')
            }
          }
        }
        this.setState({ loading: false })
      } catch (e) {
        console.log(e)
        notification.show('error', 'Unable to save successfully', 'Unable to save reward mission group successfully. Please try again later.')
      }
    })
  }

  updateDate (label, value) {
    const { promotionGroup } = this.state
    promotionGroup[label] = value

    this.setState({ promotionGroup })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  canAdd () {
    return this.hasAccess('createRewardsMission')
  }

  canDelete () {
    return this.hasAccess('deleteRewardsMission')
  }

  canList () {
    return this.hasAccess('listRewardsMission')
  }

  canRead () {
    return this.hasAccess('readRewardsMission')
  }

  canSave () {
    return this.isEdit() ? this.hasAccess('updateRewardsMission') : this.hasAccess('createRewardsMission')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(RewardsDetail))
