import React, { Component } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import moment from "moment";
import { magento } from "../../../config";
import {
  authService,
  productService,
  trendingService
} from "../../../services";
import {
  formatter,
  notification,
  sessionStorage,
  validator
} from "../../../util";

// UI
import {
  Button,
  DatePicker,
  Input,
  List,
  Page,
  Pager,
  Panel,
  SearchInput,
  Select as WDSelect,
  Spin,
  Switch as WDSwitch,
} from "../../../components";
import Col from "antd/lib/col";
import Icon from "antd/lib/icon";
import Row from "antd/lib/row";
import Form from "antd/lib/form";
import Modal from "antd/lib/modal";
import OptionModal from "antd/lib/modal";
import Select from "antd/lib/select";
import Switch from "antd/lib/switch";
import Tooltip from "antd/lib/tooltip";
import WDSelectSearch from "../../../components/SelectSearch";
import "./styles.css";
import { Checkbox } from "antd";


const filterKey = "trending.filter"
const pageSize = 100;
const confirm = Modal.confirm;
const FormItem = Form.Item;
const Option = Select.Option;
const newfilterKey = 'product.filter'
const pageKey = 'product.page'

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class TrendingProduct extends Component {
  constructor(props) {
    super(props);
    let isPromo = this.props.isPromotion
    this.state = {   
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      maxPosition: 0,
      loading: false,
      modal: {
        isAdd: true,
        selectedProduct: {},
        show: false
      },
      optionModal: {
        isAddOptionModal: true,
        selectedProductOptionModal: {},
        showOptionModal: false
      },
      param: {  
        brand: "",
        category: "",
        keyword: "",
        newArrival: "",
        discountFrom:  0,
        discountTo: 0,
        discountPeriodFrom:  "",
        discountPeriodTo: "",
        priceFrom: 0,
        priceTo: 0,
        isPromo,
      },
      
      products: { data: [], total: 0 },
      productImagePreview: "",
      searchText: "",
      sort: {},
      trendingGroup: { name: "" },
      trendings: { list: [], total: 0 }
    };
    this.selectFilter = this.selectFilter.bind(this)
    this.fetchProducts = debounce(this.fetchProducts, 500);
    this.findTrendings = debounce(this.findTrendings, 500);
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount() {
    if (this.canList()) {
      const { currentPage, filter, searchText, sort } = this.state;
      this.fetchTrendingGroup();

      if (this.isEdit()) {
        this.fetchTrendings({
          loading: true,
          currentPage,
          filter,
          searchText,
          sort
        });
      }
    } else {
      authService.unauthorizeAction("/trendings");
    }
  }

  render() {
    const modalItemLayout = {
      labelCol: { sm: 5, md: 5 },
      wrapperCol: { sm: 16, md: 16 }
    };
    const { isPromotion,form, history, readOnly = false,} = this.props;
    const {
      currentPage,
      filter,
      loading,
      modal,
      optionModal,
      products,
      productImagePreview,
      trendingGroup,
      trendings,
    } = this.state;
    const { getFieldValue } = form;
    const { list, total } = trendings;
    const { active } = filter;
    const { isAdd, selectedProduct, show } = modal;
    const { isAddOptionModal, selectedProductOptionModal, showOptionModal } = optionModal
    const { data: productList} = products;

    const formItemFullLayout = {
      labelCol: { sm: 4, md: 4 },
      wrapperCol: { sm: 20, md: 20 }
    };

    const formItemLayout = {
      labelCol: { sm: 7, md: 8 },
      wrapperCol: { sm: 14, md: 14 }
    };
    
    const formItemLayer = {
      labelCol: { sm: 24, md: 24 },
      wrapperCol: { sm: 24, md: 24 }
    };



    const columns = [
      {
        title:'',
        key:'entity_id',
        width: 1,
        render: row => {
          const { isChecked } = this.state;
          return (
            <Checkbox checked={isChecked!=null && isChecked.includes(row.sku)} value={row.sku} onChange={(e)=>this.handlechange(e)}/>
          );
        }
      },
      {
        title: 'Sku',
        key: 'sku',
        width: 2,
      },
      {
        title: 'Image',
        cssClassName: 'center-header',
        align: 'center', 
        key: 'thumbnail',
        render: (row) => {
          return(
          <img
            className={"option-modal-img"}
            alt=""
            src={
              row.thumbnail
                ? magento.media + row.thumbnail
                : "/img/default-image.png"
            }
          />
          )
        },
                  
        width: 4,         
      },
      {
        title: 'Name',
        key: 'name',
        width: 3,
      },
      {
        title: 'Brand',
        key: 'brand',
        width: 3,
      },
      {
        title: 'Category',
        key: 'category',
        width: 3,
      },
      {
        title: 'Price',
        key: 'price',
        width: 2,
      },
      {
        title: 'Discount',
        key: 'discount',
        width: 2,
      },
      {
        title: 'Discount period',
        key: 'discount_period',
        width: 4,
      }
    ]


    return (
      <Page.Content>
        <Page.Header
          title={`Trending Products`}
          description="Manage trending products"
        >
          {this.isEdit() && this.canDelete() ? (
            <Button disabled={loading} onClick={() => this.handleDelete()}>
              Delete
            </Button>
          ) : null}

          {this.canSave() ? (
            <Button disabled={loading} onClick={() => this.handleSave()}>
              Save
            </Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>
            Back
          </Button>
        </Page.Header>

        <Page.Body>
          <Form>
            <Panel title="Trending Group">
              <Row>
                <Col lg={20}>
                  <Input
                    fieldDecorator={{
                      id: "name",
                      options: {
                        initialValue: trendingGroup.name,
                        rules: [
                          {
                            min: 2,
                            message: "Name must be between 2 and 128 characters"
                          },
                          {
                            max: 128,
                            message: "Name must be between 2 and 128 characters"
                          },
                          { required: true, message: "Please enter name" },
                          { whitespace: true, message: "Please enter name" }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemFullLayout,
                      label: "Name"
                    }}
                    input={{
                      tabIndex: 1
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={10}>
                  <DatePicker
                    datePicker={{
                      allowClear: false,
                      placeholder: "Select a date",
                      style: { width: "100%" },
                      onChange: v => this.updateDate("show_from", v)
                    }}
                    fieldDecorator={{
                      id: "show_from",
                      options: {
                        initialValue: trendingGroup.show_from
                          ? moment(trendingGroup.show_from)
                          : moment().startOf("day"),
                        rules: [
                          { required: true, message: "Please select a date" }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: "From"
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>
                <Col lg={10}>
                  <DatePicker
                    datePicker={{
                      allowClear: false,
                      placeholder: "Select a date",
                      style: { width: "100%" },
                      onChange: v => this.updateDate("show_to", v)
                    }}
                    fieldDecorator={{
                      id: "show_to",
                      options: {
                        initialValue: trendingGroup.show_to
                          ? moment(trendingGroup.show_to)
                          : moment()
                              .add(1, "day")
                              .startOf("day"),
                        rules: [
                          { required: true, message: "Please select a date" }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: "To"
                    }}
                    readOnly={!this.canSave()}
                  />
                </Col>
              </Row>
            </Panel>
          </Form>

          {this.isEdit() ? (
            <Panel title={`Products (${total})`}>
              <Page.Filter>
                <Row gutter={8}>
                  <Col lg={6}>
                    <SearchInput
                      placeholder="Search Products..."
                      onChange={value => this.findTrendings(value)}
                    />
                  </Col>

                  <Col lg={6}>
                    <Row gutter={8}>
                      <Col lg={12}>
                        <Tooltip title="Trending">
                          <Select
                            allowClear
                            defaultValue={
                              typeof active === "boolean"
                                ? active
                                  ? "Yes"
                                  : "No"
                                : undefined
                            }
                            disabled={loading}
                            onChange={value => this.changeActive(value)}
                            placeholder="Trending"
                            style={{ width: "100%" }}
                          >
                            <Option value="Yes">On</Option>
                            <Option value="No">Off</Option>
                          </Select>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>

                  <Col lg={12} style={{ textAlign: "right" }}>
                    {this.canAdd() ? (
                      <Tooltip title="Maximum 200 trending products">
                        <div className="addProductBtn">
                        <Button
                          disabled={this.isMaxProduct()}
                          onClick={() => this.showProductModal(true)}
                        >
                          Add Product
                        </Button>
                        <Button
                          disabled={this.isMaxProduct()}
                          onClick={() => this.showOptionModal(true)}
                        >
                          Add Product(s)
                        </Button>
                        </div>
                        {/*<OptionModalButton
                        fieldDecorator={{
                          id: "sku",
                          options: {
                            initialValue: selectedProduct.sku,
                          }
                        }}
                        form={form}
                        formItem={{
                          ...modalItemLayout,
                          hasFeedback: false,
                        }}
                        list={productList}
                        listFunc={product => {
                          const { name, sku } = product;
                        }}
                        filterKey={filterKey}
                        readOnly = {!this.canSave()}
                        modalInfo={
                          this.state.modal
                        }   
                      />*/}
                      </Tooltip>
                    ) : null}
                  </Col>
                </Row>
              </Page.Filter>
              <Row>
                <Col>
                  <Spin loading={loading} blur>
                    <div className="wd-trending-list">
                      {list.map((item, index) => {
                        const {
                          _id,
                          active,
                          product_sku: sku,
                          product_name: name,
                          price,
                          image: thumbUrl = "",
                          position,
                          show_from: from,
                          show_to: to
                        } = item;
                        const Row = props => {
                          // const valid = moment().isBetween(from, to)
                          return (
                            <div
                              className={formatter.toClassName([
                                "wd-trending-card",
                                props.className
                              ])}
                            >
                              {/* <div className='wd-trending-active'>
                                <div className='wd-trending-active-badge' style={{ backgroundColor: valid ? '#27CD7F' : '#C1C1C1' }} />
                              </div> */}

                              <img
                                className={"wd-trending-img"}
                                alt=""
                                src={
                                  thumbUrl
                                    ? magento.media + thumbUrl
                                    : "/img/default-image.png"
                                }
                              />

                              <div className="wd-trending-info">
                                <div className="name">{name}</div>

                                <div className="priceRow">
                                  {price
                                    ? "RM " +
                                      parseFloat(
                                        Math.round(price * 100) / 100
                                      ).toFixed(2)
                                    : "NULL"}
                                  {/* <div className='position'><img className='wd-trending-order' alt='' src={'/icon/list-order.svg'} /> {position}</div> */}
                                </div>

                                {/* <div className='remark'>{formatter.toShortDate(from)} - {formatter.toShortDate(to)}</div> */}

                                {this.canSave() ? (
                                  <div
                                    className="switch"
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Switch
                                      defaultChecked={active === 1}
                                      checkedChildren="On"
                                      unCheckedChildren="Off"
                                      onChange={value =>
                                        this.editProduct(_id, {
                                          sku,
                                          position,
                                          from,
                                          to,
                                          active: value
                                        })
                                      }
                                    />
                                  </div>
                                ) : null}

                                {this.canDelete() ? (
                                  <div
                                    className={`wd-trending-delete${
                                      this.canSave() ? "" : " lower-delete"
                                    }`}
                                    onClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      this.deleteProduct(_id);
                                    }}
                                  >
                                    <div className="button">
                                      <Icon type="delete" />
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        };

                        return this.canRead() ? (
                          <a
                            key={_id}
                            onClick={() => this.showProductModal(false, item)}
                          >
                            <Row />
                          </a>
                        ) : (
                          <Row className="no-pointer" key={_id} />
                        );
                      })}
                    </div>
                  </Spin>
                </Col>
              </Row>

              <Pager
                size={pageSize}
                total={total}
                totalText={`Total ${total} products`}
                current={currentPage}
                onChange={(currentPage) => this.changePage(currentPage)}
                style={{ marginTop: '15px' }}
              />
            </Panel>
          ) : null}

          <Modal visible={show}
            className ={'baseModal'}
            width={720}
            title={isAdd ? "Add Product" : "Edit Product"}
            onOk={() => this.hideProductModal()}
            onCancel={() => this.hideProductModal()}
            footer={[
              <Button key="back" onClick={() => this.hideProductModal()}>
                Cancel
              </Button>,
              this.canAdd() || this.canSave() ? (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => this.saveProduct(isAdd, selectedProduct)}
                >
                  {isAdd ? "Add" : "Save"}
                </Button>
              ) : null
            ]}
          >
            <Form layout="vertical">
              <Row gutter={24}>
                <Col
                  md={24}
                  style={{ textAlign: "center", marginBottom: "15px" }}
                >
                  <img
                    className="product-image-medium"
                    alt=""
                    src={productImagePreview}
                  />
                </Col>
                <Row>
                <Col xs={24} md={24}>
                  <div>
                  <WDSelectSearch
                    fieldDecorator={{
                      id: "sku",
                      options: {
                        initialValue: selectedProduct.sku,
                        rules: [
                          { required: true, message: "Please select a product" }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...modalItemLayout,
                      hasFeedback: false,
                      label: "Product"
                    }}
                    list={productList}
                    listFunc={product => {
                      const { name, sku } = product;

                      return (                      
                        <Option key={sku} value={sku}>
                          <div className="text-wrap">{name}</div>
                          <div className="wd-trending-option-subtitle">
                            SKU: {sku}
                          </div>
                        </Option>
                      );
                    }}
                    filterKey={filterKey}
                    readOnly = {!this.canSave()}
                    onDoubleClick={
                      this.selectFilter
                    }
                    modalInfo={
                      this.state.modal
                    }
                    select={{
                      onChange: (value, option) =>this.selectProduct(value, option),
                      onSearch: value => this.fetchProducts(value),                      
                    }}                 
                  />
                  </div>
                </Col>
                
                
                
                </Row>      


                {getFieldValue("active") === true ? (
                  <Col>
                    {this.getPositionSelect(
                      modalItemLayout,
                      isAdd,
                      selectedProduct.position
                    )}
                  </Col>
                ) : null}

                <Col>
                  <WDSwitch
                    fieldDecorator={{
                      id: "active",
                      options: {
                        initialValue:
                          typeof selectedProduct.active === "number"
                            ? selectedProduct.active === 1
                            : true,
                        valuePropName: "checked"
                      }
                    }}
                    form={form}
                    formItem={{
                      ...modalItemLayout,
                      label: "Trending"
                    }}
                    input={{
                      checkedChildren: "On",
                      unCheckedChildren: "Off",
                      tabIndex: 2
                    }}
                    readOnly={!this.canAdd() && !this.canSave()}
                  />
                </Col>
              </Row>
            </Form>
          </Modal>
          <OptionModal
            visible={showOptionModal}
            width={"80%"}
            title={isAdd ? "Add Product" : "Edit Product"}
            onOk={() => this.hideOptionModal()}
            onCancel={() => this.hideOptionModal()}
            footer={[
              <Button key="back" onClick={() => this.hideOptionModal()}>
                Cancel
              </Button>,
              this.canAdd() || this.canSave() ? (
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => this.saveProducts(isAddOptionModal, selectedProductOptionModal)}
                >
                  {isAdd ? "Add" : "Save"}
                </Button>
              ) : null
            ]}
          >
            <div className="OptionModal">
            <Form layout="vertical" >
                  <Row>
                    <Col lg={7}>
                      <Input
                        fieldDecorator={{
                          id: "brand"
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayer,
                          label: "Brand"
                        }}
                        onChangeEvent={value =>this.updateData("brand",value)}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col lg={7}>
                      <Input
                        fieldDecorator={{
                          id: "category"
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayer,
                          label: "Category"
                        }}
                        onChangeEvent={(value) =>this.updateData("category",value)}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col lg={7}>
                      <Input
                        fieldDecorator={{
                          id: "keyword"
                        }}
                        form={form}
                        formItem={{
                          ...formItemLayer,
                          label: "Keyword"
                        }}
                        onChangeEvent={(value) =>this.updateData("keyword",value)}
                        readOnly={readOnly}
                      />
                    </Col>
                    <Col lg={3}>
                      <div className="ant-row ant-form-item wd-input"> 
                        <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 show-label">
                          New arrival?
                        </div>                      
                      <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        style={{ width: '100%' }}
                        onChange={value => this.updateData("newArrival",value)}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select>
                      </div>
                    </Col>  
                    
                  </Row>
                  <Row>
                    <Col lg={7}>
                      <Col lg={11}>
                        <Input
                          fieldDecorator={{
                            id: "discountfrom",
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "Discount"
                          }}
                          onChangeEvent={(value) =>this.updateData("discountFrom",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="ant-row ant-form-item wd-input"> 
                          <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                            a
                          </div>
                          <div >
                            -
                          </div>
                        </div>
                      </Col>
                      
                      <Col lg={11} className="to-hid-label">
                        <Input
                          fieldDecorator={{
                            id: "discountto"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "to",
                            //style:{ visibility:"hidden" }
                          }}
                          onChangeEvent={(value) =>this.updateData("discountTo",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                    </Col>
                    <Col lg={10}> 
                      <Col lg={11}>
                        <DatePicker
                          datePicker={{
                            placeholder: "From",
                            style: { width: "100%" },
                            onChange: value => this.updateData("discountPeriodFrom",value)
                          }}
                          fieldDecorator={{
                            id: "discountshow_from",
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "Discount Period"
                          }}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="ant-row ant-form-item wd-input"> 
                          <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                            a
                          </div>
                          <div >
                            -
                          </div>
                        </div>
                      </Col>
                      <Col lg={11} className="to-hid-label"> 
                        <DatePicker
                          datePicker={{
                            placeholder: "To",
                            style: { width: "100%" },
                            onChange: value => this.updateData("discountPeriodTo",value)
                          }}
                          fieldDecorator={{
                            id: "discountshow_to"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "To"
                          }}
                          readOnly={readOnly}
                        />
                      </Col>
                    </Col>
                    <Col lg={7}>
                      <Col lg={11}>
                        <Input
                          fieldDecorator={{
                            id: "pricefrom"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "Price range"
                          }}
                          onChangeEvent={(value) =>this.updateData("priceFrom",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                      <Col lg={2}>
                        <div className="ant-row ant-form-item wd-input"> 
                          <div className="ant-form-item-label ant-col-sm-24 ant-col-md-24 hid-label">
                            a
                          </div>
                          <div >
                            -
                          </div>
                        </div>
                      </Col>
                      <Col lg={11} className="to-hid-label">
                        <Input
                          fieldDecorator={{
                            id: "priceto"
                          }}
                          form={form}
                          formItem={{
                            ...formItemLayer,
                            label: "to"
                          }}
                          onChangeEvent={(value) =>this.updateData("priceTo",value)}
                          readOnly={readOnly}
                        />
                      </Col>
                    </Col>
                  </Row>  
                      <button className="wd-button" onClick={()=>this.showProductTable()} >
                        search
                      </button>
                      <Row>
                        <Col>
                            <List cols={columns} rows={products.data} /*onDoubleClick={(data)=>this.selectFilterProduct(data)}*//>
                            <Pager
                              size={pageSize}
                              total={products.total}
                              totalText={`Total ${products.total} products`}
                              current={currentPage}
                              onChange={currentPage => this.changeProductPage(currentPage)}
                              style={{ marginTop: "15px" }}
                            />
                        </Col>
                      </Row>
            </Form>
            </div>
          </OptionModal>
        </Page.Body>
      </Page.Content>
    );
  }

  checkDecimal(rule, value, callback) {
    if (value && value.length > 0 && !validator.isDecimal(value)) {
      callback(new Error("Can contain only numbers"));
    } else if (value < 1 || value > 127) {
      callback(new Error("Position must be between 1 and 127"));
    }

    callback();
  }

  changeActive(value) {
    const { currentPage, filter, searchText, sort } = this.state;

    if (value === "Yes") {
      filter.active = true;
    } else if (value === "No") {
      filter.active = false;
    } else {
      delete filter.active;
    }

    this.setState({ filter });
    this.fetchTrendings({
      loading: true,
      currentPage,
      filter,
      searchText,
      sort
    });
  }

  changePage (currentPage) {
    const { filter, searchText, sort } = this.state
    this.fetchTrendings({ loading: true, currentPage, filter, searchText, sort })
  }

  async fetchTrendings({
    loading = false,
    currentPage = 1,
    filter = {},
    sort = {},
    searchText
  }) {
    try {
      this.setState({ currentPage, loading });
      sessionStorage.setObject(filterKey, filter);
      Object.assign(filter, { trending_id: this.props.match.params.id });
      const trendings = await trendingService.listByPage(
        currentPage,
        pageSize,
        filter,
        searchText
      );

      const { list, total } = trendings;
      const inactiveList = list.filter(
        ({ active }) => active === 0 || active === false
      );
      const maxPosition = total + 1 - inactiveList.length;

      this.setState({ maxPosition, trendings });

      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    } catch (e) {
      notification.show(
        "error",
        "Unable to load successfully",
        "Unable to load trending products successfully. Please try again later."
      );
      this.setState({ loading: false });
    }
  }

  async fetchTrendingGroup() {
    const id = this.props.match.params.id;
    try {
      const group = await trendingService.getGroup(id);
      this.setState({ trendingGroup: group });
    } catch (e) {
      notification.show(
        "error",
        "Unable to load successfully",
        "Unable to load trending products successfully. Please try again later."
      );
      this.setState({ loading: false });
    }
  }

  findTrendings(text) {
    const { currentPage, filter, searchText, sort } = this.state;
    this.fetchTrendings({
      loading: true,
      currentPage: searchText !== text ? 1 : currentPage,
      filter,
      sort,
      searchText: text
    });
    this.setState({ searchText: text });
  }

  deleteProduct(id) {
    const that = this;
    const { currentPage, filter, searchText, sort, trendings } = this.state;
    const { list, total } = trendings;

    confirm({
      title: "Are you sure you want to delete this product?",
      content: "Press Ok to continue, Cancel to return",
      async onOk() {
        try {
          let updateList = [];
          let oldPosition = 0;

          for (let i = 0; i < total; i++) {
            const { _id, active, position } = list[i];
            const newItem = { id: _id };

            if (id === _id) {
              oldPosition = position;
            } else {
              if (oldPosition > 0 && position > oldPosition) {
                newItem.position = i;
              } else {
                newItem.position = i + 1;
              }

              if (active === false || active === 0) {
                newItem.position = 999;
              }

              updateList.push(newItem);
            }
          }

          const response = await trendingService.remove(id, {
            list: updateList
          });

          if (response._id) {
            const page =
              list.length - 1 > 0 ? currentPage : currentPage - 1 || 1;
            notification.show(
              "success",
              "Deleted successfully",
              "Trending product deleted successfully."
            );
            that.fetchTrendings({
              loading: true,
              currentPage: page,
              filter,
              searchText,
              sort
            });
          }
        } catch (e) {
          notification.show(
            "error",
            "Unable to delete successfully",
            "Unable to delete trending product successfully. Please try again later."
          );
        }
      }
    });
  }

  async editProduct(id, values) {
    try {
      const { maxPosition, trendings } = this.state;
      const { list } = trendings;
      const { active } = values;
      const { position: oldPosition = 0 } = list.find(item => item._id === id);
      values.positionChanged = true;
      values.position = active === false || active === 0 ? 999 : maxPosition;
      values.list = list.map((item, idx) => {
        const { _id, active, position: itemPosition } = item;
        const newItem = { id: _id };

        if (_id === id) {
          newItem.position = values.position;
        } else if (itemPosition > oldPosition) {
          newItem.position = idx;
        } else {
          newItem.position = idx + 1;
        }

        if (active === false || active === 0) {
          newItem.position = 999;
        }

        return newItem;
      });

      const response = await trendingService.save(id, values);

      if (response._id) {
        const { currentPage, filter, searchText, sort } = this.state;
        this.fetchTrendings({
          loading: true,
          currentPage,
          filter,
          searchText,
          sort
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleDelete() {
    const { history, match } = this.props;

    confirm({
      title: "Are you sure you want to delete this trending group?",
      content: "Press Ok to continue, Cancel to return",
      async onOk() {
        try {
          const response = await trendingService.removeGroup(match.params.id);
          if (response) {
            notification.show(
              "success",
              "Deleted successfully",
              "Trending group deleted successfully."
            );
            history.replace("/home/trendings");
          }
        } catch (e) {
          notification.show(
            "error",
            "Unable to delete successfully",
            "Unable to delete Trending group successfully. Please try again later."
          );
        }
      }
    });
  }


  handleSave() {
    const { history } = this.props;
    const { validateFields } = this.props.form;
    const { trendingGroup } = this.state;

    validateFields(["name", "show_from", "show_to"], async (errors, values) => {
      const { match } = this.props;
      values.show_from = values.show_from
        ? formatter.toISOString(
            moment
              .utc(values.show_from)
              .second(0)
              .millisecond(0)
          )
        : undefined;
      values.show_to = values.show_to
        ? formatter.toISOString(
            moment
              .utc(values.show_to)
              .second(0)
              .millisecond(0)
          )
        : undefined;

      this.setState({ loading: true });

      try {
        if (this.isEdit()) {
          const response = await trendingService.saveGroup(
            match.params.id,
            values
          );
          this.setState({ loading: false });

          trendingGroup.name = values.name;
          trendingGroup.show_from = values.show_from;
          trendingGroup.show_to = values.show_to;

          this.setState({ trendingGroup });

          if (response._id) {
            notification.show(
              "success",
              "Saved successfully",
              "Trending group saved successfully."
            );
          }
        } else {
          if (!errors) {
            const response = await trendingService.addGroup(values);
            this.setState({ loading: false });

            if (response._id) {
              notification.show(
                "success",
                "Saved successfully",
                "Trending group saved successfully."
              );
              history.replace(`home/trendings/${response._id}`);
            }
          }
        }
        this.setState({ loading: false });
      } catch (e) {
        console.log(e);
        notification.show(
          "error",
          "Unable to save successfully",
          "Unable to save trending group successfully. Please try again later."
        );
      }
    });
  }

  saveProducts(isAdd, selectedProduct) {
    try {
      const { form, match } = this.props;
      const { trendings, isChecked } = this.state;
      const { validateFields } = form;
      const { list, total } = trendings;

      if (total > 199) {
        notification.show(
          "error",
          "Unable to save successfully",
          "Maximum of 200 trending products."
        );
        return;
      }

      validateFields(async (errors, values) => {
        //line 1276-1278
        values.sku = isChecked
        let hasAvailableSku = false

        for (let i=0;i<values.sku.length;i++)
        {
          if (list.find(el => el.product_sku == values.sku[i])){
            values.sku[i]='';
          } else {
            hasAvailableSku = true
          }
        }

        if(!hasAvailableSku) {
          notification.show('error', 'Unable to save successfully', 'Unable to save Trending product successfully. Please try again later.')
          this.hideOptionModal()
          return
        }

        if (!errors) {
          const { active=true } = values;
          let response;
          values.from = formatter.toISOString(
            moment.utc(values.from).startOf("day")
          );
          values.to = formatter.toISOString(moment.utc(values.to).endOf("day"));
          values.trending_id = parseInt(match.params.id);
          values.position = total + 1;
          
          if (isAdd) {
            if (active === true) {
              const { position: newPosition } = values;
              const positionChanged = newPosition < total + 1;
              values.positionChanged = positionChanged;
              values.active = true;

              if (positionChanged) {
                values.list = list.map((item, idx) => {
                  const { _id, active, position: itemPosition } = item;
                  const newItem = {
                    id: _id,
                    position: itemPosition < newPosition ? idx + 1 : idx + 2
                  };

                  if (active === false || active === 0) {
                    newItem.position = 999;
                  }
                  return newItem;
                  
                });
              }
            } else {
              values.position = 999;
            }
          }
            
          response = await trendingService.addList(values);
  
          if (response._id) {
            const { currentPage, filter, searchText, sort } = this.state;
            this.fetchTrendings({
              loading: true,
              currentPage,
              filter,
              searchText,
              sort
            });
            notification.show(
              "success",
              "Saved successfully",
              "Trending product saved successfully."
            );
          } else {
            notification.show(
              "error",
              "Unable to save successfully",
              "Unable to save trending product successfully. Please try again later."
            );
          }
          this.hideOptionModal();
        }
        
        
        
      });
    } catch (e) {
      console.error(e);
    }
  }

  saveProduct(isAdd, selectedProduct) {
    try {
      const { form, match } = this.props;
      const { trendings } = this.state;
      const { validateFields } = form;
      const { list, total } = trendings;

      if (total > 199) {
        notification.show(
          "error",
          "Unable to save successfully",
          "Maximum of 200 trending products."
        );
        return;
      }



      validateFields(async (errors, values) => {
        if (list.find(el => el.product_sku == values.sku)){
          this.hideProductModal();
        }
        else if (!errors) {
          const { active } = values;
          let response;
          values.from = formatter.toISOString(
            moment.utc(values.from).startOf("day")
          );
          values.to = formatter.toISOString(moment.utc(values.to).endOf("day"));
          values.trending_id = parseInt(match.params.id);
          if (isAdd) {
            if (active === true) {
              const { position: newPosition } = values;
              const positionChanged = newPosition < total + 1;
              values.positionChanged = positionChanged;

              if (positionChanged) {
                values.list = list.map((item, idx) => {
                  const { _id, active, position: itemPosition } = item;
                  const newItem = {
                    id: _id,
                    position: itemPosition < newPosition ? idx + 1 : idx + 2
                  };

                  if (active === false || active === 0) {
                    newItem.position = 999;
                  }

                  return newItem;
                });
              }
            } else {
              values.position = 999;
            }
            response = await trendingService.add(values);
          } else {
            const { id } = selectedProduct;
            const { position: oldPosition = 0 } = list.find(
              item => item._id === id
            );
            // const itemFound = list.find((item) => item._id === id)
            // let oldPosition = itemFound.position || 0
            const { position: newPosition } = values;
            const positionChanged = newPosition !== oldPosition;
            values.positionChanged = positionChanged;

            if (positionChanged) {
              values.list = list.map((item, idx) => {
                const { _id, active, position: itemPosition } = item;
                const newItem = { id: _id };

                if (_id === id) {
                  newItem.position = newPosition || 999;
                } else {
                  if (newPosition < oldPosition) {
                    if (
                      itemPosition < newPosition ||
                      itemPosition > oldPosition
                    ) {
                      newItem.position = idx + 1;
                    } else {
                      newItem.position = idx + 2;
                    }
                  } else {
                    if (
                      itemPosition < oldPosition ||
                      itemPosition > newPosition
                    ) {
                      newItem.position = idx + 1;
                    } else {
                      newItem.position = idx;
                    }
                  }
                }

                if (active === false || active === 0) {
                  newItem.position = 999;
                }

                return newItem;
              });
            }

            response = await trendingService.save(id, values);
          }

          if (response._id) {
            const { currentPage, filter, searchText, sort } = this.state;
            this.fetchTrendings({
              loading: true,
              currentPage,
              filter,
              searchText,
              sort
            });
            this.hideProductModal();
            notification.show(
              "success",
              "Saved successfully",
              "Trending product saved successfully."
            );
          } else {
            notification.show(
              "error",
              "Unable to save successfully",
              "Unable to save trending product successfully. Please try again later."
            );
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  updateDate(label, value) {
    const { trendingGroup } = this.state;
    trendingGroup[label] = value;

    this.setState({ trendingGroup });
  }

  updateData(label, value) {
    const { param } = this.state;
    if(label=="discountPeriodFrom" || label=="discountPeriodTo" || label=="newArrival"){
      param[label] = value;
    } else {
      param[label] = value.target.value;
    }
    
    this.setState({ param });
  }

  async selectProduct(value, option) {
    const { modal } = this.state;
    const { selectedProduct } = modal;
    selectedProduct.sku = value;
    this.setState({ modal });
    const image = await productService.getMagentoImage(value);

    if (image) {
      const { file } = image;
      this.setState({ productImagePreview: file });
    }
  }

  async selectFilter (value, modal) {
    const testModal = {
      isAdd: true,
      selectedProduct: modal,
      show: true
    }
    this.setState({ modal:testModal })
    this.fetchProducts(value)
    const image = await productService.getMagentoImage(value)

    if (image) {
      const { file } = image
      this.setState({ productImagePreview: file })
    }
  }

  getPositionSelect(modalItemLayout, isAdd, position) {
    const { form } = this.props;
    const { maxPosition } = this.state;
    const defaultPosition = isAdd ? maxPosition : position;
    const list = [];
    const total = isAdd ? maxPosition : maxPosition - 1;

    for (let i = 1; i <= total; i++) {
      list.push(i);
    }

    return (
      <WDSelect
        fieldDecorator={{
          id: "position",
          options: {
            initialValue: defaultPosition
          }
        }}
        form={form}
        formItem={{
          ...modalItemLayout,
          hasFeedback: false,
          label: "Position"
        }}
        list={list}
        listFunc={position => {
          return (
            <Option key={position} value={position}>
              {position}
            </Option>
          );
        }}
        select={{
          showSearch: true,
          placeholder: "Select a position"
        }}
      />
    );
  }

  isMaxProduct() {
    const { loading, trendings } = this.state;
    const { total } = trendings;

    if (loading) {
      return true;
    }

    return total > 199;
  }

  hideProductModal() {
    const { form } = this.props;
    const { modal } = this.state;
    modal.show = false;
    this.setState({ modal });
    setTimeout(() => {
      form.resetFields();
      this.setState({
        modal: {
          isAdd: true,
          selectedProduct: {},
          show: false
        }
      });
    }, 100);
  }

  showProductModal(isAdd, selectedProduct) {
    const { modal } = this.state;
    let productImagePreview = "/img/default-image.png";
    modal.isAdd = isAdd;
    modal.show = true;
    this.fetchProducts();
    this.setState({ modal });

    if (isAdd === false) {
      const {
        _id,
        active,
        image,
        show_from: from,
        show_to: to,
        position,
        product_sku: sku
      } = selectedProduct;
      productImagePreview = image
        ? magento.media + image
        : "/img/default-image.png";
      modal.selectedProduct = {
        id: _id,
        active,
        from,
        position,
        sku,
        to
      };
    }

    this.setState({ modal, productImagePreview });
  }

  changeProductPage(currentPage) {
    const { filterKey } = this.props;
    this.newfetchProducts({ loading: true, currentPage, filterKey });
  }

  async fetchProducts() {
    try {
      const { modal, param } = this.state;
      const { selectedProduct } = modal;
      //const { sku } = selectedProduct;
      const products = await productService.listFilteredProductsByPage(
        1,
        pageSize,
        {},
        param
      );
      this.setState({ products });
    } catch (e) {
      console.error(e);
    }
  }

  handlechange(e) {
    let { isChecked } = this.state;
    
    if(isChecked!=null )
    {
      let a= isChecked.indexOf(e.target.value)
      if(a>=0)
      {
        isChecked.splice(a,1)
      }
      else {
        isChecked.push(e.target.value)
      }
    }
    else {
      isChecked = [e.target.value]
    }
    this.setState({isChecked})
  }

  async newfetchProducts({ loading = false, currentPage = 1, filter = {} }) {
    try {
      const { modal, param } = this.state;
      const { selectedProduct } = modal;
      sessionStorage.setObject(newfilterKey, filter);
      sessionStorage.setItem(pageKey, currentPage);
      this.setState({ currentPage, loading: loading });
      //const { sku } = selectedProduct;
      const products = await productService.listFilteredProductsByPage(
        currentPage,
        pageSize,
        {},
        param
      );
      this.setState({ products, loading: false });

      //this.setState({ products });
    } catch (e) {
      console.error(e);
    }
  }

  hideOptionModal() {
    let isPromo = this.props.isPromotion;
    const { form } = this.props;
    const { optionModal } = this.state;
    optionModal.showOptionModal = false;
    this.setState({ optionModal });
    setTimeout(() => {
      form.resetFields();
      this.setState({
        optionModal: {
          isAddOptionModal: true,
          selectedProductOptionModal: {},
          showOptionModal: false
        },
        param: {
          brand: "",
          category: "",
          keyword: "",
          newArrival: "",
          discountFrom: 0,
          discountTo: 0,
          discountPeriodFrom: "",
          discountPeriodTo: "",
          priceFrom: 0,
          priceTo: 0,
          isPromo
        },
        currentPage: 1
      });
    }, 100);
  }

  showOptionModal(isAdd, selectedProductOptionModal) {
    const { optionModal } = this.state;
    let productImagePreview = "/img/default-image.png";
    optionModal.isAdd = isAdd;
    optionModal.showOptionModal = true;
    this.fetchProducts();
    this.setState({ optionModal, isChecked:[] });

    if (isAdd === false) {
      const {
        _id,
        active,
        image,
        show_from: from,
        show_to: to,
        position,
        product_sku: sku
      } = selectedProductOptionModal;
      productImagePreview = image
        ? magento.media + image
        : "/img/default-image.png";
      optionModal.selectedProductOptionModal = {
        id: _id,
        active,
        from,
        position,
        sku,
        to
      };
    }

    this.setState({ optionModal, productImagePreview });
  }

  async showProductTable() {
    await this.fetchProducts();
  }


  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel);
  }

  canAdd() {
    return this.hasAccess("createTrendingProduct");
  }

  canDelete() {
    return this.hasAccess("deleteTrendingProduct");
  }

  canList() {
    return this.hasAccess("listTrendingProduct");
  }

  canRead() {
    return this.hasAccess("readTrendingProduct");
  }

  canSave() {
    return this.isEdit()
      ? this.hasAccess("updateTrendingProduct")
      : this.hasAccess("createTrendingProduct");
  }

  isEdit() {
    return this.props.match.params.id !== "add";
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(TrendingProduct));
