import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Form from 'antd/lib/form'
import Select from 'antd/lib/select'

import './styles.css'

export default class WDSelect extends Component {
  static get Option () {
    return Select.Option
  }

  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { fieldDecorator, form, formItem = {}, readOnly = false, select = {}, list = [], listFunc } = this.props
    const { getFieldDecorator } = form
    const { id, options = {} } = fieldDecorator
    let { initialValue } = options
    formItem.className = formatter.toClassName(['wd-select', formItem.className])
    initialValue = Array.isArray(initialValue) ? initialValue.map((item) => formatter.capitalize(item, false)).join(', ') : formatter.capitalize(options.initialValue, false)

    return (
      <Form.Item hasFeedback={!readOnly} {...formItem}>
        {readOnly ? (
          <span className='ant-form-text'>{initialValue}</span>
        ) : (
          getFieldDecorator(id, options)(<Select {...select}>{list.map(listFunc)}</Select>)
        )}
      </Form.Item>
    )
  }
}

WDSelect.propTypes = {
  fieldDecorator: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formItem: PropTypes.object,
  readOnly: PropTypes.bool,
  select: PropTypes.object,
  list: PropTypes.array,
  listFunc: PropTypes.func
}
