export const VENDORS_FETCHED = 'VENDORS_FETCHED'
export const VENDOR_SET_PRISTINE = 'VENDOR_SET_PRISTINE'

export const vendorsFetched = (vendors) => {
  return {
    type: VENDORS_FETCHED,
    vendors
  }
}

export const vendorSetPristine = (pristine) => {
  return {
    type: VENDOR_SET_PRISTINE,
    pristine
  }
}

