export const FETCH_REVIEW_TITLE_LIST = 'FETCH_REVIEW_TITLE_LIST'
export const REVIEW_TITLE_LOADED = 'REVIEW_TITLE_LOADED'
export const REVIEW_TITLE_FAILED = 'REVIEW_TITLE_FAILED'

export const FETCH_REVIEW_LIST = 'FETCH_REVIEW_LIST'
export const REVIEW_LOADED = 'REVIEW_LOADED'
export const REVIEW_FAILED = 'REVIEW__FAILED'

export const FETCH_REVIEW_COMMENT_LIST = 'FETCH_REVIEW_COMMENT_LIST'
export const REVIEW_COMMENT_LOADED = 'REVIEW_COMMENT_LOADED'
export const REVIEW_COMMENT_FAILED = 'REVIEW_COMMENT__FAILED'

export const fetchReviewsTitleList = (page, size, filter, query) => {
  return {
    type: FETCH_REVIEW_TITLE_LIST,
    page,
    size,
    filter,
    query
  }
}

export const fetchReviewsList = (page, size, filter, query) => {
  return {
    type: FETCH_REVIEW_LIST,
    page,
    size,
    filter,
    query
  }
}

export const fetchReviewsCommentList = (page, size, filter, query) => {
  return {
    type: FETCH_REVIEW_COMMENT_LIST,
    page,
    size,
    filter,
    query
  }
}
