import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, recommendService } from '../../../services'
import { formatter, notification, sessionStorage } from '../../../util'

// UI
import { Button, Page } from '../../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'

import './styles.css'

class Recommendation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      showModal: false,
      itemList: []
    }
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchItemList()
    } else {
      authService.unauthorizeAction()
    }
  }

  async createItemList () {
    const itemList = await recommendService.createProperties()
    this.setState({ itemList })
  }

  async fetchItemList () {
    const itemList = await recommendService.listProperties()
    this.setState({ itemList })
  }

  async syncProducts () {
    notification.show('warning', 'Function Not Available', 'This function is currently not available')
    // const itemList = await recommendService.syncProducts()
    // this.setState({ itemList })
  }

  async removeProducts () {
    // const itemList = await recommendService.syncProducts()
    // this.setState({ itemList })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values)
        const res = await recommendService.removeProducts(values.skus)
        if (res) {
          notification.show('success', 'Product(s) Removed', 'Please check the catalog list in Recombee')
        }
      }
    })
  }

  render () {
    const { itemList } = this.state
    const { getFieldDecorator } = this.props.form

    return (
      <Page.Content>
        <Page.Header title={`Recommendation Configuration`} description='Update products to recommedation engine' />

        <Page.Body>
          <Row>
            <Col lg={6}>
              <Button onClick={() => this.fetchItemList()}>Get Item Property</Button>
              {/* <Button onClick={() => this.createItemList()}>Create Item Property</Button> */}

              <div style={{ fontWeight: 'bold', marginTop: 20, textTransform: 'uppercase' }}>Item Property</div>
              {
                itemList.length > 0 ? itemList.map((item, idx) => {
                  return <div><b>{item.name}</b>, {item.type}</div>
                }) : 'No property for item yet'
              }
            </Col>
            <Col lg={6}>
              <Button onClick={() => this.syncProducts()}>Sync Products</Button>
              {/* <Button onClick={() => this.addProduct()}> + </Button> */}
            </Col>
            <Col lg={12}>
              <Button onClick={() => this.removeProducts()}>Remove Products</Button>
              <div style={{ padding: 8 }}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Item>
                    {getFieldDecorator('skus', {
                      rules: [{ required: true, message: 'Please input your username!' }]
                    })(
                      <Input placeholder='SKU list e.g. SK001, SK0002' />
                    )}
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div />
            </Col>
          </Row>
        </Page.Body>
      </Page.Content>
    )
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canList = () => {
    return this.hasAccess('listAuditLog')
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Recommendation))
