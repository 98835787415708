export const FETCH_REWARD_MISSIONS = 'FETCH_REWARD_MISSIONS'
export const REWARD_MISSIONS_LOADED = 'REWARD_MISSIONS_LOADED'
export const REWARD_MISSIONS_FAILED = 'REWARD_MISSIONS_FAILED'
export const REWARD_MISSIONS_GROUPS_LOADED = 'REWARD_MISSIONS_GROUPS_LOADED'
export const FETCH_REWARD_MISSIONS_GROUP = 'FETCH_REWARD_MISSIONS_GROUP'

export const fetchRewardMissionsGroups = (page, size, filter, query) => {
  return {
    type: FETCH_REWARD_MISSIONS_GROUP,
    page,
    size,
    filter,
    query
  }
}

export const fetchRewardMissions = (page, size, filter, query) => {
  return {
    type: FETCH_REWARD_MISSIONS,
    page,
    size,
    filter,
    query
  }
}


