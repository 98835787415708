import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { authService } from '../../../services'
import { fetchPromotionGroups } from '../../../states/actions'
import { formatter, notification, sessionStorage } from '../../../util'
import { Link } from 'react-router-dom'

// UI
import { Active, Button, List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Form from 'antd/lib/form'

import './styles.css'

const filterKey = 'promotionGroup.filter'
const pageSize = 20

class Promotion extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      maxPosition: 0,
      loading: false,
      modal: {
        isAdd: true,
        selectedProduct: {},
        show: false
      },
      products: { data: [], total: 0 },
      productImagePreview: '',
      searchText: '',
      sort: {}
    }
    this.findProducts = debounce(this.findProducts, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { promotions } = nextProps
    const { list, total } = promotions
    const inactiveList = list.filter(({ active }) => active === 0 || active === false)
    const maxPosition = total + 1 - inactiveList.length
    return { ...prevState, ...nextProps, maxPosition }
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter, searchText, sort } = this.state
      this.fetchPromotions({ loading: true, currentPage, filter, searchText, sort })
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { promotionGroups } = this.props
    const { currentPage, loading } = this.state
    const { total } = promotionGroups
    //{console.log(this.props)}
    const columns = [
      {
        title: '',
        width: 1,
        render: (row) => <ListIcon file='hot.svg' name={row.name} />
      },
      {
        key: 'title',
        title: 'Title',
        width: 7,
        render: (row) => (<div><b>{row.name}</b></div>)
      },
      {
        title: 'Products',
        key: 'products',
        width: 2
      },
      {
        title: 'Schedule To Start',
        width: 4,
        render: (row) => formatter.toStandardDate(row.show_from)
      },
      {
        title: 'Schedule To End',
        width: 4,
        render: (row) => formatter.toStandardDate(row.show_to)
      },
      {
        title: 'Active',
        width: 2,
        render: (row) => <Active active={moment().isBetween(row.show_from, row.show_to)} />
      },
      {
        key: 'createdAt',
        title: 'Created On',
        width: 4,
        render: (row) => formatter.toStandardDate(row.createdAt)
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Promotion Groups (${total})`} description='Display promotion groups' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Promotions' onChange={(value) => this.findProducts(value)} tabIndex={1} />
              </Col>

              <Col lg={18} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/home/promotion/add'>
                    <Button>Add Promotion Group</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                {/*console.log(promotionGroups)*/}
                <List cols={columns} rows={promotionGroups.list} link={this.canRead() ? '/home/promotion/<_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} promotions`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText } = this.state
    this.fetchPromotions({ loading: true, currentPage, filter, searchText })
  }

  async fetchPromotions ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) {
    try {
      this.setState({ currentPage, loading })
      sessionStorage.setObject(filterKey, filter)
      this.props.fetchPromotionGroups(currentPage, pageSize, filter, searchText)
      setTimeout(() => {
        this.setState({ loading: false })
      }, 1000)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load promotion products successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findProducts (text) {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchPromotions({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, sort, searchText: text })
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createPromotionProduct')
  }

  canList () {
    return this.hasAccess('listPromotionProduct')
  }

  canRead () {
    return this.hasAccess('readPromotionProduct')
  }
}

const mapDispatchToProps = {
  fetchPromotionGroups
}

const mapStateToProps = (state) => {
  return { ...state.Promotion }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Promotion))
