import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_FEATURED_ON, FEATURED_ON_LOADED, FEATURED_ON_FAILED } from '../actions/featuredOn'
import featuredOnService from '../../services/featuredOn'

function * fetchFeaturedOn (params) {
  try {
    const featuredOn = yield call(() => featuredOnService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: FEATURED_ON_LOADED, featuredOn })
  } catch (e) {
    yield put({ type: FEATURED_ON_FAILED, message: e.message })
  }
}

function * watchFeaturedOn () {
  yield takeEvery(FETCH_FEATURED_ON, fetchFeaturedOn)
}

export default watchFeaturedOn
