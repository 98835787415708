import { combineReducers } from 'redux'

import AdminReducer from './admin'
import AuditReducer from './audit'
import BannerReducer from './banner'
import BrandReducer from './brand'
import ButtonLayoutReducer from './button/layout'
import CarouselReducer from './carousel'
import CategoryReducer from './category'
import CollectionReducer from './collection'
import CustomPageReducer from './custom'
import DashboardReducer from './dashboard'
import FeaturedOnReducer from './featuredOn'
import IntroReducer from './intro'
import NotificationReducer from './notification'
import PopupAdReducer from './ad'
import ReviewReducer from './review'
import RoleReducer from './role'
import StoreReducer from './store'
import TemplateReducer from './template'
import TileReducer from './tile'
import TrendingReducer from './trending'
import VendorReducer from './vendor'
import CelebReducer from './celeb'
import PromotionReducer from './promotion'
import MiniBannerReducer from './miniBanner'
import CartPriceRuleReducer from './cartPriceRule'
import TopFavouriteReducer from './topFavourite'
import TopFavouriteCartPriceRuleReducer from './topFavouriteCartPriceRule'
import ProductsReducer from './products'
import ContentManagementSystemReducer from './contentManagementSystem'
import RedemptionReducer from './redemption'
import RewardMissionReducer from './rewardMission'

export default combineReducers({
  Admin: AdminReducer,
  Audit: AuditReducer,
  Banner: BannerReducer,
  Brand: BrandReducer,
  ButtonLayout: ButtonLayoutReducer,
  Carousel: CarouselReducer,
  Categories: CategoryReducer,
  Collection: CollectionReducer,
  CustomPage: CustomPageReducer,
  Dashboard: DashboardReducer,
  FeaturedOn: FeaturedOnReducer,
  Intro: IntroReducer,
  Notification: NotificationReducer,
  PopupAd: PopupAdReducer,
  Review: ReviewReducer,
  Role: RoleReducer,
  Store: StoreReducer,
  Template: TemplateReducer,
  Tile: TileReducer,
  Trending: TrendingReducer,
  Vendor: VendorReducer,
  Celeb: CelebReducer,
  Promotion: PromotionReducer,
  MiniBanner: MiniBannerReducer,
  CartPriceRule:CartPriceRuleReducer,
  TopFavourite:TopFavouriteReducer,
  TopFavouriteCartPriceRule: TopFavouriteCartPriceRuleReducer,
  Product: ProductsReducer,
  ContentManagementSystem : ContentManagementSystemReducer,
  Redemption: RedemptionReducer,
  RewardMission: RewardMissionReducer
})
