import request from '../util/request'
import queryString from '../util/queryString'

const url = '/api/redemptions/'

export default {
  addRedemption (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getRedemption (id) {
    return request(url + id)
  },
  removeRedemption (id) {
    return request(url + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveRedemption (id, values) {
    return request(url + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  listRedemptionsByPage (page, size, filter, sort, text) {

    //return request(`${url}page/${page}/size/${size}${queryString.stringify({ urlObject })}`)
    return request(`${url}page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter),sort: JSON.stringify(sort), text  })}`)
  }
}
