import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_BRAND, BRAND_LOADED, BRAND_FAILED } from '../actions/brand'
import brandService from '../../services/brand'

// Generators
function * fetchBrands (params) {
  try {
    const brands = yield call(() => brandService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: BRAND_LOADED, brands })
  } catch (e) {
    yield put({ type: BRAND_FAILED, message: e.message })
  }
}

function * watchBrand () {
  yield takeEvery(FETCH_BRAND, fetchBrands)
}

export default watchBrand
