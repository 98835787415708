import { queryString, request } from '../util'

const url = '/api/recommend/'

export default {
  createProperties () {
    return request(url + 'item/property/add')
  },
  listProperties () {
    return request(url + 'item/property/list')
  },
  syncProducts () {
    return request(url + 'products/sync')
  },
  removeProducts (sku) {
    return request(url + 'products/remove/' + sku)
  }
}
