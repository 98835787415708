export const FETCH_AUDIT_LOGS = 'FETCH_AUDIT_LOGS'
export const AUDIT_LOGS_LOADED = 'AUDIT_LOGS_LOADED'
export const AUDIT_LOGS_FAILED = 'AUDIT_LOGS_FAILED'

export const fetchAuditLogs = (page, size, filter, query) => {
  return {
    type: FETCH_AUDIT_LOGS,
    page,
    size,
    filter,
    query
  }
}
