export default {
  Moderate: 0,
  Good: 1,
  Bad: 2
}

export const LabelTitleList = [
  { name: 'Moderate', value: 0, color: 'faad14' },
  { name: 'Good', value: 1, color: '6aff00' },
  { name: 'Bad', value: 2, color: 'ff3200' }
]

export const ReviewColors = {
  YELLOW: 'faad14',
  GREEN: '6aff00',
  RED: 'ff3200'
}
