import { NOTIFICATIONS_LOADED } from '../actions/notification'

const INITIAL_STATE = {
  notifications: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NOTIFICATIONS_LOADED:
      return { ...state, notifications: { list: action.notifications.data, total: action.notifications.total } }
    default:
      return state
  }
}
