export default [
  {
    value: 'c-2-2',
    name: '2 columns left, 2 columns right',
    image: '2-2.svg',
    columns: [
      {
        side: 'left',
        position_row: 1,
        position_col: 1
      },
      {
        side: 'left',
        position_row: 2,
        position_col: 1
      },
      {
        side: 'right',
        position_row: 1,
        position_col: 2
      },
      {
        side: 'right',
        position_row: 2,
        position_col: 2
      }
    ]
  },
  {
    value: 'c-2-1',
    name: '2 columns left, 1 column right',
    image: '2-1.svg',
    columns: [
      {
        side: 'left',
        position_row: 1,
        position_col: 1,
        title: 'Title',
        description: 'Description'
      },
      {
        side: 'left',
        position_row: 2,
        position_col: 1,
        title: 'Title',
        description: 'Description'
      },
      {
        side: 'right',
        position_row: 1,
        position_col: 2,
        title: 'Title',
        description: 'Description'
      }
    ]
  },
  {
    value: 'c-1-2',
    name: '1 column left, 2 columns right',
    image: '1-2.svg',
    columns: [
      {
        side: 'left',
        position_row: 1,
        position_col: 1
      },
      {
        side: 'right',
        position_row: 1,
        position_col: 2
      },
      {
        side: 'right',
        position_row: 2,
        position_col: 2
      }
    ]
  },
  {
    value: 'c-1-1',
    name: '1 column left, 1 column right',
    image: '1-1.svg',
    columns: [
      {
        side: 'left',
        position_row: 1,
        position_col: 1
      },
      {
        side: 'right',
        position_row: 1,
        position_col: 2
      }
    ]
  }
]
