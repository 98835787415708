import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchNotifications } from '../../../states/actions'
import { formatter, notification } from '../../../util'

// UI
import { Button, List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

const pageSize = 20

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      loading: false,
      searchText: '',
      searchSort: '',
      total: 0,
      sort: {key:'title',order:0}
    }
    this.findNotifications = debounce(this.findNotifications, 500)
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchNotifications()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { notifications } = this.props
    const { currentPage, loading } = this.state
    const { total } = notifications
    const columns = [
      {
        title: '',
        width: 1,
        render: (row) => <ListIcon file='notify.svg' name={row.name} />
      },
      {
        key: 'title',
        title: 'Notification',
        width: 11,
        render: (row) => (<div><b>{row.title}</b><div style={{marginTop: 4, paddingRight: 10, fontSize: '9pt', color: '#999'}}>{row.content}</div></div>),
        onSort: (key,order)=>this.sortNotifications(key,order)
        
      },
      {
        key:'sendAt',
        title: 'Schedule To Send',
        width: 4,
        render: (row) => formatter.toStandardDate(row.sendAt),
        onSort: (key,order)=>this.sortNotifications(key,order)
      },
      {
        key: 'sentAt',
        title: 'Status',
        width: 2,
        render: (row) => (row.sentAt ? <div style={{color: '#3dc67d'}}>Sent</div> : row.sendAt ? <div style={{color: '#fcc35d'}}>Pending</div> : ''),
        onSort: (key,order)=>this.sortNotifications(key,order)
      },
      {
        key: 'audience',
        title: 'Audience',
        width: 2,
        render: (row) => (row.audience).toUpperCase(),
        onSort: (key,order)=>this.sortNotifications(key,order)
      },
      {
        key: 'createdAt',
        title: 'Created On',
        width: 4,
        render: (row) => formatter.toStandardDate(row.createdAt),
        onSort: (key,order)=>this.sortNotifications(key,order)
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Notifications (${total})`} description='Push promotion, announcement or news to app user' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Notifications' onChange={(value) => this.findNotifications(value)} tabIndex={1} />
              </Col>

              <Col lg={18} style={{textAlign: 'right'}}>
                {this.canAdd() ? (
                  <Link to='/notifications/add'>
                    <Button>Add Notification</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} rows={notifications.list} link={this.canRead() ? '/notifications/<_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} notifications`}
            current={currentPage}
            onChange={(page) => this.fetchNotifications(page)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  async fetchNotifications (page = 1, text) {
    try {
      const { filter, searchText, sort } = this.state
      this.setState({ currentPage: page })
      this.props.fetchNotifications(page, pageSize, filter, sort, (typeof text === 'string' ? text : searchText))
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load notifications successfully. Please try again later.')
    }
  }

  findNotifications (text) {
    const { currentPage, searchText } = this.state
    this.fetchNotifications(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  sortNotifications (key, order) {
    const { currentPage, searchText, sort} = this.state

    sort.key = key
    sort.order = order
    
    this.setState({sort})
    this.fetchNotifications(currentPage,searchText)
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createNotification')
  }

  canList () {
    return this.hasAccess('listNotification')
  }

  canRead () {
    return this.hasAccess('readNotification')
  }
}

const mapDispatchToProps = {
  fetchNotifications
}

const mapStateToProps = (state) => {
  return { ...state.Notification }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
