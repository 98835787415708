import request from '../util/request'
import queryString from '../util/queryString'

const url = '/api/tiles/'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  list () {
    return request(url)
  },
  get (id) {
    return request(url + id)
  },
  getTileGroup (id) {
    return request(url + 'tile_group/' + id)
  },
  save (id, values) {
    return request(url + id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  remove (id) {
    return request(url + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
