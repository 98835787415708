import { SUMMARY_LOADED } from '../actions/dashboard'

const INITIAL_STATE = {
  summary: {
    sales: { total: 0, average: 0 },
    intro: { active: 0, total: 0 },
    banners: { active: 0, total: 0 },
    collections: { active: 0, total: 0 },
    topBrands: { active: 0, total: 0 },
    topStores: { active: 0, total: 0 },
    popupAd: { active: 0, total: 0 }
  },
  chart: [],
  keywords: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUMMARY_LOADED:
      return { ...state, summary: action.summary }
    default:
      return state
  }
}
