import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import moment from 'moment'
import { authService, redemptionService, productService } from '../../../services'
import { formatter, notification } from '../../../util'

//UI
import { Button, Input, Page, Panel, Select, Spin, TextArea, WDSelectSearch } from '../../../components'
import Types , {TypeList} from '../../../constants/redemption/redemptionType'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import "./styles.css";

const confirm = Modal.confirm
const Option = Select.Option
const pageSize = 100;

class RedemptionPage extends Component {
    constructor(props) {
        super(props)
        let isPromo = this.props.isPromotion
        const {} = props
        this.state = {
            item: {},
            loading: false,
            titles: [],
            products: { data: [], total: 0 },
            param: {  
              brand: "",
              category: "",
              keyword: "",
              newArrival: "",
              discountFrom:  0,
              discountTo: 0,
              discountPeriodFrom:  "",
              discountPeriodTo: "",
              priceFrom: 0,
              priceTo: 0,
              isPromo,
            }
        }
        this.selectFilter = this.selectFilter.bind(this)
        this.fetchProducts = debounce(this.fetchProducts, 500);
    }

    componentDidMount() {
        
        if (this.canRead()) {
          this.fetchRedemption()
          this.fetchProducts()         
        } else {
          authService.unauthorizeAction('/redemption')
        }
    }

    render() {
        const formItemLayout = {
          labelCol: { sm: 7, md: 7 },
          wrapperCol: { sm: 15, md: 15 }
        }
        const { form, history } = this.props
        const { item, products, loading} = this.state
        const { data : productList } = products
        if(item.type == undefined)
        item.type=Types.PRODUCT

        return (
            <Page.Content>
                <Page.Header title='Redemption Details' description='Enter redemption details'>
                    {this.isEdit() && this.canDelete() ? (
                        <Button disabled={loading} onClick={() => this.handleDelete()}>Delete</Button>
                    ) : null}

                    {this.canSave() ? (
                        <Button disabled={loading} onClick={() => this.handleSave()}>Save</Button>
                    ) : null}

                    <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
                </Page.Header>

                <Page.Body>
                    <Spin loading={loading} blur>
                        <Form>
                        <Panel title='Redemption Information'>
                            <Row>
                            <Col lg={22}>
                            <Select
                              fieldDecorator={{
                                id: 'type',
                                options: {
                                  initialValue: item.type ,
                                  rules: [
                                    { required: true, message: 'Please select type' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...formItemLayout,
                                hasFeedback: false,
                                label: 'Type'
                              }}
                              list={TypeList}
                              listFunc={({ name, value }) => (
                                <Option key={value} value={value}>{name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                onChange: (value)=> this.changeType(value)
                              }}                  
                            /> 
                              {item.type == 'product' ? 
                              (<div className='SelectRedemptionProduct'>                          
                                <WDSelectSearch
                                  fieldDecorator={{
                                    id: "sku",
                                    options: {
                                      initialValue: item.sku,
                                      rules: [
                                        { required: true, message: "Please select a product" }
                                      ]
                                    }
                                  }}
                                  form={form}
                                  formItem={{
                                    ...formItemLayout,
                                    hasFeedback: false,
                                    label: "Name"
                                  }}
                                  list={productList}
                                  listFunc={product => {
                                    const { name, sku } = product;
              
                                    return (                      
                                      <Option key={sku} value={sku}>
                                        <div className="text-wrap">{name}</div>
                                        <div className="wd-trending-option-subtitle">
                                          SKU: {sku}
                                        </div>
                                      </Option>
                                    ) ;
                                  }}
                                  readOnly = {!this.canSave()}
                                  onDoubleClick={
                                    this.selectFilter
                                  }                                              
                                />
                                <Input
                                  fieldDecorator={{
                                      id: 'redeemPoint',
                                      options: {
                                      initialValue: item.redeemPoint,
                                      rules: [
                                          { required: true, message: 'Please enter the redeem points' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) },
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Redeem Point'
                                  }}
                                  readOnly={!this.canSave()}
                                />
                                <Input
                                  fieldDecorator={{
                                      id: 'amount',
                                      options: {
                                      initialValue: item.amount,
                                      rules: [
                                          { required: true, message: 'Please enter the amount of the voucher' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) },
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Amount'
                                  }}
                                  readOnly={!this.canSave()}
                                />
                              </div>
              
                               ): (<div>
                                <Input
                                  fieldDecorator={{
                                      id: 'name',
                                      options: {
                                      initialValue: item.name ,
                                      rules: [
                                          { required: true, message: 'Please enter the name' },
                                          { whitespace: true, message: 'Please enter the name' }
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Name'
                                  }}
                                  readOnly={!this.canSave()}
                                />

                                <Input
                                  fieldDecorator={{
                                      id: 'redeemPoint',
                                      options: {
                                      initialValue: item.redeemPoint,
                                      rules: [
                                          { required: true, message: 'Please enter the redeem points' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) },
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Redeem Point'
                                  }}
                                  readOnly={!this.canSave()}
                                />

                                <Input
                                  fieldDecorator={{
                                      id: 'valid_day',
                                      options: {
                                      initialValue: item.valid_day,
                                      rules: [
                                          { required: true, message: 'Please enter the valid days of the voucher' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) },
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Valid days'
                                  }}
                                  readOnly={!this.canSave()}
                                />

                                <Input
                                  fieldDecorator={{
                                      id: 'amount',
                                      options: {
                                      initialValue: item.amount,
                                      rules: [
                                          { required: true, message: 'Please enter the quantity of the voucher' },
                                          { validator: (rule, value, callback) => this.checkInteger(rule, value, callback) },
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Quantity'
                                  }}
                                  readOnly={!this.canSave()}
                                />

                                <Input
                                  fieldDecorator={{
                                      id: 'discount_amount',
                                      options: {
                                      initialValue: item.discount_amount,
                                      rules: [
                                          { required: true, message: 'Please enter the discount amount of the voucher' },
                                          { validator: (rule, value, callback) => this.checkDecimal(rule, value, callback) },
                                      ]
                                      }
                                  }}
                                  form={form}
                                  formItem={{
                                      ...formItemLayout,
                                      label: 'Discount Amount'
                                  }}
                                  readOnly={!this.canSave()}
                                />
                              </div>            
                             )}                          
                            </Col>
                            </Row>
                        </Panel>
                        </Form>
                    </Spin>
                </Page.Body>
            </Page.Content>
        )
    }

    checkInteger (rule, value, callback) {
      if (value && !( /^[0-9\b]+$/).test(value)) {
        callback('Value must be integer')
      }
      callback()
    }

    checkDecimal (rule, value, callback) {
      if (value && !( /^\d+(.\d{0,2})?$/).test(value)) {
        callback('Value must be decimal')
      }
      callback()
    }

    async fetchRedemption () {
        try {
          this.setState({ loading: true })
          const item = await redemptionService.getRedemption(this.props.match.params.id)
          this.setState({ item, loading: false })
        } catch (e) {
          notification.show('error', 'Unable to load successfully', 'Unable to load redemption successfully. Please try again later.')
          this.setState({ loading: false })
        }
    }

    async fetchProducts() {
      try {
        const { param } = this.state;
        const products = await productService.listFilteredProductsByPage(
          1,
          pageSize,
          {},
          param
        );
        this.setState({ products });
      } catch (e) {
        console.error(e);
      }
    }

    async changeType(value) {
      const { item} = this.state
      item.type=value
      this.setState({item})
    }


    async selectFilter (value) {
      const {item} = this.state
      item.sku=value
      this.setState({item})
      this.fetchProducts(value)   
    }

    handleDelete () {
        const { history, match } = this.props
    
        confirm({
          title: 'Are you sure you want to delete this redemption?',
          content: 'Press Ok to continue, Cancel to return',
          async onOk () {
            try {
              const response = await redemptionService.removeRedemption(match.params.id)
              if (response) {
                notification.show('success', 'Deleted successfully', 'Redemption deleted successfully.')
                history.replace('/redemption')
              }
            } catch (e) {
              notification.show('error', 'Unable to delete successfully', 'Unable to delete redemption successfully. Please try again later.')
            }
          }
        })
      }

    handleSave () {
        const { history } = this.props
        const { validateFields } = this.props.form
    
        validateFields(async (errors, values) => {

            if (!errors) {
            const { item } = this.state
            this.setState({ loading: true })
    
            try {
              if (this.isEdit()) {
                const response = await redemptionService.saveRedemption(item._id, values)
                this.setState({ item: { ...item, ...values }, loading: false })
                if (response._id) {
                  notification.show('success', 'Saved successfully', 'Redemption saved successfully.')
                  history.replace(`/redemption`)
                } else {
                  notification.show('error', 'Unable to save successfully', 'Unable to save redemption successfully. Please check if there is an existing product in the redemption page or try again later.')
                  this.setState({ loading: false })
                }
              } else {
                const response = await redemptionService.addRedemption(values)
                this.setState({ loading: false })
    
                if (response._id) {
                  this.setState({ item: { ...item, ...values, _id: response._id } })
                  notification.show('success', 'Saved successfully', 'Redemption saved successfully.')
                  history.replace(`/redemption`)
                } else {
                  notification.show('error', 'Unable to save successfully', 'Unable to save redemption successfully. Please check if there is an existing product in the redemption page or try again later.')
                  this.setState({ loading: false })
                }
              }
            } catch (e) {
              notification.show('error', 'Unable to save successfully', 'Unable to save redemption successfully. Please try again later.')
              this.setState({ loading: false })
            }
          }
        })
      }
    

    hasAccess = (permission) => {
        return authService.hasAccess(permission)
    }

    canDelete () {
        return this.hasAccess('deleteRedemptions')
    }
    
    canRead () {
        return this.hasAccess('readRedemptions')
    }
    
    canSave () {
        return this.hasAccess(this.isEdit() ? 'updateRedemptions' : 'createRedemptions')
    }

    isEdit () {
        return this.props.match.params.id !== 'add'
    }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
    return { ...state.Redemption }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Form.create()(RedemptionPage))