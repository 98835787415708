import { ROLES_FETCHED, ROLE_SET_PRISTINE } from '../actions/role'

const INITIAL_STATE = {
  roles: { data: [], total: 0 },
  pristine: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ROLES_FETCHED:
      return { ...state, roles: action.roles, pristine: false }
    case ROLE_SET_PRISTINE:
      return { ...state, pristine: action.pristine }
    default:
      return state
  }
}


