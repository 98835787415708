import React, { Component } from 'react'
import { connect } from 'react-redux'
import BannerLinkTypes, { BannerLinkTypesList } from '../../../constants/banner/linkTypes'
import { authService, brandService, categoryService, productService } from '../../../services'
import { fetchAllCategories, fetchStores, fetchTemplates } from '../../../states/actions'
import { notification, validator } from '../../../util'

// UI
import { Button, Input, Page, Panel, Select, Spin, Switch, TextArea } from '../../../components'
import UploadHolder from '../../../components/UploadHolder'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const confirm = Modal.confirm
const FormItem = Form.Item
const Option = Select.Option

class CategoryPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      brands: [],
      item: {},
      itemBrands: [],
      loading: false,
      loadingImage: false,
      modal: {
        idx: -1,
        item: {},
        show: false
      },
      products: { data: [], total: 0 },
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }
  }

  componentDidMount () {
    if (this.canRead()) {
      this.fetchAllBrands()
      this.fetchCollections()
      this.fetchCategories()
      this.fetchProducts()
      this.fetchStores()

      if (this.isEdit()) {
        this.fetchHighlight()
      }
    } else {
      authService.unauthorizeAction('/categories')
    }
  }

  render () {
    const formItemLayout = {
      labelCol: { sm: 8, md: 4, lg: 4 },
      wrapperCol: { sm: 16, md: 18, lg: 18 }
    }
    const selectFormItem = {
      labelCol: { span: 0 },
      wrapperCol: { span: 24 }
    }
    const { categories, form, history, stores, templates } = this.props
    const { brands, item, itemBrands, loading, loadingImage, modal, products, showBrandList, showCategoryList, showPageInput, showProductList, showStoreList, showTemplateList } = this.state
    const { item: modalItem, show: modalShow } = modal
    const { data: productList } = products

    return (
      <Page.Content>
        <Page.Header title='Category Highlight' description='Upload banner image, add brands, and set links'>

          {this.canSave() ? (
            <Button disabled={loading} onClick={this.handleSave}>Save</Button>
          ) : null}

          <Button disabled={loading} ghost onClick={history.goBack}>Back</Button>
        </Page.Header>

        <Page.Body>
          <Spin loading={loading} blur>
            <Form>
              <Panel title='Banner Details'>
                <div className='category-panel'>
                  <div className='category-panel-left'>
                    <div className='category-image-uploader'>
                      <UploadHolder
                        img={{
                          alt: 'Banner',
                          src: item.image ? item.image : '/img/default-image.png'
                        }}
                        loading={loadingImage}
                        readOnly={!this.canSave()}
                        height={180}
                        width={248}
                        upload={{
                          action: '/api/categories/upload/banner',
                          beforeUpload: this.checkImage,
                          data: { _id: item._id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'image',
                          onChange: this.handleUploadImage,
                          showUploadList: false
                        }}
                      />
                    </div>

                    <div className='category-image-hint'>
                      Size: 1160px x 300px (JPG or PNG) <br />
                      Maximum file size: 2 MB
                    </div>
                  </div>

                  <div className='category-panel-right'>
                    <Input
                      fieldDecorator={{
                        id: 'name',
                        options: {
                          initialValue: item.name,
                          rules: [
                            { min: 2, message: 'Name must be between 2 and 64 characters' },
                            { max: 64, message: 'Name must be between 2 and 64 characters' },
                            { required: true, message: 'Please enter name' },
                            { whitespace: true, message: 'Please enter name' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Name'
                      }}
                      input={{
                        autoFocus: true,
                        tabIndex: 1
                      }}
                      readOnly={!this.canSave()}
                    />

                    <TextArea
                      fieldDecorator={{
                        id: 'remark',
                        options: {
                          initialValue: item.remark,
                          rules: [
                            { min: 2, message: 'Remark must be between 2 and 256 characters' },
                            { max: 256, message: 'Remark must be between 2 and 256 characters' },
                            { whitespace: true, message: 'Please enter remark' }
                          ]
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Remark'
                      }}
                      input={{
                        autosize: { minRows: 2, maxRows: 5 },
                        tabIndex: 2
                      }}
                      readOnly={!this.canSave()}
                    />

                    <FormItem
                      {...formItemLayout}
                      label={(
                        <span>
                          Link&nbsp;
                          <Tooltip title='Link banner to a product or external custom page'>
                            <Icon type='question-circle-o' />
                          </Tooltip>
                        </span>
                      )}
                    >
                      <Row gutter={8}>
                        <Col md={8}>
                          <Select
                            fieldDecorator={{
                              id: 'link_type',
                              options: {
                                initialValue: item.link_type || BannerLinkTypes.NONE
                              }
                            }}
                            form={form}
                            formItem={{
                              ...selectFormItem,
                              hasFeedback: false
                            }}
                            list={BannerLinkTypesList}
                            listFunc={({ name, value }) => (
                              <Option key={value} value={value}>{name}</Option>
                            )}
                            readOnly={!this.canSave()}
                            select={{
                              onChange: this.changeLinkType,
                              style: { width: '100%' }
                            }}
                          />
                        </Col>

                        <Col md={16}>
                          {showBrandList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a brand' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItem,
                                hasFeedback: false
                              }}
                              list={brands}
                              listFunc={(brand) => (
                                <Option key={brand.option_id} value={brand.option_id.toString()}>{brand.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a brand',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showTemplateList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a collection' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItem,
                                hasFeedback: false
                              }}
                              list={templates.list}
                              listFunc={(template) => (
                                <Option key={template._id} value={template._id.toString()}>{template.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a collection',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showCategoryList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a category' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItem,
                                hasFeedback: false
                              }}
                              list={categories}
                              listFunc={(category) => (
                                <Option className='text-wrap' key={category.id} value={category.id.toString()}>{category.name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a category',
                                showSearch: true,
                                style: { width: '100%' },
                                dropdownMatchSelectWidth: false,
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showStoreList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value ? item.link_value.toString() : undefined,
                                  rules: [
                                    { required: true, message: 'Please select a store' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItem,
                                hasFeedback: false
                              }}
                              list={stores.list}
                              listFunc={(store) => (
                                <Option key={store._id} value={store.vendor_id.toString()}>{store.vendor_name}</Option>
                              )}
                              readOnly={!this.canSave()}
                              select={{
                                placeholder: 'Select a store',
                                showSearch: true,
                                style: { width: '100%' },
                                filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }}
                            />
                          ) : null}

                          {showProductList ? (
                            <Select
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value,
                                  rules: [
                                    { required: true, message: 'Please select a product' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItem,
                                hasFeedback: false
                              }}
                              list={productList}
                              listFunc={(product) => {
                                const { name, sku } = product

                                return (
                                  <Option key={sku} value={sku}>
                                    <div className='text-wrap'> {name}</div>

                                    <div className='wd-trending-option-subtitle'>SKU: {sku}</div>
                                  </Option>
                                )
                              }}
                              readOnly={!this.canSave()}
                              select={{
                                filterOption: false,
                                onSearch: this.fetchProducts,
                                placeholder: 'Select a product',
                                showSearch: true,
                                dropdownMatchSelectWidth: false,
                                style: { width: '100%' }
                              }}
                            />
                          ) : null}

                          {showPageInput ? (
                            <Input
                              fieldDecorator={{
                                id: 'link_value',
                                options: {
                                  initialValue: item.link_value,
                                  rules: [
                                    { required: true, message: 'Please enter custom page URL' },
                                    { whitespace: true, message: 'Please enter custom page URL' }
                                  ]
                                }
                              }}
                              form={form}
                              formItem={{
                                ...selectFormItem,
                                hasFeedback: false
                              }}
                              input={{
                                placeholder: 'Enter custom page URL',
                                tabIndex: 3
                              }}
                              readOnly={!this.canSave()}
                            />
                          ) : null}
                        </Col>
                      </Row>
                    </FormItem>

                    <Switch
                      fieldDecorator={{
                        id: 'active',
                        options: {
                          initialValue: item.active === 1 || false,
                          valuePropName: 'checked'
                        }
                      }}
                      form={form}
                      formItem={{
                        ...formItemLayout,
                        label: 'Visible'
                      }}
                      input={{
                        checkedChildren: 'Yes',
                        unCheckedChildren: 'No',
                        tabIndex: 4
                      }}
                      readOnly={!this.canSave()}
                      tooltip={`Banner is visible when set to 'Yes'`}
                    />
                  </div>
                </div>
              </Panel>

              <Panel title='Brand Details'>
                <div style={{ textAlign: 'right' }}>
                  {this.canAdd() ? (
                    <Button disabled={loading} onClick={this.showBrandModal()}>Add Brand</Button>
                  ) : null}
                </div>

                <div className='category-brand-list'>
                  {itemBrands.map((brand, idx) => {
                    const { _id, active, image_thumb: thumbUrl, name } = brand

                    return (
                      <div className='list-item' key={_id} onClick={this.showBrandModal(idx, brand)}>
                        <div className='active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />

                        <img className='image' alt='' src={thumbUrl || '/img/default-image.png'} />

                        <div className='label'>{name}</div>
                      </div>
                    )
                  })}
                </div>
              </Panel>

              <Modal
                title='Brand Details'
                visible={modalShow}
                width={720}
                footer={[
                  this.canDelete() ? (modalItem._id ? <Button disabled={loading} key='delete' onClick={this.handleDeleteBrand}>Delete</Button> : null) : null,
                  <Button disabled={loading} key='back' onClick={this.hideBrandModal}>Cancel</Button>,
                  (!modalItem._id && this.canAdd()) || (modalItem._id && this.canSave()) ? <Button disabled={loading} key='submit' type='primary' onClick={this.handleSaveBrand}>Save</Button> : null
                ]}
                onCancel={this.hideBrandModal}
              >
                <Spin loading={loading}>
                  <div className='category-brand-image-uploader'>
                    <UploadHolder
                      img={{
                        alt: 'Brand',
                        src: modalItem.image ? modalItem.image : '/img/default-image.png'
                      }}
                      loading={loadingImage}
                      readOnly={(modalItem._id || !this.canAdd()) && (!modalItem._id || !this.canSave())}
                      height={128}
                      width={128}
                      upload={{
                        action: '/api/categories/upload/brand',
                        beforeUpload: this.checkImage,
                        data: {
                          _id: modalItem._id
                        },
                        disabled: loading,
                        headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                        name: 'image',
                        onChange: this.handleUploadBrandImage,
                        showUploadList: false
                      }}
                    />
                  </div>

                  <div className='category-brand-image-hint'>
                    Size: 128px x 128px (JPG or PNG) <br />
                    Maximum file size: 2 MB
                  </div>

                  <Select
                    fieldDecorator={{
                      id: 'brand.link_value',
                      options: {
                        initialValue: modalItem.link_value ? modalItem.link_value.toString() : undefined,
                        rules: [
                          { required: true, message: 'Please select a brand' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      hasFeedback: false,
                      label: 'Brand'
                    }}
                    list={brands}
                    listFunc={(brand) => (
                      <Option key={brand.option_id} value={brand.option_id.toString()}>{brand.name}</Option>
                    )}
                    readOnly={(modalItem._id || !this.canAdd()) && (!modalItem._id || !this.canSave())}
                    select={{
                      onChange: this.changeBrandLinkValue,
                      placeholder: 'Select a brand',
                      showSearch: true,
                      style: { width: '100%' },
                      filterOption: (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                  />

                  <TextArea
                    fieldDecorator={{
                      id: 'brand.remark',
                      options: {
                        initialValue: modalItem.remark,
                        rules: [
                          { min: 2, message: 'Remark must be between 2 and 256 characters' },
                          { max: 256, message: 'Remark must be between 2 and 256 characters' },
                          { whitespace: true, message: 'Please enter remark' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'Remark'
                    }}
                    input={{
                      autosize: { minRows: 2, maxRows: 5 },
                      tabIndex: 5
                    }}
                    readOnly={(modalItem._id || !this.canAdd()) && (!modalItem._id || !this.canSave())}
                  />

                  <Switch
                    fieldDecorator={{
                      id: 'brand.active',
                      options: {
                        initialValue: modalItem.active === 1 || false,
                        valuePropName: 'checked'
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'Visible'
                    }}
                    input={{
                      checkedChildren: 'Yes',
                      unCheckedChildren: 'No',
                      tabIndex: 6
                    }}
                    readOnly={(modalItem._id || !this.canAdd()) && (!modalItem._id || !this.canSave())}
                    tooltip={`Brand is visible when set to 'Yes'`}
                  />
                </Spin>
              </Modal>
            </Form>
          </Spin>
        </Page.Body>
      </Page.Content>
    )
  }

  checkImage = (file) => {
    if (!validator.isImage(file)) {
      notification.show('error', 'Unsupported image format', 'Image can only be in JPEG or PNG format.')
      return false
    }

    return true
  }

  changeLinkType = (value) => {
    const { form } = this.props
    const { item } = this.state
    const { resetFields } = form
    const showList = {
      showBrandList: false,
      showCategoryList: false,
      showPageInput: false,
      showProductList: false,
      showStoreList: false,
      showTemplateList: false
    }

    if (value === BannerLinkTypes.BRAND) {
      showList.showBrandList = true
    } else if (value === BannerLinkTypes.CATEGORY) {
      showList.showCategoryList = true
    } else if (value === BannerLinkTypes.COLLECTION) {
      showList.showTemplateList = true
    } else if (value === BannerLinkTypes.STORE) {
      showList.showStoreList = true
    } else if (value === BannerLinkTypes.PRODUCT) {
      showList.showProductList = true
    } else if (value === BannerLinkTypes.CUSTOM_PAGE) {
      showList.showPageInput = true
    }

    resetFields(['link_value'])
    item.link_type = value
    item.link_value = undefined
    this.setState({ item, ...showList })
  }

  changeBrandLinkValue = (value, option) => {
    const { modal } = this.state
    const { props } = option
    const { children } = props
    const { item } = modal
    item.name = children
    this.setState({ modal })
  }

  fetchAllBrands = async () => {
    try {
      const item = await brandService.getAllBrands()
      this.setState({ brands: item })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load brands successfully. Please try again later.')
    }
  }

  fetchCategories = async () => {
    try {
      this.props.fetchAllCategories()
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load categories successfully. Please try again later.')
    }
  }

  fetchCollections = async () => {
    try {
      this.props.fetchTemplates(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load collections successfully. Please try again later.')
    }
  }

  fetchHighlight = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { banner, brands } = await categoryService.getHighlight(id)
      const { link_type: type } = banner
      const showList = {
        showBrandList: type === BannerLinkTypes.BRAND,
        showCategoryList: type === BannerLinkTypes.CATEGORY,
        showPageInput: type === BannerLinkTypes.CUSTOM_PAGE,
        showProductList: type === BannerLinkTypes.PRODUCT,
        showStoreList: type === BannerLinkTypes.STORE,
        showTemplateList: type === BannerLinkTypes.COLLECTION
      }
      this.setState({ item: banner, itemBrands: brands, loading: false, ...showList })
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load banner and brands successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchProducts = async (text) => {
    try {
      const products = await productService.listProductsByPage(1, 100, {}, text)
      this.setState({ products })
    } catch (e) {
      console.error(e)
    }
  }

  fetchStores = async () => {
    try {
      this.props.fetchStores(1, 100, {}, '')
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load stores successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form, match } = this.props
    const { item } = this.state
    const { validateFields } = form

    validateFields(['name', 'remark', 'link_type', 'link_value', 'active'], async (errors, values) => {
      if (!errors) {
        const { params } = match
        const { _id, image, image_thumb } = item
        const { active, remark } = values
        const { id } = params
        values.category_id = parseInt(id, 10)
        values.active = active ? 1 : 0
        values.remark = remark || ''
        this.setState({ loading: true })

        try {
          if (_id > 0) {
            const response = await categoryService.saveBanner(_id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Banner saved successfully.')
            }
          } else {
            values.image = image
            values.image_thumb = image_thumb
            const response = await categoryService.addBanner(values)
            this.setState({ loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Banner saved successfully.')
              this.setState({ item: { ...item, ...values, _id: response._id } })
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save banner successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleDeleteBrand = () => {
    const that = this
    const { itemBrands, modal } = this.state

    confirm({
      title: 'Confirm to delete this brand?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const { idx, item } = modal
          const { _id } = item
          const response = await categoryService.removeBrand(_id)

          if (response) {
            notification.show('success', 'Deleted successfully', 'Brand deleted successfully.')
            itemBrands.splice(idx, 1)
            that.hideBrandModal()
          }
        } catch (e) {
          notification.show('error', 'Unable to delete successfully', 'Unable to delete brand successfully. Please try again later.')
        }
      }
    })
  }

  handleSaveBrand = () => {
    const { form, match } = this.props
    const { itemBrands, modal } = this.state
    const { validateFields } = form

    validateFields(['brand.remark', 'brand.link_value', 'brand.active'], async (errors, values) => {
      if (!errors) {
        const { params } = match
        const { idx, item } = modal
        const { brand } = values
        const { active, link_value, remark } = brand
        const { id } = params
        const { _id, image, image_thumb, name } = item
        const newValues = {
          category_id: parseInt(id, 10),
          active: active ? 1 : 0,
          link_type: 'brand',
          link_value,
          name,
          remark: remark || ''
        }
        this.setState({ loading: true })

        try {
          if (_id > 0) {
            const response = await categoryService.saveBrand(_id, newValues)
            this.setState({ loading: false })

            if (response._id) {
              notification.show('success', 'Saved successfully', 'Brand saved successfully.')
              itemBrands[idx] = { ...itemBrands[idx], ...newValues }
              this.setState({ itemBrands })
              this.hideBrandModal()
            }
          } else {
            newValues.image = image
            newValues.image_thumb = image_thumb
            const response = await categoryService.addBrand(newValues)
            this.setState({ loading: false })

            if (response._id) {
              const { _id, image, image_thumb } = response
              notification.show('success', 'Saved successfully', 'Brand saved successfully.')
              itemBrands.push({ ...newValues, _id, image, image_thumb })
              this.setState({ itemBrands })
              this.hideBrandModal()
            }
          }
        } catch (e) {
          notification.show('error', 'Unable to save successfully', 'Unable to save brand successfully. Please try again later.')
          this.setState({ loading: false })
        }
      }
    })
  }

  handleUploadImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { item } = this.state
      item.image = response.url
      item.image_thumb = response.thumbUrl
      this.setState({ item, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  handleUploadBrandImage = (info) => {
    const { status, response } = info.file
    this.setState({ loadingImage: true })

    if (status === 'done') {
      const { itemBrands, modal } = this.state
      const { idx, item } = modal
      const { url, thumbUrl } = response
      item.image = url
      item.image_thumb = thumbUrl

      if (idx > -1) {
        itemBrands[idx].image = url
        itemBrands[idx].image_thumb = thumbUrl
      }
      this.setState({ itemBrands, modal, loadingImage: false })
    } else {
      console.log('status', status)
    }
  }

  hideBrandModal = () => {
    const { form } = this.props
    const { resetFields } = form
    const modal = {
      idx: -1,
      item: {},
      show: false
    }
    resetFields(['brand.remark', 'brand.link_value', 'brand.active'])
    this.setState({ modal })
  }

  showBrandModal = (idx = -1, brand = {}) => () => {
    const modal = {
      idx,
      item: Object.assign({}, brand),
      show: true
    }
    this.setState({ modal })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createCategory')
  }

  canDelete () {
    return this.hasAccess('deleteCategory')
  }

  canRead () {
    return this.hasAccess('readCategory')
  }

  canSave () {
    return this.hasAccess(this.isEdit() ? 'updateCategory' : 'createCategory')
  }

  isEdit () {
    return this.props.match.params.id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchAllCategories,
  fetchStores,
  fetchTemplates
}

const mapStateToProps = (state) => {
  return {
    ...state.Categories,
    ...state.Store,
    ...state.Template
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CategoryPage))
