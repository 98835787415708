import { AUDIT_LOGS_LOADED } from '../actions/audit'

const INITIAL_STATE = {
  auditLogs: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUDIT_LOGS_LOADED:
      return { ...state, auditLogs: { list: action.auditLogs.data, total: action.auditLogs.total } }
    default:
      return state
  }
}
