import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_ADS, ADS_LOADED, ADS_FAILED } from '../actions/ad'
import adService from '../../services/ad'

// Generators
function * fetchAds (params) {
  try {
    const ads = yield call(() => adService.listByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: ADS_LOADED, ads })
  } catch (e) {
    yield put({ type: ADS_FAILED, message: e.message })
  }
}

function * watchAd () {
  yield takeEvery(FETCH_ADS, fetchAds)
}

export default watchAd
