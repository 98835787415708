import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatter } from '../../util'
// Actions
import { fetchChart, fetchSummary, fetchKeywords } from '../../states/actions'

// UI
import { Page } from '../../components'
import SmallCard from '../../components/SmallCard'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'

export class Dashboard extends Component {
  componentDidMount () {
    this.props.fetchSummary()
  }

  render () {
    const { summary } = this.props
    const { banners, collections, topBrands, topStores, sales } = summary

    return (
      <Page.Content>
        <Page.Header title='Dashboard' description='' />
        <Page.Body>
          <Row>
            <Col md={24}>
              <Row gutter={8}>
                <Col xs={12} sm={12} md={4} lg={4}>
                  <SmallCard label='Today Sales' value={formatter.toPrice(sales.today)} />
                </Col>

                <Col xs={12} sm={12} md={4} lg={4}>
                  <SmallCard label='Today Average Order' value={formatter.toPrice(sales.todayAverage)} />
                </Col>

                <Col xs={12} sm={12} md={4} lg={4}>
                  <SmallCard label='Lifetime Sales' value={formatter.toPrice(sales.total)} />
                </Col>

                <Col xs={12} sm={12} md={4} lg={4}>
                  <SmallCard label='Average Order' value={formatter.toPrice(sales.average)} />
                </Col>

                <Col xs={12} sm={12} md={4} lg={4}>
                  <Link to={{
                    pathname: '/home/stores'
                  }}>
                    <SmallCard label='Official Stores' value={topStores.active} total={topStores.total} />
                  </Link>
                </Col>

                <Col xs={12} sm={12} md={4} lg={4}>
                  <Link to={{
                    pathname: '/home/brands'
                  }}>
                    <SmallCard label='Top Brands' value={topBrands.active} total={topBrands.total} />
                  </Link>
                </Col>
              </Row>

              <Row style={{marginTop: '20px'}}>
                <Col sm={24} md={16} />
                <Col sm={24} md={8} style={{textAlign: 'center'}}>
                  <img alt='' src='img/screen.png' />
                </Col>
              </Row>
            </Col>
          </Row>
        </Page.Body>
      </Page.Content>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.Dashboard
})

export default connect(mapStateToProps, {
  fetchChart,
  fetchSummary,
  fetchKeywords
})(Dashboard)
