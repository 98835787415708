import { call, put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_CATEGORIES,
  CATEGORIES_LOADED,
  CATEGORIES_FAILED,
  FETCH_ALL_CATEGORIES,
  ALL_CATEGORIES_LOADED,
  ALL_CATEGORIES_FAILED,
  FETCH_WEB_CATEGORIES,
  WEB_CATEGORIES_LOADED,
  WEB_CATEGORIES_FAILED,
  FETCH_CATEGORIES_SLIDER,
  CATEGORIES_SLIDER_LOADED,
  CATEGORIES_SLIDER_FAILED
} from '../actions/category'
import categoryService from '../../services/category'

// Generators
function * fetchCategories (params) {
  try {
    const categories = yield call(() => categoryService.list())
    yield put({ type: CATEGORIES_LOADED, categories })
  } catch (e) {
    yield put({ type: CATEGORIES_FAILED, message: e.message })
  }
}

function * fetchAllCategories (params) {
  try {
    const categories = yield call(() => categoryService.listAll())
    yield put({ type: ALL_CATEGORIES_LOADED, categories })
  } catch (e) {
    yield put({ type: ALL_CATEGORIES_FAILED, message: e.message })
  }
}

function * fetchWebCategories (params) {
  try {
    const webCategories = yield call(() => categoryService.listWebByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: WEB_CATEGORIES_LOADED, webCategories })
  } catch (e) {
    yield put({ type: WEB_CATEGORIES_FAILED, message: e.message })
  }
}

function * fetchCategoriesSlider (params) {
  try {
    const categoriesSlider = yield call(() => categoryService.listSliderByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: CATEGORIES_SLIDER_LOADED, categoriesSlider })
  } catch (e) {
    yield put({ type: CATEGORIES_SLIDER_FAILED, message: e.message })
  }
}

function * watchCategories () {
  yield takeEvery(FETCH_CATEGORIES, fetchCategories)
  yield takeEvery(FETCH_ALL_CATEGORIES, fetchAllCategories)
  yield takeEvery(FETCH_WEB_CATEGORIES, fetchWebCategories)
  yield takeEvery(FETCH_CATEGORIES_SLIDER, fetchCategoriesSlider)
}

export default watchCategories
