import request from '../../util/request'

const url = '/api/button/configs'

export default {
  addButton (values) {
    return request(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  getButton (id) {
    return request(`${url}/${id}`)
  },
  removeButton (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  saveButton (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    })
  },
  listButtonsByLayoutId (lid) {
    return request(`${url}/layouts/${lid}`)
  }
}
