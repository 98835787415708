export const FETCH_CUSTOM_PAGE = 'FETCH_CUSTOM_PAGE'
export const CUSTOM_PAGE_LOADED = 'CUSTOM_PAGE_LOADED'
export const CUSTOM_PAGE_FAILED = 'CUSTOM_PAGE_FAILED'

export const fetchCustomPages = (page, size, filter, query) => {
  return {
    type: FETCH_CUSTOM_PAGE,
    page,
    size,
    filter,
    query
  }
}
