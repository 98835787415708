import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { authService, roleService } from '../../../services'
import { rolesFetched } from '../../../states/actions/role'
import { formatter, notification, sessionStorage } from '../../../util'

// UI
import { Button, List, ListIcon, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'

import './styles.css'
import role from '../../../services/role'

const filterKey = 'role.filter'
const pageKey = 'role.page'
const pageSize = 20

class Role extends Component {
  constructor (props) {
    super(props)
    const { roles: { data: list, total } } = props
    const currentPage = sessionStorage.getItem(pageKey)
    this.state = {
      currentPage: currentPage ? parseInt(currentPage, 10) : 1,
      filter: sessionStorage.getObject(filterKey),
      list,
      loading: false,
      searchText: '',
      total
    }
    this.findRoles = debounce(this.findRoles, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { roles: { data: list, total } } = nextProps
    return { ...prevState, list, total }
  }

  componentDidMount () {
    if (this.canList()) {
      const { currentPage, filter } = this.state
      this.fetchRoles({ currentPage, filter })
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { currentPage, list, loading, total } = this.state
    const columns = [
      {
        title: '',
        width: 1,
        render: (row) => <ListIcon file='admin.svg' name={row.name} />
      },
      {
        key: 'name',
        title: 'Name',
        width: 8
      },
      {
        key: 'remark',
        title: 'Remark',
        width: 7
      },
      {
        key: 'createdAt',
        title: 'Created At',
        width: 4,
        render: (row) => formatter.toStandardDate(row.createdAt)
      },
      {
        key: 'updatedAt',
        title: 'Updated At',
        width: 4,
        render: (row) => formatter.toStandardDate(row.updatedAt)
      }
    ]

    return (
      <Page.Content>
        <Page.Header title={`Roles (${total})`} description='Manage user roles & permissions' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Roles' onChange={this.findRoles} tabIndex={1} />
              </Col>

              <Col lg={18} style={{ textAlign: 'right' }}>
                {this.canAdd() ? (
                  <Link to='/settings/roles/add'>
                    <Button>Add Role</Button>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <List cols={columns} loading={loading} rows={list} link={this.canRead() ? '/settings/roles/<_id>' : ''} />
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} roles`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText } = this.state
    this.fetchRoles({ loading: true, currentPage, filter, searchText })
  }

  fetchRoles = async ({ loading = false, currentPage = 1, filter = {}, searchText }) => {
    try {
      const { rolesFetched, pristine } = this.props
      sessionStorage.setObject(filterKey, filter)
      sessionStorage.setItem(pageKey, currentPage)
      this.setState({ currentPage, loading: loading || pristine })
      const response = await roleService.listByPage(currentPage, pageSize, filter, searchText)
      this.setState({ list: response.data, loading: false, total: response.total })
      rolesFetched(response)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load roles successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  findRoles = (text) => {
    const { currentPage, filter, searchText } = this.state
    this.fetchRoles({ loading: true, currentPage: searchText !== text ? 1 : currentPage, filter, searchText: text })
    this.setState({ searchText: text })
  }

  hasAccess = (permission) => {
    return authService.hasAccess(permission)
  }

  canAdd = () => {
    return this.hasAccess('createRole')
  }

  canList = () => {
    return this.hasAccess('listRole')
  }

  canRead = () => {
    return this.hasAccess('readRole')
  }
}

const mapDispatchToProps = {
  rolesFetched
}

const mapStateToProps = (state) => {
  return { ...state.Role }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Role)
