import { BUTTON_LAYOUTS_LOADED } from '../../actions/button/layout'

const INITIAL_STATE = {
  layouts: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BUTTON_LAYOUTS_LOADED:
      return { ...state, layouts: { list: action.layouts.data, total: action.layouts.total } }
    default:
      return state
  }
}
