
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  //FETCH_CATEGORIES,
  //CATEGORIES_LOADED,
  //CATEGORIES_FAILED,
  //FETCH_ALL_CATEGORIES,
  //ALL_CATEGORIES_LOADED,
  //ALL_CATEGORIES_FAILED,
  //WEB_CATEGORIES_LOADED,
  //WEB_CATEGORIES_FAILED,
  FETCH_MINI_BANNER,
  MINI_BANNER_LOADED,
  MINI_BANNER_FAILED
} from '../actions/miniBanner'
import miniBannerService from '../../services/miniBanner'
// Generators
/*
function * fetchCategories (params) {
  try {
    const categories = yield call(() => categoryService.list())
    yield put({ type: CATEGORIES_LOADED, categories })
  } catch (e) {
    yield put({ type: CATEGORIES_FAILED, message: e.message })
  }
}
*/
/*
function * fetchAllCategories (params) {
  try {
    const categories = yield call(() => categoryService.listAll())
    yield put({ type: ALL_CATEGORIES_LOADED, categories })
  } catch (e) {
    yield put({ type: ALL_CATEGORIES_FAILED, message: e.message })
  }
}
*/
/*
function * fetchWebCategories (params) {
  try {
    const webCategories = yield call(() => categoryService.listWebByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: WEB_CATEGORIES_LOADED, webCategories })
  } catch (e) {
    yield put({ type: WEB_CATEGORIES_FAILED, message: e.message })
  }
}
*/

function * fetchMiniBanner (params) {
  try {
    const miniBanner = yield call(() => miniBannerService.listSliderByPage(params.page, params.size, params.filter, params.query))
    yield put({ type: MINI_BANNER_LOADED, miniBanner })
  } catch (e) {
    yield put({ type: MINI_BANNER_FAILED, message: e.message })
  }
}

function * watchMiniBanner () {
  //yield takeEvery(FETCH_CATEGORIES, fetchCategories)
  //yield takeEvery(FETCH_ALL_CATEGORIES, fetchAllCategories)
  yield takeEvery(FETCH_MINI_BANNER, fetchMiniBanner)
}

export default watchMiniBanner

