export const FETCH_INTRO = 'FETCH_INTRO'
export const INTRO_LOADED = 'INTRO_LOADED'
export const INTRO_FAILED = 'INTRO_FAILED'

export const fetchIntro = (page, size, filter, query) => {
  return {
    type: FETCH_INTRO,
    page,
    size,
    filter,
    query
  }
}
