import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { formatter, notification, sessionStorage } from '../../../util'
import { fetchMiniBanner } from '../../../states/actions'
import moment from 'moment'

// UI
import { Button, Page, Pager, SearchInput, Spin } from '../../../components'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const filterKey = 'miniBanner.filter'
const pageSize = 24
const Option = Select.Option

class MiniBanner extends Component {
  
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: sessionStorage.getObject(filterKey),
      list: [],
      loading: false,
      searchText: '',
      total: 0
    }
    this.fetchMiniBanner = debounce(this.fetchBanners, 500)
    
  }

  componentDidMount () {
    if (this.canList()) {
      this.fetchBanners()
    } else {
      authService.unauthorizeAction()
    }
  }

  render () {
    const { miniBanner } = this.props
    const { currentPage, filter, loading } = this.state
    const { list, total } = miniBanner
    const { active } = filter
    return (
      <Page.Content>
        <Page.Header title={`Mini Bannner (${total})`} description='Mini Banner in sequence (For WEB Only)' />

        <Page.Body>
          <Page.Filter>
            <Row gutter={8}>
              <Col lg={6}>
                <SearchInput placeholder='Search Mini Banners' onChange={(value) => this.findBanners(value)} />
              </Col>

              <Col lg={12}>
                <Row gutter={8}>
                  <Col lg={5}>
                    <Tooltip title='Visible'>
                      <Select
                        allowClear
                        defaultValue={typeof active === 'boolean' ? (active ? 'Yes' : 'No') : undefined}
                        disabled={loading}
                        onChange={(value) => this.changeActive(value)}
                        placeholder='Visible'
                        style={{ width: '100%' }}
                      >
                        <Option value='Yes'>Yes</Option>
                        <Option value='No'>No</Option>
                      </Select>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              <Col lg={6} style={{ textAlign: 'right' }}>
                {this.canAdd() ? (
                  <Link to='/home/mini-banner/add'>
                    <Tooltip title='Maximum 100 mini banners'>
                      <Button disabled={this.isMaxNumber()}>Add Banner</Button>
                    </Tooltip>
                  </Link>
                ) : null}
              </Col>
            </Row>
          </Page.Filter>

          <Row>
            <Col>
              <Spin loading={loading} blur>
                <div className='wd-banner-list'>
                  {list.map((item) => {
                    const { _id, active, name, image_thumb: thumbUrl = '', position, show_from: showFrom, show_to: showTo } = item
                    const Row = (props) => {
                      const valid = moment().isBetween(showFrom, showTo)
                      return (
                        <div className={formatter.toClassName(['wd-banner-card ' + (!valid ? ' wd-banner-card-dim' : ''), props.className])}>
                          <div className='wd-banner-active'>
                            <div className='wd-banner-active-badge' style={{ backgroundColor: active ? '#27CD7F' : '#C1C1C1' }} />
                          </div>

                          <img className='wd-banner-img' alt='' src={thumbUrl || '/img/default-image.png'} />

                          <div className='wd-banner-info'>
                            <div className='name'>{name}</div>

                            <div className='remark'>
                              <div className='order'>{ formatter.toShortDate(showFrom) } - { formatter.toShortDate(showTo) }</div>
                              <div className='order'><img className='wd-banner-order' alt='' src={'/icon/list-order.svg'} /> {position}</div>
                            </div>
                          </div>
                        </div>
                      )
                    }

                    return this.canRead() ? (
                      <Link key={_id} to={`/home/mini-banner/${_id}`}>
                        <Row />
                      </Link>
                    ) : (
                      <Row className='no-pointer' key={_id} />
                    )
                  })}
                </div>
              </Spin>
            </Col>
          </Row>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} mini banners`}
            current={currentPage}
            onChange={(page) => this.fetchBanners(page)}
            style={{ marginTop: '15px' }}
          />
        </Page.Body>
      </Page.Content>
    )
  }

  changeActive (value) {
    const { currentPage, filter, searchText } = this.state

    if (value === 'Yes') {
      filter.active = true
    } else if (value === 'No') {
      filter.active = false
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchMiniBanner(currentPage, searchText)
  }

  async fetchBanners (page = 1, text) {
    try {
      const { filter, searchText } = this.state
      sessionStorage.setObject(filterKey, filter)
      this.setState({ currentPage: page })
      this.props.fetchMiniBanner(page, pageSize, filter, typeof text === 'string' ? text : searchText)
    } catch (e) {
      notification.show('error', 'Unable to load successfully', 'Unable to load mini banners successfully. Please try again later.')
    }
  }

  findBanners (text) {
    const { currentPage, searchText } = this.state
    this.fetchMiniBanner(searchText !== text ? 1 : currentPage, text)
    this.setState({ searchText: text })
  }

  hasAccess (permission) {
    return authService.hasAccess(permission)
  }

  canAdd () {
    return this.hasAccess('createMiniBanner')
  }

  canList () {
    return this.hasAccess('listMiniBanner')
  }

  canRead () {
    return this.hasAccess('readMiniBanner')
  }

  isMaxNumber () {
    const { miniBanner } = this.props
    const { loading } = this.state
    const { total } = miniBanner

    if (loading) {
      return true
    }

    return total > 99
  }
}

const mapDispatchToProps = {
  fetchMiniBanner
}

const mapStateToProps = (state) => {
  return { ...state.MiniBanner}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiniBanner)
