export default [
  {
    value: '3',
    name: 'Full Block'
  },
  {
    value: '1.5,1.5',
    name: '1:1 Block'
  },
  {
    value: '1,2',
    name: '1:2 Block'
  },
  {
    value: '2,1',
    name: '2:1 Block'
  },
  {
    value: '1,1,1',
    name: '1:1:1 Block'
  }
]
