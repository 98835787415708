import { CUSTOM_PAGE_LOADED } from '../actions/custom'

const INITIAL_STATE = {
  customPages: { list: [], total: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOM_PAGE_LOADED:
      return { ...state, customPages: { list: action.pages.data, total: action.pages.total } }
    default:
      return state
  }
}
