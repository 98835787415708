export const FETCH_CAROUSELS = 'FETCH_CAROUSELS'
export const CAROUSELS_LOADED = 'CAROUSELS_LOADED'
export const CAROUSELS_FAILED = 'CAROUSELS_FAILED'

export const fetchCarousels = (page, size, filter, query) => {
  return {
    type: FETCH_CAROUSELS,
    page,
    size,
    filter,
    query
  }
}
