import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import authService from '../../../services/auth'

import './styles.css'

export default class Submenu extends Component {
  constructor () {
    super()
    this.state = {
      expand: false
    }
  }

  componentDidMount () {
    this.setState({ expand: window.location.pathname.indexOf(this.props.route.path) >= 0 || false })
  }

  render () {
    const { onChange, route } = this.props
    const { expand } = this.state
    const submenus = route.submenu.map((menu, index) => {
      return this.showMenu(route, menu) ? (
        <NavLink key={index} to={menu.path} className='Menu-subitem' activeClassName='Menu-subitem-selected' onClick={onChange}>
          <div className='Menu-subitem-label'>{menu.name} { menu.mark ? <span style={{ color: '#cc0000' }}>•</span> : ''}</div>
          <span className='Menu-subitem-tooltip'>{menu.name}</span>
        </NavLink>
      ) : null
    }).filter((submenu) => submenu)

    return (
      submenus.length > 0 ? (
        <div>
          <div className='Menu-item' onClick={() => { this.setState({expand: !expand}) }}>
            <img src={process.env.PUBLIC_URL + '/icon/' + route.icon} className='Menu-item-icon' alt={route.name} />
            <div className='Menu-item-label'>{route.name}</div>
            { route.submenu ? <img src={process.env.PUBLIC_URL + '/icon/' + (expand ? 'expand-less.svg' : 'expand-more.svg')} className='Menu-item-expand-icon' alt={route.name} /> : null }
          </div>
          <div className={expand ? 'Menu-subitem-visible' : 'Menu-subitem-hidden'}>{submenus}</div>
        </div>
      ) : null
    )
  }

  showMenu (route, menu) {
    const { permission } = menu

    if (permission) {
      return authService.hasAccess(permission)
    }

    return true
  }
}
