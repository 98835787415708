export default {
  DELETE: 'delete',
  INSERT: 'insert',
  UPDATE: 'update'
}

export const AuditActionsList = [
  { name: 'Add', value: 'insert' },
  { name: 'Modify', value: 'update' },
  { name: 'Delete', value: 'delete' }
]
